import { Col, Row, Skeleton } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDefinedTranslations } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { ContactPerson } from '../../types';

type Props = {
  reserver?: ContactPerson;
};
const ContactPersonDetails: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const definedTranslate = useDefinedTranslations();

  return props.reserver ? (
    <>
      <Row justify="space-between" align="middle" className="mb-3">
        <Col>{t(Translations.Common.Gender)} : </Col>
        <Col>
          <strong>{definedTranslate.gender(props.reserver.gender)}</strong>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" className="mb-3">
        <Col>{t(Translations.Common.FirstName)} :</Col>
        <Col>
          <strong>{props.reserver.firstName}</strong>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" className="mb-3">
        <Col>{t(Translations.Common.LastName)} : </Col>
        <Col>
          <strong>{props.reserver.lastName}</strong>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" className="mb-3">
        <Col>{t(Translations.Common.Email)} :</Col>
        <Col>
          <strong dir="ltr" className="en-font">
            {props.reserver.email}
          </strong>
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>{t(Translations.Common.PhoneNumber)} : </Col>
        <Col>
          <strong dir="ltr" className="en-font">
            {props.reserver.phoneNumber}
          </strong>
        </Col>
      </Row>
    </>
  ) : (
    <Skeleton active paragraph={{ rows: 1 }} />
  );
});

export default ContactPersonDetails;
