import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import ir from 'react-phone-input-2/lang/ir.json';

import { useAppSelector, useGlobalization } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { LanguageCode } from '../../../../features/localization/cultures';
import { ipLocationSelector } from '../..';

interface Props extends PhoneInputProps {
  useIpLocation?: boolean;
}
const PhoneInput: React.VFC<Props> = (props) => {
  const { t } = useTranslation();

  const { culture } = useGlobalization();

  const ipLocation = useAppSelector(ipLocationSelector);

  let defaultCountry = undefined;
  if (ipLocation && props.useIpLocation) {
    if (props.country === undefined) {
      defaultCountry = ipLocation.alpha2.toLowerCase();
    }
  }

  let phoneInputLanguage;
  switch (culture.LanguageCode) {
    case LanguageCode.Fa:
      phoneInputLanguage = ir;
      break;
    default:
      phoneInputLanguage = {};
      break;
  }

  return (
    <ReactPhoneInput
      {...props}
      containerClass="ltr-tel-input w-100"
      inputClass={'w-100'}
      localization={phoneInputLanguage}
      country={defaultCountry}
      searchPlaceholder={t(Translations.Common.Search)}
      enableSearch
    />
  );
};

export default PhoneInput;
