import { Checkbox, Tooltip } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../features/hooks';
import { useGlobalization } from '../../../../../../features/hooks';
import { Translations } from '../../../../../../features/localization';
import { useBookingTempFilters } from '../../../../../booking';
import { FilterItemTitle, FormattedCurrency } from '../../../../../shared';
import classes from './FlightsFilterAirline.module.scss';

type Props = {
  isPackage?: boolean;
};
const FlightsFilterAirline: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const { reversePositionOnRtl } = useGlobalization();

  const { filter } = useBookingTempFilters({ moduleType: props.isPackage ? 'Package' : 'Flight' });

  const marketingAirlines = useAppSelector((state) =>
    props.isPackage ? state.package.temp.packages.constants.flights.airlines : state.flight.temp.flights.constants.marketingAirlines
  );
  const filteredAirlines = useAppSelector((state) =>
    props.isPackage ? state.package.temp.packages.processing.filters.flights.airlines : state.flight.temp.flights.filters.airlines
  );

  const handleResetClicked = useCallback(() => {
    filter({ type: 'Airline', value: undefined });
  }, [filter]);

  const handleFilterChanged = (checkedValues: CheckboxValueType[]) => {
    const values = checkedValues as string[];
    filter({ type: 'Airline', value: values });
  };

  return (
    <>
      <FilterItemTitle
        title={t(Translations.Flight.Airline)}
        resetButtonVisibility={filteredAirlines && filteredAirlines.length > 0}
        onResetClick={handleResetClicked}
      />
      <Checkbox.Group onChange={handleFilterChanged} value={filteredAirlines} className={classes.airlineFilterHolder}>
        {marketingAirlines.map((item) => (
          <Checkbox key={item.airline.code} value={item.airline.code} className={classes.airlineFilterItem + ' ' + reversePositionOnRtl('left') + '-aligned'}>
            <div className={classes.airlineFilterInner}>
              <img src={item.airline.photoUrl} alt={item.airline.name} className={classes.airlineFilterIcon} />
              <div className={classes.airlineName}>
                <Tooltip title={item.airline.name}>{item.airline.name}</Tooltip>
              </div>
              <span className={classes.airlinePrice}>({<FormattedCurrency number={item.minPrice} currencyDisplay="code" />})</span>
            </div>
          </Checkbox>
        ))}
      </Checkbox.Group>
    </>
  );
});

export default FlightsFilterAirline;
