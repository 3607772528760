import { Divider, Popover, Tooltip } from 'antd';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { UserPermission } from '../../../../../../features/constants';
import { useAppSelector, useGlobalization, useHttpCall, useInterval, useMount, useNumberFormat, usePermissions } from '../../../../../../features/hooks';
import { Translations } from '../../../../../../features/localization';
import { currencySelector } from '../../../../../account';
import { paymentSlice } from '../../../../../payment';
import { GetDepositBalancesResponse } from '../../../../../payment/apiTypes';
import { getDepositBalancesHttp } from '../../../../../payment/http/payment-http';
import { FormattedCurrency } from '../../../../index';
import classes from './DepositBalances.module.scss';

const DepositBalances: React.VFC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { reversePositionOnRtl } = useGlobalization();
  const numberFormat = useNumberFormat();
  const { hasPermission } = usePermissions();

  const currency = useAppSelector(currencySelector);
  const depositBalances = useAppSelector((state) => state.payment.depositBalances);
  const depositBalancesLastModified = useAppSelector((state) => state.payment.depositBalancesLastModified);
  const [hasAnimationClass, setHasAnimationClass] = useState(false);

  const getDepositBalancesApi = useHttpCall(getDepositBalancesHttp);

  const currentCurrencyDepositBalance = useMemo(() => {
    return depositBalances?.find((f) => f.currencyType === currency)?.amount;
  }, [currency, depositBalances]);

  const depositBalancesPopoverContent = useMemo(() => {
    if (hasPermission(UserPermission.UseDeposit) && depositBalances) {
      return depositBalances.map((item, index) => (
        <React.Fragment key={item.currencyType}>
          <FormattedCurrency number={item.amount} currency={item.currencyType} currencyDisplay="code" />
          {depositBalances.length !== index + 1 ? <Divider dashed className="my-2" /> : undefined}
        </React.Fragment>
      ));
    }
    return undefined;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositBalances]);

  useMount(() => {
    getDepositBalances();
  });

  useInterval(() => {
    getDepositBalances();
  }, 300 * 1000);

  useEffect(() => {
    if (depositBalancesLastModified && depositBalancesLastModified.manual) {
      getDepositBalances();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositBalancesLastModified]);

  const getDepositBalances = () => {
    getDepositBalancesApi.call<GetDepositBalancesResponse>(undefined).then((response) => {
      if (response && response.success) {
        if (!isEqual(response.result, depositBalances)) {
          dispatch(paymentSlice.actions.setDepositBalances(response.result!));
          if (depositBalances) {
            setHasAnimationClass(true);
            setTimeout(() => {
              setHasAnimationClass(false);
            }, 3000);
          }
        }
      }
    });
  };

  return hasPermission(UserPermission.UseDeposit) && currentCurrencyDepositBalance ? (
    <Popover content={depositBalancesPopoverContent} placement="bottom">
      <Tooltip title={numberFormat.currency(currentCurrencyDepositBalance, currency, 'name')} placement={reversePositionOnRtl('left')}>
        <div className={clsx({ [classes.depositBalance]: true, [classes.animation]: hasAnimationClass })}>
          {t(Translations.Payment.DepositBalance)}:
          <span style={{ fontWeight: 'bold' }} className="font-weight-bold">
            <FormattedCurrency number={currentCurrencyDepositBalance} currency={currency} useDefaultHtmlDirection tooltip={{ show: false }} />
          </span>
        </div>
      </Tooltip>
    </Popover>
  ) : null;
};

export default DepositBalances;
