import { ApiDataItem } from '../../../types';

export const TicketingApiUrls = {
  Tenant: {
    Create: '/api/services/app/TenantTicketing/Create',
    AddTicketMessageByTicketId: '/api/services/app/TenantTicketing/AddTicketMessageByTicketId',
    GetAll: '/api/services/app/TenantTicketing/GetAll',
    Get: '/api/services/app/TenantTicketing/Get',
    UploadAttachment: '/api/services/app/TenantTicketing/UploadAttachment',
  },
};

export const TicketingApiDataMap: Map<string, ApiDataItem> = new Map();
