import { currencySelector } from '../../modules/account';
import { CurrencyDisplay } from '../../types';
import { useAppSelector, useGlobalization } from './index';

const useNumberFormat = () => {
  const currencyState = useAppSelector(currencySelector);
  const { culture } = useGlobalization();

  const simple = (number: number) => {
    return new Intl.NumberFormat().format(number);
  };

  const currency = (number: number, currency?: string, currencyDisplay?: CurrencyDisplay) => {
    const currencyType = currency ?? currencyState;
    return new Intl.NumberFormat(culture.Name, { style: 'currency', currency: currencyType, currencyDisplay: currencyDisplay }).format(number);
  };

  return { simple, currency };
};

export default useNumberFormat;
