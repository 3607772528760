import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useAntdValidation, useAppSelector, useHttpCall, useMount } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { fromQueryString, stringIsNullOrEmpty, toQueryString } from '../../../../../utils/helpers';
import { GetFlightSuppliersResponse, GetPNRDetailsRequest } from '../../../apiTypes';
import { flightGetSuppliersHttp } from '../../../http/flight-http';
import { flightSlice } from '../../../index';
import { flightApiHasTicketingTrueSuppliersSelector } from '../../../redux/flight-selectors';
import { FlightTicketingQueryString } from '../../../types';

type Props = {
  isInline?: boolean;
  hideSupplier?: boolean;
};
type FormValues = GetPNRDetailsRequest;

const FlightTicketingSearchForm: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryStringObj = fromQueryString(location.search) as FlightTicketingQueryString;

  const suppliers = useAppSelector(flightApiHasTicketingTrueSuppliersSelector);
  const pending = useAppSelector((state) => state.flight.temp.ticketing.pending);

  const getSuppliersApi = useHttpCall(flightGetSuppliersHttp);

  const [form] = Form.useForm<FormValues>();
  const { labelWithRules } = useAntdValidation(form);

  useMount(() => {
    if (!suppliers) {
      getSuppliersApi.call<GetFlightSuppliersResponse>({ hasTicketing: true }).then((response) => {
        if (response && response.success) {
          dispatch(flightSlice.actions.setApiHasTicketingTrueSuppliers(response.result!.supplierList));
        }
      });
    }
  });

  useEffect(() => {
    if (queryStringObj.pnr && queryStringObj.supplier) {
      form.setFieldsValue({ PNRCode: queryStringObj.pnr, SupplierId: queryStringObj.supplier });
    }
  }, [form, location.search, queryStringObj.pnr, queryStringObj.supplier]);

  const handleSubmit = (values: FormValues) => {
    const queryString = toQueryString({
      pnr: values.PNRCode,
      supplier: !stringIsNullOrEmpty(values.SupplierId) ? values.SupplierId : queryStringObj.supplier,
      date: Date.now(),
    } as FlightTicketingQueryString);
    history.push(`/flights/ticketing?${queryString}`);
  };

  return (
    <Spin spinning={getSuppliersApi.pending}>
      <Form form={form} labelCol={{ span: 24 }} onFinish={handleSubmit} className={'flight-ticketing-search-form'}>
        <Row gutter={15}>
          <Col lg={props.isInline ? 5 : 12} sm={9} xs={24}>
            <Form.Item
              name="PNRCode"
              className="mb-0"
              {...labelWithRules({ label: t(Translations.Flight.PNRCode), rules: [{ type: 'Required' }, { type: 'EnglishLetterAndNumbersOnly' }] })}>
              <Input />
            </Form.Item>
          </Col>
          {!props.hideSupplier && (
            <Col lg={props.isInline ? 5 : 12} sm={9} xs={24}>
              <Form.Item name="SupplierId" className="mb-0" {...labelWithRules({ label: t(Translations.Booking.Supplier), rules: [{ type: 'Required' }] })}>
                <Select allowClear>
                  {suppliers
                    ?.filter((f) => f.hasTicketing)
                    .map((item) => (
                      <Select.Option key={item.supplierId} value={item.supplierName}>
                        {item.supplierName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col lg={props.isInline ? 4 : 24} sm={6} xs={24}>
            <div className="align-with-formItems-sm align-with-formItems-lg align-with-formItems-xl">
              <Button type="primary" htmlType="submit" loading={pending} className="mt-4 mt-sm-0">
                {t(Translations.Common.GetInformation)}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
});

export default FlightTicketingSearchForm;
