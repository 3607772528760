import { EyeInvisibleOutlined, EyeTwoTone, KeyOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { LocalStorageKey } from '../../../features/constants';
import { useAntdValidation, useAppSelector } from '../../../features/hooks';
import { Tenant } from '../../../types';
import { Env } from '../../../utils/env';
import { getJsonStorage, isDevelopment } from '../../../utils/helpers';
import { AbpErrorAlert } from '../../shared';
import { loginSaga } from '../redux/authentication-sagas';

type FormValues = Readonly<{
  tenant: string;
  username: string;
  password: string;
}>;

const Login: React.VFC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const forgotPassword = useAppSelector((state) => state.authentication.forgotPasswordSentSwitch);
  const loginApiState = useAppSelector((state) => state.authentication.loginApi);
  const verifyTenantApiState = useAppSelector((state) => state.authentication.verifyTenantApi);

  const [formInstance] = Form.useForm();
  const { labelWithRules } = useAntdValidation(formInstance);

  // tenants.length > 0 ? tenants[0].name : ''

  const initialValues: FormValues = {
    tenant: (getJsonStorage(LocalStorageKey.CurrentTenant) as Tenant)?.name ?? '',
    username: '',
    password: '',
  };

  const handleSubmit = (values: FormValues) => {
    dispatch(loginSaga({ tenant: values.tenant, username: values.username, password: values.password, history: history }));
  };

  return (
    <>
      <div className="w-100 max-w-400">
        {forgotPassword && <Alert variant="info">Please check your email to reset your password.</Alert>}
        <AbpErrorAlert error={loginApiState.error} />
        <AbpErrorAlert error={verifyTenantApiState.error} />
        <Form form={formInstance} initialValues={initialValues} onFinish={handleSubmit} layout="vertical">
          <Form.Item className="white-label" name="tenant" {...labelWithRules({ label: 'Office Id', rules: [{ type: 'Required' }] })}>
            <Input type="text" placeholder="Office Id" size={Env.TenantHost === 'ProTraveling' ? 'middle' : 'large'} />
          </Form.Item>
          <Form.Item className="white-label" name="username" {...labelWithRules({ label: 'Username', rules: [{ type: 'Required' }] })}>
            <Input prefix={<UserOutlined />} type={'email'} placeholder="Enter Your Username" size={Env.TenantHost === 'ProTraveling' ? 'middle' : 'large'} />
          </Form.Item>
          <Form.Item className="white-label" name="password" {...labelWithRules({ label: 'Password', rules: [{ type: 'Required' }] })}>
            <Input.Password
              prefix={<KeyOutlined />}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              placeholder="Enter Your Password"
              size={Env.TenantHost === 'ProTraveling' ? 'middle' : 'large'}
            />
          </Form.Item>
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <Button
              className={`${
                Env.TenantHost === 'AyanAir' || Env.TenantHost === 'BarreTravel'
                  ? 'text-white'
                  : Env.TenantHost === 'ProTraveling'
                  ? 'pro-orange-btn'
                  : 'antd-gold6-btn'
              }`}
              htmlType="submit"
              type={Env.TenantHost === 'AyanAir' || Env.TenantHost === 'BarreTravel' ? 'ghost' : 'primary'}
              loading={loginApiState.pending || verifyTenantApiState.pending}
              size={Env.TenantHost === 'ProTraveling' ? 'middle' : 'large'}>
              Sign In
            </Button>
            {isDevelopment() && (
              <Button
                htmlType="button"
                type="dashed"
                size={Env.TenantHost === 'ProTraveling' ? 'middle' : 'large'}
                onClick={() => {
                  localStorage.clear();
                  window.location.reload();
                }}>
                Reset App
              </Button>
            )}
            {Env.TenantHost === 'AyanAir' || Env.TenantHost === 'BarreTravel' || Env.TenantHost === 'ProTraveling' || (
              <Link to={'/auth/signUp'} className="text-light">
                SignUp
              </Link>
            )}
          </div>
        </Form>
      </div>
    </>
  );
};

export default Login;
