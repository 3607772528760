import { RootState } from '../../../features/redux';

export const flightSuppliersSelector = (state: RootState) => {
  return state.flight.suppliers;
};

export const flightApiHasTicketingTrueSuppliersSelector = (state: RootState) => {
  return state.flight.suppliersApiHasTicketingTrue;
};

export const tempItinerariesSummarySelector = (state: RootState) => {
  return state.flight.temp.flights.constants.itinerariesSummary;
};

export const tempFlightsSortSelector = (state: RootState) => {
  return state.flight.temp.flights.sort;
};
