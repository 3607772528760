import clsx from 'clsx';
import React, { useMemo } from 'react';

import { useGlobalization } from '../../../../features/hooks';
import { dateStringToMoment, isEqualDate, momentToISOUtcDate } from '../../../../utils/helpers';
import { FlightCalendarRouteItem, MarketingAirline } from '../../../flight/apiTypes';
import PriceCalendarItem from './PriceCalendarItem';

type Props = {
  current: {
    airline?: MarketingAirline;
    date: string;
    fare: number;
    currency: string;
  };
  items: FlightCalendarRouteItem[];
};
const PriceCalendarSingle: React.VFC<Props> = React.memo((props: Props) => {
  const { formatShortMonthWeekDay } = useGlobalization();

  const days = useMemo(() => {
    const moment = dateStringToMoment(props.current.date)!;

    return [-3, -2, -1, 0, 1, 2, 3].map((item) => {
      const clone = moment.clone();
      return clone.add(item, 'day');
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.current.date]);

  return (
    <table className={'price-calendar'}>
      <thead>
        <tr>
          {days.map((day) => (
            <th key={day.toDate().toString()} className="price-calendar-th">
              {formatShortMonthWeekDay(day.toDate().toString())}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {days.map((day) => {
            const item = props.items.find((f) => isEqualDate(f.departureDate, momentToISOUtcDate(day)!, 'day'));
            return (
              <td key={day.toDate().toString()} className={'price-calendar-td' + clsx({ 'price-calendar-selected': false })}>
                {item ? <PriceCalendarItem fare={item.fare} currency={item.currency} /> : '---'}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
});

export default PriceCalendarSingle;
