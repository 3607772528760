import { Checkbox, Tooltip } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useGlobalization } from '../../../../../../features/hooks';
import { Translations } from '../../../../../../features/localization';
import { useBookingTempFilters } from '../../../../../booking';
import { FilterItemTitle } from '../../../../../shared';
import classes from '../FlightFilter.module.scss';

type Props = {
  isPackage?: boolean;
};
const FlightsFilterAirports: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const { reversePositionOnRtl } = useGlobalization();

  const { filter } = useBookingTempFilters({ moduleType: props.isPackage ? 'Package' : 'Flight' });

  const airports = useAppSelector((state) =>
    props.isPackage ? state.package.temp.packages.constants.flights.airports : state.flight.temp.flights.constants.airports
  );
  const filteredAirports = useAppSelector((state) =>
    props.isPackage ? state.package.temp.packages.processing.filters.flights.airports : state.flight.temp.flights.filters.airports
  );

  const handleResetClicked = useCallback(() => {
    filter({ type: 'Airport', value: undefined });
  }, [filter]);

  const handleFilterChanged = (checkedValues: CheckboxValueType[]) => {
    const values = checkedValues as string[];
    filter({ type: 'Airport', value: values });
  };

  return (
    <>
      <FilterItemTitle
        title={t(Translations.Flight.Airports)}
        resetButtonVisibility={filteredAirports && filteredAirports.length > 0}
        onResetClick={handleResetClicked}
      />
      <Checkbox.Group onChange={handleFilterChanged} value={filteredAirports} className="w-100">
        {airports.map((item) => (
          <Checkbox key={item.locationCode} value={item.locationCode} className={classes.airportFilterItem + ' ' + reversePositionOnRtl('left') + '-aligned'}>
            <div className={`pt-1 ${classes.airportFilterInner}`}>
              <div className={classes.airportName}>
                <Tooltip title={item.locationName}>{item.locationName}</Tooltip>
              </div>
              <span className={classes.airportPrice}>({item.locationCode})</span>
            </div>
          </Checkbox>
        ))}
      </Checkbox.Group>
    </>
  );
});

export default FlightsFilterAirports;
