import { BookingModuleType } from '../../booking/types';

export const getBaseRouteByModule = (bookingModule: BookingModuleType) => {
  switch (bookingModule) {
    case 'Hotel':
      return 'hotel';
    case 'Flight':
      return 'flights';
    case 'Package':
      return 'packages';
    case 'DomesticHotel':
      return 'domestic/hotel';
    case 'DomesticFlight':
      return 'domestic/flights';
  }
};
