/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import { UserPermission } from '../../../../../features/constants';
import { usePermissions } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { useDomesticModules } from '../../../../../modules/booking';
import { isSectionEnabled } from '../../../../../modules/shared/TenantHostConfig';
import { isProduction } from '../../../../../utils/helpers';
import { checkIsActive } from '../../../../_helpers';

type MenuItem = {
  relativePosition: boolean;
  title: string;
  url: string;
  iconKeyFontawesome?: string;
  hide?: boolean;
  permissions?: UserPermission[];
};
export const HeaderMenu = React.memo(({ layoutProps }: any) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { hasPermissions } = usePermissions();
  const { isEnabled: domesticFlightEnabled } = useDomesticModules('FlightDomestic');
  const { isEnabled: domesticHotelEnabled } = useDomesticModules('HotelDomestic');

  const getMenuItemActive = (url: string) => {
    return checkIsActive(location, url) ? 'menu-item-active' : '';
  };

  const reservesMenu = useMemo<readonly MenuItem[]>(() => {
    return [
      {
        relativePosition: false,
        title: t(Translations.Common.Hotel),
        url: '/hotel/reserves',
        iconKeyFontawesome: 'hotel',
        hide: !isSectionEnabled('Hotel'),
      },
      {
        relativePosition: false,
        title: t(Translations.Common.Flight),
        url: '/flights/reserves',
        iconKeyFontawesome: 'plane',
      },
      {
        relativePosition: false,
        title: t(Translations.Common.Package),
        url: '/package/reserves',
        iconKeyFontawesome: 'box',
        hide: !isSectionEnabled('Package'),
      },
      {
        relativePosition: false,
        title: t(Translations.Flight.DomesticFlight),
        url: '/domesticFlights/reserves',
        iconKeyFontawesome: 'plane',
        hide: !domesticFlightEnabled,
      },
      {
        relativePosition: false,
        title: t(Translations.Common.DomesticHotel),
        url: '/domestic/hotels/reserves',
        iconKeyFontawesome: 'hotel',
        hide: !domesticHotelEnabled,
      },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuItem = (menuItem: MenuItem) => {
    let pass: boolean;
    if (menuItem.permissions) {
      pass = hasPermissions(menuItem.permissions);
    }
    pass = !menuItem.hide;

    if (pass) {
      const relativePositionClassName = menuItem.relativePosition ? 'menu-item-rel' : undefined;
      return (
        <li key={menuItem.title} className={`menu-item ${relativePositionClassName} ${getMenuItemActive(menuItem.url)}`}>
          <NavLink className="menu-link" to={menuItem.url}>
            {menuItem.iconKeyFontawesome && (
              <span className="menu-icon">
                <i className={`fas fa-${menuItem.iconKeyFontawesome}`} />
              </span>
            )}
            <span className="menu-text">{menuItem.title}</span>
          </NavLink>
        </li>
      );
    }
    return undefined;
  };

  return (
    <div id="kt_header_menu" className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`} {...layoutProps.headerMenuAttributes}>
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-rel ${getMenuItemActive('/home')}`}>
          <NavLink className="menu-link" to="/">
            <span className="menu-text">{t(Translations.Menu.Home)}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {menuItem({
          relativePosition: true,
          url: '/payment/transactions',
          permissions: [UserPermission.ViewTransactions],
          title: t(Translations.Menu.Transactions),
        })}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {menuItem({
          relativePosition: true,
          url: '/payment/chargeDeposit',
          permissions: [UserPermission.ChargeDeposit],
          title: t(Translations.Menu.ChargeDeposit),
          hide: !isSectionEnabled('ChargeDeposit'),
        })}
        {/*end::1 Level*/}

        {/*Classic submenu*/}
        {/*begin::1 Level*/}
        <li data-menu-toggle={layoutProps.menuDesktopToggle} aria-haspopup="true" className={`menu-item menu-item-submenu menu-item-rel`}>
          <div className="menu-link menu-toggle">
            <span className="menu-text">{t(Translations.Booking.Reserves)}</span>
            <i className="menu-arrow" />
          </div>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">{reservesMenu.map(menuItem)}</ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {menuItem({
          relativePosition: true,
          url: '/ticketing',
          title: t(Translations.Ticketing.Ticketing),
          hide: isProduction(),
        })}
        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
});
