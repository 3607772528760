import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

import classes from './QuantityInput.module.scss';

type Props = {
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  small?: boolean;
};
const QuantityInput: React.FC<Props> = (props) => {
  const [value, setValue] = useState<number>(props.min != undefined ? props.min : 1);

  const decrease = () => {
    if (props.min == null || value > props.min) {
      setValue((prevValue) => prevValue - 1);
    }
  };

  const increase = () => {
    if (props.max == null || value < props.max) {
      setValue((prevValue) => prevValue + 1);
    }
  };
  useEffect(() => {
    props.onChange(value);
  }, [value]);

  return (
    <span className={`${classes.wrapper} ${props.small ? classes.small : ''}`}>
      <MinusOutlined className={`${classes.decrease} ${props.min != null && value <= props.min ? classes.disabled : ''}`} onClick={decrease} />
      <input type="text" readOnly value={value} />
      <PlusOutlined className={`${classes.increase} ${props.max != null && value >= props.max ? classes.disabled : ''}`} onClick={increase} />
    </span>
  );
};

export default QuantityInput;
