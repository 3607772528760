import { ApiDataItem } from '../../../types';

export enum CoordinatorApiUrls {
  //Tenant Orders
  GetAllTenantOrders = '/api/services/app/TenantOrder/GetAll',

  //Terminal
  GetAffiliate = '/api/services/app/Terminal/GetAffiliate',
}

export const CoordinatorApiDataMap: Map<string, ApiDataItem> = new Map();
