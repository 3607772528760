import React from 'react';

import classes from './LoadingDots.module.scss';

const LoadingDots: React.FC = React.memo(() => {
  return (
    <div className={classes.loading}>
      {[0, 1, 2, 3, 4, 5, 6].map((item) => (
        <span className={classes.item} key={item} />
      ))}
    </div>
  );
});
export default LoadingDots;
