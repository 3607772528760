import 'react-multi-date-picker/styles/colors/green.css';
import 'react-multi-date-picker/styles/colors/red.css';
import 'react-multi-date-picker/styles/colors/purple.css';

import { Button } from 'antd';
import clsx from 'clsx';
import moment from 'moment-jalaali';
import React, { useMemo, useRef, useState } from 'react';
import { Calendar } from 'react-date-object';
import gregorianCalendar from 'react-date-object/calendars/gregorian';
import persianCalendar from 'react-date-object/calendars/persian';
import gregorian_en from 'react-date-object/locales/gregorian_en';
import persian_fa from 'react-date-object/locales/persian_fa';
import opacity from 'react-element-popper/animations/opacity';
import transition from 'react-element-popper/animations/transition';
import { useTranslation } from 'react-i18next';
import MultiDatePicker, { CalendarProps, DateObject, DatePickerProps } from 'react-multi-date-picker';

import { useGlobalization } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { getToday, momentToISOUtcDate } from '../../../../utils/helpers';

const persianWeekDays = ['ش', 'ی', 'د', 'س', 'چ', 'پ', 'ج'];

type Props = {
  value?: string | string[];
  onChange?: (value: string | string[] | undefined) => void;
  minDate?: string;
  maxDate?: string;
  mode?: 'single' | 'multiple' | 'range';
  color?: 'green' | 'red' | 'purple';
  initialCalendar?: 'persian' | 'gregorian';
  disabled?: boolean;
  originalProps?: CalendarProps & DatePickerProps;
  placeholder?: string;
  hideTodayButton?: boolean;
};
const PDatePicker2: React.VFC<Props> = React.memo((props: Props) => {
  const { t } = useTranslation();
  const { reversePositionOnRtl } = useGlobalization();

  const value = useMemo(() => {
    if (props.value) {
      if (Array.isArray(props.value)) {
        return (props.value as string[]).map((item) => new DateObject(item));
      } else {
        return new DateObject(props.value as string);
      }
    } else {
      return undefined;
    }
  }, [props.value]);

  const [calendar, setCalendar] = useState<Calendar>(props.initialCalendar === 'persian' ? persianCalendar : gregorianCalendar);

  const datePickerRef = useRef<any>();

  const handleChanged = (selectedDates: DateObject | DateObject[]) => {
    if (selectedDates && props.onChange) {
      if (Array.isArray(selectedDates)) {
        selectedDates.forEach((date) => {
          if (!date.isValid) {
            console.warn('Date is not valid', selectedDates);
            return;
          }
        });
        if (selectedDates.length > 1) {
          const dates = (selectedDates as DateObject[]).map((item) => momentToISOUtcDate(moment(item.toDate()))!);
          props.onChange(dates);
        }
      } else {
        if (selectedDates.isValid) {
          props.onChange(momentToISOUtcDate(moment((selectedDates as DateObject).toDate())));
        } else {
          console.warn('Date is not valid', selectedDates);
        }
      }
    }

    if (props.mode === 'single' || (Array.isArray(selectedDates) && selectedDates.length > 1)) {
      datePickerRef.current!.closeCalendar();
    }
  };

  const handleClear = () => {
    props.onChange?.(undefined);
  };

  const handleToggleCalendar = () => {
    if (calendar === persianCalendar) {
      setCalendar(gregorianCalendar);
    } else {
      setCalendar(persianCalendar);
    }
  };

  const handleSetToday = () => {
    props.onChange?.(props.mode !== 'single' ? [getToday()] : getToday());
  };

  return (
    <MultiDatePicker
      inputMode="none"
      containerClassName={`no-font-variant ${clsx({ english: calendar === gregorianCalendar })}`}
      inputClass="ant-input no-font-variant"
      animations={[opacity(), transition({ from: 15, duration: 150 })]}
      value={value}
      onChange={handleChanged}
      calendar={calendar}
      placeholder={props.placeholder}
      locale={calendar === persianCalendar ? persian_fa : gregorian_en}
      minDate={props.minDate}
      maxDate={props.maxDate}
      range={props.mode === 'range'}
      numberOfMonths={props.mode === 'range' ? 2 : 1}
      weekDays={calendar === persianCalendar ? persianWeekDays : undefined}
      disabled={props.disabled}
      className={props.color}
      ref={datePickerRef}
      style={{
        width: '100%',
        boxSizing: 'border-box',
      }}
      containerStyle={{
        width: '100%',
      }}
      calendarPosition={`bottom-${reversePositionOnRtl('left')}`}
      {...props.originalProps}>
      <div className="persian multi-datepicker-footer">
        {!props.hideTodayButton && (
          <Button type="link" style={{ margin: '5px' }} onClick={handleSetToday}>
            {t(Translations.Common.Today)}
          </Button>
        )}
        <Button className="mx-3" type={'primary'} onClick={handleToggleCalendar}>
          {calendar === persianCalendar ? t(Translations.Common.Gregorian) : t(Translations.Common.Shamsi)}
        </Button>
        <Button type="link" danger className="mx-3" onClick={handleClear}>
          {t(Translations.Common.Clear)}
        </Button>
      </div>
    </MultiDatePicker>
  );
});

PDatePicker2.defaultProps = {
  mode: 'single',
};

export default PDatePicker2;
