import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import imageError from '../../../../assets/Error.svg';
import { useMount } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { sharedSlice } from '../../index';

const RenderError: React.VFC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(sharedSlice.actions.setDisplayPath(null));
  });

  function handleBackToHome() {
    localStorage.clear();
    window.location.href = process.env.REACT_APP_AppAddress;
  }

  return (
    <Row className="justify-content-md-center main-height-full">
      <Col md={{ span: 5 }} className="text-center my-auto">
        <img src={imageError} alt="unauthorized" className="mb-2" />
        <p className="mb-2 text-purple">{t(Translations.Common.WeAreWorkingToSolveTheProblemSorryForTheInconvenience)}</p>
        <p className="mb-9 text-info">{t(Translations.Common.BrowserCacheErrorDescription)}</p>

        <Button variant="primary" type="button" className="min-width-md">
          <span className="fas fa-home mx-2" />
          <span className="mx-2" onClick={handleBackToHome}>
            {t(Translations.Common.BackToHome)}
          </span>
        </Button>
      </Col>
    </Row>
  );
};
export default RenderError;
