import { Button } from 'antd';
import objectPath from 'object-path';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import AyanAirLogo from '../../../../../assets/ayanAir-logo.svg';
import BarreTravelLogo from '../../../../../assets/barre-travel/barretravel.png';
import itoursLogo from '../../../../../assets/itours-logo.svg';
import ProTravelingLogo from '../../../../../assets/proTraveling-logo.svg';
import { Env } from '../../../../../utils/env';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import { HeaderMenu } from './HeaderMenu';

export function HeaderMenuWrapper() {
  const uiService = useHtmlClassService();

  const [responsiveMenuOpen, setResponsiveMenuOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setResponsiveMenuOpen(false);
  }, [location.pathname]);

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      ktMenuClasses: uiService.getClasses('header_menu', true),
      rootArrowEnabled: objectPath.get(uiService.config, 'header.menu.self.root-arrow'),
      menuDesktopToggle: objectPath.get(uiService.config, 'header.menu.desktop.toggle'),
      headerMenuAttributes: uiService.getAttributes('header_menu'),
      headerSelfTheme: objectPath.get(uiService.config, 'header.self.theme'),
      ulClasses: uiService.getClasses('header_menu_nav', true),
      disabledAsideSelfDisplay: objectPath.get(uiService.config, 'aside.self.display') === false,
    };
  }, [uiService]);
  // const getHeaderLogo = () => {
  //   let result = 'logo-light.png';
  //   if (layoutProps.headerSelfTheme && layoutProps.headerSelfTheme !== 'dark') {
  //     result = 'logo-dark.png';
  //   }
  //   return toAbsoluteUrl(`/media/logos/${result}`);
  // };

  return (
    <>
      <Button
        htmlType="button"
        className="position-fixed panel-mobile-menu-btn"
        onClick={() => {
          setResponsiveMenuOpen(true);
        }}>
        <span className="fas fa-bars" />
      </Button>
      {!!responsiveMenuOpen && (
        <div
          className="responsive-menu-ovellay"
          onClick={() => {
            setResponsiveMenuOpen(false);
          }}
        />
      )}

      {/*begin::Header Menu Wrapper*/}
      <div className={`header-menu-wrapper header-menu-wrapper-left ${responsiveMenuOpen ? 'isOpen' : ''}`} id="kt_header_menu_wrapper">
        {layoutProps.disabledAsideSelfDisplay && (
          <>
            <Button
              htmlType="button"
              className="panel-mobile-menu-btn ml-4 mb-5"
              onClick={() => {
                setResponsiveMenuOpen(false);
              }}>
              <span className="fas fa-times" />
            </Button>

            {/*begin::Header Logo*/}
            <div className="header-logo">
              <Link to="/">
                <img
                  alt="logo"
                  src={
                    Env.TenantHost === 'AyanAir'
                      ? AyanAirLogo
                      : Env.TenantHost === 'BarreTravel'
                      ? BarreTravelLogo
                      : Env.TenantHost === 'ProTraveling'
                      ? ProTravelingLogo
                      : itoursLogo
                  }
                  className={'itours-header-logo'}
                />
              </Link>
            </div>
            {/*end::Header Logo*/}
          </>
        )}
        {/*begin::Header Menu*/}
        <HeaderMenu layoutProps={layoutProps} />
        {/*end::Header Menu*/}
      </div>
      {/*Header Menu Wrapper*/}
    </>
  );
}
