import React from 'react';

import { DefinedRoute } from '../../types';

const prefix = '/hotel';

const HotelRoutes = (): readonly DefinedRoute[] => [
  {
    type: 'Route',
    path: '/hotels',
    children: React.lazy(() => import('./pages/Hotels/Hotels')),
    exact: true,
  },
  {
    type: 'Route',
    path: `${prefix}/reserves`,
    children: React.lazy(() => import('./pages/HotelsReserves/HotelsReserves')),
    exact: true,
  },
  {
    type: 'Route',
    path: `${prefix}/reserves/reserve`,
    children: React.lazy(() => import('./pages/HotelReserveDetails/HotelReserveDetails')),
    exact: true,
  },
  {
    type: 'Route',
    path: `${prefix}/:hotelId`,
    children: React.lazy(() => import('./pages/HotelDetails/HotelDetails')),
    exact: true,
  },
  {
    type: 'Route',
    path: `${prefix}/prereserve/:preReserveKey`,
    children: React.lazy(() => import('./pages/HotelPreReserve/HotelPreReserve')),
    exact: true,
  },
  {
    type: 'Route',
    path: `${prefix}/payment/confirm`,
    children: React.lazy(() => import('./pages/HotelPaymentConfirm/HotelPaymentConfirm')),
    exact: true,
  },
];

export default HotelRoutes;
