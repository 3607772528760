import { ApiDataItem } from '../../../types';

export enum DomesticFlightApiUrls {
  GetAirport = '/api/services/app/Airport/Search',
  GetKeyAvailability = '/api/services/app/BookingFlight/Availability',
  GetFlights = '/api/services/app/BookingFlight/GetAvailability',
  BookingFlightValidate = '/api/services/app/BookingFlight/Validate',
  GetValidate = '/api/services/app/BookingFlight/GetValidate',
  PreReserve = '/api/services/app/BookingFlight/PreReserve',
  ConfirmReserve = '/api/services/app/BookingFlight/Confirm',
  GetReserve = '/api/services/app/TenantReserve/Get',
  GetReserveAll = '/api/services/app/TenantReserve/GetAll',
}

export const DomesticFlightApiDataMap: Map<string, ApiDataItem> = new Map();
