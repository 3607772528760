import { Card, Checkbox, Col, Row, Skeleton } from 'antd';
import clsx from 'clsx';
import minBy from 'lodash/minBy';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useGlobalization } from '../../../../../../features/hooks';
import { Translations } from '../../../../../../features/localization';
import { useBookingTempFilters } from '../../../../../booking';
import { FormattedCurrency } from '../../../../../shared';
import { FlightItinerarySummary } from '../../../../types';
import classes from './FlightsAirlineTopFilter.module.scss';

type PriceCheckBoxComponentProps = {
  price?: number;
  airlineCode: string;
  direct: boolean;
};
const PriceCheckBoxComponent: React.VFC<PriceCheckBoxComponentProps> = React.memo((props) => {
  return props.price ? (
    <Checkbox
      className={clsx({ [classes.customCheckbox]: true, [classes.grayBg]: props.direct, [classes.blueBg]: !props.direct })}
      value={`${props.airlineCode}%%${props.direct ? 0 : 1}`}>
      <FormattedCurrency number={props.price} currencyDisplay="code" tooltip={{ show: false }} />
    </Checkbox>
  ) : (
    <div className={clsx({ [classes.noValue]: true, [classes.grayBg]: props.direct, [classes.blueBg]: !props.direct })}> - </div>
  );
});

type Props = {
  isPackage?: boolean;
};
const FlightsAirlineTopFilter: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const { reversePositionOnRtl } = useGlobalization();

  const { filter } = useBookingTempFilters({ moduleType: props.isPackage ? 'Package' : 'Flight' });

  const itinerarySummaries = useAppSelector((state) =>
    props.isPackage ? state.package.temp.packages.response?.flights : state.flight.temp.flights.constants.itinerariesSummary
  );
  const airlines = useAppSelector((state) =>
    props.isPackage ? state.package.temp.packages.constants.flights.airlines : state.flight.temp.flights.constants.marketingAirlines
  );
  const pending = useAppSelector((state) =>
    props.isPackage ? !state.package.temp.packages.response?.isFlightCompleted : state.flight.temp.flights.apiPending
  );

  const airlinesHolderDiv = useRef<HTMLDivElement>(null);
  const leftShadowDiv = useRef<HTMLDivElement>(null);
  const rightShadowDiv = useRef<HTMLDivElement>(null);

  const scrollEffect = () => {
    const xPos = Math.abs(airlinesHolderDiv.current!.scrollLeft);
    const filtersWidth = airlinesHolderDiv.current!.offsetWidth;

    if (xPos < airlinesHolderDiv.current!.scrollWidth - filtersWidth) {
      rightShadowDiv.current!.style.opacity = '1';
      if (xPos === 0) {
        leftShadowDiv.current!.style.opacity = '0';
      } else {
        leftShadowDiv.current!.style.opacity = '1';
      }
    } else if (airlinesHolderDiv.current!.scrollWidth - filtersWidth >= 0) {
      rightShadowDiv.current!.style.opacity = '0';
      if (xPos > 0) {
        leftShadowDiv.current!.style.opacity = '1';
      } else {
        leftShadowDiv.current!.style.opacity = '0';
      }
    } else {
      leftShadowDiv.current!.style.opacity = '0';
      rightShadowDiv.current!.style.opacity = '0';
    }
  };

  const getStopsMinPrice = (airlineFlights: FlightItinerarySummary[], direct: boolean) => {
    let flights;
    if (direct) {
      flights = airlineFlights.filter((f) => f.stop === 0);
    } else {
      flights = airlineFlights.filter((f) => f.stop > 0);
    }

    if (flights.length > 0) {
      return minBy(flights, 'priceInfo.itinTotalFare.totalFare')?.priceInfo!.itinTotalFare.totalFare;
    }
  };

  const handleChanged = (checkedValue: string[]) => {
    if (checkedValue) {
      const array: { airline: string; direct: boolean }[] = [];
      for (const item of checkedValue) {
        const splits = item.split('%%');
        const airline = splits[0];
        const direct = splits[1] === '0';
        array.push({ airline, direct });
      }
      filter({ type: 'AirlineWithStop', value: array });
    }
  };

  return (
    <Card className="antd-card-small-padding mb-4 box-shadow">
      {pending ? (
        <Skeleton active className="no-title full-width-p" />
      ) : (
        airlines &&
        airlines.length > 0 && (
          <div className={`${classes.airlineFilterWrapper}  ${reversePositionOnRtl('left') === 'right' ? classes.rightToLeft : ''}`}>
            <div className={classes.airlineFilterLabel}>
              <div ref={leftShadowDiv} className={classes.leftShadow} />
              <div className={classes.airline}>{t(Translations.Flight.Airline)}</div>
              <div className={`${classes.label} ${classes.grayBg}`}>{t(Translations.Flight.Direct)}</div>
              <div className={`${classes.label} ${classes.blueBg}`}>1+ Stop</div>
            </div>
            <div className={classes.airlineItemsHolder} ref={airlinesHolderDiv} onScroll={scrollEffect} onLoad={scrollEffect}>
              <Checkbox.Group onChange={(checkedValue) => handleChanged(checkedValue as string[])}>
                <Row gutter={[4, 0]} className={classes.airlineItemsRow}>
                  {airlines.map((item, index) => {
                    const airlineFlights = itinerarySummaries!.filter((f) => f.airline.code === item.airline.code);
                    const directMinPrice = getStopsMinPrice(airlineFlights, true);
                    const oneStopPlusMinPrice = getStopsMinPrice(airlineFlights, false);

                    return (
                      <Col key={index} flex="100px">
                        <div className={classes.airlineItem}>
                          <img className={classes.airlineLogo} alt={item.airline.name} title={item.airline.name} src={item.airline.photoUrl} />
                          <PriceCheckBoxComponent price={directMinPrice} airlineCode={item.airline.code} direct />
                          <PriceCheckBoxComponent price={oneStopPlusMinPrice} airlineCode={item.airline.code} direct={false} />
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </div>
            <div ref={rightShadowDiv} className={classes.rightShadow} />
          </div>
        )
      )}
    </Card>
  );
});

export default FlightsAirlineTopFilter;
