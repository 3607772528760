import { EnvironmentOutlined, InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Rate, Row, Skeleton, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import roomSvg from '../../../../assets/room-default-image.svg';
import { useGlobalization } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { getDurationAsHoursAndMinutes } from '../../../../utils/helpers';
import { FlightOriginDestinationOption } from '../../../flight/apiTypes';
import { HotelBoard, HotelGetPreReserveByKeyResponse, HotelReserveItem } from '../../../hotel/apiTypes';
import HotelCancellationPolicy from '../../../hotel/components/shared/HotelCancellationPolicy/HotelCancellationPolicy';
import { FormattedCurrency } from '../../../shared';
import { PackageGetPreReserveByKeyResponse, PackageGetReserveResponse } from '../../apiTypes';
import classes from '../Packages/PackageFlights/PackageFlightListItem/PackageFlightListItem.module.scss';

type Props = {
  preReserveData?: PackageGetPreReserveByKeyResponse;
  reserveData?: PackageGetReserveResponse;
  showContinueButton?: boolean;
  pending: boolean;
  reservePending?: boolean;
};

const PackageBookingSummary: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const { formatHourMinute, formatShortMonthWeekDay, reversePositionOnRtl, formatShortDate } = useGlobalization();

  const airportTimeComponent = (airport: any, dateTime: string) => (
    <div>
      <div className="title-xs mb-0 no-wrap">{formatHourMinute(dateTime)}</div>
      <div className="title-xs mb-0 no-wrap monthWeekDay-small-in-rtl">{formatShortMonthWeekDay(dateTime)}</div>
      <div>
        <Tooltip
          title={
            <div>
              <div>{airport.countryName}</div>
              <div>{airport.cityName}</div>
              <div>{airport.locationName}</div>
            </div>
          }>
          <Tag color="default" className={'mx-0'}>
            {airport.locationCode} <InfoCircleOutlined />
          </Tag>
        </Tooltip>
      </div>
    </div>
  );

  let flight;
  let originDestinationOptions: FlightOriginDestinationOption[] | undefined = undefined;
  let flightPrice: number | undefined = undefined;

  let hotel: HotelReserveItem | HotelGetPreReserveByKeyResponse | undefined = undefined;
  let hotelRooms: { name: string; board: HotelBoard; adults: number; children: number; ages: number[] }[];

  if (props.preReserveData) {
    flight = props.preReserveData.preReservedFlight;
    flightPrice = flight.pricedItinerary.airItineraryPricingInfo.itinTotalFare.totalFare;
    originDestinationOptions = flight.pricedItinerary.airItinerary.originDestinationOptions;

    hotel = props.preReserveData.preReservedHotel;
  } else if (props.reserveData) {
    flight = props.reserveData.reserveFlightInfo;
    flightPrice = flight.flightFare.totalFare;
    originDestinationOptions = props.reserveData.reserveFlightInfo.airItinerary.originDestinationOptions;

    hotel = props.reserveData.reserveHotelInfo;
  }

  hotelRooms = (hotel?.accommodation.rooms as { name: string; board: HotelBoard; adults: number; children: number; ages: number[] }[])?.map((room) => ({
    name: room.name,
    board: room.board,
    adults: room.adults,
    children: room.children,
    ages: room.ages,
  }));

  return (
    <>
      {!props.pending ? (
        flight &&
        originDestinationOptions &&
        hotel &&
        hotelRooms && (
          <>
            {originDestinationOptions.map((option, index) => {
              const firstSegment = option.flightSegments[0];
              const lastSegment = option.flightSegments[option.flightSegments.length - 1];
              return (
                <React.Fragment key={index}>
                  <div className={classes.segmentItem}>
                    <Row gutter={[10, 10]} align={'middle'} className="mb-0 no-wrap-flex-sm">
                      <Col flex="40px" className={'p-0'}>
                        <img src={firstSegment.marketingAirline.photoUrl} alt={firstSegment.marketingAirline.name} className={classes.airlineLogo} />
                      </Col>
                      <Col flex="auto">
                        <div className={classes.originDurationDestination}>
                          {airportTimeComponent(firstSegment.departureAirport, firstSegment.departureDateTime)}
                          <Tooltip
                            title={
                              <div>
                                {' '}
                                <span className="hidden-xs hidden-sm hidden-md">{t(Translations.Common.Duration)}: </span>{' '}
                                {getDurationAsHoursAndMinutes(option.journeyDuration)} ({moment.duration(option.journeyDuration).toISOString()})
                              </div>
                            }>
                            <small className={classes.duration}>{getDurationAsHoursAndMinutes(option.journeyDuration)}</small>
                          </Tooltip>
                          <div className="text-right">{airportTimeComponent(lastSegment.arrivalAirport, lastSegment.arrivalDateTime)}</div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              );
            })}

            <Divider className="my-4" />
            <div className={classes.hotelSummeryHeader}>
              <div className={classes.hotelImageBlock} style={{ backgroundImage: 'url(' + hotel.accommodation.mainPhoto + ')' }} />
              <div className={`padding-${reversePositionOnRtl('left')}-1 ${classes.hotelNameBlock}`}>
                <h3 className="title-sm mb-2">{hotel.accommodation.name}</h3>
                <Rate className="mb-2" disabled value={hotel.accommodation.rating} />
                <div className="text-muted">
                  <EnvironmentOutlined className="align-middle" />
                  <span className="align-middle mx-2">{hotel.accommodation.address}</span>
                </div>
              </div>
            </div>
            <Divider className="my-4" />

            <Row justify="space-between" align={'middle'} gutter={[16, 0]} className="mb-1">
              <Col>{t(Translations.Hotel.CheckIn)}: </Col>
              <Col>
                <Tooltip title={t(Translations.Hotel.PropertyLocalTime)}>
                  {formatShortDate(hotel.date.checkIn)} <InfoCircleOutlined />
                </Tooltip>
              </Col>
            </Row>
            <Row justify="space-between" align={'middle'} gutter={[16, 0]} className="mb-1">
              <Col>{t(Translations.Hotel.CheckOut)}:</Col>
              <Col>
                <Tooltip title={t(Translations.Hotel.PropertyLocalTime)}>
                  {formatShortDate(hotel.date.checkOut)} <InfoCircleOutlined />
                </Tooltip>
              </Col>
            </Row>
            <Row justify="space-between" align={'middle'} gutter={[16, 0]} className="mb-1">
              <Col>{t(Translations.Common.Duration)}:</Col>
              <Col>{t(Translations.Hotel.NightCount, { count: hotel.date.duration })}</Col>
            </Row>
            <Divider className="my-4" />
            <div>
              {hotelRooms!.map((room, index) => (
                <div key={index} className={`${classes.roomSummeryHeader}  ${index > 0 ? 'mt-6' : ''}`}>
                  <div className={classes.hotelImageBlock} style={{ backgroundImage: 'url(' + roomSvg + ')' }} />
                  <div className={`padding-${reversePositionOnRtl('left')}-1 ${classes.hotelNameBlock}`}>
                    <h4 className="title-xs mb-2">{room.name}</h4>
                    <ul className="bullet-list mb-2">
                      <li>{room.board.name}</li>
                    </ul>
                    <div>
                      <span className="align-middle">({t(Translations.Common.AdultCount, { count: room.adults })}, </span>
                      <Tooltip title={room.children > 0 ? `${t(Translations.Common.AgeOfChildren)} - ${room.ages.join(',')}` : undefined}>
                        {t(Translations.Common.ChildCount, { count: room.children })}
                        {room.children > 0 && (
                          <span>
                            {' '}
                            <InfoCircleOutlined className="align-middle" />
                          </span>
                        )}
                        )
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Divider className="my-4" />
            <Row>
              <Col>
                <HotelCancellationPolicy tagStatus cancellationPolicy={hotel.cancellationPolicy} />
              </Col>
            </Row>
            <Divider className="my-4" />
            {/*<h5 className={'title-xs mb-2'}>*/}
            {/*  <Row align={'middle'} justify={'space-between'}>*/}
            {/*    <Col className="font-weight-bold">{t(Translations.Package.HotelPrice)}</Col>*/}
            {/*    <Col className="font-weight-bold">*/}
            {/*      <FormattedCurrency number={10000000} currencyDisplay="code" />*/}
            {/*    </Col>*/}
            {/*  </Row>*/}
            {/*</h5>*/}
            {/*<h5 className={'title-xs mb-2'}>*/}
            {/*  <Row align={'middle'} justify={'space-between'}>*/}
            {/*    <Col className="font-weight-bold">{t(Translations.Package.FlightPrice)}</Col>*/}
            {/*    <Col className="font-weight-bold">*/}
            {/*      <FormattedCurrency number={12300000} currencyDisplay="code" />*/}
            {/*    </Col>*/}
            {/*  </Row>*/}
            {/*</h5>*/}

            <h5 className={'mb-4 text-danger'}>
              <Row align={'middle'} justify={'space-between'}>
                <Col>{t(Translations.Common.TotalPrice)}</Col>
                <Col>
                  <FormattedCurrency number={hotel.salePrice + flightPrice!} currencyDisplay="code" />
                </Col>
              </Row>
            </h5>
            {props.showContinueButton && (
              <Button type="primary" htmlType="submit" danger block loading={props.reservePending}>
                {t(Translations.Common.ContinueToPayment)}
                <RightOutlined className="mirror-in-rtl" />
              </Button>
            )}
          </>
        )
      ) : (
        <div>
          <Skeleton className={'no-title mb-6 mt-4'} loading active avatar paragraph={{ width: '100%' }} />
          <Skeleton className={'no-title my-6'} loading active avatar paragraph={{ width: '100%' }} />
          <Divider className="my-0" />
          <Skeleton active paragraph={{ rows: 10 }} />
          <Divider className="my-0" />
          <Skeleton className="one-line-skeleton mb-0" loading active title={{ width: '100%' }} paragraph={{ rows: 0 }} />
        </div>
      )}
    </>
  );
});

export default PackageBookingSummary;
