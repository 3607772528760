import Axios from 'axios';

import { AxiosInstanceData } from '../../../types';
import { urlWithQuery } from '../../../utils/helpers';
import { DomesticHotelAvailabilityRequest, DomesticHotelPrereserveParams, DomesticHotelReservesRequest } from '../apiTypes';
import { DomesticHotelDetailInsertCommentRequestParams } from '../types';
import { DomesticHotelApiDataMap, DomesticHotelApiUrls } from './domesticHotel-apiData';

const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_DomesticHotelApiBaseUrl;
axiosInstance.defaults.headers.common = {
  apikey: '68703d73-c92c-4105-9f71-9f718aaad2cc',
};

const axiosInstance3 = Axios.create();
axiosInstance3.defaults.baseURL = process.env.REACT_APP_DomesticHotelApiBaseUrl3;
axiosInstance3.defaults.headers.common = {
  apikey: '68703d73-c92c-4105-9f71-9f718aaad2cc',
  tenantid: '1084',
};
const axiosInstance4 = Axios.create();
axiosInstance4.defaults.baseURL = process.env.REACT_APP_DomesticHotelApiBaseUrl4;
axiosInstance4.defaults.headers.common = {
  apikey: '68703d73-c92c-4105-9f71-9f718aaad2cc',
};

export const domesticHotelAvailabilityHttp = (data: DomesticHotelAvailabilityRequest) => {
  return axiosInstance.post(DomesticHotelApiUrls.Availability, data, {
    headers: {
      apikey: '68703d73-c92c-4105-9f71-9f718aaad2cc',
    },
  });
};

export const getHotelLocationHttp = (data: string) => {
  return axiosInstance4.post(DomesticHotelApiUrls.GetLocation + '?input=' + data);
};

export const getDomesticHotelByIdHttp = (data: { hotelId: number }) => {
  const url = urlWithQuery(DomesticHotelApiUrls.GetHotelById, data);
  return axiosInstance.get(url);
};

export const getEntityNameByLocationIdHttp = (data: { location: string }) => {
  const url = urlWithQuery(DomesticHotelApiUrls.GetEntityNameByLocation, data);
  return axiosInstance.get(url);
};

export const getRatesHttp = (data: { locationId: string }) => {
  const url = urlWithQuery(DomesticHotelApiUrls.GetRates, data);
  return axiosInstance.get(url);
};

export const getScoreHttp = (data: { pageId: number }) => {
  const url = urlWithQuery(DomesticHotelApiUrls.GetScore, data);
  return axiosInstance.get(url);
};

export const getDomesticHotelGetPageByUrlHttp = (data: { url: string; isNewVisitor: boolean }) => {
  const url = urlWithQuery(DomesticHotelApiUrls.getPageByUrl, data);
  return axiosInstance.get(url);
};

export const DomesticHotelInsertCommentHttp = (data: DomesticHotelDetailInsertCommentRequestParams) => {
  return axiosInstance.post(DomesticHotelApiUrls.InsertComment, data);
};

export const DomesticHotelAxiosData: AxiosInstanceData = {
  instance: axiosInstance,
  DataMap: {
    MapObject: DomesticHotelApiDataMap,
    Urls: DomesticHotelApiUrls,
  },
};

export const DomesticHotelAxiosData3: AxiosInstanceData = {
  instance: axiosInstance3,
  DataMap: {
    MapObject: DomesticHotelApiDataMap,
    Urls: DomesticHotelApiUrls,
  },
};

export const getAvailibilityHttp = (data: { cityId: number; checkIn: string; checkOut: string }) => {
  return axiosInstance3.post(DomesticHotelApiUrls.HotelAvailability, data);
};

export const getAvailibilityByIdHttp = (data: { hotelIds: number[]; checkIn: string; checkOut: string }) => {
  return axiosInstance3.post(DomesticHotelApiUrls.HotelAvailabilityById, data);
};

export const getRoomsHttp = (data: { Id: number; checkIn: string; checkOut: string }) => {
  const url = urlWithQuery(DomesticHotelApiUrls.GetRooms, data);
  return axiosInstance3.get(url);
};

export const validateHttp = (data: { bookingToken: string; checkin: string; checkout: string; count: number }) => {
  return axiosInstance3.post(DomesticHotelApiUrls.Validate, data);
};

export const getValidateHttp = (data: { Id: string }) => {
  const url = urlWithQuery(DomesticHotelApiUrls.GetValidate, data);
  return axiosInstance3.get(url);
};

export const preReserve = (data: DomesticHotelPrereserveParams) => {
  return axiosInstance3.post(DomesticHotelApiUrls.PreReserveHotel, data);
};

export const getReserveHttp = (data: { ReserveId: string; Username: string }) => {
  const url = urlWithQuery(DomesticHotelApiUrls.GetReserveById, data);
  return axiosInstance3.get(url);
};

export const DomesticHotelConfirmHttp = (data: { reserveId: string; username: string }) => {
  return axiosInstance3.post(DomesticHotelApiUrls.Confirm, data);
};

export const domesticHotelGetTenantReserves = (data: DomesticHotelReservesRequest) => {
  const url = urlWithQuery(DomesticHotelApiUrls.GetAllReserves, data);
  return axiosInstance3.get(url);
};

export const domesticHotelGetTenantReserve = (data: { ReserveId: string; Username: string }) => {
  const url = urlWithQuery(DomesticHotelApiUrls.GetSingleReserve, data);
  return axiosInstance3.get(url);
};
