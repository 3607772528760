import React from 'react';

import { DefinedRoute } from '../../types';

const prefix = '/payment';
const PaymentRoutes = (): readonly DefinedRoute[] => {
  return [
    {
      type: 'Route',
      exact: true,
      path: `${prefix}/transactions`,
      children: React.lazy(() => import('./pages/Transactions/Transactions')),
    },
    {
      type: 'Route',
      exact: true,
      path: `${prefix}/chargeDeposit`,
      children: React.lazy(() => import('./pages/ChargeDeposit/ChargeDeposit')),
    },
    {
      type: 'Route',
      path: `${prefix}/method`,
      children: React.lazy(() => import('./pages/PaymentMethod/PaymentMethod')),
      exact: true,
    },
  ];
};

export default PaymentRoutes;
