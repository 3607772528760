import React from 'react';

import { DefinedRoute } from '../../types';

const DomesticFlightRoutes = (): readonly DefinedRoute[] => [
  {
    type: 'Route',
    path: '/domestic/flights/:location',
    children: React.lazy(() => import('./pages/DomesticFlights/DomesticFlights')),
    exact: true,
  },
  {
    type: 'Route',
    path: '/domestic/flights/passangers/info',
    children: React.lazy(() => import('./pages/DomesticFlightPassengers/DomesticFlightPassengers')),
    exact: true,
  },
  {
    type: 'Route',
    path: '/domestic/flights/payment/confirm',
    children: React.lazy(() => import('./pages/DomesticFlightPaymentConfirm/DomesticFlightPaymentConfirm')),
    exact: true,
  },
  {
    type: 'Route',
    path: '/domesticflights/reserves',
    children: React.lazy(() => import('./pages/DomesticFlightsReserves/DomesticFlightsReserves')),
    exact: true,
  },
  {
    type: 'Route',
    path: '/domesticflights/reserves/reserve',
    children: React.lazy(() => import('./pages/DomesticFlightReserveDetails/DomesticFlightReserveDetails')),
    exact: true,
  },
];

export default DomesticFlightRoutes;
