import { FormInstance } from 'antd/es/form';
import { Rule } from 'antd/lib/form';
import persianRex from 'persian-rex';
import { useTranslation } from 'react-i18next';

import { combineRegularExpressions } from '../../utils/helpers';
import { Translations } from '../localization';

type ValidatorFunctionArgument = { validator: (value: any) => boolean; rejectionMessage: string };
type LabelWithRules = {
  label?: string;
  fieldName?: string;
  rules: {
    type:
      | 'Min'
      | 'Max'
      | 'Required'
      | 'EnglishLetterOnly'
      | 'EnglishLetterAndNumbersOnly'
      | 'EnglishLetterAndWhitespaceOnly'
      | 'EnglishLetterAndNumbersAndWhitespaceOnly'
      | 'MatchField'
      | 'Pattern'
      | 'EnglishPersianLetterAndSpaceOnly'
      | 'PersianLetterAndSpaceOnly'
      | 'NumberOnly'
      | 'Email'
      | 'Birthdate'
      | 'Function';
    arguments?: (string | number | { matchFieldName: string; rejectionMessage: string } | ValidatorFunctionArgument)[];
  }[];
};
const useAntdValidation = (formInstance: FormInstance) => {
  const { t } = useTranslation();

  // const requiredRule = (fieldNameKey: string): Rule => ({
  //   required: true,
  //   message: t(Translations.Validations.FieldRequired, { field: t(fieldNameKey) }),
  // });
  //
  // const minRule = (fieldName: string, min: number): Rule => ({
  //   min: min,
  //   message: t(Translations.Validations.FieldMin, { field: fieldName, count: min }),
  // });
  //
  // const maxRule = (fieldName: string, max: number): Rule => ({
  //   max: max,
  //   message: t(Translations.Validations.FieldMax, { field: fieldName, count: max }),
  // });
  //
  // const onlyEnglishLettersRule = (fieldName: string, allowWhitespace: boolean = false): Rule => ({
  //   pattern: new RegExp(allowWhitespace ? '^[a-zA-Z ]*$' : '^[a-zA-Z]*$'),
  //   message: t(Translations.Validations.FieldOnlyEnglishLetters, { field: fieldName }),
  // });
  //
  // const matchFieldRule = (matchFieldName: string, rejectionMessage: string): Rule => ({
  //   validator(rule: RuleObject, value: StoreValue) {
  //     if (!value || formInstance.getFieldValue(matchFieldName) === value) {
  //       return Promise.resolve();
  //     }
  //     return Promise.reject(rejectionMessage);
  //   },
  // });

  const labelWithRules = (data: LabelWithRules) => {
    // eslint-disable-next-line array-callback-return
    const rules = data.rules.map<Rule>((rule) => {
      switch (rule.type) {
        case 'Min':
          let min;
          if (rule.arguments) {
            min = +rule.arguments[0];
          } else {
            min = 1;
          }
          return { min: min, message: t(Translations.Validations.FieldMin, { field: data.label ?? data.fieldName, count: min }) };
        case 'Max':
          let max;
          if (rule.arguments) {
            max = +rule.arguments[0];
          } else {
            max = 1;
          }
          return { max: max, message: t(Translations.Validations.FieldMax, { field: data.label ?? data.fieldName, count: max }) };
        case 'Required':
          return { required: true, message: t(Translations.Validations.FieldRequired, { field: data.label ?? data.fieldName }) };
        case 'EnglishLetterOnly':
          return { pattern: new RegExp('^[a-zA-Z]*$'), message: t(Translations.Validations.FieldOnlyEnglishLetters, { field: data.label ?? data.fieldName }) };
        case 'EnglishLetterAndNumbersOnly':
          return {
            pattern: new RegExp('^[a-zA-Z0-9]*$'),
            message: t(Translations.Validations.FieldOnlyEnglishLettersOrNumbers, { field: data.label ?? data.fieldName }),
          };
        case 'EnglishLetterAndWhitespaceOnly':
          return { pattern: new RegExp('^[a-zA-Z ]*$'), message: t(Translations.Validations.FieldOnlyEnglishLetters, { field: data.label ?? data.fieldName }) };
        case 'EnglishLetterAndNumbersAndWhitespaceOnly':
          return {
            pattern: new RegExp('^[a-zA-Z0-9 ]*$'),
            message: t(Translations.Validations.FieldOnlyEnglishLettersOrNumbers, { field: data.label ?? data.fieldName }),
          };
        case 'Pattern':
          return {
            pattern: new RegExp(rule.arguments![0] as string),
            message: rule.arguments?.[1] as string,
          };
        case 'MatchField':
          return {
            validator: (r, value) => {
              if (rule.arguments) {
                const ruleArgument = rule.arguments[0] as { matchFieldName: string; rejectionMessage: string };
                if (!value || formInstance.getFieldValue(ruleArgument.matchFieldName) === value) {
                  return Promise.resolve();
                }
                return Promise.reject(ruleArgument.rejectionMessage);
              }
            },
          };
        case 'EnglishPersianLetterAndSpaceOnly':
          return {
            pattern: new RegExp(/^[a-zA-Zآابپتثجچحخدذرزژسشصضطظعغفقکگلمنوهیئ ]*$/),
            message: t(Translations.Validations.JustEnglishPersianLetterAndSpace),
          };
        case 'PersianLetterAndSpaceOnly':
          return {
            pattern: new RegExp('^' + combineRegularExpressions(persianRex.lettersASCIRange, '\\s') + '+$'),
            message: t(Translations.Validations.JustPersianLetterAndSpace),
          };
        case 'NumberOnly':
          return {
            pattern: /^[۰۱۲۳۴۵۶۷۸۹0-9]{0,10}$/,
            message: t(Translations.Validations.invalidNumber, { field: data.label ?? data.fieldName }),
          };
        case 'Email':
          return {
            pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
            message: t(Translations.Validations.invalidEmail),
          };
        case 'Birthdate':
          return { required: true, message: rule.arguments?.[0] as string };
        case 'Function':
          return {
            validator: (_, value) => {
              const ruleArguments = rule.arguments as ValidatorFunctionArgument[];
              let errorMessages: string[] | undefined = undefined;

              ruleArguments.forEach((argument) => {
                if (!argument.validator(value)) {
                  if (!errorMessages) {
                    errorMessages = [];
                  }
                  errorMessages.push(argument.rejectionMessage);
                }
              });

              if (errorMessages as unknown as string[]) {
                return Promise.reject(errorMessages!.join('\r\n'));
              }

              return Promise.resolve();
            },
          };
      }
    });
    return { label: data.label, rules: rules };
  };

  //return { requiredRule, minRule, maxRule, onlyEnglishLettersRule, matchFieldRule, labelWithRules };
  return { labelWithRules };
};

export default useAntdValidation;
