import moment from 'moment-jalaali';
import React, { useEffect, useState } from 'react';

import { useInterval } from '../../../../features/hooks';
import classes from './CountDown.module.scss';

type Props = {
  seconds: number;
  intervalMs?: number;
  auto?: boolean;
  onChange?: (seconds: number) => void;
  onCompleted?: () => void;
};
const CountDown: React.FC<Props> = React.memo((props) => {
  const [remainedSeconds, setRemainedSeconds] = useState<number>(props.seconds);

  const interval = useInterval(() => {
    if (props.auto && remainedSeconds > 0) {
      setRemainedSeconds((prevState) => prevState - 1);
    }
  }, props.intervalMs ?? 1000);

  useEffect(() => {
    if (remainedSeconds <= 0) {
      props.onCompleted?.();
      interval.cleanup();
    }
    props.onChange?.(remainedSeconds);
  }, [remainedSeconds]);

  useEffect(() => {
    if (!props.auto) {
      interval.cleanup();
    }
  }, [props.auto]);

  useEffect(() => {
    if (!props.auto) {
      setRemainedSeconds(props.seconds);
    }
  }, [props.seconds, props.auto]);

  const min = Math.floor(remainedSeconds / 60);
  const sec = remainedSeconds % 60;
  return (
    <div className={classes.timeCapacity}>
      <div className={classes.minutes}>{moment(min + '-' + sec, 'm-s').format('mm')}</div>
      <div className={classes.symbol}>:</div>
      <div className={classes.seconds}>{moment(min + '-' + sec, 'm-s').format('ss')}</div>
    </div>
  );
});

export default CountDown;
