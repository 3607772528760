import { EnterOutlined, EnvironmentOutlined, MinusOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import debounce from 'lodash/debounce';
import { Moment } from 'moment';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useAntdValidation, useAppSelector, useGlobalization, useHttpCall } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { CultureName } from '../../../../../features/localization/cultures';
import { disableOtherDatesAntdDatePicker, disableYesterdayAndBeforeAntdDatePicker, getNowISO } from '../../../../../utils/helpers';
import { ImprovedAutoComplete, PDatePicker2 } from '../../../../shared';
import { GetAirportResponse } from '../../../apiTypes';
import { getAirportHttp } from '../../../http/flight-http';
import { flightSlice } from '../../../index';
import { FlightFormValues } from '../../../types';

type Props = {
  order: number;
  inline?: boolean;
  previousDepartureDate: Moment | any;
  nextDepartureDate: Moment | undefined;
  onDepartureDateChanged: () => void;
  formInstance: FormInstance<FlightFormValues>;
};
const FlightSearchFlight: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { culture } = useGlobalization();

  const flightSearchTempState = useAppSelector((state) => state.flight.temp.flightSearch);
  const [fromAirports, setFromAirports] = useState<GetAirportResponse>([]);
  const [toAirports, setToAirports] = useState<GetAirportResponse>([]);

  const getAirportApi = useHttpCall(getAirportHttp);

  const { labelWithRules } = useAntdValidation(props.formInstance);

  const airportSearchSegment = useRef<'from' | 'to'>('from');

  const destinationInputRef = useRef<Input>(null);

  const debouncedGetAirports = debounce(async (value: string, to: boolean) => {
    airportSearchSegment.current = to ? 'to' : 'from';
    const response = await getAirportApi.call<GetAirportResponse>({ value: value });
    if (response && response.success && response.result) {
      if (to) {
        setToAirports(response.result!);
      } else {
        setFromAirports(response.result!);
      }
    } else {
      if (to) {
        setToAirports([]);
      } else {
        setFromAirports([]);
      }
    }
  }, 300);

  const handleAirportSearched = async (value: string, to: boolean) => {
    if (value && value.length > 2) {
      await debouncedGetAirports(value, to);
    } else {
      if (to) {
        setToAirports([]);
      } else {
        setFromAirports([]);
      }
    }
  };

  const handleRemoveWayChanged = () => {
    dispatch(flightSlice.actions.tempSetFlightSearch({ qty: flightSearchTempState.qty - 1 }));
  };

  const handleSwapOriginDestination = () => {
    const validateSection = (obj: GetAirportResponse) => {
      return obj?.[0]?.Code;
    };

    const values = props.formInstance.getFieldsValue();
    if (validateSection(values.origins) && validateSection(values.destinations)) {
      props.formInstance.setFieldsValue({
        origins: values.destinations,
        destinations: values.origins,
      });
    }
  };

  return (
    <>
      <Row gutter={[10, 0]}>
        <Col xs={24} md={16} xl={flightSearchTempState.tripType === 'RoundTrip' ? (props.inline ? 16 : 24) : 16}>
          <Row gutter={[10, 0]} wrap={false}>
            <Col flex={'auto'}>
              <Form.Item
                className="mb-4"
                name={['origins', props.order]}
                {...labelWithRules({ label: t(Translations.Common.From), rules: [{ type: 'Required' }] })}>
                <ImprovedAutoComplete
                  asyncPending={airportSearchSegment.current === 'from' && getAirportApi.pending}
                  //additionVisibleCondition={flightSearchTempState.dropdownVisibility}
                  textPropertyName="Name"
                  renderTooltip={(option) => (
                    <>
                      {option.ParentName}
                      <br />
                      {option.Name}
                    </>
                  )}
                  options={fromAirports}
                  onInputChanged={(value) => handleAirportSearched(value, false)}
                  onChange={(item) => {
                    if (item) {
                      destinationInputRef.current?.focus();
                    }
                  }}
                  renderOption={(option) => (
                    <Row className={`no-wrap-flex ${option.Type === 2 ? 'pl-5' : ''}`}>
                      <Col flex="20px">{option.Type === 1 ? <EnvironmentOutlined className="align-middle" /> : <EnterOutlined className="align-middle" />}</Col>
                      <Col flex="auto">
                        <div>{option.Name}</div>
                        <small>{option.ParentName}</small>
                      </Col>
                    </Row>
                  )}
                />
              </Form.Item>
            </Col>
            {flightSearchTempState.tripType !== 'OpenJaw' && (
              <Col flex={'40px'}>
                <button className={'origin-destination-toggle-btn align-with-formItems'} type={'button'} onClick={handleSwapOriginDestination}>
                  <SwapOutlined />
                </button>
              </Col>
            )}
            <Col flex={'auto'}>
              <Form.Item
                className="mb-4"
                name={['destinations', props.order]}
                {...labelWithRules({ label: t(Translations.Common.To2), rules: [{ type: 'Required' }] })}>
                <ImprovedAutoComplete
                  inputRef={destinationInputRef}
                  asyncPending={airportSearchSegment.current === 'to' && getAirportApi.pending}
                  textPropertyName="Name"
                  renderTooltip={(option) => (
                    <>
                      {option.ParentName}
                      <br />
                      {option.Name}
                    </>
                  )}
                  options={toAirports}
                  onInputChanged={(value) => handleAirportSearched(value, true)}
                  renderOption={(option) => (
                    <Row className={`no-wrap-flex ${option.Type === 2 ? 'pl-5' : ''}`}>
                      <Col flex="20px">{option.Type === 1 ? <EnvironmentOutlined className="align-middle" /> : <EnterOutlined className="align-middle" />}</Col>
                      <Col flex="auto">
                        <div>{option.Name}</div>
                        <small>{option.ParentName}</small>
                      </Col>
                    </Row>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        {flightSearchTempState.tripType === 'RoundTrip' ? (
          <Col xs={24} md={8} xl={props.inline ? 8 : 24}>
            <Form.Item
              className="mb-4"
              name={`roundTripDates`}
              {...labelWithRules({ label: t(Translations.Flight.DepartureAndReturnDates), rules: [{ type: 'Required' }] })}>
              {culture.Name === CultureName.FaIr ? (
                <PDatePicker2 initialCalendar="persian" mode="range" minDate={getNowISO()} />
              ) : (
                <DatePicker.RangePicker
                  inputReadOnly
                  dropdownClassName="disabled-year-arrow"
                  className={'w-100'}
                  disabledDate={disableYesterdayAndBeforeAntdDatePicker}
                />
              )}
            </Form.Item>
          </Col>
        ) : (
          <Col xs={24} md={8}>
            <Row className={'no-wrap-flex'} gutter={[10, 0]}>
              <Col flex={'auto'}>
                <Form.Item
                  className="mb-4"
                  name={['departures', props.order]}
                  {...labelWithRules({ label: t(Translations.Flight.DepartureDate), rules: [{ type: 'Required' }] })}>
                  {culture.Name === CultureName.FaIr ? (
                    <PDatePicker2 initialCalendar="persian" minDate={getNowISO()} />
                  ) : (
                    <DatePicker
                      className="w-100"
                      defaultPickerValue={props.previousDepartureDate}
                      dropdownClassName="disabled-year-arrow"
                      disabledDate={(current) =>
                        disableOtherDatesAntdDatePicker(current, props.previousDepartureDate, props.nextDepartureDate, { disableYesterdayAndBefore: true })
                      }
                      onChange={props.onDepartureDateChanged}
                    />
                  )}
                </Form.Item>
              </Col>
              {flightSearchTempState.tripType === 'OpenJaw' && flightSearchTempState.qty > 2 && flightSearchTempState.qty === props.order + 1 && (
                <Col flex={'32px'} className={'align-with-formItems'}>
                  <Button type="primary" danger htmlType="button" icon={<MinusOutlined />} onClick={() => handleRemoveWayChanged()} />
                </Col>
              )}
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
});

export default FlightSearchFlight;
