import React from 'react';

type Props = {
  icon: React.ReactNode;
  title: string;
  className?: string;
};
const TabHeaderWithIcon: React.VFC<Props> = (props) => {
  return (
    <span className={props.className}>
      {props.icon} {props.title}
    </span>
  );
};

export default TabHeaderWithIcon;
