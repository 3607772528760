import { Card, Col, Row, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useGlobalization } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { DomesticFlightTripType } from '../../../domesticFlight/types';
import { getAirTripTypeTranslationKey } from '../../../flight/helpers/FlightHelpers';
import classes from './RecentSearches.module.scss';

type Props = {
  search: any;
};
const RecentSearchDomesticFlight: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const { reversePositionOnRtl, formatShortDate, formatShortMonthWeekDay } = useGlobalization();

  let query = `${props.search.originCode}-${props.search.destinationCode}?adult=${+props.search.adult}&children=${+props.search.child}&infant=${+props.search
    .infant}&departureTime=${props.search.departureTime}&departureCode=${props.search.originText}&returnCode=${props.search.destinationText}&isGregorian=${
    props.search.isGregorian
  }`;
  if (props.search.airTripType === 'RoundTrip') {
    const returnTime = props.search.returnTime;
    query += `&returnTime=${returnTime}`;
  }

  return (
    <Col xs={24} sm={12} className={classes.recentSearchItem}>
      <Link to={`domestic/flights/${query}`} className={classes.recentSearchItemLink}>
        <Card className={`antd-card-small-padding ${classes.recentSearchItemCard} ${reversePositionOnRtl('left')}-aligned`}>
          <div className={classes.recentSearchInfo}>
            <div className={'text-muted mb-1'}>{t(`Flight.${getAirTripTypeTranslationKey(props.search.airTripType as DomesticFlightTripType)}`)}</div>
            {props.search.airTripType === 'OpenJaw' ? (
              <>
                {props.search.originCodes.map((routeItem: string, index: number) => (
                  <Row key={index} gutter={[15, 0]} align={'middle'} className={'mb-1'}>
                    <Col>
                      <h4 className={'title-sm mb-0'}>
                        <Tooltip title={props.search.originText}>{props.search.originCode}</Tooltip> {t(Translations.Common.To2)}{' '}
                        <Tooltip title={props.search.destinationText}>{props.search.destinationCode}</Tooltip>
                      </h4>
                    </Col>
                    <Col>
                      <Tooltip title={<span className="en-font">{formatShortDate(props.search.departureTime)}</span>}>
                        <div className={'text-primary font-weight-bold'}>{formatShortMonthWeekDay(props.search.departureTime)}</div>
                      </Tooltip>
                    </Col>
                  </Row>
                ))}
              </>
            ) : (
              <>
                <h4 className={'title-sm mb-1'}>
                  <Tooltip title={props.search.originText}>{props.search.originCode}</Tooltip> {t(Translations.Common.To2)}{' '}
                  <Tooltip title={props.search.destinationText}>{props.search.destinationCode}</Tooltip>
                </h4>

                <div className={'text-primary font-weight-bold mb-1'}>
                  <Tooltip title={<span className="en-font">{formatShortDate(props.search.departureTime)}</span>}>
                    {formatShortMonthWeekDay(props.search.departureTime)}
                  </Tooltip>
                  {props.search.airTripType === 'RoundTrip' ? (
                    <>
                      {' '}
                      -{' '}
                      <Tooltip title={<span className="en-font">{formatShortDate(props.search.returnTime)}</span>}>
                        {formatShortMonthWeekDay(props.search.returnTime)}
                      </Tooltip>
                    </>
                  ) : null}
                </div>
              </>
            )}

            <span className={'text-muted'}>
              {t(Translations.Common.AdultCount, { count: props.search.adult })}{' '}
              {props.search.child > 0 && (
                <>
                  {t(Translations.Common.Comma)} {t(Translations.Common.ChildCount, { count: props.search.child })}
                </>
              )}{' '}
              {props.search.infant > 0 && (
                <>
                  {t(Translations.Common.Comma)} {t(Translations.Common.InfantCount, { count: props.search.infant })}
                </>
              )}
            </span>
          </div>

          <span className={`fas fa-plane mirror-in-rtl bg-blue  type-circle-icon ${classes.recentSearchItemIcon}`} />
          <span className={`text-primary ${classes.recentSearchItemSearch}`}>{t(Translations.Common.Search)}</span>
        </Card>
      </Link>
    </Col>
  );
});

export default RecentSearchDomesticFlight;
