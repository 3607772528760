import { createAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { History } from 'history';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { LocalStorageExpiration, LocalStorageKey } from '../../../features/constants';
import { AbpResponse, ApiResponse, Tenant } from '../../../types';
import { getJsonStorage, getStorage, handleAxiosError, initializeFromLocalStorageAndApi, removeStorage, setStorage } from '../../../utils/helpers';
import { accountSlice } from '../../account';
import { getCurrentProfileHttp } from '../../account/http/account-http';
import { GetCurrentProfileResponse } from '../../account/types';
import { authenticationSlice, getAuthTokenStorage } from '../../authentication';
import { getPermissionsHttp } from '../../authentication/http/authentication-http';
import { GetAffiliateResponse } from '../../authentication/types';
import { flightSlice } from '../../flight';
import { GetFlightSuppliersResponse } from '../../flight/apiTypes';
import { flightGetSuppliersHttp } from '../../flight/http/flight-http';
import { GetIpLocationResponse } from '../apiTypes';
import { getAffiliateHttp } from '../http/coordinator-http';
import { getIPLocationHttp } from '../http/shared-http';
import sharedSlice from './shared-slice';
import { CurrencyName, SupportedCurrencies } from '..';

//#region actions

const prefix = 'shared_saga';

const HandleHttpError = `${prefix}/HandleHttpError`;
export const handleHttpErrorDefaultSaga = createAction<AxiosError>(HandleHttpError);

const InitializeApp = `${prefix}/InitializeApp`;
export const initializeAppSaga = createAction<{ history: History }>(InitializeApp);

// const GetIPLocation = `${prefix}/GetIPLocation`;
// export const getIPLocationSaga = createAction(GetIPLocation);

//#endregion

function* handleHttpErrorDefault(action: ReturnType<typeof handleHttpErrorDefaultSaga>) {
  const payload = action.payload;
  yield put(sharedSlice.actions.handleHttpErrorDefault(payload));
}

function* initializeApp() {
  try {
    let error;

    //#region Current Tenant
    const currentTenantObj = getJsonStorage(LocalStorageKey.CurrentTenant);
    if (currentTenantObj) {
      yield put(authenticationSlice.actions.setTenant(currentTenantObj as Tenant));
    }
    //#endregion

    //#region IpLocation

    const ipLocation: GetIpLocationResponse | undefined = yield initializeFromLocalStorageAndApi<GetIpLocationResponse>(
      LocalStorageKey.IpLocation,
      'Getting Ip Location',
      (obj) => obj.currency_code !== undefined && obj.alpha2 !== undefined,
      getIPLocationHttp,
      undefined,
      function* (obj) {
        yield put(sharedSlice.actions.setIpLocation(obj));
      },
      LocalStorageExpiration.Minute * 5,
      false
    );

    //#endregion

    //#region Tenants
    let tenants = [];
    const tenantsJsonObj = getJsonStorage(LocalStorageKey.Tenants);
    if (Array.isArray(tenantsJsonObj)) {
      tenants = tenantsJsonObj;
    }
    yield put(sharedSlice.actions.setTenants(tenants));
    //#endregion

    //#region Authentication Token and Permissions

    const authTokenStorage = getAuthTokenStorage();
    if (authTokenStorage) {
      const profileAxiosResponse: ApiResponse<GetCurrentProfileResponse> = yield call(getCurrentProfileHttp);
      error = handleAxiosError(profileAxiosResponse);
      if (error) {
        yield put(sharedSlice.actions.setInitializationError({ initializationState: 'Getting user information', abpError: error }));

        //Test
        //yield put(sharedSlice.actions.setInitializationError({ initializationState: 'Getting user information', abpError: { message: 'AAAA', code: 0 } }));

        return;
      } else {
        const {
          data: { result: profile },
        } = profileAxiosResponse as AxiosResponse<AbpResponse<GetCurrentProfileResponse>>;
        if (profile!.isActive) {
          yield put(authenticationSlice.actions.setAuthToken(authTokenStorage));
          yield put(accountSlice.actions.updateProfileSuccess(profile!));
          //yield put(accountSlice.actions.updateProfileReset());

          //#region Permissions
          const permissionsAxiosResponse: AxiosResponse<AbpResponse<string[]>> = yield call(getPermissionsHttp);
          error = handleAxiosError(permissionsAxiosResponse);
          if (error) {
            yield put(sharedSlice.actions.setInitializationError({ initializationState: 'Getting user permissions', abpError: error }));
            return;
          } else {
            const {
              data: { result: permissions },
            } = permissionsAxiosResponse as AxiosResponse<AbpResponse<string[]>>;
            yield put(authenticationSlice.actions.setPermissions(permissions!));

            //#region Get Flight Suppliers
            yield initializeFromLocalStorageAndApi<GetFlightSuppliersResponse>(
              LocalStorageKey.FlightSuppliers,
              'Getting Flight Suppliers',
              (obj) => obj.supplierList !== undefined && obj.supplierList.length > 0,
              flightGetSuppliersHttp,
              undefined,
              function* (obj) {
                yield put(flightSlice.actions.setSuppliers(obj.supplierList));
              },
              LocalStorageExpiration.Minute * 5,
              false,
              true
            );
            //#endregion
          }
          //#endregion

          //#region Affiliate
          yield initializeFromLocalStorageAndApi<GetAffiliateResponse>(
            LocalStorageKey.Affiliate,
            'Getting affiliate',
            (obj) => obj.id !== undefined && obj.name !== undefined && obj.company !== undefined,
            getAffiliateHttp,
            undefined,
            function* (storageObj) {
              yield put(authenticationSlice.actions.setAffiliate(storageObj));
              yield put(authenticationSlice.actions.loginSuccess({ time: new Date() }));
            },
            authTokenStorage.expireInSeconds,
            true
          );
          //#endregion
        }
      }
    }
    //#endregion

    //#region Currency
    const currencyStorage = getStorage(LocalStorageKey.Currency);
    let usageCurrency = currencyStorage ?? ipLocation?.currency_code ?? CurrencyName.USD;
    if (!SupportedCurrencies.find((f) => f.Name === usageCurrency)) {
      usageCurrency = CurrencyName.USD;
    }
    setStorage(LocalStorageKey.Currency, usageCurrency);
    yield put(accountSlice.actions.setCurrency(usageCurrency));
    //#endregion

    yield put(sharedSlice.actions.initialized());
  } catch (ex) {
    console.error('Initialization', ex);

    removeStorage(LocalStorageKey.AuthToken);
    removeStorage(LocalStorageKey.Affiliate);
    removeStorage(LocalStorageKey.IpLocation);
    removeStorage(LocalStorageKey.FlightSuppliers);

    yield put(accountSlice.actions.removeProfile());
    yield put(authenticationSlice.actions.logout());

    localStorage.setItem('__CE__', ex.toString()!);

    if (!window.location.href.includes('/auth/login')) {
      window.location.href = '/auth/login';
    } else {
      window.location.reload();
    }
  }
}

export function* watchShared() {
  yield all([takeLatest(HandleHttpError, handleHttpErrorDefault), takeLatest(InitializeApp, initializeApp)]);
}
