export enum UserPermission {
  AddCreditCard = 'AddCreditCard',
  BackOffice = 'BackOffice',
  CancelBooking = 'CancelBooking',
  ChargeDeposit = 'ChargeDeposit',
  ConfirmAndIncreaseReceipt = 'ConfirmAndIncreaseReceipt',
  CreateTicket = 'CreateTicket',
  Hangfire = 'Hangfire',
  MakeBooking = 'MakeBooking',
  ManageCommission = 'ManageCommission',
  ManageCompanyAndUser = 'ManageCompanyAndUser',
  ManualReceipt = 'ManualReceipt',
  Permissions = 'Permissions',
  UseDeposit = 'UseDeposit',
  Users = 'Users',
  ViewBookings = 'ViewBookings',
  ViewInvoices = 'ViewInvoices',
  ViewTickets = 'ViewTickets',
  ViewTransactions = 'ViewTransactions',
  VoidTicket = 'VoidTicket',
}
