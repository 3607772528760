import { ArrowLeftOutlined, CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import React from 'react';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Translations } from '../../../../features/localization';
import { urlWithQuery } from '../../../../utils/helpers';
import { BookingModuleType } from '../../../booking/types';
import { PaymentMethodQueryString } from '../../types';
import classes from './PaymentConfirmResult.module.scss';

type Props = {
  paymentSuccess: boolean;
  bookingPending: boolean;
  refundingDepositPending: boolean;
  refundingDepositError: boolean;
  complete: boolean;
  reserveId: string;
  username: string;
  canceled: boolean;
  deposit?: boolean;
  type: BookingModuleType;
  manualBook?: boolean;
  extraSuccess?: React.ReactNode;
  unconfirmedBook?: boolean;
};
const PaymentConfirmResult: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const translatedType = () => {
    switch (props.type) {
      case 'Hotel':
        return t(Translations.Common.Hotel);
      case 'Flight':
        return t(Translations.Common.Flight);
      case 'Package':
        return t(Translations.Booking.Package);
      case 'DomesticFlight':
        return t(Translations.Flight.DomesticFlight);
      case 'DomesticHotel':
        return t(Translations.Common.Hotel);
      default:
        break;
    }
  };

  const pendingComponent = () => {
    return (
      <Card className={'box-shadow'}>
        <div className="text-center  my-20">
          <LoadingOutlined className={classes.resultIcon} />
          {props.bookingPending && <h3 className="title-lg">{t(Translations.Payment.PaymentOperationPending)}</h3>}
          {props.refundingDepositPending && <h3 className="title-lg">{t(Translations.Payment.RefundingDeposit)}</h3>}
          <div className="mt-3">
            <BootstrapAlert variant="warning" className="d-inline-block">
              <span className="mx-1 fas fa-exclamation-triangle" />{' '}
              {t(props.refundingDepositPending ? Translations.Common.DoNotCloseThisWindow : Translations.Payment.DoNotCloseThisWindowUntilWeBookTheVar, {
                type: translatedType()?.toLowerCase(),
              })}
            </BootstrapAlert>
          </div>
          <div>
            {t(Translations.Booking.ReserveId)} : <span className="font-weight-bolder">{props.reserveId}</span>
          </div>
        </div>
      </Card>
    );
  };

  const successComponent = () => {
    const renderUnconfirmed = () => (
      <>
        <div>{t(props.type === 'Flight' ? Translations.Flight.UnconfirmedTicket : Translations.Hotel.UnconfirmedBook)}</div>
        <div>{t(Translations.Flight.UnconfirmedTicketExtra)}</div>
        <div className="font-weight-bold text-danger">{t(Translations.Flight.PleaseAvoidDuplicateReservation)}</div>
      </>
    );

    return (
      <Card className={'box-shadow'}>
        <div className="text-center my-20">
          <span className="text-success">
            <CheckCircleOutlined className={classes.resultIcon} />
          </span>
          <h3 className="title-lg">{t(Translations.Payment.PaymentOperationSucceed)}</h3>
          <div className="mb-4 font-size-h5 text-info">
            {props.type === 'Flight' && (
              <>
                {props.unconfirmedBook
                  ? renderUnconfirmed()
                  : props.manualBook
                  ? t(Translations.Flight.ManualBookSuccessResultDescription)
                  : t(Translations.Flight.TicketHasBeenIssuedSuccessfully)}
              </>
            )}
            {props.type === 'Hotel' && props.unconfirmedBook ? renderUnconfirmed() : t(Translations.Booking.BookingHasBeenSuccessful)}
            {props.type === 'DomesticFlight' && t(Translations.Flight.TicketHasBeenIssuedSuccessfully)}
          </div>
          <div className="mb-4 title-sm">
            {t(Translations.Booking.ReserveId)} : <span className="font-weight-bolder">{props.reserveId}</span>
          </div>
          {props.extraSuccess}
        </div>
      </Card>
    );
  };

  const errorComponent = () => {
    return (
      <Card className={'box-shadow'}>
        <div className="text-center  my-20">
          <span className="text-danger">
            <CloseCircleOutlined className={classes.resultIcon} />
          </span>
          <h3 className="title-lg text-danger">
            {!props.paymentSuccess ? t(Translations.Payment.PaymentOperationFailed) : t(Translations.Booking.BookingFailed)}
          </h3>
          {props.canceled ? (
            <>
              <div>{t(Translations.Payment.SeemsYouCanceledPaymentOperation)}</div>
              <div className="mb-4 text-info">{t(Translations.Payment.GoingBackToPaymentPageAutomatically, { count: 10 })}</div>
            </>
          ) : (
            <>
              {props.deposit ? (
                props.refundingDepositError ? (
                  <h3 className="title-lg text-danger">{t(Translations.Payment.RefundingDepositFailed)}</h3>
                ) : (
                  <h3 className="title-lg text-success">{t(Translations.Payment.RefundingDepositSuccess)}</h3>
                )
              ) : null}
              {/*ToDo : uncomment these lines of codes when you implemented Contact Us*/}
              {/*<div className="mb-3">{t(Translations.Support.ContactUsForMoreInformation)}</div>*/}
              {/*<Button className="mb-4" type="default" htmlType="button" icon={<span className="far fa-envelope mx-1" />}>*/}
              {/*  {t(Translations.Common.ContactUs)}*/}
              {/*</Button>*/}
            </>
          )}

          <div className="mb-4 title-sm">
            {t(Translations.Booking.ReserveId)} : <span className="font-weight-bolder">{props.reserveId}</span>
          </div>
          <Link to={urlWithQuery('/payment/method', { reserveId: props.reserveId, username: props.username, type: props.type } as PaymentMethodQueryString)}>
            <Button type="primary" htmlType="button" icon={<ArrowLeftOutlined />}>
              {t(Translations.Payment.BackToPayment)}
            </Button>
          </Link>
        </div>
      </Card>
    );
  };

  return props.paymentSuccess
    ? props.bookingPending || props.refundingDepositPending
      ? pendingComponent()
      : props.complete
      ? successComponent()
      : errorComponent()
    : errorComponent();
});

export default PaymentConfirmResult;
