import Axios from 'axios';

import { AxiosInstanceData2 } from '../../types';
import { urlWithQuery } from '../../utils/helpers';
import { IdentityApiDataMap, IdentityApiUrls } from './identity-apiData';
import { NotificationGetUserNotificationRq } from './identity-apiTypes';

const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_AuthenticationApiBaseUrl;

export const NotificationHttp = {
  getUserNotification: (data: NotificationGetUserNotificationRq) => {
    const url = urlWithQuery(IdentityApiUrls.Notification.GetUserNotification, data);
    return axiosInstance.get(url);
  },
};

export const IdentityAxiosData: AxiosInstanceData2 = {
  instance: axiosInstance,
  DataMap: {
    MapObject: IdentityApiDataMap,
    Urls: IdentityApiUrls,
  },
};
