import { Card, Col, Tooltip } from 'antd';
import sum from 'lodash/sum';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useGlobalization } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { toQueryString } from '../../../../utils/helpers';
import { PackagesQueryString } from '../../../package/types';
import classes from './RecentSearches.module.scss';

type Props = {
  search: PackagesQueryString;
};
const RecentSearchPackage: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const { reversePositionOnRtl, formatShortDate, formatShortMonthWeekDay } = useGlobalization();

  return (
    <Col xs={24} sm={12} className={classes.recentSearchItem}>
      <Link to={`packages?${toQueryString(props.search, 'bracket')}`} className={classes.recentSearchItemLink}>
        <Card className={`antd-card-small-padding ${classes.recentSearchItemCard} ${reversePositionOnRtl('left')}-aligned`}>
          <div className={classes.recentSearchInfo}>
            <h4 className={'title-sm mb-1'}>
              <Tooltip title={props.search.originText}>{props.search.originCode}</Tooltip> {t(Translations.Common.To2)}{' '}
              <Tooltip title={props.search.destinationText}>{props.search.destinationCode}</Tooltip>
            </h4>
            <div className={'text-primary font-weight-bold mb-1'}>
              <Tooltip title={<span className="en-font">{formatShortDate(props.search.checkIn)}</span>}>
                {formatShortMonthWeekDay(props.search.checkOut)}
              </Tooltip>{' '}
              -{' '}
              <Tooltip title={<span className="en-font">{formatShortDate(props.search.checkIn)}</span>}>
                {formatShortMonthWeekDay(props.search.checkOut)}
              </Tooltip>
            </div>
            <span className={'text-muted'}>
              {t(Translations.Hotel.RoomCount, { count: props.search.adults.length })} {t(Translations.Common.Comma)}{' '}
              {t(Translations.Common.AdultCount, { count: sum(props.search.adults) })} {t(Translations.Common.Comma)}{' '}
              {t(Translations.Common.ChildCount, { count: sum(props.search.children) })}
            </span>
          </div>
          <span className={`fas fa-box bg-purple type-circle-icon ${classes.recentSearchItemIcon}`} />
          <span className={`text-primary ${classes.recentSearchItemSearch}`}>{t(Translations.Common.Search)}</span>
        </Card>
      </Link>
    </Col>
  );
});

export default RecentSearchPackage;
