import { Tooltip } from 'antd';
import React from 'react';

import { MarketingAirline } from '../../../flight/apiTypes';
import { FormattedCurrency } from '../../../shared';

type Props = {
  airline?: MarketingAirline;
  fare: number;
  currency: string;
};
const PriceCalendarItem: React.VFC<Props> = React.memo((props: Props) => {
  return (
    <>
      {props.airline && (
        <Tooltip title={`${props.airline.name} (${props.airline.code})`}>
          <img src={props.airline.photoUrl} alt={props.airline.name} />
        </Tooltip>
      )}
      <FormattedCurrency number={props.fare} currency={props.currency} />
    </>
  );
});

export default PriceCalendarItem;
