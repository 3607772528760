import { Button } from 'antd';
import React from 'react';
import { Tooltip as BootstrapTooltip, Dropdown, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DropdownTopbarItemToggler } from '../../../../../_metronic/_partials/dropdowns';
import { Translations } from '../../../../../features/localization';
import { SupportPaths } from '../../../../support';

type Props = {};
const NotificationsDropdown: React.VFC<Props> = React.memo(() => {
  const { t } = useTranslation();

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-notifications">
        <OverlayTrigger overlay={<BootstrapTooltip id="language-panel-tooltip">{t(Translations.Tooltips.SelectLanguage)}</BootstrapTooltip>}>
          <div className="btn btn-icon btn-clean btn-dropdown btn-lg mx-1">
            <i className="fas fa-bell" />
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
        <div className="p-4">
          A
          <hr />
          <Link to={SupportPaths.Notifications}>
            <Button type="primary" className="w-100">
              {t(Translations.Common.SeeAll)}
            </Button>
          </Link>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
});

export default NotificationsDropdown;
