import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { getAuthTokenStorage } from '../../modules/authentication';
import { ApiDataItem, ApiErrorStorage } from '../../types';
import { addOrSetArrayStorage, getNowISO, removeStorage, toJson } from '../../utils/helpers';
import { LocalStorageKey } from '../constants';
import { RootState, store } from '../redux';

export const cancelRequest = (apiDataItem: ApiDataItem, message?: string) => {
  if (apiDataItem) {
    apiDataItem.cancelTokenSource?.cancel(message);
    apiDataItem.cancelTokenSource = undefined;
  }
};

export const requestSetAuthTokenInterceptors = (config: AxiosRequestConfig) => {
  const authTokenStorage = getAuthTokenStorage();

  const state = store.getState() as RootState;
  const authenticationState = state.authentication;
  const currency = state.account.currency;
  const cultureName = state.account.culture.Name;

  config.headers = {
    apikey: authenticationState.terminalId,
    ...config.headers,
    'Accept-Language': cultureName ?? 'en-us',
    'Content-Type': 'application/json',
    currency: currency ?? 'USD',
    tenantid: authenticationState.tenant?.id.toString() ?? '7',
  };
  if (authTokenStorage?.accessToken) {
    config.headers['Authorization'] = `Bearer ${authTokenStorage.accessToken}`;
  }
  return config;
};

export const requestSetCancelTokenInterceptors = async (config: AxiosRequestConfig) => {
  //@ts-ignore
  const apiDataItem: ApiDataItem = config.apiDataItem;
  if (apiDataItem) {
    apiDataItem.cancelTokenSource = Axios.CancelToken.source();
    config.cancelToken = apiDataItem.cancelTokenSource.token;
  }
  return config;
};

export const responseSuccessNullifyCancelTokenInterceptors = async (response: AxiosResponse) => {
  //@ts-ignore
  const apiDataItem: ApiDataItem = response.config.apiDataItem;
  if (apiDataItem) {
    apiDataItem!.cancelTokenSource = undefined;
  }
  return response;
};

export const responseErrorNullifyCancelTokenInterceptors = async (error: any) => {
  if (Axios.isCancel(error)) {
    console.info('Request cancelled');
    return { ___customCancel___: true };
  }

  const apiDataItem: ApiDataItem = error.config.apiDataItem;
  if (apiDataItem) {
    apiDataItem.cancelTokenSource = undefined;
  }

  addOrSetArrayStorage(
    LocalStorageKey.ApiErrors,
    { date: getNowISO(), response: error.response, message: error.message, config: error.config } as ApiErrorStorage,
    200
  );

  console.error('Error Response', toJson(error.response));
  console.error('Error Request', error.request);
  console.error('Error Message', toJson(error.message));
  console.error('Error Config', toJson(error.config));

  if (error.response?.status === 401) {
    removeStorage(LocalStorageKey.AuthToken);
    removeStorage(LocalStorageKey.Affiliate);
    removeStorage(LocalStorageKey.FlightSuppliers);
    window.location.href = '/auth/login';
  }

  return error;
};
