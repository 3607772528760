import { HomeOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Clock from '../../../../assets/timer.svg';
import { Translations } from '../../../../features/localization';
import { isDevelopment } from '../../../../utils/helpers';

type Props = {
  endTime?: string;
  onRefresh?: () => void;
};
const TimeoutModal: React.VFC<Props> = React.memo((props: Props) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleRefresh = () => {
    if (props.onRefresh) {
      props.onRefresh();
      setVisible(false);
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (props.endTime) {
      if (isDevelopment()) {
        console.log('Start Timeout Modal : ', new Date());
        console.log('End Timeout Modal should be : ', new Date(props.endTime));
      }

      const timeoutMs = new Date(props.endTime).getTime() - Date.now();
      timeoutRef.current = setTimeout(() => {
        console.log('End Timeout Modal : ', new Date());
        setVisible(true);
      }, timeoutMs);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [props.endTime]);

  return (
    <Modal visible={visible} closable={false} footer={null} className="text-center" centered>
      <div className="py-4">
        <img src={Clock} alt="timer" width={90} height={90} className="mb-6" />
        <h3 className="title-lg">{t(Translations.Common.StillAround)}</h3>
        <p className="mb-4">{t(Translations.Common.IdleTimeoutModalContent)}</p>
        <Space>
          <a href="/">
            <Button type="default" htmlType="button" icon={<HomeOutlined />} size="large" className="mt-3">
              {t(Translations.Menu.Home)}
            </Button>
          </a>
          <Button type="primary" htmlType="button" icon={<SyncOutlined />} size="large" onClick={handleRefresh} className="mt-3">
            {t(Translations.Common.Refresh)}
          </Button>
        </Space>
      </div>
    </Modal>
  );
});

export default TimeoutModal;
