import { Collapse, Skeleton } from 'antd';
import range from 'lodash/range';
import React, { useEffect, useState } from 'react';

import { useHttpCall } from '../../../../../features/hooks';
import { AbpErrorAlert } from '../../../../shared';
import { GetFlightRulesResponse } from '../../../apiTypes';
import { getFlightRulesHttp } from '../../../http/flight-http';

type Props = {
  itineraryKey?: string;
};
const FlightDetailsRules: React.VFC<Props> = React.memo((props) => {
  const [flightRules, setFlightRules] = useState<GetFlightRulesResponse['flightRules']>();

  const getFlightRulesApi = useHttpCall(getFlightRulesHttp);

  useEffect(() => {
    if (props.itineraryKey) {
      getFlightRulesApi.call<GetFlightRulesResponse>({ key: props.itineraryKey }).then((response) => {
        if (response && response.success) {
          setFlightRules(response.result!.flightRules);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.itineraryKey]);

  return (
    <>
      <AbpErrorAlert error={getFlightRulesApi.error} />
      {getFlightRulesApi.pending ? (
        <Collapse>
          {range(5).map((item) => (
            <Collapse.Panel key={item} header={<Skeleton.Input active size="small" style={{ width: 300 }} />} />
          ))}
        </Collapse>
      ) : (
        <>
          {flightRules?.map((item) => (
            <React.Fragment key={item.fareBase}>
              {item.fareBase && <h3>{item.fareBase}</h3>}
              <Collapse>
                {item.flightRule.map((rule, index) => (
                  <Collapse.Panel key={index} header={rule.ruleTitle}>
                    {rule.ruleDescription}
                  </Collapse.Panel>
                ))}
              </Collapse>
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
});

export default FlightDetailsRules;
