import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import sortBy from 'lodash/sortBy';
import uniqWith from 'lodash/uniqWith';
import moment from 'moment';

import { DomesticFlightItem, DomesticFlightResponse } from '../apiTypes';
import {
  DomesticFlightBusinessClass,
  DomesticFlightBusinessValues,
  DomesticFlightFilterField,
  DomesticFlightFilterFlight,
  DomesticFlightPath,
  DomesticFlightPriceRange,
  DomesticFlightSelected,
  DomesticFlightSortFlights,
  DomesticFlightTripType,
} from '../types';

type DomesticFlightsSlice = {
  temp: {
    departureFlights: DomesticFlightItem[];
    returnFlights: DomesticFlightItem[];
    flights: DomesticFlightItem[];
    apiPending: boolean;
    pendingFilter: boolean;
    page: number;
    pageSize: number;
    total: number;
    sort: DomesticFlightSortFlights;
    // filterItems: FilterItems;
    filters: DomesticFlightFilterFlight;
    path: DomesticFlightPath;
    flightSelected: DomesticFlightSelected;
    flightTripType: DomesticFlightTripType;

    filterCabinClass: string[];
    filterBusinessClass: ('System' | 'Charter')[];
    filterPrice: DomesticFlightPriceRange | [];
    filterAirlines: string[];
    filterDepartureTime: [number, number] | undefined;
    filterArrivalTime: [number, number] | undefined;
    loadingValidate: string;

    airlines: DomesticFlightItem[];
    businessClass: DomesticFlightBusinessClass;
    cabinClass: object[] | {};
    priceRange: DomesticFlightPriceRange;
    departureTime: [number, number];
    arrivalTime: [number, number];
  };
};

const initialState: DomesticFlightsSlice = {
  temp: {
    departureFlights: [],
    returnFlights: [],
    flights: [],
    apiPending: true,
    pendingFilter: true,
    page: 1,
    pageSize: 10,
    total: 0,
    sort: 'price',
    flightTripType: 'OneWay',

    airlines: [],
    businessClass: {} as DomesticFlightBusinessClass,
    cabinClass: {} as object[],
    priceRange: [0, 100] as DomesticFlightPriceRange,
    departureTime: [0, 24],
    arrivalTime: [0, 24],

    filters: {
      price: [],
      cabinClass: [],
      businessClass: [],
      airlines: [],
      departureTime: undefined,
      arrivalTime: undefined,
    },
    path: 'departureFlights',
    flightSelected: {
      departureFlights: '',
      returnFlights: '',
    },
    filterCabinClass: [],
    filterBusinessClass: [],
    filterPrice: [],
    filterAirlines: [],
    filterDepartureTime: undefined,
    filterArrivalTime: undefined,
    loadingValidate: '',
  },
};

const domesticFlightSlice = createSlice({
  name: 'domesticFlight',
  initialState: initialState,
  reducers: {
    tempSetresetAll: (state) => {
      state.temp.departureFlights = [];
      state.temp.returnFlights = [];
      state.temp.flights = [];
      state.temp.total = 0;

      state.temp.airlines = [];
      state.temp.businessClass = {} as DomesticFlightBusinessClass;
      state.temp.cabinClass = {} as object[];
      state.temp.priceRange = [0, 100] as DomesticFlightPriceRange;
      state.temp.departureTime = [0, 24];
      state.temp.arrivalTime = [0, 24];

      state.temp.filters = {
        price: [],
        cabinClass: [],
        businessClass: [],
        airlines: [],
        departureTime: undefined,
        arrivalTime: undefined,
      };
      state.temp.flightSelected = {
        departureFlights: '',
        returnFlights: '',
      };
      state.temp.filterCabinClass = [];
      state.temp.filterBusinessClass = [];
      state.temp.filterPrice = [];
      state.temp.filterAirlines = [];
      state.temp.filterDepartureTime = undefined;
      state.temp.filterArrivalTime = undefined;

      state.temp.path = 'departureFlights';
      state.temp.page = 1;
    },
    tempSetFlights: (state, action: PayloadAction<DomesticFlightResponse>) => {
      const departureFlights = uniqWith(state.temp.departureFlights.concat(action.payload.departureFlights), isEqual);
      const returnFlights = uniqWith(state.temp.returnFlights.concat(action.payload.returnFlights), isEqual);

      state.temp.departureFlights = departureFlights;
      state.temp.returnFlights = returnFlights;
      state.temp.flights = departureFlights;
      state.temp.total = state.temp.flights.length;
      state.temp.pendingFilter = state.temp.flights.length ? false : true;
    },
    tempSetApiPending: (state, action: PayloadAction<boolean>) => {
      state.temp.apiPending = action.payload;
    },
    tempSetSortFlights: (state, action: PayloadAction<DomesticFlightItem[]>) => {
      state.temp.flights = action.payload;
    },
    tempSetPage: (state, action: PayloadAction<{ page: number; pageSize?: number }>) => {
      state.temp.page = action.payload.page;
      state.temp.pageSize = action.payload.pageSize ?? 10;
    },
    tempSetSort: (state, action: PayloadAction<DomesticFlightSortFlights>) => {
      state.temp.sort = action.payload;
    },
    tempSetFilterItems: (state) => {
      const list = groupBy(state.temp[state.temp.path], (flight) => flight.airline.code);
      const airlineList = map(list, (items) => minBy(items, (item) => item.adultPrice)) as DomesticFlightItem[];
      state.temp.airlines = sortBy(airlineList, 'adultPrice');

      const businessClass = groupBy(state.temp[state.temp.path], (flight) => flight.flightType);
      // businessClass['Charter'] = businessClass['Charter'];
      // delete businessClass[''];
      state.temp.businessClass = businessClass as unknown as DomesticFlightBusinessClass;

      const cabinClass = groupBy(state.temp[state.temp.path], (flight) => flight.cabinClass.name);
      state.temp.cabinClass = cabinClass as Object[] | {};

      const minPrice = minBy(state.temp[state.temp.path], (flight) => flight.adultPrice);
      const maxPrice = maxBy(state.temp[state.temp.path], (flight) => flight.adultPrice);
      state.temp.priceRange = [minPrice?.adultPrice, maxPrice?.adultPrice] as DomesticFlightPriceRange;
    },

    tempSetPath: (state, action: PayloadAction<DomesticFlightPath>) => {
      state.temp.path = action.payload;
    },
    tempSetFlightSelected: (state, action: PayloadAction<{ item: DomesticFlightPath; value: DomesticFlightItem | '' }>) => {
      state.temp.flightSelected[action.payload.item] = action.payload.value;
    },
    tempSetFlightTripType: (state, action: PayloadAction<DomesticFlightTripType>) => {
      state.temp.flightTripType = action.payload;
    },
    tempSetLoadingValidate: (state, action: PayloadAction<string>) => {
      state.temp.loadingValidate = action.payload;
    },
    tempSetFilters: (state, action: PayloadAction<{ item: DomesticFlightFilterField; values: any }>) => {
      state.temp[action.payload.item] = action.payload.values;

      let filteredResults = [];
      filteredResults = [...state.temp[state.temp.path]].filter((flight) => {
        const cobinFilter = state.temp.filterCabinClass.length ? state.temp.filterCabinClass.indexOf(flight.cabinClass.name as string) : 1;
        if (
          (state.temp.filterAirlines.length === 0 || state.temp.filterAirlines.includes(flight.airline.code)) &&
          (flight.adultPrice && state.temp.filterPrice.length ? flight.adultPrice >= state.temp.filterPrice[0] : true) &&
          (flight.adultPrice && state.temp.filterPrice.length ? flight.adultPrice <= state.temp.filterPrice[1] : true) &&
          (state.temp.filterBusinessClass.length === 0 || state.temp.filterBusinessClass.includes(flight.flightType as DomesticFlightBusinessValues)) &&
          (state.temp.filterCabinClass.length === 0 || cobinFilter > 0 || cobinFilter === 0) &&
          (!state.temp.filterDepartureTime ||
            (state.temp.filterDepartureTime[0] <= Number(moment(flight.departureTime).format('H')) &&
              state.temp.filterDepartureTime[1] > Number(moment(flight.departureTime).format('H')))) &&
          (!state.temp.filterArrivalTime ||
            (state.temp.filterArrivalTime[0] <= Number(moment(flight.arrivalTime).format('H')) &&
              state.temp.filterArrivalTime[1] > Number(moment(flight.arrivalTime).format('H'))) ||
            (!state.temp.filterArrivalTime[0] && !flight.arrivalTime))
        ) {
          return true;
        } else {
          return false;
        }
      });
      state.temp.flights = filteredResults;
      state.temp.page = 1;
      state.temp.total = filteredResults.length;
    },
    tempResetFiltres: (state) => {
      state.temp.filterCabinClass = [];
      state.temp.filterBusinessClass = [];
      state.temp.filterPrice = [];
      state.temp.filterAirlines = [];
      state.temp.filterDepartureTime = undefined;
      state.temp.filterArrivalTime = undefined;

      state.temp.flights = state.temp[state.temp.path];
      state.temp.total = state.temp[state.temp.path].length;
    },
  },
});

export default domesticFlightSlice;
