import Axios from 'axios';

import { AxiosInstanceData2 } from '../../../types';
import { isDevelopment } from '../../../utils/helpers';
import { SharedApiDataMap, SharedApiUrls } from './shared-apiData';

const axiosInstance = Axios.create();

if (isDevelopment()) {
  // const mock = new MockAdapter(axiosInstance, { onNoMatch: 'passthrough', delayResponse: 0 });
  // sharedMock(mock);
}

export const getIPLocationHttp = () => {
  return axiosInstance.get(`${process.env.REACT_APP_IpLocationApiBaseUrl}${SharedApiUrls.IpLocation.GetIpLocation}`);
};

export const SharedAxiosData: AxiosInstanceData2 = {
  instance: axiosInstance,
  DataMap: {
    MapObject: SharedApiDataMap,
    Urls: SharedApiUrls,
  },
};
