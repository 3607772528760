import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FormInstance } from 'antd/es/form';
import range from 'lodash/range';
import React from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector, useManualRerender } from '../../../../../features/hooks';
import { flightSlice } from '../../../index';
import { FlightFormValues } from '../../../types';
import FlightSearchFlight from '../FlightSearchFlight/FlightSearchFlight';

type Props = {
  inline?: boolean;
  formInstance: FormInstance<FlightFormValues>;
};
const FlightSearchFlights: React.VFC<Props> = React.memo((props) => {
  const dispatch = useDispatch();

  const { rerender } = useManualRerender();

  const flightSearchTempState = useAppSelector((state) => state.flight.temp.flightSearch);

  const handleAddWayChanged = () => {
    dispatch(flightSlice.actions.tempSetFlightSearch({ qty: flightSearchTempState.qty + 1 }));
  };

  //Need this to for manually rerender
  const handleDepartureDateChanged = () => {
    rerender();
  };

  return (
    <>
      {range(0, flightSearchTempState.qty).map((item) => {
        const fieldsValue: FlightFormValues = props.formInstance.getFieldsValue();
        const previousDepartureDate = fieldsValue.departures?.[item - 1];
        const nextDepartureDate = fieldsValue.departures?.[item + 1];

        return (
          <FlightSearchFlight
            formInstance={props.formInstance}
            inline={props.inline}
            key={item}
            order={item}
            previousDepartureDate={previousDepartureDate}
            nextDepartureDate={nextDepartureDate}
            onDepartureDateChanged={handleDepartureDateChanged}
          />
        );
      })}
      {flightSearchTempState.tripType === 'OpenJaw' && flightSearchTempState.qty < 5 && flightSearchTempState.qty > 1 && (
        <Button type="dashed" htmlType="button" icon={<PlusOutlined />} className="mb-3" onClick={() => handleAddWayChanged()} />
      )}
    </>
  );
});

export default FlightSearchFlights;
