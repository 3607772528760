import range from 'lodash/range';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { fromQueryString } from '../../../../../utils/helpers';
import { HotelsQueryString } from '../../../types';
import HotelSearchFormRoom from '../HotelSearchFormRoom/HotelSearchFormRoom';

type Props = {
  roomQty: number;
  onRemoveRoom: () => void;
  inline?: boolean;
  fillFromQueryString?: boolean;
  defaultAllChildrenQty: number;
  resetTime: number | undefined;
  decreaseChildren?: { date: number; number: number };
};

const HotelSearchFormRooms: React.VFC<Props> = React.memo((props) => {
  const location = useLocation();
  const queryStringObj = fromQueryString<HotelsQueryString>(location.search, 'bracket');

  const [allChildrenQty, setAllChildrenQty] = useState(props.defaultAllChildrenQty);

  const handleChildrenQtyChanged = useCallback((qty: number) => {
    setAllChildrenQty((prevState) => prevState + qty);
  }, []);

  useEffect(() => {
    if (props.resetTime) {
      setAllChildrenQty(0);
    }
  }, [props.resetTime]);

  useEffect(() => {
    if (props.decreaseChildren) {
      setAllChildrenQty((prevState) => prevState - props.decreaseChildren!.number);
    }
  }, [props.decreaseChildren]);

  return (
    <>
      {range(props.roomQty).map((item) => {
        let defaultChildrenQty = 0;

        if (props.fillFromQueryString && queryStringObj.children[item]) {
          defaultChildrenQty = +queryStringObj.children[item];
        }

        return (
          <HotelSearchFormRoom
            key={item}
            allRoomQty={props.roomQty}
            allChildrenQty={allChildrenQty}
            defaultChildrenQty={defaultChildrenQty}
            onChildQtyChanged={handleChildrenQtyChanged}
            roomNumber={item}
            inline={props.inline}
            onRemoveRoom={props.onRemoveRoom}
            showRemoveButton={props.roomQty !== 1 && props.roomQty === item + 1}
            resetTime={props.resetTime}
          />
        );
      })}
    </>
  );
});

export default HotelSearchFormRooms;
