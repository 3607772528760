import moment, { Moment } from 'moment';

import { LanguageCode } from '../../features/localization/cultures';
import { LocalizationHelpers } from './LocalizationHelpers';

const today = moment().utc(true).startOf('day');

export const disableTodayAndBeforeAntdDatePicker = (current: any) => {
  return current && moment(current).utc(true) < moment().utc(true).endOf('day');
};

export const disableTodayAndAfterAntdDatePicker = (current: any) => {
  return current && moment(current).utc(true) > moment().utc(true).endOf('day');
};

export const disableYesterdayAndBeforeAntdDatePicker = (current: any) => {
  return moment(current).utc(true) < moment().utc(true).subtract(1, 'day').endOf('day');
};

export const disableTomorrowAndAfterAntdDatePicker = (current: any) => {
  return moment(current).utc(true) > moment().utc(true).endOf('day');
};

export const disableBeforeDateAntdDatePicker = (current: any, date: Moment) => {
  return moment(current).utc(true).isBefore(moment(date).utc(true), 'day');
};

export const disableAfterDateAntdDatePicker = (current: any, date: Moment) => {
  return moment(current).utc(true).isAfter(moment(date).utc(true), 'day');
};

export const disableOtherDatesAntdDatePicker = (
  current: Moment | any,
  from: Moment | undefined,
  to: Moment | undefined,
  options?: {
    disableYesterdayAndBefore?: boolean;
    disableToday?: boolean;
  }
) => {
  let beforeCondition = false;
  let afterCondition = false;
  let yesterdayAndBeforeCondition = false;
  let todayCondition = false;

  if (from) {
    beforeCondition = disableBeforeDateAntdDatePicker(current, from);
  }
  if (to) {
    afterCondition = disableAfterDateAntdDatePicker(current, to);
  }
  if (options?.disableYesterdayAndBefore) {
    yesterdayAndBeforeCondition = disableYesterdayAndBeforeAntdDatePicker(current);
  }
  if (options?.disableToday) {
    todayCondition = moment(current).utc(true).startOf('day').isSame(today);
  }

  return beforeCondition || afterCondition || yesterdayAndBeforeCondition || todayCondition;
};

export const antdDatePickerValue = (date: Date | undefined): Moment | undefined => {
  return date ? moment(date) : undefined;
};

export const getAntdLocale = () => {
  let antdLocale;
  switch (LocalizationHelpers.getCurrentCulture().LanguageCode) {
    case LanguageCode.En:
      antdLocale = require('antd/es/locale/en_US');
      break;
    case LanguageCode.Fa:
      antdLocale = require('antd/es/locale/fa_IR');
      break;
    case LanguageCode.Nb:
      antdLocale = require('antd/es/locale/nb_NO');
      break;
  }
  return antdLocale;
};

export const inputNumberCommaSeparatedProps = {
  formatter: (value: number | string | undefined) => value!.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  parser: (value: string | undefined) => value!.replace(/(,*)/g, ''),
};
