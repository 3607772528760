import { Table, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDefinedTranslations, useGlobalization } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { Passenger } from '../../types';

type Props = {
  passengers: Passenger[];
};

const PassengersInformation: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const { gender } = useDefinedTranslations();
  const { formatShortDate } = useGlobalization();

  const passengers = useMemo(() => {
    if (props.passengers) {
      return props.passengers.map((item, index) => {
        return { ...item, row: index + 1 };
      });
    }
  }, [props.passengers]);

  const passengerFareColumns = [
    {
      title: t(Translations.Common.Row),
      dataIndex: 'row',
      key: 'row',
    },
    {
      title: t(Translations.Common.Gender),
      dataIndex: 'gender',
      render: (val: boolean) => gender(val),
    },
    {
      title: t(Translations.Common.Name),
      render: (val: string, record: Passenger) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: t(Translations.Common.Birthday),
      dataIndex: 'birthDate',
      key: 'birthDate',
      render: (val: string) => val && <Tooltip title={val}>{formatShortDate(val)}</Tooltip>,
    },
    {
      title: t(Translations.Common.NationalID),
      dataIndex: 'nationalId',
      key: 'nationalId',
    },
    {
      title: t(Translations.Booking.PassportNumber),
      dataIndex: 'passportNumber',
      key: 'passportNumber',
    },
    {
      title: t(Translations.Booking.PassportCountry),
      dataIndex: 'passportCountry',
      key: 'passportCountry',
      render: (val: string) => val && <Tooltip title={val}>{val}</Tooltip>,
    },
    {
      title: t(Translations.Booking.PassportExpireDate),
      dataIndex: 'passportExpireDate',
      key: 'passportExpireDate',
      render: (val: string) => val && <Tooltip title={val}>{formatShortDate(val)}</Tooltip>,
    },
  ];

  if (props.passengers[0].departureTicketNumber) {
    passengerFareColumns.push({
      title: t(Translations.Booking.DepartureTicketNumber),
      dataIndex: 'departureTicketNumber',
      key: 'departureTicketNumber',
      render: (val: string) => val && <Tooltip title={val}>{val}</Tooltip>,
    });
  }

  if (props.passengers[0].returnTicketNumber) {
    passengerFareColumns.push({
      title: t(Translations.Booking.ReturnTicketNumber),
      dataIndex: 'returnTicketNumber',
      key: 'returnTicketNumber',
      render: (val: string) => val && <Tooltip title={val}>{val}</Tooltip>,
    });
  }

  return (
    <>
      {/* <h3 className="title-sm mt-5 mb-2">{t(Translations.Flight.PassengersInformation)}</h3> */}
      <div className="box-shadow">
        <Table
          rowKey="row"
          dataSource={passengers}
          columns={passengerFareColumns}
          bordered
          pagination={false}
          className="center-aligned-antd-table small-padding-antd-table antd-dark-label-table"
        />
      </div>
    </>
  );
});

export default PassengersInformation;
