import { LocalStorageKey, LocalStorageVersion } from '../constants/localStorage';

const cleanAndSetAppVersion = () => {
  localStorage.clear();
  localStorage.setItem(LocalStorageKey.LocalStorageVersionKey, LocalStorageVersion.toString());
};

export const cleanStorageOnBeforeVersion = (version: number): boolean => {
  const storageVersion = localStorage.getItem(LocalStorageKey.LocalStorageVersionKey);
  if (!storageVersion) {
    cleanAndSetAppVersion();
    return true;
  } else {
    const storageVersionNumber = +storageVersion;
    if (isNaN(storageVersionNumber) || storageVersionNumber < version) {
      cleanAndSetAppVersion();
      return true;
    }
  }
  return false;
};
