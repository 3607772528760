import { ClearOutlined, LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Radio, Row, Space, Tag } from 'antd';
import startsWith from 'lodash/startsWith';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { useAntdValidation, useGlobalization } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { fromQueryString, getNowISO } from '../../../../utils/helpers';
import { addToRecentSearches } from '../../../booking';
import FlightSearchPassengers from '../../../flight/components/SearchForm/FlightSearchPassengers/FlightSearchPassengers';
import { PDatePicker2 } from '../../../shared';
import { DomesticFlightFormValues, DomesticFlightQueryString, DomesticFlightTripType } from '../../types';
import FlightSearchAirport from './FlightSearchAirport/FlightSearchAirport';

type Props = {
  inline?: boolean;
  paths?: string[];
};

const DomesticFlightSearchForm: React.VFC<Props> = React.memo((props) => {
  const [form] = Form.useForm<DomesticFlightFormValues>();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { culture, formatShortDate } = useGlobalization();
  const queryStringObj = fromQueryString<DomesticFlightQueryString>(location.search);
  let isGregorian = JSON.parse(JSON.stringify(queryStringObj?.isGregorian === 'false' || culture.LanguageCode === 'fa' ? false : true));
  const [flightTripType, setFlightTripType] = useState<DomesticFlightTripType>(queryStringObj.returnTime ? 'RoundTrip' : 'OneWay');

  const { labelWithRules } = useAntdValidation(form);

  useEffect(() => {
    if (startsWith(location.pathname, '/domestic/flights') && props.paths?.length) {
      isGregorian = queryStringObj?.isGregorian === 'false' ? false : true;

      form.setFieldsValue({
        tripType: queryStringObj.returnTime ? 'RoundTrip' : 'OneWay',
        departureCode: { name: `${queryStringObj.departureCode}`, code: props.paths[0] },
        returnCode: { name: `${queryStringObj.returnCode}`, code: props.paths[1] },
        adults: +queryStringObj.adult,
        children: +queryStringObj.children,
        infants: +queryStringObj.infant,
        isGregorian: isGregorian,
      });
      if (queryStringObj.returnTime) {
        form.setFieldsValue({
          roundTripDates: [
            moment(queryStringObj.departureTime).utc(true).format('YYYY-MM-DD'),
            moment(queryStringObj.returnTime).utc(true).format('YYYY-MM-DD'),
          ],
        });
      } else {
        form.setFieldsValue({
          departureDate: moment(queryStringObj.departureTime).utc(true).format('YYYY-MM-DD'),
        });
      }
    }
  }, [location.search]);

  const handleTripTypeChanged = (value: DomesticFlightTripType) => {
    setFlightTripType(value);
  };

  const handleFormValueChange = (changedValues: DomesticFlightFormValues, values: DomesticFlightFormValues) => {
    if (values.infants > values.adults) {
      form.setFieldsValue({
        infants: values.adults,
      });
    }
  };

  const handleSubmit = (values: DomesticFlightFormValues) => {
    let query = `${values.departureCode.code}-${values.returnCode.code}?adult=${values.adults}&children=${values.children}&infant=${values.infants}&departureCode=${values.departureCode.name}&returnCode=${values.returnCode.name}&isGregorian=${values.isGregorian}`;
    let obj: any = {
      airTripType: flightTripType,
      originCode: values.departureCode.code,
      originText: values.departureCode.name,
      destinationCode: values.returnCode.code,
      destinationText: values.returnCode.name,
      adult: values.adults,
      child: values.children,
      infant: values.infants,
      isGregorian: values.isGregorian,
      ct: Date.now(),
    };
    if (flightTripType === 'RoundTrip') {
      const departureTime = moment(values.roundTripDates[0]).format('YYYY-MM-DD');
      const returnTime = moment(values.roundTripDates[1]).format('YYYY-MM-DD');
      query += `&departureTime=${departureTime}&returnTime=${returnTime}`;
      obj.departureTime = departureTime;
      obj.returnTime = returnTime;
    } else {
      const departureTime = moment(values.departureDate).format('YYYY-MM-DD');
      query += `&departureTime=${departureTime}`;
      obj.departureTime = departureTime;
    }
    addToRecentSearches(obj, 'DomesticFlight');
    history.push(`/domestic/flights/${query}`);
  };

  const handleReset = () => {
    form.resetFields();
  };

  const changeDate = (time: 'next' | 'prev') => {
    const values = queryStringObj;

    if (time == 'next') {
      values.departureTime = moment(queryStringObj.departureTime, 'YYYY-MM-DD').add(1, 'days');
    } else {
      values.departureTime = moment(queryStringObj.departureTime, 'YYYY-MM-DD').subtract(1, 'days');
    }
    const departureTime = moment(values.departureTime).format('YYYY-MM-DD');
    let query = `${props.paths?.[0]}-${props.paths?.[1]}?adult=${values.adult}&children=${values.children}&infant=${values.infant}&departureTime=${departureTime}&departureCode=${values.departureCode}&returnCode=${values.returnCode}`;

    history.push(`/domestic/flights/${query}`);
  };

  return (
    <>
      <Form
        labelCol={{ span: 24 }}
        form={form}
        onFinish={handleSubmit}
        onValuesChange={handleFormValueChange}
        className={'flight-search-form'}
        validateTrigger="onSubmit">
        <Row justify="space-between" align="middle">
          <Col>
            <Form.Item className="mb-0" name="tripType" initialValue={flightTripType}>
              <Radio.Group onChange={(event) => handleTripTypeChanged(event.target.value)}>
                <Radio value="OneWay">{t(Translations.Flight.OneWay)}</Radio>
                <Radio value="RoundTrip">{t(Translations.Flight.RoundTrip)}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {startsWith(location.pathname, '/domestic/flights') && flightTripType === 'OneWay' && (
            <Col>
              <Button type="link" htmlType="button" className="px-0" onClick={() => changeDate('prev')}>
                <LeftOutlined className="mirror-in-rtl" /> {t(Translations.Flight.PreviousDay)}
              </Button>
              <Tag className={'mx-2'}>{formatShortDate(queryStringObj.departureTime as string)}</Tag>
              {/* <Tag className={'mx-2'}>2021, Jan 5</Tag> */}
              <Button type="link" htmlType="button" className="px-0" onClick={() => changeDate('next')}>
                {t(Translations.Flight.NextDay)} <RightOutlined className="mirror-in-rtl" />
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[10, 0]}>
          <Col xs={24} xl={props.inline ? 12 : 24}>
            <Row gutter={[10, 0]}>
              <FlightSearchAirport inline={props.inline} formInstance={form} flightTripType={flightTripType} />
              <Col xs={24} md={flightTripType === 'RoundTrip' ? 12 : 8} xl={props.inline ? (flightTripType === 'RoundTrip' ? 12 : 8) : 24}>
                {flightTripType === 'RoundTrip' ? (
                  <Form.Item
                    className="mb-4"
                    name="roundTripDates"
                    {...labelWithRules({
                      label: t(Translations.Flight.DepartureAndReturnDates),
                      rules: [{ type: 'Required' }],
                    })}>
                    <PDatePicker2 minDate={getNowISO()} mode="range" hideTodayButton />
                  </Form.Item>
                ) : (
                  <Form.Item
                    className="mb-4"
                    name="departureDate"
                    {...labelWithRules({
                      label: t(Translations.Flight.DepartureDate),
                      rules: [{ type: 'Required' }],
                    })}>
                    <PDatePicker2 minDate={getNowISO()} hideTodayButton />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={16} xl={props.inline ? 7 : 24}>
            <FlightSearchPassengers formInstance={form} />
          </Col>
          <Col xs={24} md={8} xl={props.inline ? 5 : 24}>
            <div className={`align-with-formItems-md align-with-formItems-lg ${props.inline ? 'align-with-formItems-xl text-md-right' : ''}`}>
              <Space>
                <Button className="form-action-Btn" type="primary" htmlType="submit" icon={<SearchOutlined />} loading={false}>
                  {t(Translations.Common.Search)}
                </Button>

                <Button className="form-action-Btn" type="default" htmlType="button" icon={<ClearOutlined />} onClick={() => handleReset()}>
                  {t(Translations.Common.ResetForm)}
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
});

export default DomesticFlightSearchForm;
