import { errorLogCreateHttp } from '@itours-l/logger';
import React from 'react';

import { isDevelopment, isStaging, toJson } from '../../../../utils/helpers';
import { getAffiliateStorage } from '../../../account';
import { getAuthTokenStorage } from '../../../authentication';
import RenderError from '../../pages/RenderError/RenderError';

class MainErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    //console.warn(error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    const affiliateStorage = getAffiliateStorage();
    const authTokenStorage = getAuthTokenStorage();

    if (!isDevelopment()) {
      try {
        errorLogCreateHttp({
          app: 'Agent',
          type: 'auto',
          //@ts-ignore
          env: isStaging() ? 'staging' : 'prod',
          message: error.message,
          url: window.location.href,
          officeId: affiliateStorage?.name,
          user: authTokenStorage?.user.userName,
          extra: toJson({
            stack: error.stack,
            componentStack: errorInfo.componentStack,
          })!,
        }).then();
      } catch (e: any) {
        console.error('Indexing error log failed.', ' ----------------- ', e);
      }
    }
  }
  render() {
    if (this.state.hasError) {
      return <RenderError />;
    }

    return this.props.children;
  }
}

export default MainErrorBoundary;
