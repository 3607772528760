import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UserPermission } from '../../../features/constants';
import { AbpError, ApiReduxState, Tenant } from '../../../types';
import { resetApiState, setApiErrorState, setApiPendingState, setApiSuccessState } from '../../../utils/helpers';
import { GetAffiliateResponse, LoginPayload, TokenAuthLoginResponse } from '../types';

type AuthenticationState = {
  username?: string;
  userId?: number;
  loginTime?: Date;
  isAuthenticated: boolean;
  fromLoginPage: boolean;
  tenant?: Tenant;
  terminalId: string;
  forgotPasswordSentSwitch: boolean;
  permissions: readonly UserPermission[];
  authToken?: TokenAuthLoginResponse;
  affiliate?: GetAffiliateResponse;
  verifyTenantApi: ApiReduxState;
  loginApi: ApiReduxState;
};

const initialState: AuthenticationState = {
  loginTime: undefined,
  isAuthenticated: false,
  fromLoginPage: false,
  tenant: undefined,
  terminalId: 'ACE01BF4-AAEE-45D6-ABE7-F3FF519052DB',
  forgotPasswordSentSwitch: false,
  permissions: [],
  verifyTenantApi: {},
  loginApi: {},
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState,
  reducers: {
    verifyTenantPending: (state) => {
      setApiPendingState(state.verifyTenantApi);
    },
    verifyTenantSuccess: (state, action: PayloadAction<Tenant>) => {
      setApiSuccessState(state.verifyTenantApi);

      state.tenant = action.payload;
    },
    verifyTenantError: (state, action: PayloadAction<AbpError>) => {
      setApiErrorState(state.verifyTenantApi, action.payload);
    },
    setTenant: (state, action: PayloadAction<Tenant>) => {
      state.tenant = action.payload;
    },
    forgotPasswordSentSwitch: (state, action: PayloadAction<boolean>) => {
      state.forgotPasswordSentSwitch = action.payload;
    },
    loginPending: (state) => {
      setApiPendingState(state.loginApi);
    },
    setAuthToken: (state, action: PayloadAction<TokenAuthLoginResponse>) => {
      state.authToken = action.payload;
      state.userId = action.payload.user.id;
      state.username = action.payload.user.userName;
    },
    setAffiliate: (state, action: PayloadAction<GetAffiliateResponse>) => {
      state.affiliate = action.payload;
      state.terminalId = action.payload.id;
    },
    loginSuccess: (state, action: PayloadAction<LoginPayload>) => {
      state.isAuthenticated = true;
      state.loginTime = action.payload.time;
      state.fromLoginPage = action.payload.fromLoginPage ?? false;

      resetApiState(state.loginApi);
    },
    loginError: (state, action: PayloadAction<AbpError>) => {
      setApiErrorState(state.loginApi, action.payload);
    },
    setPermissions: (state, action: PayloadAction<readonly string[]>) => {
      state.permissions = action.payload as UserPermission[];
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.loginTime = undefined;
      state.permissions = [];
      state.authToken = undefined;
    },
  },
});

export default authenticationSlice;
