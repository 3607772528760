import flatten from 'lodash/flatten';
import flattenDeep from 'lodash/flattenDeep';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';

import { getDatePortionStringOrUndefinedFromMoment, stringIsNullOrEmpty, toQueryString } from '../../../utils/helpers';
import { HotelCancellationPolicyStatus, HotelGetReserveByIdResponse, HotelItem } from '../apiTypes';
import { HotelDetailsQueryString, HotelsQueryString } from '../types';

export const buildHotelBoardsFilterArray = (hotels: HotelItem[]) => {
  const allBoards = flattenDeep(hotels.map((item) => item.boards));
  return uniqBy(allBoards, 'code');
};

export const getHotelBoardCount = (hotels: HotelItem[] | undefined, boardCode: string) => {
  if (hotels) {
    const allBoards = flattenDeep(hotels.map((item) => item.boards));
    return allBoards.filter((f) => f.code === boardCode).length;
  }
  return 0;
};

export const buildHotelDistrictsFilterArray = (hotels: HotelItem[]) => {
  const allRegions = flattenDeep(hotels.map((item) => item.regions));
  return uniqBy(allRegions, 'id');
};

export const getHotelRegionCount = (hotels: HotelItem[] | undefined, regionId: number) => {
  if (hotels) {
    const allRegions = flattenDeep(hotels.map((item) => item.regions));
    return allRegions.filter((f) => f.id === regionId).length;
  }
  return 0;
};

export const getUniqueHotelFeatures = (hotels: HotelItem[]) => {
  const allFeatures = flattenDeep(hotels.map((item) => item.features));
  return uniqBy(allFeatures, 'keyword');
};

export const getHotelFeatureCount = (hotels: HotelItem[] | undefined, featureKeyword: string) => {
  if (hotels) {
    const allFeatures = flattenDeep(hotels.map((item) => item.features));
    return allFeatures.filter((f) => f.keyword === featureKeyword).length;
  }
  return 0;
};

export const getHotelRatingCount = (hotels: HotelItem[] | undefined, rating: number) => {
  if (hotels) {
    const allRatings = flattenDeep(hotels.map((item) => item.rating));
    return allRatings.filter((f) => f === rating).length;
  }
  return 0;
};

export const getHotelsPriceRange = (hotels: HotelItem[]): [number, number] => {
  if (hotels && hotels.length > 0) {
    const min = minBy(hotels, (item) => item.salePrice)!.salePrice;
    const max = maxBy(hotels, (item) => item.salePrice)!.salePrice;
    return [min, max];
  }
  return [0, 0];
};

export const processPriceFilterArray = (array: [number, number], priceType: 'total' | 'average', duration: number) => {
  let newArray = [];
  if (priceType === 'average') {
    for (const item of array) {
      newArray.push(item * duration);
    }
  } else {
    newArray = array;
  }
  return newArray as [number, number];
};

export const getHotelCancellationPolicyStatusTextColor = (status: HotelCancellationPolicyStatus) => {
  switch (status) {
    case 'Refundable':
      return 'green7';
    case 'NonRefundable':
      return 'red';
    case 'Unknown':
      return '';
  }
};

export const getHotelCancellationPolicyStatusAntdTagColor = (status: HotelCancellationPolicyStatus) => {
  switch (status) {
    case 'Refundable':
      return 'green';
    case 'NonRefundable':
      return 'red';
    case 'Unknown':
      return undefined;
  }
};

export const createHotelsAndHotelDetailsQueryStringFromResponse = (response: HotelGetReserveByIdResponse) => {
  const hotelsQueryStringObj = {
    adults: response.accommodation.rooms.map((room) => room.adults),
    children: response.accommodation.rooms.map((room) => room.children),
    childrenAges: flatten(response.accommodation.rooms.map((room) => room.ages)),
    checkIn: getDatePortionStringOrUndefinedFromMoment(moment(response.date.checkIn), true),
    checkOut: getDatePortionStringOrUndefinedFromMoment(moment(response.date.checkOut), true),
    nationality: !stringIsNullOrEmpty(response.nationalityCode) ? response.nationalityCode : undefined,
    destination: response.city.id,
  } as HotelsQueryString;

  const hotels = toQueryString(hotelsQueryStringObj, 'bracket');
  const hotelDetails = toQueryString(omit({ ...hotelsQueryStringObj } as HotelDetailsQueryString, 'destination'), 'bracket');

  return { hotels, hotelDetails };
};
