import { ApiDataItem } from '../../../types';

export enum DomesticHotelApiUrls {
  //Booking
  Availability = '/v2/Hotel/SearchHotels',
  GetLocation = '/api/services/app/Entity/Search',
  GetHotelById = '/v2/Hotel/GetHotelById',
  getPageByUrl = '/v2/Page/GetPageByUrl',
  GetEntityNameByLocation = '/v2/Entity/GetEntityNameByLocation',
  GetRates = '/v2/Comment/GetRates',
  GetScore = '/v2/Comment/GetScore',
  InsertComment = '/v2/Comment/InsertComment',
  Reserve = '/api/services/app/BookingHotelDomestic/Reserve',
  GetReserve = '/api/services/app/BookingHotelDomestic/GetReserve',
  HotelAvailability = '/api/services/app/Booking/Availability',
  HotelAvailabilityById = '/api/services/app/Booking/AvailabilityByHotelId',
  GetRooms = '/api/services/app/Booking/GetRoom',
  Validate = '/api/services/app/Booking/Validate',
  GetValidate = '/api/services/app/Booking/GetValidate',
  PreReserveHotel = '/api/services/app/Booking/PreReserve',
  Confirm = '/api/services/app/Booking/Confirm',
  GetReserveById = '/api/services/app/Reserve/Get',

  //Tenant Reserves
  GetAllReserves = '/api/services/app/TenantReserve/GetAll',
  GetSingleReserve = '/api/services/app/TenantReserve/Get',
}

export const DomesticHotelApiDataMap: Map<string, ApiDataItem> = new Map();
