import { ArrowRightOutlined, ClockCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Tag, Tooltip } from 'antd';
import clsx from 'clsx';
import last from 'lodash/last';
import moment from 'moment';
import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useGlobalization } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { getDurationAsHoursAndMinutes } from '../../../../../utils/helpers';
import { getFlightSegmentStopType } from '../../../helpers/FlightHelpers';
import { FlightItinerarySummary } from '../../../types';
import classes from '../FlightDetailsModal/FlightDetailsModal.module.scss';
import FlightSegmentBaggageDetails from '../FlightSegmentBaggageDetails/FlightSegmentBaggageDetails';
import FlightSegmentExtraBaggageDetails from '../FlightSegmentExtraBaggageDetails/FlightSegmentExtraBaggageDetails';

type Props = {
  itinerary: FlightItinerarySummary;
  showExtraBaggageInformation?: boolean;
};
const FlightDetailsSegments: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const { formatShortDate, formatWeekDay } = useGlobalization();

  return (
    <>
      {props.itinerary.originDestinationOptions.map((option, optionIndex) => {
        const departureAirport = option.flightSegments[0].departureAirport;
        const arrivalAirport = last(option.flightSegments)!.arrivalAirport;

        return (
          <React.Fragment key={optionIndex}>
            <Row justify="space-between" className="my-3">
              <Col>
                <Tooltip
                  className="d-inline-block"
                  title={
                    <>
                      {departureAirport.cityName}
                      <br />
                      {departureAirport.countryName}
                    </>
                  }>
                  <b className="title-md mb-0">{option.flightSegments[0].departureAirport.locationCode}</b> <InfoCircleOutlined />
                </Tooltip>
                <ArrowRightOutlined className="mx-2 align-middle mirror-in-rtl" />{' '}
                <Tooltip
                  className="d-inline-block"
                  title={
                    <>
                      {arrivalAirport.cityName}
                      <br />
                      {arrivalAirport.countryName}
                    </>
                  }>
                  <b className="title-md mb-0">{last(option.flightSegments)!.arrivalAirport.locationCode}</b> <InfoCircleOutlined />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title={moment.duration(option.journeyDuration).toISOString()}>
                  <b>
                    {t(Translations.Flight.JourneyDuration)}: {getDurationAsHoursAndMinutes(option.journeyDuration)}
                  </b>{' '}
                  <InfoCircleOutlined />
                </Tooltip>
              </Col>
            </Row>
            <div className={classes.responsiveTableHolder}>
              <Table bordered className={classes.grayTheadTable}>
                <thead>
                  <tr>
                    <th>{t(Translations.Flight.MarketingAirline)}</th>
                    <th>{t(Translations.Flight.OperatingAirline)}</th>
                    <th>{t(Translations.Flight.FlightNumber)}</th>
                    <th>
                      {t(Translations.Flight.CabinClass)} ({t(Translations.Common.Code)})
                    </th>
                    <th>{t(Translations.Flight.Baggage)}</th>
                    {props.itinerary.hasExtraBaggage && props.showExtraBaggageInformation && <th>{t(Translations.Flight.ExtraBaggage)}</th>}
                    <th>{t(Translations.Flight.DepartureOrigin)}</th>
                    <th>{t(Translations.Flight.DepartureTime)}</th>
                    <th>{t(Translations.Flight.ArrivalDestination)}</th>
                    <th>{t(Translations.Flight.ArrivalTime)}</th>
                    <th>{t(Translations.Common.Duration)}</th>
                  </tr>
                </thead>
                <tbody>
                  {option.flightSegments.map((segment, segmentIndex) => {
                    const nextSegment = option.flightSegments[segmentIndex + 1];

                    const stopType = getFlightSegmentStopType(option, segmentIndex);

                    return (
                      <React.Fragment key={segmentIndex}>
                        <tr>
                          <td>
                            <img src={segment.marketingAirline.photoUrl} alt={segment.marketingAirline.name} className={classes.airlineLogo} />
                            <div>{segment.marketingAirline.name}</div>
                          </td>
                          <td>
                            <img src={segment.operatingAirline.photoUrl} alt={segment.operatingAirline.name} className={classes.airlineLogo} />
                            <div>{segment.operatingAirline.name}</div>
                          </td>
                          <td>{segment.flightNumber}</td>
                          <td>
                            <b className="small-font-size">
                              {segment.cabinClass.name} ({segment.cabinClass.code})
                            </b>
                            {segment.resBookDesigCode && (
                              <div className="mt-1">
                                <Tag>{segment.resBookDesigCode}</Tag>
                              </div>
                            )}
                          </td>
                          <td>
                            <FlightSegmentBaggageDetails segment={segment} />
                          </td>
                          {props.itinerary.hasExtraBaggage && props.showExtraBaggageInformation && (
                            <td>
                              <FlightSegmentExtraBaggageDetails segment={segment} />
                            </td>
                          )}
                          <td className="detail-location">
                            <span className="small-font-size">{segment.departureAirport.locationName} </span>
                            <div className="mt-2">
                              <b>({segment.departureAirport.locationCode})</b>
                            </div>
                            {segment.departureAirport.terminalID && <div>Terminal {segment.departureAirport.terminalID}</div>}
                            <div className="small-font-size mt-2">
                              <span>{segment.departureAirport.cityName}, </span>
                              <span>{segment.departureAirport.countryName} </span>
                            </div>
                          </td>
                          <td className="detail-time">
                            <div className="no-wrap">{formatShortDate(segment.departureDateTime)}</div>
                            <div className="small-font-size mt-2">{formatWeekDay(segment.departureDateTime)}</div>
                            <div className="small-font-size mt-2"> {moment(segment.departureDateTime).utc(true).format('HH:mm')}</div>
                          </td>

                          <td className="detail-location">
                            <span className="small-font-size">{segment.arrivalAirport.locationName} </span>
                            <div className="mt-2">
                              <b>({segment.arrivalAirport.locationCode})</b>
                            </div>
                            {segment.arrivalAirport.terminalID && (
                              <div>
                                {t(Translations.Flight.Terminal)} {segment.arrivalAirport.terminalID}
                              </div>
                            )}
                            <div className="small-font-size mt-2">
                              <span>{segment.arrivalAirport.cityName}, </span>
                              <span>{segment.arrivalAirport.countryName} </span>
                            </div>
                          </td>
                          <td className="detail-time">
                            <div className="no-wrap">{formatShortDate(segment.arrivalDateTime)}</div>
                            <div className="small-font-size mt-2">{formatWeekDay(segment.arrivalDateTime)}</div>
                            <div className="small-font-size mt-2"> {moment(segment.arrivalDateTime).utc(true).format('HH:mm')}</div>
                          </td>

                          <td className="detail-duration">
                            <b className="no-wrap">{getDurationAsHoursAndMinutes(segment.flightDuration)}</b>
                          </td>
                        </tr>
                        {stopType && (
                          <tr>
                            <td
                              colSpan={props.itinerary.hasExtraBaggage && props.showExtraBaggageInformation ? 10 : 9}
                              className={clsx({
                                'text-align-initial': true,
                                'text-danger': stopType === 'AirportChange',
                                'text-warning': stopType !== 'AirportChange',
                              })}>
                              <ClockCircleOutlined className="align-middle" />
                              <b className="mx-2">
                                {stopType === 'TerminalChange' && (
                                  <>
                                    {t(Translations.Flight.TerminalChange)}:{' '}
                                    {t(Translations.Flight.PassengerWillBeChangingTerminalsFromTo, {
                                      airport: segment.arrivalAirport.locationName,
                                      terminalFrom: segment.arrivalAirport.terminalID,
                                      terminalTo: nextSegment.departureAirport.terminalID,
                                    })}
                                  </>
                                )}
                                {stopType === 'AirportChange' && (
                                  <>
                                    <b className="mx-2">
                                      {t(Translations.Flight.StopForAirportChange)}: {t(Translations.Flight.PassengerWillBeChangingAirports)} (
                                      {segment.arrivalAirport.locationCode} {t(Translations.Common.To2)} {nextSegment.departureAirport.locationName}).{' '}
                                      {t(Translations.Flight.ATransitVisaMayBeRequired)}
                                    </b>
                                    <Tooltip
                                      className="align-middle"
                                      title={t(Translations.Flight.PassengerHasToChangeAirportFromTo, {
                                        airportFrom: segment.arrivalAirport.locationName,
                                        airportTo: nextSegment.departureAirport.locationName,
                                      })}>
                                      <InfoCircleOutlined />
                                    </Tooltip>
                                  </>
                                )}
                                {stopType === 'Waiting' && t(Translations.Flight.WaitingTimeIn, { city: segment.arrivalAirport.locationName })}
                              </b>
                            </td>
                            <td>
                              <b className="text-warning">{getDurationAsHoursAndMinutes(segment.stopTime)}</b>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
});

export default FlightDetailsSegments;
