import MockAdapter from 'axios-mock-adapter';

import { AuthenticationApiUrls } from './authentication-apiData';

const authenticationMock = (mock: MockAdapter) => {
  mock.onGet(AuthenticationApiUrls.GetPermissions).reply(200, {
    result: [
      'Users',
      'ManualReceipt',
      'ConfirmAndIncreaseReceipt',
      'Permissions',
      'CancelBooking',
      'MakeBooking',
      'ViewBookings',
      'ViewTickets',
      'CreateTicket',
      'ManageCommission',
      'ManageCompanyAndUser',
      'AddCreditCard',
      'UseDeposit',
      'ViewInvoices',
      'ViewTransactions',
      'VoidTicket',
      'ChargeDeposit',
    ],
    targetUrl: null,
    success: true,
    error: null,
    unAuthorizedRequest: false,
    __abp: true,
  });
};

export default authenticationMock;
