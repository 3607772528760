import { Col, Row } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../features/hooks';
import { Translations } from '../../../../../../features/localization';
import { PackageAvailabilityRequest } from '../../../../../package/apiTypes';
import { LowFareSearchRequest } from '../../../../apiTypes';
import classes from '../FlightFilter.module.scss';
import FlightsFilterDepartureArrival from '../FlightsFilterDepartureArrival/FlightsFilterDepartureArrival';

type Props = {
  isPackage?: boolean;
};
const FlightsFilterTimes: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const searchData = useAppSelector((state) =>
    props.isPackage ? state.package.temp.packages.request.search : state.flight.temp.flights.constants.searchData
  )!;

  const originDestinationCodes = useMemo<{ origin: string; destination: string }[]>(() => {
    if (props.isPackage) {
      const packageSearchData = searchData as PackageAvailabilityRequest;
      return [
        { origin: packageSearchData.originCode, destination: packageSearchData.destinationCode },
        { origin: packageSearchData.destinationCode, destination: packageSearchData.originCode },
      ];
    } else {
      return (searchData as LowFareSearchRequest).originCodes.map((item, index) => ({
        origin: item,
        destination: (searchData as LowFareSearchRequest).destinationCodes[+index],
      }));
    }
  }, [props.isPackage, searchData]);

  return (
    <>
      <h6 className="title-sm mb-0">
        {t(Translations.Common.Time)} ({t(Translations.Common.Hour)})
      </h6>
      {originDestinationCodes.map((item, index) => (
        <div key={index}>
          <Row justify="space-between" align="middle" className={`mt-5 mb-3 ${classes.itineraryDirection}`}>
            <Col>{item.origin}</Col>
            <Col flex="auto">
              <div className="text-center position-relative">
                <span className={classes.flightDashedLine} />
                <span className={`fas fa-plane mirror-in-rtl text-muted ${classes.airplaneIcon}`} />
              </div>
            </Col>
            <Col>{item.destination}</Col>
          </Row>
          <FlightsFilterDepartureArrival
            isPackage={props.isPackage}
            optionIndex={index}
            type="DepartureTime"
            origin={item.origin}
            destination={item.destination}
          />
          <FlightsFilterDepartureArrival
            isPackage={props.isPackage}
            optionIndex={index}
            type="ArrivalTime"
            origin={item.origin}
            destination={item.destination}
          />
        </div>
      ))}
    </>
  );
});

export default FlightsFilterTimes;
