import React from 'react';

import { DefinedRoute } from '../../types';

const PackageRoutes = (): readonly DefinedRoute[] => [
  {
    type: 'Route',
    path: '/packages',
    children: React.lazy(() => import('./pages/Packages/Packages')),
    exact: true,
  },
  {
    type: 'Route',
    path: '/packages/reserve',
    children: React.lazy(() => import('./pages/PackageReserve/PackageReserve')),
    exact: true,
  },
  {
    type: 'Route',
    path: '/packages/payment/confirm',
    children: React.lazy(() => import('./pages/PackagePaymentConfirm/PackagePaymentConfirm')),
    exact: true,
  },
  {
    type: 'Route',
    path: '/package/reserves',
    children: React.lazy(() => import('./pages/PackageReserves/PackageReserves')),
    exact: true,
  },
];

export default PackageRoutes;
