import React from 'react';

import { DefinedRoute } from '../../types';

const AuthRoutes: DefinedRoute[] = [
  { type: 'Route', path: '/auth/signUp', children: React.lazy(() => import('./pages/SignUp/SignUp')) },
  { type: 'Route', path: '/auth', children: React.lazy(() => import('./pages/AuthPage/AuthPage')) },
  { type: 'Route', path: '/contact', children: React.lazy(() => import('./pages/Contact/Contact')) },
  { type: 'Route', path: '/about', children: React.lazy(() => import('./pages/About/About')) },
  { type: 'Route', path: '/support', children: React.lazy(() => import('./pages/Support/Support')) },
  { type: 'Route', path: '/products/B2BSellingPlatform', children: React.lazy(() => import('./pages/Products/B2BSellingPlatform/B2BSellingPlatform')) },
  { type: 'Route', path: '/products/B2CBookingSystem', children: React.lazy(() => import('./pages/Products/B2CBookingSystem/B2CBookingSystem')) },
  { type: 'Route', path: '/products/whiteLabel', children: React.lazy(() => import('./pages/Products/WhiteLabel/WhiteLabel')) },
  { type: 'Route', path: '/products/api', children: React.lazy(() => import('./pages/Products/Api/Api')) },
  { type: 'Redirect', path: '/auth' },
];

export default AuthRoutes;
