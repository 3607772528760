import { ApiDataItem } from '../../../types';

export enum PackageApiUrls {
  //Booking
  GetAirport = '/api/services/app/Booking/GetAirports',
  Availability = '/api/services/app/Booking/Availability',
  GetAvailability = '/api/services/app/Booking/GetAvailability',
  GetHotelDetailById = '/api/services/app/Booking/GetHotelDetailById',
  PreReserveFlight = '/api/services/app/Booking/PreReserveFlight',
  GetFlightCancellationPolicy = '/api/services/app/Booking/GetFlightCancellationPolicy',
  PreReserveRoom = '/api/services/app/Booking/PreReserveRoom',
  Reserve = '/api/services/app/Booking/Reserve',
  Confirm = '/api/services/app/Booking/Confirm',
  GetHotelCancellationPolicy = '/api/services/app/Booking/GetHotelCancellationPolicy',
  ValidateFlight = '/api/services/app/Booking/ValidateFlight',

  //PreReserve
  GetPreReserve = '/api/services/app/PreReserve/Get',

  //Reserve
  GetReserve = '/api/services/app/Reserve/Get',

  //TenantReserve
  GetTenantReserve = '/api/services/app/TenantReserve/Get',
  GetAllTenantReserves = '/api/services/app/TenantReserve/GetAll',
}

export const PackageApiDataMap: Map<string, ApiDataItem> = new Map();
