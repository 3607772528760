import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Tag, Tooltip } from 'antd';
import last from 'lodash/last';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useGlobalization } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { getDurationAsHoursAndMinutes } from '../../../../../utils/helpers';
import { DepartureAirport } from '../../../apiTypes';
import { FlightItinerarySummary } from '../../../types';
import listItemClasses from '../FlightListItem/FlightListItem.module.scss';
import classes from '../FlightListItem/FlightListItem.module.scss';

type Props = {
  itinerary: FlightItinerarySummary;
};
const FlightDetailsJourneySummary: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const { formatHourMinute, formatShortMonthWeekDay } = useGlobalization();

  const airportCodes = useAppSelector((state) => state.flight.temp.flights.airportCodes)!;

  const airportTimeComponent = (dateTime: string, airPort: DepartureAirport) => {
    return (
      <div className="text-left">
        <Tooltip title={dateTime} overlayClassName="english">
          <div className="font-weight-bolder title-sm mb-0 no-wrap">{formatHourMinute(dateTime)}</div>
          <div className="font-weight-bolder title-sm no-wrap monthWeekDay-small-in-rtl mb-1">{formatShortMonthWeekDay(dateTime)}</div>
        </Tooltip>
        <Tooltip
          title={
            <>
              {airPort.countryName}
              <br />
              {airPort.cityName}
              <br />
              {airPort.locationName}
              <br />
              {airPort.terminalID}
            </>
          }
          overlayClassName="english text-left">
          <div>
            <Tag color={airportCodes ? (airportCodes.includes(airPort.locationCode) ? 'green' : 'orange') : 'default'}>
              {airPort.locationCode}
              <InfoCircleOutlined className="mx-1" />
            </Tag>
          </div>
        </Tooltip>
      </div>
    );
  };

  return (
    <div>
      {props.itinerary.originDestinationOptions.map((option, index) => {
        const firstSegment = option.flightSegments[0];
        const lastSegment = last(option.flightSegments);

        return (
          <React.Fragment key={index}>
            <Row gutter={[10, 10]} align={'middle'} className="mb-0">
              <Col xs={24} sm={8} md={7} lg={8}>
                <Row align="middle" gutter={[10, 0]}>
                  <Col xs={24} sm={12} md={4} lg={5} className="static">
                    <div>
                      <img src={firstSegment.marketingAirline.photoUrl} alt={firstSegment.marketingAirline.name} className={listItemClasses.airlineLogo} />
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={20} lg={19} className="small-font-size">
                    <div>
                      <div>{firstSegment.marketingAirline.name}</div>
                      <div>
                        <span> {firstSegment.marketingAirline.code} </span> {firstSegment.flightNumber}
                      </div>
                      <div>
                        {firstSegment.cabinClass.name} {firstSegment.resBookDesigCode && <>({firstSegment.resBookDesigCode})</>}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12}>
                <div className={classes.originDurationDestination}>
                  {airportTimeComponent(firstSegment.departureDateTime, firstSegment.departureAirport)}
                  <Tooltip title={moment.duration(option.journeyDuration).toISOString()}>
                    <div className={classes.duration}>
                      <span className="hidden-xs hidden-sm hidden-md">{t(Translations.Common.Duration)}: </span>{' '}
                      {getDurationAsHoursAndMinutes(option.journeyDuration)} <InfoCircleOutlined />
                    </div>
                  </Tooltip>
                  {airportTimeComponent(lastSegment!.arrivalDateTime, lastSegment!.arrivalAirport)}
                </div>
              </Col>
            </Row>
            {props.itinerary.originDestinationOptions.length - 1 !== index && <Divider className="my-3" />}
          </React.Fragment>
        );
      })}
    </div>
  );
});

export default FlightDetailsJourneySummary;
