import { useMemo } from 'react';

import { useAppSelector } from '../../../features/hooks';
import { isProduction } from '../../../utils/helpers';
import { currencySelector, getAffiliateStorage } from '../../account';
import { isSectionEnabled } from '../../shared/TenantHostConfig';

const ITTestTenantId = 1084;

const useDomesticModules = (type: 'FlightDomestic' | 'HotelDomestic') => {
  const currency = useAppSelector(currencySelector);

  const affiliateStorage = getAffiliateStorage();

  const isEnabled = useMemo<boolean>(() => {
    if (!isSectionEnabled(type)) {
      return false;
    }

    if (!isProduction()) {
      return true;
    }

    // Currency should be IRR
    if (currency?.toLowerCase() !== 'irr') {
      return false;
    }

    // Only IT Test Tenant can see Domestic Flight
    if (type === 'FlightDomestic' && affiliateStorage.tenantId !== ITTestTenantId) {
      return false;
    }

    // Country Code should be IR
    return affiliateStorage?.countryCode.toLowerCase() === 'ir';
  }, [affiliateStorage?.countryCode, affiliateStorage.tenantId, currency, type]);

  return { isEnabled };
};

export default useDomesticModules;
