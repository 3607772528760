import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Translations } from '../../../../features/localization';
import { Env } from '../../../../utils/env';
import { useHtmlClassService } from '../../_core/MetronicLayout';

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();
  const { t } = useTranslation();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses('footer', true),
      footerContainerClasses: uiService.getClasses('footer_container', true),
    };
  }, [uiService]);

  return (
    <div className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`} id="kt_footer">
      <div className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}>
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted font-weight-bold mr-2">{today.toString()}</span> &copy;{' '}
          {/*<a href="#" target="_blank" rel="noopener noreferrer" className="text-dark-75 text-hover-primary">*/}
          {Env.TenantHost === 'AyanAir'
            ? 'Ayan Air'
            : Env.TenantHost === 'BarreTravel'
            ? 'Barre Travel'
            : Env.TenantHost === 'ProTraveling'
            ? 'Pro Traveling'
            : t(Translations.Common.iTours)}
          , {t(Translations.Common.AllRightsReserved)}.{/*</a>*/}
        </div>
      </div>
    </div>
  );
}
