import { Skeleton } from 'antd';
import React from 'react';

const ReserveItemSkeleton: React.VFC = React.memo(() => {
  return (
    <>
      <Skeleton avatar paragraph={{ rows: 0 }} active />
      <Skeleton paragraph={{ rows: 4 }} className="no-title" active />
    </>
  );
});

export default ReserveItemSkeleton;
