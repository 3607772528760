import { PrinterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { currentTenantSelector } from '../../../authentication';

type Props = {
  reserveId: string;
  username: string;
  buttonProps?: ButtonProps;
  buttonText?: string;
};
const DomesticVoucherLinkButton: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const currentTenant = useAppSelector(currentTenantSelector);

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`${process.env.REACT_APP_VoucherApiBaseUrl}/en/${currentTenant!.name}/hoteldomestic/voucher?reserveId=${props.reserveId}&username=${
        props.username
      }`}>
      <Button type="default" className="reserveListButton printBtn" htmlType="button" icon={<PrinterOutlined />} {...props.buttonProps}>
        {props.buttonText || t(Translations.Hotel.Voucher)}
      </Button>
    </a>
  );
});

export default DomesticVoucherLinkButton;
