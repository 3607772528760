import { unitOfTime } from 'moment';
import moment, { Moment } from 'moment-jalaali';

const getDateFormat = (useDash: boolean) => {
  return useDash ? 'YYYY-MM-DD' : 'YYYY/MM/DD';
};

export const getDatePortionStringOrUndefinedFromMoment = (moment: Moment | undefined, useDash: boolean = false) => {
  if (!moment) {
    return undefined;
  }
  return moment.utc(true).startOf('day').format(getDateFormat(useDash));
};

export const getDateTimeStringOrUndefinedFromMoment = (moment: Moment | undefined, useDash: boolean = false) => {
  if (!moment) {
    return undefined;
  }
  return moment.utc(true).format(`${getDateFormat(useDash)} HH:mm:ss`);
};

export const getTimeStringOrUndefinedFromMoment = (moment: Moment | undefined) => {
  if (!moment) {
    return undefined;
  }
  return moment.utc(true).format('HH:mm');
};

export const getToday = (useDash: boolean = false) => {
  return moment().utc(true).format(getDateFormat(useDash));
};

export const getNowISO = (keepLocalTime: boolean = true) => {
  return moment().utc(keepLocalTime).toISOString();
};

export const getNow = (locale?: string) => {
  let momentObj = moment();
  if (locale) {
    momentObj = momentObj.locale(locale);
  }
  return momentObj;
};

export const getTomorrow = (locale?: string) => {
  let momentObj = moment().add(1, 'day');
  if (locale) {
    momentObj = momentObj.locale(locale);
  }
  return momentObj;
};

export const getYesterday = (locale?: string) => {
  let momentObj = moment().subtract(1, 'day');
  if (locale) {
    momentObj = momentObj.locale(locale);
  }
  return momentObj;
};

export const momentToISOUtcDate = (date: Moment | string | undefined) => {
  if (!date) {
    return undefined;
  }

  return moment(date).utc(true).startOf('day').toISOString();
};

export const momentToISOUtcDateOrUndefined = (date: Moment | undefined) => {
  return moment(date).utc(true).startOf('day').toISOString();
};

export const toISOUtcDate = (gregorianDate: string) => {
  return moment(gregorianDate).utc(true).startOf('day').toISOString();
};

export const getDurationAsDays = (dateStart: string, dateEnd: string) => {
  const start = moment(dateStart).utc(true);
  const end = moment(dateEnd).utc(true);
  return end.diff(start, 'days');
};

export const dateStringToMoment = (date: string | undefined, locale?: string, utc?: boolean) => {
  if (!date) {
    return undefined;
  }
  let momentObj = moment(date);
  if (locale) {
    momentObj = momentObj.locale(locale);
  }
  momentObj = momentObj.utc(utc);
  return momentObj;
};

export const isValidDate = (date: string | undefined, locale?: string, utc?: boolean) => {
  const momentObj = dateStringToMoment(date, locale, utc);
  return momentObj?.isValid();
};

export const isZeroDuration = (duration: string) => {
  return duration === '00:00:00';
};

export const isEqualDate = (date1: string, date2: string, granularity?: unitOfTime.StartOf) => {
  return moment(date1).isSame(date2, granularity);
};
