import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Translations } from '../../../../features/localization';

type Props = {
  array: any[] | undefined;
  showAllState: [boolean, Dispatch<SetStateAction<boolean>>];
  defaultFiltersShowNumber: number;
};
const ShowAllOrLessFilters: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const handleClicked = () => {
    props.showAllState[1]((prevState) => !prevState);
  };

  return (
    <>
      {props.array && props.array.length > props.defaultFiltersShowNumber && (
        <div className="mt-1">
          <Button type="link" htmlType="button" className="px-0" onClick={handleClicked}>
            {!props.showAllState[0] ? (
              <>
                {t(Translations.Common.ShowAll)} <DownOutlined className="mx-1" />
              </>
            ) : (
              <>
                {t(Translations.Common.Less)} <UpOutlined className="mx-1" />
              </>
            )}
          </Button>
        </div>
      )}
    </>
  );
});

export default ShowAllOrLessFilters;
