import { Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useInterval } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';

type Props = {
  startTime?: string;
  endTime: string;
  onFinish?: () => void;
  isDefaultRedirectDisabled?: boolean;
};
const RemainingTime: React.VFC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const [remainingTime, setRemainingTime] = useState<string>();

  const durationRef = useRef<number>(moment.utc(props.endTime).diff(props.startTime ?? Date.now()));

  useEffect(() => {
    durationRef.current = moment.utc(props.endTime).diff(props.startTime ?? Date.now());
  }, [props.endTime, props.startTime]);

  const interval = useInterval(() => {
    if (durationRef.current <= 0) {
      setRemainingTime('00:00:00');
      interval.cleanup();
      if (props.onFinish) {
        props.onFinish();
      } else {
        if (!props.isDefaultRedirectDisabled) {
          history.push('/');
        }
      }
    } else {
      const durationFormatted = new Date(durationRef.current).toISOString().substr(11, 8);
      durationRef.current -= 1000;
      setRemainingTime(durationFormatted);
    }
  }, 1000);

  return <Tooltip title={`${t(Translations.Booking.ReserveExpirationTime)} : ${props.endTime}`}>{remainingTime}</Tooltip>;
};

RemainingTime.defaultProps = {
  isDefaultRedirectDisabled: false,
};

export default RemainingTime;
