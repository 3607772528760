import { Card, Empty } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Translations } from '../../../../features/localization';

const EmptyReservesList: React.VFC = React.memo(() => {
  const { t } = useTranslation();

  return (
    <Card className="box-shadow text-center mb-4">
      <Empty description={t(Translations.Booking.NoReservesFound)} />
    </Card>
  );
});

export default EmptyReservesList;
