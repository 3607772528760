import Axios from 'axios';

import { AxiosInstanceData } from '../../../types';
import { isDevelopment, urlWithQuery } from '../../../utils/helpers';
import { HotelReferenceApiDataMap, HotelReferenceApiUrls } from './hotelReference-apiData';

//import MockAdapter from 'axios-mock-adapter';

//import hotelReferenceMock from './hotelReference-mock';

const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_HotelReferenceApiBaseUrl;

if (isDevelopment()) {
  // const mock = new MockAdapter(axiosInstance, { onNoMatch: 'passthrough', delayResponse: 0 });
  // hotelReferenceMock(mock);
}

export const getHotelsReferenceByCityId = (data: { id: number }) => {
  const url = urlWithQuery(HotelReferenceApiUrls.GetByCityId, data);
  return axiosInstance.get(url);
};

export const getHotelReferenceById = (data: { id: number }) => {
  const url = urlWithQuery(HotelReferenceApiUrls.GetById, data);
  return axiosInstance.get(url);
};

export const HotelReferenceAxiosData: AxiosInstanceData = {
  instance: axiosInstance,
  DataMap: {
    MapObject: HotelReferenceApiDataMap,
    Urls: HotelReferenceApiUrls,
  },
};
