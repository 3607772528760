import Axios from 'axios';

import { AxiosInstanceData2 } from '../../../types';
import { urlWithQuery } from '../../../utils/helpers';
import { TicketingApiDataMap, TicketingApiUrls } from './ticketing-apiData';
import { TicketAddMessageByTicketIdRq, TicketingCreateRq, TicketsGetAllRq } from './ticketing-apiTypes';

const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_TicketingApiBaseUrl;

export const TicketingHttp = {
  create: (data: TicketingCreateRq) => {
    return axiosInstance.post(TicketingApiUrls.Tenant.Create, data);
  },
  addTicketMessageByTicketId: (data: TicketAddMessageByTicketIdRq) => {
    return axiosInstance.post(TicketingApiUrls.Tenant.AddTicketMessageByTicketId, data);
  },
  getAll: (data: TicketsGetAllRq) => {
    const url = urlWithQuery(TicketingApiUrls.Tenant.GetAll, data);
    return axiosInstance.get(url);
  },
  get: (data: { id: number }) => {
    const url = urlWithQuery(TicketingApiUrls.Tenant.Get, data);
    return axiosInstance.get(url);
  },
  uploadAttachment: (data: { attachment: string | Blob }) => {
    const formData = new FormData();
    formData.append('Attachment', data.attachment);
    return axiosInstance.post(TicketingApiUrls.Tenant.UploadAttachment, formData);
  },
};

export const TicketingAxiosData: AxiosInstanceData2 = {
  instance: axiosInstance,
  DataMap: {
    MapObject: TicketingApiDataMap,
    Urls: TicketingApiUrls,
  },
};
