import objectPath from 'object-path';
import React, { useMemo } from 'react';

import { CurrencySelectorDropdown, NotificationsDropdown } from '../../../../modules/shared';
import { isDevelopment, isProduction } from '../../../../utils/helpers';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { LanguageSelectorDropdown } from '../extras/dropdowns/LanguageSelectorDropdown';
import { QuickUserToggler } from '../extras/QuiclUserToggler';

export function Topbar() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(uiService.config, 'extras.search.display'),
      viewNotificationsDisplay: objectPath.get(uiService.config, 'extras.notifications.display'),
      viewQuickActionsDisplay: objectPath.get(uiService.config, 'extras.quick-actions.display'),
      viewCartDisplay: objectPath.get(uiService.config, 'extras.cart.display'),
      viewQuickPanelDisplay: objectPath.get(uiService.config, 'extras.quick-panel.display'),
      viewLanguagesDisplay: objectPath.get(uiService.config, 'extras.languages.display'),
      viewUserDisplay: objectPath.get(uiService.config, 'extras.user.display'),
    };
  }, [uiService]);

  return (
    <div className="topbar">
      {layoutProps.viewLanguagesDisplay && !isProduction() && <LanguageSelectorDropdown />}

      <CurrencySelectorDropdown />

      {isDevelopment() && <NotificationsDropdown />}

      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  );
}
