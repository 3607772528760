import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Translations } from '../../../../features/localization';
import { FormattedCurrency } from '../../../shared';

type Props = {
  newPrice?: number;
};
const PriceChangeModal: React.VFC<Props> = React.memo((props: Props) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (props.newPrice) {
      setVisible(true);
    }
  }, [props.newPrice]);

  const handleOkClicked = () => {
    setVisible(false);
  };

  return props.newPrice ? (
    <Modal
      visible={visible}
      title={t(Translations.Booking.PriceChange)}
      onCancel={handleOkClicked}
      footer={
        <Button type="primary" htmlType="button" onClick={handleOkClicked}>
          {t(Translations.Common.Ok)}
        </Button>
      }>
      <p>{t(Translations.Booking.ThePriceOfThisReserveHasBeenChanged)}</p>
      <p>
        {t(Translations.Booking.NewPrice)} :
        <strong>
          <FormattedCurrency number={props.newPrice} tooltip={{ show: true, position: 'top' }} />
        </strong>
      </p>
    </Modal>
  ) : null;
});

export default PriceChangeModal;
