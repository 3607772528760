import { Button, Col, Popconfirm, Row } from 'antd';
import reverse from 'lodash/reverse';
import takeRight from 'lodash/takeRight';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LocalStorageKey } from '../../../../features/constants';
import { useManualRerender } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { fromJson, getStorage, removeStorage } from '../../../../utils/helpers';
import { FlightsQueryString } from '../../../flight/types';
import { HotelsQueryString } from '../../../hotel/types';
import { PackagesQueryString } from '../../../package/types';
import { RecentSearch } from '../../types';
import RecentSearchDomesticFlight from './RecentSearchDomesticFlight';
import RecentSearchFlight from './RecentSearchFlight';
import RecentSearchHotel from './RecentSearchHotel';
import RecentSearchPackage from './RecentSearchPackage';

const RecentSearches: React.VFC = React.memo(() => {
  const { t } = useTranslation();

  const manualRerender = useManualRerender();

  const [recentSearches, setRecentSearches] = useState<RecentSearch[]>();

  useEffect(() => {
    const recentSearchesJson = getStorage(LocalStorageKey.RecentSearches);
    if (recentSearchesJson) {
      const recent6SearchesArray = takeRight(fromJson(recentSearchesJson) as RecentSearch[], 6);
      const recentSearchesArray = reverse(recent6SearchesArray);

      setRecentSearches(recentSearchesArray);
    } else {
      setRecentSearches(undefined);
    }
  }, [manualRerender.rerenderData]);

  const handleClearHistoryClicked = () => {
    removeStorage(LocalStorageKey.RecentSearches);
    manualRerender.rerender();
  };

  return (
    <>
      {recentSearches && recentSearches.length > 0 ? (
        <>
          <Row gutter={[20, 20]} className={'my-4'}>
            {/*<div className="mt-3">*/}
            {/*  <Input.Search placeholder={t(Translations.Common.SearchRecentSearches)} allowClear enterButton={t(Translations.Common.Search)} size="large" />*/}
            {/*</div>*/}
            {recentSearches.map((item) => {
              switch (item.type) {
                case 'Hotel':
                  return <RecentSearchHotel key={item.date} search={item.search as HotelsQueryString} />;
                case 'Flight':
                  return <RecentSearchFlight key={item.date} search={item.search as FlightsQueryString} />;
                case 'Package':
                  return <RecentSearchPackage key={item.date} search={item.search as PackagesQueryString} />;
                case 'DomesticHotel':
                  break;
                case 'DomesticFlight':
                  return <RecentSearchDomesticFlight key={item.date} search={item.search as any} />;
              }
              return null;
            })}
          </Row>
          <Row justify={'center'}>
            <Col>
              <Popconfirm
                title={t(Translations.Common.AreYouSure)}
                onConfirm={handleClearHistoryClicked}
                okText={t(Translations.Common.Yes)}
                cancelText={t(Translations.Common.No)}
                okButtonProps={{ danger: true }}
                overlayStyle={{ width: 200 }}>
                <Button type="dashed" size="large" className="w-100">
                  {t(Translations.Booking.ClearSearchHistory)}
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </>
      ) : (
        <div className="text-info my-2 text-center font-weight-bold">{t(Translations.Booking.EmptySearchHistoryInfo)}</div>
      )}
    </>
  );
});

export default RecentSearches;
