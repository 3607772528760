import React from 'react';

import { FlightSegmentModel } from '../../../apiTypes';

type Props = {
  segment: FlightSegmentModel;
};
const FlightSegmentExtraBaggageDetails: React.VFC<Props> = React.memo((props) => {
  return (
    <>
      {props.segment.extraBaggageInformation?.map((baggageItem) => (
        <div key={baggageItem.passengerType}>
          {baggageItem.baggageAllowance} {baggageItem.unitType} for {baggageItem.passengerType}
        </div>
      ))}
    </>
  );
});

export default FlightSegmentExtraBaggageDetails;
