import { Form, FormInstance, Input, Select } from 'antd';
import React, { useMemo } from 'react';

import { useAntdValidation } from '../../../../features/hooks';

type Props = {
  formInstance: FormInstance;
  type: 'text' | 'select' | 'textarea';
  name: string;
  defaultValue?: string;
  values?: {
    label: string;
    value: string;
  }[];
};
const SimpleDynamicAntdFormElement: React.VFC<Props> = React.memo((props: Props) => {
  const { labelWithRules } = useAntdValidation(props.formInstance);

  const element = useMemo(() => {
    switch (props.type) {
      case 'text':
        return <Input type="text" />;
      case 'select':
        return (
          <Select>
            {props.values?.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        );
      case 'textarea':
        return <Input.TextArea rows={4} />;
    }
  }, [props.type, props.values]);

  return (
    <Form.Item name={props.name} initialValue={props.defaultValue} {...labelWithRules({ label: props.name, rules: [{ type: 'Required' }] })}>
      {element}
    </Form.Item>
  );
});

export default SimpleDynamicAntdFormElement;
