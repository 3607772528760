import Axios from 'axios';

import { AxiosInstanceData, GetAllRequestBase } from '../../../types';
import { urlWithQuery } from '../../../utils/helpers';
import { AdminApiDataMap, AdminApiUrls } from './admin-apiData';

const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_AuthenticationApiBaseUrl;

export const getAllUsersHttp = (data: { Keyword?: string; IsActive?: boolean } & GetAllRequestBase) => {
  const url = urlWithQuery(AdminApiUrls.GetAllUsers, data);
  return axiosInstance.get(url);
};

export const getUserHttp = (data: { id: number }) => {
  const url = urlWithQuery(AdminApiUrls.GetUser, data);
  return axiosInstance.get(url);
};

export const createUserHttp = (data: any) => {
  return axiosInstance.post(AdminApiUrls.CreateUser, data);
};

export const updateUserHttp = (data: any) => {
  return axiosInstance.put(AdminApiUrls.UpdateUser, data);
};

export const deleteUserHttp = (data: { Id: number }) => {
  const url = urlWithQuery(AdminApiUrls.DeleteUser, data);
  return axiosInstance.delete(url);
};

export const getUserPermissionsForEditHttp = (data: { id: number }) => {
  const url = urlWithQuery(AdminApiUrls.GetUserPermissionsForEdit, data);
  return axiosInstance.get(url);
};

export const resetUserPasswordHttp = (data: { id: number; newPassword: string }) => {
  return axiosInstance.post(AdminApiUrls.ResetUserPassword, data);
};

export const updateUserPermissionsHttp = (data: { id: number; grantedPermissionNames: string[] }) => {
  return axiosInstance.put(AdminApiUrls.UpdateUserPermissions, data);
};

export const AdminAxiosData: AxiosInstanceData = {
  instance: axiosInstance,
  DataMap: {
    MapObject: AdminApiDataMap,
    Urls: AdminApiUrls,
  },
};
