import { Button, Col, Divider, Modal, Row, Tabs } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Translations } from '../../../../../features/localization';
import { FormattedCurrency, TabHeaderWithIcon } from '../../../../shared';
import { getTranslateKeyFlightPassengerType } from '../../../helpers/FlightHelpers';
import { FlightItinerarySummary } from '../../../types';
import FlightBookOperations from '../../FlightBookOperations/FlightBookOperations';
import FlightDetailsBaggage from '../FlightDetailsBaggage/FlightDetailsBaggage';
import FlightDetailsJourneySummary from '../FlightDetailsJourneySummary/FlightDetailsJourneySummary';
import FlightDetailsPriceBreakdown from '../FlightDetailsPriceBreakdown/FlightDetailsPriceBreakdown';
import FlightDetailsRules from '../FlightDetailsRules/FlightDetailsRules';
import FlightDetailsSegments from '../FlightDetailsSegments/FlightDetailsSegments';
import classes from './FlightDetailsModal.module.scss';

type Props = {
  visibilityType: 'ItineraryExist' | 'Manual';
  visible?: boolean;
  itinerary?: FlightItinerarySummary;
  onClose: () => void;
  showBookButtons?: boolean;
  isPackage?: boolean;
  showSelectButton?: boolean;
  onSelectFlight?: (item: FlightItinerarySummary) => void;
  extraBaggageSelected?: boolean;
  showExtraBaggageInformation?: boolean;
};
const FlightDetailsModal: React.VFC<Props> = React.memo((props) => {
  const { itinerary } = props;

  const { t } = useTranslation();

  const handleSelectFlightClicked = () => {
    props.onSelectFlight!(props.itinerary!);
  };

  const totalFare = useMemo(() => {
    if (itinerary) {
      if (props.extraBaggageSelected) {
        return itinerary.priceInfo?.itinTotalFare.totalFareWithExtraBaggage ?? itinerary.priceInfoSummary!.flightFare.totalFareWithExtraBaggage;
      } else {
        return itinerary.priceInfo?.itinTotalFare.totalFare ?? itinerary.priceInfoSummary!.flightFare.totalFare;
      }
    }
  }, [itinerary, props.extraBaggageSelected]);

  return (
    <Modal
      closable
      footer={null}
      visible={props.visibilityType === 'ItineraryExist' ? itinerary !== undefined : props.visible}
      centered
      onCancel={props.onClose}
      width={1200}
      className="antd-modal-no-padding">
      {itinerary && (
        <Row>
          <Col xs={24} md={19} className={classes.detailModalMainCol}>
            <div className="p-5">
              <FlightDetailsJourneySummary itinerary={itinerary} />
            </div>
            <Divider className="my-0" />
            <div className="flight-detail-tab-holder">
              <Tabs defaultActiveKey="segments">
                <Tabs.TabPane
                  key="segments"
                  tab={<TabHeaderWithIcon icon={<span className="fas fa-plane-departure" />} title={t(Translations.Flight.Segments)} />}>
                  <FlightDetailsSegments itinerary={itinerary} showExtraBaggageInformation={props.extraBaggageSelected || props.showExtraBaggageInformation} />
                </Tabs.TabPane>

                {itinerary.key && (
                  <Tabs.TabPane key="rules" tab={<TabHeaderWithIcon icon={<span className="fas fa-gavel" />} title={t(Translations.Common.Rules)} />}>
                    <FlightDetailsRules itineraryKey={itinerary.key} />
                  </Tabs.TabPane>
                )}

                {itinerary.priceInfo && (
                  <Tabs.TabPane
                    key="breakdown"
                    tab={<TabHeaderWithIcon icon={<span className="fas fa-dollar-sign" />} title={t(Translations.Common.PriceBreakdown)} />}>
                    <FlightDetailsPriceBreakdown
                      itinerary={itinerary}
                      showExtraBaggageInformation={props.extraBaggageSelected || props.showExtraBaggageInformation}
                    />
                  </Tabs.TabPane>
                )}

                <Tabs.TabPane key="baggage" tab={<TabHeaderWithIcon icon={<span className="fas fa-briefcase" />} title={t(Translations.Flight.Baggage)} />}>
                  <FlightDetailsBaggage itinerary={itinerary} showExtraBaggageInformation={props.extraBaggageSelected || props.showExtraBaggageInformation} />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Col>
          <Col xs={24} md={5} className={classes.flightDetailsModalAside}>
            <div className="p-5">
              <h3 className="title-sm">{t(Translations.Common.PriceDetails)}</h3>
              <div>
                {itinerary.priceInfo && (
                  <>
                    {itinerary.priceInfo.ptC_FareBreakdown.map((item, index) => (
                      <Row key={index} className="mb-1" justify="space-between">
                        <Col>
                          {t(getTranslateKeyFlightPassengerType(item.passengerTypeQuantity.code))} (x {item.passengerTypeQuantity.quantity})
                        </Col>
                        <Col>
                          <FormattedCurrency
                            number={props.extraBaggageSelected ? item.passengerFare.totalFareWithExtraBaggage! : item.passengerFare.totalFare}
                            currencyDisplay="code"
                          />
                        </Col>
                      </Row>
                    ))}
                    <Divider className="my-3" />
                  </>
                )}
                <Row justify="space-between">
                  <Col>
                    <b>{t(Translations.Common.TotalPrice)}</b>
                  </Col>
                  <Col>
                    <b>
                      <FormattedCurrency number={totalFare!} currencyDisplay="code" />
                    </b>
                  </Col>
                </Row>
              </div>
            </div>
            {props.isPackage && props.showSelectButton && (
              <div className="p-5">
                <Button block type="primary" htmlType="button" className="mb-3" onClick={handleSelectFlightClicked}>
                  {t(Translations.Package.SelectFlight)}
                </Button>
              </div>
            )}
            {props.showBookButtons && !props.isPackage && (
              <div className="p-5">
                {itinerary.hasExtraBaggage && (
                  <Link target="_blank" rel="noopener noreferrer" to={`/flights/flightPI?key=${itinerary.key}&extraBaggage=true`}>
                    <Button type="default" className="min-width-md antd-green7-btn mb-3 multi-line-btn" block>
                      {t(Translations.Flight.BookWithExtraBaggage)}{' '}
                      <span className="no-wrap">
                        {' '}
                        (+
                        <FormattedCurrency number={itinerary.priceInfo!.itinTotalFare.extraBaggage!} tooltip={{ show: false }} />)
                      </span>
                    </Button>
                  </Link>
                )}

                <FlightBookOperations itinerary={itinerary} block />
              </div>
            )}
          </Col>
        </Row>
      )}
    </Modal>
  );
});

export default FlightDetailsModal;
