import { CheckOutlined, CloseOutlined, CreditCardOutlined, EnvironmentOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Rate, Row, Skeleton, Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import moment from 'moment-jalaali';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BreakfastIcon from '../../../../../assets/coffee.svg';
import FoodIcon from '../../../../../assets/food.svg';
import roomSvg from '../../../../../assets/room-default-image.svg';
import HalfBoard from '../../../../../assets/silverware.svg';
import { useAppSelector } from '../../../../../features/hooks';
import useGlobalization from '../../../../../features/hooks/useGlobalization';
import { Translations } from '../../../../../features/localization';
import { currencySelector } from '../../../../account';
import { FormattedCurrency } from '../../../../shared';
import { DomesticSummaryHotelDetail, DomesticSummaryReserveDetail } from '../../../apiTypes';
import classes from './DomesticHotelBookingSummary.module.scss';

type Props = {
  summaryHotelInfo?: DomesticSummaryHotelDetail | undefined;
  summaryReserveInfo?: DomesticSummaryReserveDetail | undefined;
  hasSubmitBtn?: boolean;
  submitButtonLoading?: boolean;
};
const DomesticHotelBookingSummary: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const { reversePositionOnRtl, formatShortDate, culture } = useGlobalization();
  const currency = useAppSelector(currencySelector);

  let childCount = 0;
  let childPrice = 0;
  let extraBedCount = 0;
  let extraBedPrice = 0;
  if (props.summaryReserveInfo?.rooms) {
    childCount = props.summaryReserveInfo.rooms.reduce((total, item) => (item.selectedChild ? total + 1 : total), 0);
    childPrice = props.summaryReserveInfo.rooms.reduce((total, item) => (item.selectedChild ? total + (item.childFee || 0) : total), 0);
    extraBedCount = props.summaryReserveInfo.rooms.reduce((total, item) => total + (item.selectedExtraBed || 0), 0);
    extraBedPrice = props.summaryReserveInfo.rooms.reduce((total, item) => total + (item.selectedExtraBed || 0) * (item.extraBedFee || 0), 0);
  }

  const hasDiscount =
    props.summaryReserveInfo?.boardPrice && props.summaryReserveInfo.salePrice && props.summaryReserveInfo.salePrice < props.summaryReserveInfo.boardPrice;

  return !props.summaryHotelInfo ? (
    <Skeleton active paragraph={{ rows: 15 }} />
  ) : (
    <>
      <div className={classes.hotelSummeryHeader}>
        <div className={classes.hotelImageBlock} style={{ backgroundImage: 'url(' + props.summaryHotelInfo.ImageUrl + ')' }} />
        <div className={`padding-${reversePositionOnRtl('left')}-1 ${classes.hotelNameBlock}`}>
          <h3 className="title-sm mb-2">
            {culture.Language === 'Persian'
              ? `${props.summaryHotelInfo.HotelCategoryName} ${props.summaryHotelInfo.HotelName} ${props.summaryHotelInfo.CityName} `
              : `${props.summaryHotelInfo.CityName}  ${props.summaryHotelInfo.HotelName} ${props.summaryHotelInfo.HotelCategoryName} `}
          </h3>
          <Rate className="mb-2" disabled value={props.summaryHotelInfo.HotelRating} />
          <div className="text-muted">
            <EnvironmentOutlined className="align-middle" />
            <span className="align-middle mx-2">{props.summaryHotelInfo.Address}</span>
          </div>
        </div>
      </div>
      <Divider />
      {props.summaryReserveInfo ? (
        <>
          <Row justify="space-between" align={'middle'} gutter={[16, 0]} className="mb-1">
            <Col>{t(Translations.Hotel.CheckIn)}: </Col>
            <Col>
              <Tooltip title={formatShortDate(props.summaryReserveInfo.checkinDate)}>
                {moment(props.summaryReserveInfo.checkinDate).format('jYYYY/jMM/jDD')} <InfoCircleOutlined />
              </Tooltip>
            </Col>
          </Row>
          <Row justify="space-between" align={'middle'} gutter={[16, 0]} className="mb-1">
            <Col>{t(Translations.Hotel.CheckOut)}:</Col>
            <Col>
              <Tooltip title={formatShortDate(props.summaryReserveInfo.checkoutDate)}>
                {moment(props.summaryReserveInfo.checkoutDate).format('jYYYY/jMM/jDD')} <InfoCircleOutlined />
              </Tooltip>
            </Col>
          </Row>
          <Row justify="space-between" align={'middle'} gutter={[16, 0]} className="mb-1">
            <Col>{t(Translations.Common.Duration)}:</Col>
            <Col>
              {t(Translations.Hotel.NightCount, { count: moment(props.summaryReserveInfo.checkoutDate).diff(props.summaryReserveInfo.checkinDate, 'day') })}
            </Col>
          </Row>
        </>
      ) : (
        <Skeleton />
      )}

      <Divider />
      <div>
        {props.summaryReserveInfo ? (
          props.summaryReserveInfo.rooms.map((room, index) => {
            let boardIcon: string | null = null;
            switch (room.boardCode) {
              case 'HB':
                boardIcon = HalfBoard;
                break;
              case 'FB':
                boardIcon = FoodIcon;
                break;
              case 'BB':
                boardIcon = BreakfastIcon;
                break;
              default:
                boardIcon = null;
            }
            return (
              <div key={index}>
                <div className={`${classes.roomSummeryHeader}  ${index > 0 ? 'mt-6' : ''}`}>
                  <div
                    className={`${classes.hotelImageBlock} ${room.image ? classes.roomApiImage : ''}`}
                    style={{ backgroundImage: `url('${room.image || roomSvg}')` }}
                  />
                  <div className={`padding-${reversePositionOnRtl('left')}-1 ${classes.hotelNameBlock}`}>
                    <h4 className="title-xs mb-2 font-fa">{room.name}</h4>
                    <div>
                      {room.bed} {t(Translations.Hotel.Guests)}
                      {room.selectedChild && <span> + 1 {t(Translations.Common.Child)}</span>}
                      {!!room.selectedExtraBed && (
                        <span>
                          {' '}
                          + {room.selectedExtraBed} {t(Translations.Hotel.ExtraBed)}
                        </span>
                      )}
                    </div>
                    <div>
                      <div className="mb-1">
                        {boardIcon && <img src={boardIcon} className={`mr-2 ${classes.icon}`} alt={room.boardCode} />}
                        <b>{room.boardCode}</b>
                      </div>
                      <div className="mb-1">
                        {!!room.cancellationPolicyStatus && (
                          <span
                            className={clsx({
                              green7: room.cancellationPolicyStatus === 'Refundable',
                              red7: room.cancellationPolicyStatus !== 'Refundable',
                              'vertical-align-middle': true,
                            })}>
                            {room.cancellationPolicyStatus === 'Refundable' ? <CheckOutlined className="mr-2" /> : null}
                            {t(`Booking.CancellationPolicy${room.cancellationPolicyStatus}`)}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {room.description && (
                  <div className="mb-3 mt-3 font-fa" dir={'rtl'}>
                    <Text type="warning">
                      <InfoCircleOutlined /> {room.description}
                    </Text>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <Skeleton />
        )}
      </div>
      <Divider />
      {props.summaryReserveInfo?.rooms[0].availablityType === 'Request' ? (
        <b className="text-danger">{t(Translations.Common.ThePriceNeedsToBeInquired)}</b>
      ) : props.summaryReserveInfo ? (
        <>
          {(!extraBedPrice && !childPrice) || (
            <Row gutter={[16, 10]} align="middle" justify="space-between" className="mb-1">
              <Col>
                <h5 className="title-xs mb-0">{t(Translations.Hotel.Rooms)}</h5>
              </Col>
              <Col>
                <FormattedCurrency number={props.summaryReserveInfo.salePrice} currency={currency} />
              </Col>
            </Row>
          )}
          {!!extraBedCount && (
            <Row gutter={[16, 10]} align="middle" justify="space-between" className="mb-1">
              <Col>
                <h5 className="title-xs mb-0">
                  {t(Translations.Hotel.ExtraBed)} (x{extraBedCount})
                </h5>
              </Col>
              <Col>
                <FormattedCurrency number={extraBedPrice} currency={currency} />
              </Col>
            </Row>
          )}
          {!!childCount && (
            <Row gutter={[16, 10]} align="middle" justify="space-between" className="mb-1">
              <Col>
                <h5 className="title-xs mb-0">
                  {t(Translations.Common.Child)} (x{childCount})
                </h5>
              </Col>
              <Col>
                <FormattedCurrency number={childPrice} currency={currency} />
              </Col>
            </Row>
          )}

          {hasDiscount && (
            <>
              <Row gutter={[16, 10]} align="middle" justify="space-between" className="mb-1">
                <Col>
                  <h5 className="title-xs mb-0 text-muted">{t(Translations.Payment.PreviousPrice)}</h5>
                </Col>
                <Col>
                  <s className="text-muted">
                    <FormattedCurrency number={props.summaryReserveInfo.boardPrice} />
                  </s>
                </Col>
              </Row>
              <Row gutter={[16, 10]} align="middle" justify="space-between" className="mb-1">
                <Col>
                  <h5 className="title-xs mb-0">{t(Translations.Payment.Discount)}</h5>
                </Col>
                <Col>
                  <FormattedCurrency number={props.summaryReserveInfo.discount} />
                </Col>
              </Row>
            </>
          )}

          <Row gutter={[16, 10]} align="middle" justify="space-between">
            <Col>
              <h5 className="title-sm mb-0 text-danger">
                {hasDiscount || !!extraBedPrice || !!childPrice ? t(Translations.Common.FinalPrice) : t(Translations.Common.Price)}
              </h5>
            </Col>
            <Col>
              <h5 className="title-md mb-0 text-danger">
                <span className="align-middle">
                  <FormattedCurrency number={props.summaryReserveInfo.salePrice + extraBedPrice + childPrice} currency={currency} />
                </span>
              </h5>
            </Col>
          </Row>
        </>
      ) : (
        <Skeleton />
      )}

      {props.hasSubmitBtn && (
        <div className="text-center mt-3">
          <Button type="primary" htmlType={'submit'} danger icon={<CreditCardOutlined />} size="large" className="w-100" loading={props.submitButtonLoading}>
            {props.summaryReserveInfo?.rooms[0].availablityType === 'Request'
              ? t(Translations.Common.ReservationRequest)
              : t(Translations.Common.ContinueToPayment)}
          </Button>
        </div>
      )}
    </>
  );
});

export default DomesticHotelBookingSummary;
