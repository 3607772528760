import { Slider } from 'antd';
import { SliderMarks } from 'antd/es/slider';
import has from 'lodash/has';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import moon from '../../../../../../assets/moon.svg';
import sun from '../../../../../../assets/sun.svg';
import sunrise from '../../../../../../assets/sunrise.svg';
import sunset from '../../../../../../assets/sunset.svg';
import { useAppSelector } from '../../../../../../features/hooks';
import { Translations } from '../../../../../../features/localization';
import { useBookingTempFilters } from '../../../../../booking';
import { FilterItemTitle } from '../../../../../shared';
import classes from '../FlightFilter.module.scss';

type Props = {
  optionIndex: number;
  type: 'DepartureTime' | 'ArrivalTime';
  origin: string;
  destination: string;
  isPackage?: boolean;
};
const FlightsFilterDepartureArrival: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const { filter } = useBookingTempFilters({ moduleType: props.isPackage ? 'Package' : 'Flight' });

  const hoursMinMax = useAppSelector((state) =>
    props.isPackage
      ? state.package.temp.packages.constants.flights.fromToHourRange![props.optionIndex][props.type === 'DepartureTime' ? 'departure' : 'arrival']
      : state.flight.temp.flights.constants.fromToHourRange![props.optionIndex][props.type === 'DepartureTime' ? 'departure' : 'arrival']
  );

  const marks = useMemo<SliderMarks | undefined>(() => {
    const determineIconByHour = (hour: number) => {
      if (hour <= 3 || hour >= 21) {
        return moon;
      } else if (hour > 3 && hour < 11) {
        return sunrise;
      } else if (hour >= 11 && hour <= 16) {
        return sun;
      } else if (hour > 16 && hour < 21) {
        return sunset;
      }
      return '';
    };

    const createMark = (hour: number) => ({
      label: (
        <div>
          <div>{hour}'</div> <img src={determineIconByHour(hour)} alt="time" className={classes.dayTimeIcon} />
        </div>
      ),
    });

    const createMarkIfEmpty = (obj: SliderMarks, min: number, max: number, hour: number) => {
      if (!has(obj, hour) && min < hour && max > hour) {
        marks[hour] = createMark(hour);
      }
    };

    const marks: SliderMarks = {};
    if (hoursMinMax) {
      const min = hoursMinMax[0];
      const max = hoursMinMax[1];

      marks[min] = createMark(min);
      marks[max] = createMark(max);

      createMarkIfEmpty(marks, min, max, 6);
      createMarkIfEmpty(marks, min, max, 12);
      createMarkIfEmpty(marks, min, max, 18);
    }
    return marks;
  }, [hoursMinMax]);

  const handleResetClicked = useCallback(() => {
    filter({ type: props.type, value: { optionIndex: props.optionIndex, times: undefined } });
  }, [filter, props.optionIndex, props.type]);

  const handleFilterChanged = (value: [number, number]) => {
    filter({ type: props.type, value: { optionIndex: props.optionIndex, times: value } });
  };

  return (
    <>
      <FilterItemTitle
        title={
          <label className="mb-0">
            {props.type === 'DepartureTime'
              ? `${t(Translations.Flight.DepartureTime)} ${t(Translations.Common.From)} ${props.origin}`
              : `${t(Translations.Flight.ArrivalTime)} ${t(Translations.Common.To2)} ${props.destination}`}
          </label>
        }
        resetButtonVisibility={false}
        onResetClick={handleResetClicked}
      />

      <Slider
        className="pb-15 mt-0 mb-2"
        onAfterChange={handleFilterChanged}
        step={1}
        min={hoursMinMax[0]}
        max={hoursMinMax[1]}
        defaultValue={hoursMinMax}
        tipFormatter={(value) => `${value}`}
        range
        marks={marks}
      />
    </>
  );
});

export default FlightsFilterDepartureArrival;
