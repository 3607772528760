import React from 'react';
import { Switch } from 'react-router-dom';

import { Layout } from '../_metronic/layout';
import { useAppSelector } from '../features/hooks';
import { DefinedRoute } from '../types';
import { createFromDefinedRoutes } from '../utils/helpers';
import { AccountRoutes } from './account';
import { AdminRoutes } from './admin';
import { AuthRoutes, LogoutPage } from './authentication';
import { DomesticFlightRoutes } from './domesticFlight';
import { DomesticHotelRoutes } from './domesticHotel';
import { FlightRoutes } from './flight';
import { HotelRoutes } from './hotel';
import { PackageRoutes } from './package';
import { PaymentRoutes } from './payment';
import { SharedRoutes } from './shared';
import { SupportRoutes } from './support';
import { TestRoutes } from './testModule';
import { TicketingRoutes } from './ticketing';

const Routes = React.memo(() => {
  //const history = useHistory();

  const isAuthenticated = useAppSelector((state) => state.authentication.isAuthenticated);
  //const fromLoginPage = useAppSelector((state) => state.authentication.fromLoginPage);

  let mainRoutes: DefinedRoute[] | undefined = undefined;
  if (isAuthenticated) {
    mainRoutes = [
      { type: 'Route', path: '/auth/logout', children: LogoutPage, exact: true },
      { type: 'Builder', children: createFromDefinedRoutes(SharedRoutes()) },
      { type: 'Builder', children: createFromDefinedRoutes(TestRoutes()) },
      { type: 'Builder', children: createFromDefinedRoutes(AccountRoutes()) },
      { type: 'Builder', children: createFromDefinedRoutes(AdminRoutes()) },
      { type: 'Builder', children: createFromDefinedRoutes(PaymentRoutes()) },
      { type: 'Builder', children: createFromDefinedRoutes(SupportRoutes()) },
      { type: 'Builder', children: createFromDefinedRoutes(FlightRoutes()) },
      { type: 'Builder', children: createFromDefinedRoutes(HotelRoutes()) },
      { type: 'Builder', children: createFromDefinedRoutes(DomesticHotelRoutes()) },
      { type: 'Builder', children: createFromDefinedRoutes(PackageRoutes()) },
      { type: 'Builder', children: createFromDefinedRoutes(DomesticFlightRoutes()) },
      { type: 'Builder', children: createFromDefinedRoutes(TicketingRoutes()) },
      //{ type: 'Redirect', exact: true, from: '/', path: '/home' },
      { type: 'Redirect', path: '/home' },
    ];
  }

  return mainRoutes ? (
    <Layout>
      <Switch>{createFromDefinedRoutes(mainRoutes)}</Switch>
    </Layout>
  ) : (
    <Switch>{createFromDefinedRoutes(AuthRoutes)}</Switch>
  );
});

export default Routes;
