import { TableProps } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import React, { useCallback, useMemo, useState } from 'react';

import { useAppSelector } from '../../../features/hooks';
import { GetAllGenericResponse } from '../../../types';
import { scrollTop } from '../../../utils/helpers';
import { ShowTotalPagination, preferencesSelector } from '..';

type AntdTableAsyncHookOptions = {
  pageSize?: number;
  rowKey?: string;
  last20?: boolean;
};
function useAntdTableAsync<TRecordType>(options: AntdTableAsyncHookOptions = {}) {
  const preferences = useAppSelector(preferencesSelector);

  if (!options.pageSize) {
    options.pageSize = options.last20 ? 20 : preferences.pageSize;
  }
  options.rowKey = options.rowKey ?? 'id';

  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [records, setRecords] = useState<TRecordType[]>();
  const [pageSize, setPageSize] = useState(options.pageSize);

  const setData = useCallback((data: GetAllGenericResponse<TRecordType>) => {
    setRecords(data.items);
    setTotal(data.totalCount);
  }, []);

  return useMemo(() => {
    const paginationConfig: TablePaginationConfig = {
      showSizeChanger: !options.last20,
      hideOnSinglePage: options.last20,
      total: options.last20 ? 20 : total,

      size: 'default',
      position: ['bottomLeft'],
      pageSize: pageSize,
      current: page,
      onShowSizeChange: (current, size) => {
        setPageSize(size);
        scrollTop();
      },
      onChange: (page) => {
        setPage(page);
        scrollTop();
      },
      showTotal: (total: number, range: [number, number]) => <ShowTotalPagination from={range[0]} to={range[1]} total={total} />,
    };

    const tableProps: TableProps<TRecordType> = {
      size: 'middle',
      pagination: paginationConfig,
      dataSource: records,
      rowKey: options.rowKey,
    };

    const skipCount = (page - 1) * options.pageSize!;
    const skipCountCompute = (currentPage: number) => (currentPage - 1) * options.pageSize!;

    return { page, setPage, pageSize, total, records, tableProps, setData, paginationConfig, skipCount, skipCountCompute };
  }, [options.last20, options.pageSize, options.rowKey, page, pageSize, records, setData, total]);
}

export default useAntdTableAsync;
