import { Card, Col, Row, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useGlobalization } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { toQueryString } from '../../../../utils/helpers';
import { getAirTripTypeTranslationKey } from '../../../flight/helpers/FlightHelpers';
import { FlightTripType, FlightsQueryString } from '../../../flight/types';
import classes from './RecentSearches.module.scss';

type Props = {
  search: FlightsQueryString;
};
const RecentSearchFlight: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const { reversePositionOnRtl, formatShortDate, formatShortMonthWeekDay } = useGlobalization();

  return (
    <Col xs={24} sm={12} className={classes.recentSearchItem}>
      <Link to={`flights?${toQueryString(props.search, 'bracket')}`} className={classes.recentSearchItemLink}>
        <Card className={`antd-card-small-padding ${classes.recentSearchItemCard} ${reversePositionOnRtl('left')}-aligned`}>
          <div className={classes.recentSearchInfo}>
            <div className={'text-muted mb-1'}>{t(`Flight.${getAirTripTypeTranslationKey(props.search.airTripType as FlightTripType)}`)}</div>
            {props.search.airTripType === 'OpenJaw' ? (
              <>
                {props.search.originCodes.map((routeItem: string, index: number) => (
                  <Row key={index} gutter={[15, 0]} align={'middle'} className={'mb-1'}>
                    <Col>
                      <h4 className={'title-sm mb-0'}>
                        <Tooltip title={props.search.originsText[index]}>{props.search.originCodes[index]}</Tooltip> {t(Translations.Common.To2)}{' '}
                        <Tooltip title={props.search.destinationsText[index]}>{props.search.destinationCodes[index]}</Tooltip>
                      </h4>
                    </Col>
                    <Col>
                      <Tooltip title={<span className="en-font">{formatShortDate(props.search.departureDateTimes[index])}</span>}>
                        <div className={'text-primary font-weight-bold'}>{formatShortMonthWeekDay(props.search.departureDateTimes[index])}</div>
                      </Tooltip>
                    </Col>
                  </Row>
                ))}
              </>
            ) : (
              <>
                <h4 className={'title-sm mb-1'}>
                  <Tooltip title={props.search.originsText[0]}>{props.search.originCodes[0]}</Tooltip> {t(Translations.Common.To2)}{' '}
                  <Tooltip title={props.search.destinationsText[0]}>{props.search.destinationCodes[0]}</Tooltip>
                </h4>

                <div className={'text-primary font-weight-bold mb-1'}>
                  <Tooltip title={<span className="en-font">{formatShortDate(props.search.departureDateTimes[0])}</span>}>
                    {formatShortMonthWeekDay(props.search.departureDateTimes[0])}
                  </Tooltip>
                  {props.search.airTripType === 'RoundTrip' ? (
                    <>
                      {' '}
                      -{' '}
                      <Tooltip title={<span className="en-font">{formatShortDate(props.search.departureDateTimes[1])}</span>}>
                        {formatShortMonthWeekDay(props.search.departureDateTimes[1])}
                      </Tooltip>
                    </>
                  ) : null}
                </div>
              </>
            )}

            <span className={'text-muted'}>
              {props.search.cabinClassCode ? (
                <>
                  <Tooltip title={t(Translations.Flight.CabinClass)}>
                    {props.search.cabinClassCode === 'all'
                      ? t(Translations.Common.All)
                      : props.search.cabinClassCode === 'Economy'
                      ? t(Translations.Flight.Economy)
                      : props.search.cabinClassCode === 'Firstclass'
                      ? t(Translations.Flight.FirstClass)
                      : props.search.cabinClassCode === 'Business'
                      ? t(Translations.Flight.Business)
                      : props.search.cabinClassCode === 'Premium'
                      ? t(Translations.Flight.Premium)
                      : props.search.cabinClassCode}
                  </Tooltip>{' '}
                  -{' '}
                </>
              ) : null}
              {t(Translations.Common.AdultCount, { count: props.search.adult })}{' '}
              {props.search.child > 0 && (
                <>
                  {t(Translations.Common.Comma)} {t(Translations.Common.ChildCount, { count: props.search.child })}
                </>
              )}{' '}
              {props.search.infant > 0 && (
                <>
                  {t(Translations.Common.Comma)} {t(Translations.Common.InfantCount, { count: props.search.infant })}
                </>
              )}
            </span>
          </div>

          <span className={`fas fa-plane mirror-in-rtl bg-blue  type-circle-icon ${classes.recentSearchItemIcon}`} />
          <span className={`text-primary ${classes.recentSearchItemSearch}`}>{t(Translations.Common.Search)}</span>
        </Card>
      </Link>
    </Col>
  );
});

export default RecentSearchFlight;
