import React from 'react';
import { useTranslation } from 'react-i18next';

import { Translations } from '../../../../../features/localization';
import { FlightSegmentModel } from '../../../apiTypes';

type Props = {
  segment: FlightSegmentModel;
  hasExtraBaggage?: boolean;
};
const FlightSegmentBaggageDetails: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const baggageInformation = props.segment.baggageInformation;
  let updatedBaggageInformation: any[] = [];
  if (props.hasExtraBaggage && props.segment.extraBaggageInformation && props.segment.extraBaggageInformation.length > 0) {
    for (let i = 0; i < baggageInformation.length; i++) {
      const extraBaggageItemObject = props.segment.extraBaggageInformation!.filter(
        (extraBaggageItem) => extraBaggageItem.passengerType === baggageInformation[i].passengerType
      )[0];
      updatedBaggageInformation.push({
        ...baggageInformation[i],
        extraBaggage: extraBaggageItemObject.baggageAllowance,
        extraBaggageUnitType: extraBaggageItemObject.unitType,
      });
    }
  } else {
    updatedBaggageInformation = props.segment.baggageInformation;
  }
  return (
    <>
      {updatedBaggageInformation.map((baggageItem) => (
        <div key={baggageItem.passengerType} className={'no-wrap'}>
          <span dir={'ltr'} className={'mx-1'}>
            {baggageItem.baggageAllowance}
            {baggageItem.unitType}
          </span>
          {props.hasExtraBaggage && props.segment.extraBaggageInformation && props.segment.extraBaggageInformation.length > 0 && (
            <>
              {' '}
              (+ {baggageItem.extraBaggage} {baggageItem.extraBaggageUnitType} extra baggage)
            </>
          )}{' '}
          {t(Translations.Common.For)} {baggageItem.passengerType}
        </div>
      ))}
    </>
  );
});

export default FlightSegmentBaggageDetails;
