import Axios from 'axios';

import { AxiosInstanceData } from '../../../types';
import { isDevelopment, urlWithQuery } from '../../../utils/helpers';
import { GetHotelByIdRequest, HotelGetCancellationPolicyRequest } from '../../hotel/apiTypes';
import { PackageAvailabilityRequest, PackageGetAllTenantReservesRequest, PackagePreReserveRoomRequest, PackageReserveRequest } from '../apiTypes';
import { PackageApiDataMap, PackageApiUrls } from './package-apiData';

const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_PackageApiBaseUrl;

if (isDevelopment()) {
  //const mock = new MockAdapter(axiosInstance, { onNoMatch: 'passthrough', delayResponse: 0 });
  //packageMock(mock);
}

export const packageGetAirportHttp = (data: { Value: string }) => {
  const url = urlWithQuery(PackageApiUrls.GetAirport, data);
  return axiosInstance.get(url);
};

export const packageAvailabilityHttp = (data: PackageAvailabilityRequest) => {
  return axiosInstance.post(PackageApiUrls.Availability, data);
};

export const packageGetAvailabilityHttp = (data: { token: string }) => {
  const url = urlWithQuery(PackageApiUrls.GetAvailability, data);
  return axiosInstance.get(url);
};

export const packageGetHotelDetailsByIdHttp = (data: GetHotelByIdRequest) => {
  return axiosInstance.post(PackageApiUrls.GetHotelDetailById, data);
};

export const packagePreReserveFlightHttp = (data: { flightKey: string }) => {
  return axiosInstance.post(PackageApiUrls.PreReserveFlight, data);
};

export const packageGetFlightCancellationPolicyHttp = (data: { reserveId: string; username: string }) => {
  return axiosInstance.post(PackageApiUrls.GetFlightCancellationPolicy, data);
};

export const packagePreReserveRoomHttp = (data: PackagePreReserveRoomRequest) => {
  return axiosInstance.post(PackageApiUrls.PreReserveRoom, data);
};

export const packageReserveHttp = (data: PackageReserveRequest) => {
  return axiosInstance.post(PackageApiUrls.Reserve, data);
};

export const packageConfirmHttp = (data: { reserveId: number; username: string }) => {
  return axiosInstance.post(PackageApiUrls.Confirm, data);
};

export const packageGetHotelCancellationPolicy = (data: HotelGetCancellationPolicyRequest) => {
  const url = urlWithQuery(PackageApiUrls.GetHotelCancellationPolicy, data);
  return axiosInstance.get(url);
};

export const packageValidateFlightHttp = (data: { flightKey: string }) => {
  return axiosInstance.post(PackageApiUrls.ValidateFlight, data);
};

export const packageGetPreReserveHttp = (data: { PreReserveKey: string }) => {
  const url = urlWithQuery(PackageApiUrls.GetPreReserve, data);
  return axiosInstance.get(url);
};

export const packageGetReserveHttp = (data: { ReserveId: number }) => {
  const url = urlWithQuery(PackageApiUrls.GetReserve, data);
  return axiosInstance.get(url);
};

export const packageGetAllTenantReservesHttp = (data: PackageGetAllTenantReservesRequest) => {
  const url = urlWithQuery(PackageApiUrls.GetAllTenantReserves, data);
  return axiosInstance.get(url);
};

export const PackageAxiosData: AxiosInstanceData = {
  instance: axiosInstance,
  DataMap: {
    MapObject: PackageApiDataMap,
    Urls: PackageApiUrls,
  },
};
