import { Card, Tabs } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useAppSelector, useMount } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { isProduction } from '../../../../utils/helpers';
import { RecentSearches, useDomesticModules } from '../../../booking';
import { DomesticFlightSearchForm } from '../../../domesticFlight';
import { DomesticHotelSearchForm } from '../../../domesticHotel';
import { FareQuoteSearchForm, FlightSearchForm, FlightTicketingSearchForm } from '../../../flight';
import { HotelSearchForm } from '../../../hotel';
import { PackageSearchForm } from '../../../package';
import { TabHeaderWithIcon, preferencesSelector, sharedSlice } from '../../index';
import { isSectionEnabled } from '../../TenantHostConfig';
import classes from './Home.module.scss';

const flightDomesticTabKey = 'FlightDomestic';
const flightNormalTabKey = 'Flight';
const flightFlexTabKey = 'FlightFlex';
const flightFareViewTabKey = 'FareViewFlight';
const hotelTabKey = 'Hotel';
const hotelDomesticTabKey = 'HotelDomestic';
const flightEthnicTabKey = 'Ethnic';
const packageTabKey = 'Package';
const ticketingTabKey = 'Ticketing';

function Home() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isEnabled: domesticHotelEnabled } = useDomesticModules('HotelDomestic');
  const { isEnabled: domesticFlightEnabled } = useDomesticModules('FlightDomestic');

  const preferences = useAppSelector(preferencesSelector);

  const [tabActiveKey, setTabActiveKey] = useState(preferences.homeSearchFormTabKey);

  useMount(() => {
    dispatch(sharedSlice.actions.setDisplayPath({ title: t(Translations.Menu.Home), fontawesomeIcon: 'home', disableAutoLastBreadcrumb: true }));

    return () => {
      dispatch(sharedSlice.actions.setDisplayPath(null));
    };
  });

  const handleTabChange = (value: string) => {
    setTabActiveKey(value as 'FlightDomestic' | 'Flight' | 'Hotel' | 'HotelDomestic' | 'Ethnic' | 'Package' | 'Ticketing');
  };

  return (
    <>
      {!isProduction() && <h6>Flight Api Address : {process.env.REACT_APP_FlightApiBaseUrl}</h6>}

      <Card className="box-shadow mb-6 antd-card-small-padding-mobile">
        <Tabs activeKey={tabActiveKey} onChange={handleTabChange} className={classes.homeSearchTabs} destroyInactiveTabPane>
          {isSectionEnabled('Flight') && (
            <Tabs.TabPane
              key={flightNormalTabKey}
              tab={<TabHeaderWithIcon icon={<i className="fas fa-plane mirror-in-rtl" />} title={t(Translations.Flight.ForeignFlight)} />}>
              <FlightSearchForm inline isEthnic={false} />
            </Tabs.TabPane>
          )}
          {domesticFlightEnabled && (
            <Tabs.TabPane
              key={flightDomesticTabKey}
              tab={<TabHeaderWithIcon icon={<i className="fas fa-plane mirror-in-rtl" />} title={t(Translations.Flight.DomesticFlight)} />}>
              <DomesticFlightSearchForm inline />
            </Tabs.TabPane>
          )}
          {isSectionEnabled('Hotel') && (
            <Tabs.TabPane key={hotelTabKey} tab={<TabHeaderWithIcon icon={<i className="fas fa-hotel" />} title={t(Translations.Hotel.ForeignHotel)} />}>
              <HotelSearchForm inline hide={tabActiveKey !== hotelTabKey} />
            </Tabs.TabPane>
          )}
          {domesticHotelEnabled && (
            <Tabs.TabPane
              key={hotelDomesticTabKey}
              tab={<TabHeaderWithIcon icon={<i className="fas fa-hotel" />} title={t(Translations.Common.DomesticHotel)} />}>
              <DomesticHotelSearchForm inline hide={tabActiveKey !== hotelDomesticTabKey} />
            </Tabs.TabPane>
          )}
          {isSectionEnabled('Package') && (
            <Tabs.TabPane key={packageTabKey} tab={<TabHeaderWithIcon icon={<i className="fas fa-box" />} title={t(Translations.Booking.Package)} />}>
              <PackageSearchForm inline />
            </Tabs.TabPane>
          )}
          {isSectionEnabled('Ethnic') && (
            <Tabs.TabPane
              key={flightEthnicTabKey}
              tab={<TabHeaderWithIcon icon={<i className="fas fa-plane mirror-in-rtl" />} title={t(Translations.Booking.Ethnic)} />}>
              <FlightSearchForm inline isEthnic />
            </Tabs.TabPane>
          )}
          {isSectionEnabled('Ticketing') && (
            <Tabs.TabPane key={ticketingTabKey} tab={<TabHeaderWithIcon icon={<i className="fas fa-ticket-alt" />} title={t(Translations.Flight.Ticketing)} />}>
              <FlightTicketingSearchForm isInline />
            </Tabs.TabPane>
          )}
          <Tabs.TabPane
            key={flightFareViewTabKey}
            tab={
              <TabHeaderWithIcon
                icon={<i className="fas fa-plane text-green mirror-in-rtl" />}
                title={t(Translations.Flight.FareView)}
                className="text-green bold"
              />
            }>
            <FareQuoteSearchForm inline />
          </Tabs.TabPane>
          <Tabs.TabPane
            key={flightFlexTabKey}
            tab={<TabHeaderWithIcon icon={<i className="fas fa-calendar-alt mirror-in-rtl" />} title="Sabre Calendar Route" />}>
            <FlightSearchForm inline isEthnic={false} isFlexCalendar />
          </Tabs.TabPane>
        </Tabs>
      </Card>

      <Card className="box-shadow antd-card-small-padding-mobile" title={t(Translations.Common.RecentSearches)}>
        <RecentSearches />
        {/* <Tabs>
          <Tabs.TabPane
            key={'recentSearches'}
            tab={<TabHeaderWithIcon icon={<i className="fas fa-history" />} title={t(Translations.Common.RecentSearches)} />}>
            <SectionErrorBoundary>
              
            </SectionErrorBoundary>
          </Tabs.TabPane>
          <Tabs.TabPane
            key={'waitingPayments'}
            tab={<TabHeaderWithIcon icon={<i className="fas fa-shopping-cart" />} title={t(Translations.Common.WaitingPayments)} />}>
            <SectionErrorBoundary>
              <WaitingPayments />
            </SectionErrorBoundary>
          </Tabs.TabPane>
        </Tabs> */}
      </Card>
    </>
  );
}

export default Home;
