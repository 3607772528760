import { DatePicker, Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAntdValidation, useGlobalization } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { CultureName } from '../../../../../features/localization/cultures';
import { disableYesterdayAndBeforeAntdDatePicker, getNowISO } from '../../../../../utils/helpers';
import { PDatePicker2 } from '../../../../shared';

type Props = {
  form: FormInstance;
};
const PackageSearchFormDates: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const { culture } = useGlobalization();
  const { labelWithRules } = useAntdValidation(props.form);

  return (
    <>
      <Form.Item
        className="mb-4"
        name="checkInCheckOut"
        {...labelWithRules({ label: t(Translations.Hotel.CheckInAndCheckOut), rules: [{ type: 'Required' }] })}>
        {culture.Name === CultureName.FaIr ? (
          <PDatePicker2 initialCalendar="persian" mode="range" minDate={getNowISO()} />
        ) : (
          <DatePicker.RangePicker
            inputReadOnly
            dropdownClassName="disabled-year-arrow"
            className={'w-100'}
            disabledDate={disableYesterdayAndBeforeAntdDatePicker}
          />
        )}
      </Form.Item>
    </>
  );
});

export default PackageSearchFormDates;
