import { ApiDataItem } from '../../../types';

export enum AdminApiUrls {
  GetAllUsers = '/api/services/app/User/GetAll',
  GetUser = '/api/services/app/User/Get',
  CreateUser = '/api/services/app/User/Create',
  UpdateUser = '/api/services/app/User/Update',
  DeleteUser = '/api/services/app/User/Delete',
  GetUserPermissionsForEdit = '/api/services/app/User/GetUserPermissionsForEdit',
  UpdateUserPermissions = '/api/services/app/User/UpdateUserPermissions',
  ResetUserPassword = '/api/services/app/User/ResetPassword',
}

export const AdminApiDataMap: Map<string, ApiDataItem> = new Map();
