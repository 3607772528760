import { PrinterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { toQueryString } from '../../../../utils/helpers';
import { currentTenantSelector } from '../../../authentication';

type Props = {
  reserveId: string;
  username: string;
  buttonText?: string;
  fullWidth?: boolean;
  blue?: boolean;
};
const PrintTicketLinkButton: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const currentTenant = useAppSelector(currentTenantSelector);

  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={`${process.env.REACT_APP_VoucherApiBaseUrl}/en/${currentTenant!.name}/flight/ticket?${toQueryString({
        reserveId: props.reserveId,
        username: props.username,
      })}`}>
      <Button
        type="default"
        block={props.fullWidth}
        htmlType="button"
        className={` reserveListButton printBtn ${props.blue ? 'blue-btn' : ''}`}
        icon={<PrinterOutlined />}>
        {props.buttonText || t(Translations.Flight.Ticket)}
      </Button>
    </a>
  );
});

export default PrintTicketLinkButton;
