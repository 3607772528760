import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Translations } from '../localization';

const useDefinedTranslations = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    const gender = (value: boolean) => {
      return value ? t(Translations.Common.Male) : t(Translations.Common.Female);
    };

    const priority = (value: 'High' | 'Medium' | 'Low') => {
      switch (value) {
        case 'High':
          return t(Translations.Common.PriorityHigh);
        case 'Medium':
          return t(Translations.Common.PriorityMedium);
        case 'Low':
          return t(Translations.Common.PriorityLow);
      }
    };

    return { gender, priority };
  }, [t]);
};

export default useDefinedTranslations;
