import React from 'react';

type Props = {};
const PriceCalendarDual: React.VFC<Props> = React.memo((props: Props) => {
  return (
    <div>
      <p>A</p>
    </div>
  );
});

export default PriceCalendarDual;
