import { Checkbox, Col, Form, Input, Row, Space } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Translations } from '../../../../../features/localization';
import { isDevelopment } from '../../../../../utils/helpers';
import { CheckableButton, TextArea } from '../../../../shared';

type Props = {
  pending?: boolean;
};
const HotelPreReserveSpecialRequests: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const [otherRequestsChecked, setOtherRequestsChecked] = useState<boolean>();

  return (
    <>
      <Form.Item name="definedSpecialRequests" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <p>{t(Translations.Hotel.SpecialRequestsDescription1)}</p>
      <p>{t(Translations.Hotel.SpecialRequestsDescription2)}</p>
      {isDevelopment() && (
        <>
          <Row gutter={[20, 0]} className="mt-8">
            <Col xs={24} sm={12} md={8} xl={6}>
              <span className="fas fa-bed text-muted" /> <span className="font-weight-bold mx-2">{t(Translations.Hotel.BedPreference)}</span>
            </Col>
            <Col xs={24} sm={12} md={16} xl={18}>
              <Space>
                <Form.Item name={['definedSpecialRequests', 'extraBed']} noStyle>
                  <CheckableButton text={t(Translations.Hotel.ExtraBed)} size="small" disabled={props.pending} />
                </Form.Item>
                <Form.Item name={['definedSpecialRequests', 'babyCot']} noStyle>
                  <CheckableButton text={t(Translations.Hotel.BabyCot)} size="small" disabled={props.pending} />
                </Form.Item>
              </Space>
            </Col>
          </Row>
          <Row gutter={[20, 0]} className="mt-5">
            <Col xs={24} sm={12} md={8} xl={6}>
              <span className="fas fa-wheelchair text-muted" /> <span className="font-weight-bold mx-2">{t(Translations.Common.Accessibility)}</span>
            </Col>
            <Col xs={24} sm={12} md={16} xl={18}>
              <Form.Item name={['definedSpecialRequests', 'accessibleBathroom']} noStyle>
                <CheckableButton text={t(Translations.Hotel.AccessibleBathroom)} size="small" disabled={props.pending} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 0]} className="mt-5">
            <Col xs={24} sm={12} md={8} xl={6}>
              <span className="fas fa-wine-bottle text-muted" /> <span className="font-weight-bold mx-2">{t(Translations.Hotel.MinibarPreference)}</span>
            </Col>
            <Col xs={24} sm={12} md={16} xl={18}>
              <Form.Item name={['definedSpecialRequests', 'removeAlcoholFromMinibar']} noStyle>
                <CheckableButton text={t(Translations.Hotel.RemoveAlcoholFromMinibar)} size="small" disabled={props.pending} />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Row gutter={[20, 0]} className="mt-5">
        <Col>
          <Checkbox checked={otherRequestsChecked} onChange={(e) => setOtherRequestsChecked(e.target.checked)} disabled={props.pending}>
            {t(Translations.Common.OtherRequests)}
          </Checkbox>
        </Col>
      </Row>
      {otherRequestsChecked && (
        <Row gutter={[20, 0]} className="mt-5">
          <Col span={24}>
            <Form.Item name="otherSpecialRequests">
              <TextArea
                dir="ltr"
                placeholder={t(Translations.Hotel.SpecialRequestsPlaceholder)}
                rows={2}
                maxLength={200}
                showMaxLength
                disabled={props.pending}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
});

export default HotelPreReserveSpecialRequests;
