import { useMemo } from 'react';

import { ipLocationSelector } from '../../modules/shared';
import { useAppSelector } from './index';

const useIpLocation = () => {
  const ipLocation = useAppSelector(ipLocationSelector);

  const isFromIran = useMemo(() => {
    return ipLocation?.alpha2.toLowerCase() === 'ir';
  }, [ipLocation]);

  return { ipLocation, isFromIran };
};

export default useIpLocation;
