import { Checkbox, Col, Row } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../features/hooks';
import { Translations } from '../../../../../../features/localization';
import { useBookingTempFilters } from '../../../../../booking';
import { FilterItemTitle } from '../../../../../shared';
import { getHotelBoardCount } from '../../../../helpers/HotelHelpers';

type Props = {
  isPackage: boolean;
};
const HotelsFilterBoards: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const { filter } = useBookingTempFilters({ moduleType: props.isPackage ? 'Package' : 'Hotel' });

  const hotels = useAppSelector((state) => state.package.temp.packages.response?.hotels);
  const boards = useAppSelector((state) => (props.isPackage ? state.package.temp.packages.constants.hotels.boards : undefined));
  const filteredBoards = useAppSelector((state) => (props.isPackage ? state.package.temp.packages.processing.filters.hotels.boards : undefined));

  const handleResetClicked = () => {
    filter({ type: 'HotelBoards', value: undefined });
  };

  const handleChanged = (checkedValue: CheckboxValueType[]) => {
    filter({ type: 'HotelBoards', value: checkedValue as string[] });
  };

  return (
    <>
      <FilterItemTitle
        title={t(Translations.Hotel.Boards)}
        resetButtonVisibility={filteredBoards && filteredBoards.length > 0}
        onResetClick={handleResetClicked}
      />
      <Checkbox.Group className="w-100" value={filteredBoards} onChange={handleChanged}>
        {boards &&
          boards.map((item) => (
            <Row key={item.code} justify="space-between" className="mb-2">
              <Col>
                <Checkbox value={item.code}>
                  {item.name} ({item.code})
                </Checkbox>
              </Col>
              <Col>
                <span className="font-weight-bold">({getHotelBoardCount(hotels, item.code)})</span>
              </Col>
            </Row>
          ))}
      </Checkbox.Group>
    </>
  );
});

export default HotelsFilterBoards;
