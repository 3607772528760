import React from 'react';

import { DefinedRoute } from '../../types';

const prefix = '/support';
export const SupportPaths = {
  Notifications: `${prefix}/notifications`,
};
const SupportRoutes = (): readonly DefinedRoute[] => [
  { type: 'Route', exact: true, path: `${prefix}/about`, children: React.lazy(() => import('./pages/About/About')) },
  { type: 'Route', exact: true, path: `${prefix}/contact`, children: React.lazy(() => import('./pages/Contact/Contact')) },
  { type: 'Route', exact: true, path: `${prefix}/terms`, children: React.lazy(() => import('./pages/Terms/Terms')) },
  { type: 'Route', exact: true, path: SupportPaths.Notifications, children: React.lazy(() => import('./pages/Notifications/Notifications')) },
];

export default SupportRoutes;
