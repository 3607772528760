import { EnterOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import debounce from 'lodash/debounce';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAntdValidation, useHttpCall } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { GetAirportResponse } from '../../../../flight/apiTypes';
import { getAirportHttp } from '../../../../flight/http/flight-http';
import { ImprovedAutoComplete } from '../../../../shared';

type Props = {
  form: FormInstance;
  defaultOrigin?: { Name: string; Code: string };
  defaultDestination?: { Name: string; Code: string };
};
const PackageSearchFormLocations: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const [fromAirports, setFromAirports] = useState<GetAirportResponse>([]);
  const [toAirports, setToAirports] = useState<GetAirportResponse>([]);

  const { labelWithRules } = useAntdValidation(props.form);

  const getAirportApi = useHttpCall(getAirportHttp, { notificationOnError: true });

  const airportSearchSegment = useRef<'from' | 'to'>('from');

  const destinationInputRef = useRef<Input>(null);

  const debouncedGetAirports = debounce(async (value: string, to: boolean) => {
    airportSearchSegment.current = to ? 'to' : 'from';
    const response = await getAirportApi.call<GetAirportResponse>({ value: value });
    if (response && response.success && response.result) {
      if (to) {
        setToAirports(response.result);
      } else {
        setFromAirports(response.result);
      }
    } else {
      if (to) {
        setToAirports([]);
      } else {
        setFromAirports([]);
      }
    }
  }, 300);

  const handleAirportSearched = async (value: string, to: boolean) => {
    if (value && value.length > 2) {
      await debouncedGetAirports(value, to);
    } else {
      if (to) {
        setToAirports([]);
      } else {
        setFromAirports([]);
      }
    }
  };

  return (
    <Row gutter={[10, 0]}>
      <Col span={12}>
        <Form.Item className="mb-4" name="origin" {...labelWithRules({ label: t(Translations.Common.From), rules: [{ type: 'Required' }] })}>
          <ImprovedAutoComplete
            defaultItem={props.defaultOrigin}
            asyncPending={airportSearchSegment.current === 'from' && getAirportApi.pending}
            textPropertyName="Name"
            renderTooltip={(option) => (
              <>
                {option.ParentName}
                <br />
                {option.Name}
              </>
            )}
            options={fromAirports}
            onInputChanged={(value) => handleAirportSearched(value, false)}
            onChange={(item) => {
              if (item) {
                destinationInputRef.current?.focus();
              }
            }}
            renderOption={(option) => (
              <Row className={`no-wrap-flex ${option.Type === 2 ? 'pl-5' : ''}`}>
                <Col flex="20px">{option.Type === 1 ? <EnvironmentOutlined className="align-middle" /> : <EnterOutlined className="align-middle" />}</Col>
                <Col flex="auto">
                  <div>{option.Name}</div>
                  <small>{option.ParentName}</small>
                </Col>
              </Row>
            )}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item className="mb-4" name="destination" {...labelWithRules({ label: t(Translations.Common.To2), rules: [{ type: 'Required' }] })}>
          <ImprovedAutoComplete
            inputRef={destinationInputRef}
            defaultItem={props.defaultDestination}
            asyncPending={airportSearchSegment.current === 'to' && getAirportApi.pending}
            textPropertyName="Name"
            renderTooltip={(option) => (
              <>
                {option.ParentName}
                <br />
                {option.Name}
              </>
            )}
            options={toAirports}
            onInputChanged={(value) => handleAirportSearched(value, true)}
            renderOption={(option) => (
              <Row className={`no-wrap-flex ${option.Type === 2 ? 'pl-5' : ''}`}>
                <Col flex="20px">{option.Type === 1 ? <EnvironmentOutlined className="align-middle" /> : <EnterOutlined className="align-middle" />}</Col>
                <Col flex="auto">
                  <div>{option.Name}</div>
                  <small>{option.ParentName}</small>
                </Col>
              </Row>
            )}
          />
        </Form.Item>
      </Col>
    </Row>
  );
});

export default PackageSearchFormLocations;
