import { PrinterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Translations } from '../../../../features/localization';
import { toQueryString } from '../../../../utils/helpers';

type Props = {
  type: 'Hotel' | 'Flight';
  reserveId: number;
  username: string;
  fullWidth?: boolean;
  blue?: boolean;
};
const InvoiceLinkButton: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={`${process.env.REACT_APP_VoucherApiBaseUrl}/en/factor/${props.type === 'Flight' ? 'flight' : 'hotel'}?${toQueryString({
        reserveId: props.reserveId,
        username: props.username,
      })}`}>
      <Button
        type="default"
        block={props.fullWidth}
        htmlType="button"
        className={`reserveListButton invoiceBtn ${props.blue ? 'blue-btn' : ''}`}
        icon={<PrinterOutlined />}>
        {t(Translations.Payment.Invoice)}
      </Button>
    </a>
  );
});

export default InvoiceLinkButton;
