import { Col, Row } from 'antd';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';

import B2BIcon from '../../../../assets/B2B.svg';
import B2CIcon from '../../../../assets/B2C.svg';
import barreTravelWhiteLogo from '../../../../assets/barre-travel/barretravel-white.png';
import FlightIcon from '../../../../assets/flight.svg';
import HotelIcon from '../../../../assets/hotel.svg';
import PackageIcon from '../../../../assets/package.svg';
import WebApiIcon from '../../../../assets/web-api.svg';
import WhiteLabelIcon from '../../../../assets/white-label.svg';
import { useAppSelector } from '../../../../features/hooks';
import AuthPageFooter from '../../components/AuthPageFooter/AuthPageFooter';
import ForgotPassword from '../../components/ForgotPassword';
import Login from '../../components/Login';
import classes from './AuthPageBarreTravel.module.scss';

const AuthPageBarreTravel: React.VFC = () => {
  const isAuthenticated = useAppSelector((state) => state.authentication.isAuthenticated);
  const { path } = useRouteMatch();

  return (
    <>
      {isAuthenticated ? (
        <Redirect to="/" />
      ) : (
        <div className="ayanair-auth">
          <div className={classes.topBanner}>
            <div className="page-container ayanair-auth">
              <Row>
                <Col xs={24} md={12} xl={8}>
                  <nav className={classes.ayanAirAuthNav}>
                    <Link to={'auth/login'} className={classes.navLink}>
                      Home
                    </Link>
                    <Link to={'/about'} className={classes.navLink}>
                      About us
                    </Link>
                    <Link to={'/contact'} className={classes.navLink}>
                      Contact Us
                    </Link>
                  </nav>

                  <img src={barreTravelWhiteLogo} alt="barre travel" className={classes.logo} />
                  <p className="mt-4 mb-6 text-center text-white">The best and fastest way to book your next trip</p>
                  <div className={classes.formHolder}>
                    <div className={'d-none'}>
                      <Switch>
                        <Route path={`${path}/login`}>
                          <Login />
                        </Route>
                        <Route path={`${path}/forgot-password`}>
                          <ForgotPassword />
                        </Route>
                        <Redirect exact from={`${path}`} to={`${path}/login`} />
                        <Redirect to={`${path}/login`} />
                      </Switch>
                    </div>
                    <Login />
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className={classes.servicesSection}>
            <div className="page-container">
              <h2 className="title-lg text-purple">WE COVER ALL TRAVEL SERVICES</h2>
              <p>Our services are available for both leisure and corporate travel.</p>
              <br />
              <Row align={'middle'} justify={'center'} gutter={[20, 20]} className={'mt-10'}>
                <Col>
                  <div className={classes.serviceItemHolder}>
                    <img src={FlightIcon} className={classes.icon} alt="Flight" />
                    <h3 className={'title-xs mb-0 mt-2 px-2'}>Flight</h3>
                  </div>
                </Col>
                <Col>
                  <div className={classes.serviceItemHolder}>
                    <img src={HotelIcon} className={classes.icon} alt="Hotel" />
                    <h3 className={'title-xs mb-0 mt-2 px-2'}>Hotel</h3>
                  </div>
                </Col>
                <Col>
                  <div className={classes.serviceItemHolder}>
                    <img src={PackageIcon} className={classes.icon} alt="Package" />
                    <h3 className={'title-xs mb-0 mt-2 px-2'}>Package</h3>
                  </div>
                </Col>
                <Col>
                  <div className={classes.serviceItemHolder}>
                    <img src={B2BIcon} className={classes.icon} alt="B2B Selling Platform" />
                    <h3 className={'title-xs mb-0 mt-2 px-2'}>B2B Selling Platform</h3>
                  </div>
                </Col>
                <Col>
                  <div className={classes.serviceItemHolder}>
                    <img src={B2CIcon} className={classes.icon} alt="B2C Booking System" />
                    <h3 className={'title-xs mb-0 mt-2 px-2'}>B2C Booking System</h3>
                  </div>
                </Col>
                <Col>
                  <div className={classes.serviceItemHolder}>
                    <img src={WhiteLabelIcon} className={classes.icon} alt="White Label" />
                    <h3 className={'title-xs mb-0 mt-2 px-2'}>White Label</h3>
                  </div>
                </Col>
                <Col>
                  <div className={classes.serviceItemHolder}>
                    <img src={WebApiIcon} className={classes.icon} alt="API" />
                    <h3 className={'title-xs mb-0 mt-2 px-2'}>API</h3>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          {/* <section className="py-15 bg-beige about-section ">
            <div className="page-container">
              <div>
                <h3 className="title-xl text-center text-blue text-uppercase">About Us</h3>
                <p className="text-center mb-6">
                  Ayan Air Company published in 2019 that become one of the best service companies in (Travelling and tourism) sector.
                </p>
                <p className="text-center mb-10">
                  We planned for having a system to put all the ( Travelling and tourism) services in it therefore we have created (Ayan Air system) which is a
                  (B2C) website for servicing all our customers throughout the world,and (B2B) website which is fully and friendly one that serve the travel
                  agencies.
                </p>

                <h3 className="title-lg text-center text-uppercase">Our services consists of:</h3>
                <Row justify="center">
                  <Col xs={24} lg={12}>
                    <div className="bullet-style-div">International tickets</div>
                    <div className="bullet-style-div">Domestic tickets ( including Iraq and Iran domestic flights)</div>
                    <div className="bullet-style-div">Worldwide hotels</div>
                    <div className="bullet-style-div">Package and tours</div>
                  </Col>
                  <Col xs={24} lg={4}>
                    <div className="bullet-style-div">Car rental</div>
                    <div className="bullet-style-div">Health insurance</div>
                    <div className="bullet-style-div">Airport Transfer</div>
                    <div className="bullet-style-div">CIP airport service</div>
                  </Col>
                </Row>
              </div>
            </div>
          </section> */}
          <AuthPageFooter />
        </div>
      )}
    </>
  );
};

export default AuthPageBarreTravel;
