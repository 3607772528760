import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGlobalization } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { FormattedCurrency } from '../../../../shared';
import classes from './HotelAgentProfit.module.scss';

type Props = {
  salePrice: number;
  introPrice: number;
};
const HotelAgentProfit: React.VFC<Props> = (props) => {
  const { t } = useTranslation();

  const { reversePositionOnRtl } = useGlobalization();

  return (
    <div className={`${classes.Card}`}>
      <Row justify="space-between" align="middle" className="mb-2">
        <Col>{t(Translations.Common.Customer)}:</Col>
        <Col>
          <FormattedCurrency number={props.salePrice} tooltip={{ show: true, position: reversePositionOnRtl('right') }} />
        </Col>
      </Row>
      <Row justify="space-between" align="middle" className="mb-2">
        <Col>{t(Translations.Common.NetPrice)}:</Col>
        <Col>
          <FormattedCurrency number={props.introPrice} tooltip={{ show: true, position: reversePositionOnRtl('right') }} />
        </Col>
      </Row>
      <Row justify="space-between" align="middle" className="mb-2  text-success">
        <Col>
          <b>{t(Translations.Common.Profit)} :</b>
        </Col>
        <Col>
          <b>
            <FormattedCurrency number={props.salePrice - props.introPrice} tooltip={{ show: true, position: reversePositionOnRtl('right') }} />
          </b>
        </Col>
      </Row>
      <div className="mt-4">{t(Translations.Hotel.HotelAgentProfitDescription)}</div>
    </div>
  );
};

export default HotelAgentProfit;
