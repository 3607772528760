import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Translations } from '../../../../../features/localization';
import { FormattedCurrency } from '../../../../shared';
import { getTranslateKeyFlightPassengerType } from '../../../helpers/FlightHelpers';
import { FlightItinerarySummary } from '../../../types';
import classes from '../FlightDetailsModal/FlightDetailsModal.module.scss';

type Props = {
  itinerary: FlightItinerarySummary;
  showExtraBaggageInformation?: boolean;
};
const FlightDetailsPriceBreakdown: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  return (
    <div className={'scrollable-table-holder'}>
      <Table bordered className={classes.grayTheadTable}>
        <thead>
          <tr>
            <th>{t(Translations.Common.PassengerType)}</th>
            <th>{t(Translations.Common.BaseFare)}</th>
            <th>{t(Translations.Common.Fees)}</th>
            <th>{t(Translations.Common.Taxes)}</th>
            <th>{t(Translations.Payment.Commission)}</th>
            <th>{t(Translations.Common.PerPassenger)}</th>
            {props.itinerary.hasExtraBaggage && props.showExtraBaggageInformation && <th>{t(Translations.Flight.ExtraBaggage)}</th>}
            <th>{t(Translations.Common.TotalFare)}</th>
            {props.itinerary.hasExtraBaggage && props.showExtraBaggageInformation && <th>{t(Translations.Flight.TotalFareWithExtraBaggage)}</th>}
          </tr>
        </thead>
        <tbody>
          {props.itinerary.priceInfo!.ptC_FareBreakdown.map((item, index) => (
            <tr key={index}>
              <td>
                <span className="no-wrap">
                  {t(getTranslateKeyFlightPassengerType(item.passengerTypeQuantity.code))} (x {item.passengerTypeQuantity.quantity})
                </span>
              </td>
              <td>
                <FormattedCurrency number={item.passengerFare.baseFare} currencyDisplay="code" />
              </td>
              <td>
                <div className="no-wrap">
                  {item.passengerFare.fess
                    ? item.passengerFare.fess.map((fee, feeIndex) => (
                        <span key={feeIndex}>
                          <FormattedCurrency number={fee.amount} /> (x {item.passengerTypeQuantity.quantity})
                        </span>
                      ))
                    : '--'}
                </div>
              </td>
              <td>
                <div className="no-wrap">
                  {item.passengerFare.taxes
                    ? item.passengerFare.taxes.map((tax, feeIndex) => (
                        <span key={feeIndex}>
                          <FormattedCurrency number={tax.amount} /> (x {item.passengerTypeQuantity.quantity})
                        </span>
                      ))
                    : '--'}
                </div>
              </td>
              <td>
                <span className="no-wrap">
                  <FormattedCurrency number={item.passengerFare.commission} currencyDisplay="code" /> (x {item.passengerTypeQuantity.quantity})
                </span>
              </td>
              <td>
                <span className="no-wrap">
                  <FormattedCurrency number={item.passengerFare.totalFare} currencyDisplay="code" /> (x {item.passengerTypeQuantity.quantity})
                </span>
              </td>
              {props.itinerary.hasExtraBaggage && props.showExtraBaggageInformation && (
                <td>
                  <span className="no-wrap">
                    <FormattedCurrency number={item.passengerFare.extraBaggage!} currencyDisplay="code" /> (x {item.passengerTypeQuantity.quantity})
                  </span>
                </td>
              )}
              <td>
                <span className="no-wrap">
                  <FormattedCurrency number={item.passengerFare.totalFare * item.passengerTypeQuantity.quantity} currencyDisplay="code" />
                </span>
              </td>
              {props.itinerary.hasExtraBaggage && props.showExtraBaggageInformation && (
                <td>
                  <span className="no-wrap">
                    <FormattedCurrency number={item.passengerFare.totalFareWithExtraBaggage! * item.passengerTypeQuantity.quantity} currencyDisplay="code" />
                  </span>
                </td>
              )}
            </tr>
          ))}
          <tr>
            <td>{t(Translations.Common.Total)}</td>
            <td>
              <FormattedCurrency number={props.itinerary.priceInfo!.itinTotalFare.baseFare} currencyDisplay="code" />
            </td>
            <td>--</td>
            <td>
              <div className="no-wrap">
                {props.itinerary.priceInfo!.itinTotalFare.taxes
                  ? props.itinerary.priceInfo!.itinTotalFare.taxes.map((tax, feeIndex) => (
                      <span key={feeIndex}>
                        <FormattedCurrency number={tax.amount} />
                      </span>
                    ))
                  : '--'}
              </div>
            </td>
            <td>
              <FormattedCurrency number={props.itinerary.priceInfo!.itinTotalFare.commission} currencyDisplay="code" />
            </td>
            <td />
            {props.itinerary.hasExtraBaggage && props.showExtraBaggageInformation && (
              <td>
                <FormattedCurrency number={props.itinerary.priceInfo!.itinTotalFare.extraBaggage!} currencyDisplay="code" />
              </td>
            )}
            <td>
              <FormattedCurrency number={props.itinerary.priceInfo!.itinTotalFare.totalFare} currencyDisplay="code" />
            </td>
            {props.itinerary.hasExtraBaggage && props.showExtraBaggageInformation && (
              <td>
                <FormattedCurrency number={props.itinerary.priceInfo!.itinTotalFare.totalFareWithExtraBaggage} currencyDisplay="code" />
              </td>
            )}
          </tr>
        </tbody>
      </Table>
    </div>
  );
});

export default FlightDetailsPriceBreakdown;
