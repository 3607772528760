import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, notification } from 'antd';
import sum from 'lodash/sum';
import moment, { Moment } from 'moment';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { useGlobalization, useMount } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { CultureName } from '../../../../features/localization/cultures';
import { convertToNumberArray, fromQueryString, momentToISOUtcDate, toQueryString } from '../../../../utils/helpers';
import { addToRecentSearches } from '../../../booking';
import { GetAirportResponse } from '../../../flight/apiTypes';
import { HotelSearchFormRooms } from '../../../hotel';
import classes from '../../../hotel/components/shared/HotelSearchForm/HotelSearchForm.module.scss';
import { FormItemActions } from '../../../shared';
import { PackagesQueryString } from '../../types';
import PackageSearchFormDates from './PackageSearchFormDates/PackageSearchFormDates';
import PackageSearchFormLocations from './PackageSearchFormLocations/PackageSearchFormLocations';
import PackageSearchFormOptions from './PackageSearchFormOptions/PackageSearchFormOptions';

type PackageFormValues = {
  origin: GetAirportResponse[0];
  destination: GetAirportResponse[0];
  adults: number[];
  children: number[];
  childAge: number[][];
  nationalityCode: string;
  checkInCheckOut: [Moment, Moment];
  [key: string]: any;
};
type Props = {
  inline?: boolean;
  fillFromQueryString?: boolean;
};
const PackageSearchForm: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryStringObj = fromQueryString<PackagesQueryString>(location.search, 'bracket');
  const history = useHistory();

  const { culture } = useGlobalization();

  const [resetTime, setResetTime] = useState<number>();
  const [roomQty, setRoomQty] = useState(1);
  const [repeatSearchModalVisibility, setRepeatSearchModalVisibility] = useState(false);

  const [form] = Form.useForm<PackageFormValues>();

  useMount(() => {
    if (props.fillFromQueryString) {
      setRoomQty(queryStringObj.adults.length);

      const childrenAges: number[][] = [];

      let childrenAgesStartPosition = 0;
      for (const item in queryStringObj.children) {
        const childrenQty = +queryStringObj.children[item];
        if (childrenQty > 0) {
          const currentRoomChildrenAges = queryStringObj.childrenAges!.slice(childrenAgesStartPosition, childrenAgesStartPosition + childrenQty);
          childrenAges[item] = [];
          for (const childAge in currentRoomChildrenAges) {
            childrenAges[item].push(+currentRoomChildrenAges[childAge]);
          }
          childrenAgesStartPosition += childrenQty;
        } else {
          //@ts-ignore
          childrenAges[item] = undefined;
        }
      }

      const checkinDate = moment(queryStringObj.checkIn).utc(true);
      const checkoutDate = moment(queryStringObj.checkOut).utc(true);

      form.setFieldsValue({
        destination: { Name: queryStringObj.destinationText, Code: queryStringObj.destinationCode },
        origin: { Name: queryStringObj.originText, Code: queryStringObj.originCode },
        adults: convertToNumberArray(queryStringObj.adults),
        children: convertToNumberArray(queryStringObj.children),
        childAge: childrenAges,
        checkInCheckOut: culture.Name === CultureName.FaIr ? [checkinDate.toISOString(), checkoutDate.toISOString()] : [checkinDate, checkoutDate],
        nationalityCode: queryStringObj.nationalityCode,
      });
    }
  });

  const handleSearchAgainClicked = () => {};

  const handleSubmit = (values: PackageFormValues) => {
    if (!values.checkInCheckOut[0] || !values.checkInCheckOut[1]) {
      notification.error({
        message: t(Translations.Booking.PleaseEnterCheckInCheckOutDate),
      });
      form.scrollToField('checkInCheckOut');
      return;
    }

    if (sum(values.adults) + sum(values.children) > 9) {
      notification.error({
        message: t(Translations.Flight.TotalPassengersValidationText),
      });
      return;
    }

    const childrenAges: number[] = [];
    if (values.childAge) {
      values.childAge.forEach((roomChildAges) => {
        if (roomChildAges !== undefined && roomChildAges !== null) {
          roomChildAges.forEach((childAge) => {
            childrenAges.push(+childAge);
          });
        }
      });
    }

    const newSearchData = {
      originCode: values.origin.Code,
      originText: values.origin.Name,
      destinationCode: values.destination.Code,
      destinationText: values.destination.Name,
      adults: values.adults.map((item) => item.toString()),
      children: values.children.map((item) => item.toString()),
      childrenAges: childrenAges.map((item) => item.toString()),
      nationalityCode: values.nationalityCode,
      checkIn: momentToISOUtcDate(values.checkInCheckOut[0]),
      checkOut: momentToISOUtcDate(values.checkInCheckOut[1]),
    } as PackagesQueryString;

    addToRecentSearches(newSearchData, 'Package');

    history.push(`/packages?${toQueryString(newSearchData, 'bracket')}`);
  };

  const handleReset = useCallback(() => {
    form.resetFields();
    setRoomQty(1);
    setResetTime(Date.now());
  }, [form]);

  const handleAddRoom = () => {
    setRoomQty((prevState) => prevState + 1);
  };

  const handleRemoveRoom = useCallback(() => {
    setRoomQty((prevState) => prevState - 1);
  }, []);

  return (
    <>
      <Modal
        visible={repeatSearchModalVisibility}
        okText={t(Translations.Booking.SearchAgain)}
        onOk={handleSearchAgainClicked}
        centered
        onCancel={() => setRepeatSearchModalVisibility(false)}>
        {t(Translations.Booking.RepeatSearchConfirmation)}
      </Modal>
      <Form
        form={form}
        onFinish={handleSubmit}
        onReset={handleReset}
        labelCol={{ span: 24 }}
        className={`hotel-search-form ${props.inline ? 'inline-search-form' : ''}`}>
        <Row gutter={[10, 0]}>
          <Col xs={24} lg={{ span: props.inline ? 10 : 24, order: 1 }}>
            <PackageSearchFormLocations
              form={form}
              defaultOrigin={
                queryStringObj.originCode && queryStringObj.originText ? { Name: queryStringObj.originText, Code: queryStringObj.originCode } : undefined
              }
              defaultDestination={
                queryStringObj.destinationCode && queryStringObj.destinationText
                  ? { Name: queryStringObj.destinationText, Code: queryStringObj.destinationCode }
                  : undefined
              }
            />
          </Col>
          <Col xs={24} lg={{ span: props.inline ? 6 : 24, order: 2 }}>
            <PackageSearchFormDates form={form} />
          </Col>
          <Col xs={24} lg={{ span: 24, order: props.inline ? 5 : 3 }}>
            <Row gutter={[10, 0]}>
              <Col>
                <HotelSearchFormRooms
                  defaultAllChildrenQty={props.fillFromQueryString ? sum(convertToNumberArray(queryStringObj.children)) : 0}
                  roomQty={roomQty}
                  onRemoveRoom={handleRemoveRoom}
                  inline={props.inline}
                  fillFromQueryString={props.fillFromQueryString}
                  resetTime={resetTime}
                />
              </Col>
              {roomQty < 4 && (
                <Col xs={props.inline ? undefined : 24}>
                  <Form.Item className={`mb-4  ${props.inline ? 'align-with-formItems' : 'mt-3'}`}>
                    <Button className={classes.addRoomBtn} icon={<PlusOutlined />} type={'dashed'} onClick={handleAddRoom}>
                      {t(Translations.Hotel.AddAnotherRoom)}
                    </Button>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Col>
          <Col xs={24} lg={{ span: props.inline ? 4 : 24, order: props.inline ? 3 : 4 }}>
            <PackageSearchFormOptions form={form} />
          </Col>
          <Col xs={24} lg={{ span: props.inline ? 4 : 24, order: props.inline ? 4 : 5 }} className={props.inline ? 'text-right' : ''}>
            <div className={props.inline ? 'align-with-formItems-lg align-with-formItems-lg align-with-formItems-xl' : 'mt-2'}>
              <FormItemActions formInstance={form} submitText={t(Translations.Common.Search)} submitIcon={<SearchOutlined />} />
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
});

export default PackageSearchForm;
