import { Button, Modal, Tag } from 'antd';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useGlobalization } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { FormattedCurrency } from '../../../../shared';
import { HotelGetCancellationPolicyResponse } from '../../../apiTypes';
import { getHotelCancellationPolicyStatusAntdTagColor, getHotelCancellationPolicyStatusTextColor } from '../../../helpers/HotelHelpers';
import classes from './HotelCancellationPolicy.module.scss';

type Props = {
  tagStatus?: boolean;
  cancellationPolicy: HotelGetCancellationPolicyResponse;
  showSteps?: boolean;
};

const HotelCancellationPolicy: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const { reversePositionOnRtl, formatShortDate } = useGlobalization();

  const [modalVisibility, setModalVisibility] = useState(false);

  const cancellationStatusTranslated = props.cancellationPolicy.status ? t(`Booking.CancellationPolicy${props.cancellationPolicy.status}`) : undefined;
  return (
    <>
      {props.tagStatus ? (
        <Tag className="mb-1" color={getHotelCancellationPolicyStatusAntdTagColor(props.cancellationPolicy.status)}>
          {cancellationStatusTranslated}
        </Tag>
      ) : (
        <h5 className={getHotelCancellationPolicyStatusTextColor(props.cancellationPolicy.status)}>{cancellationStatusTranslated}</h5>
      )}
      <ul className="bullet-list mt-3">
        {props.cancellationPolicy.fees.map((item, index) =>
          item.amount === 0 ? (
            <li key={index}>
              <Trans
                i18nKey={Translations.Hotel.CancellationIsFreeContent}
                values={{ date: formatShortDate(item.toDate) }}
                components={{ green: <span className="green7" /> }}
              />
            </li>
          ) : (
            <li key={index}>
              <Trans
                i18nKey={Translations.Hotel.CancellationPartRefundableContent}
                components={{ price: <FormattedCurrency number={item.amount} currencyDisplay="name" useDefaultHtmlDirection tooltip={{ show: false }} /> }}
                values={{ date: formatShortDate(item.fromDate) }}
              />
            </li>
          )
        )}
      </ul>
      {props.showSteps ? (
        <>
          <br /> <br />
          <div
            className={clsx({ [classes.rightToLeft]: reversePositionOnRtl('left') === 'right', [classes.chartHolder]: true })}
            // className={`${classes.chartHolder}  ${reversePositionOnRtl('left')}-to-${reversePositionOnRtl('right')} px-20 pt-15`}
          >
            <div className={`${classes.item}  ${classes.grey}`}>
              <span className={`${classes.status} ${classes.grey}`}>
                <span className="fas fa-minus" />
              </span>
              <div className={`${classes.itemTitle} title-xs`}>9 Sep</div>
              <div className={classes.text}>
                <b>Full refund</b>
                <p className="text-muted mb-0">You will not be charged</p>
              </div>
            </div>
            <div className={`${classes.item}  ${classes.green}`}>
              <span className={`${classes.status} ${classes.green}`} />
              <div className={`${classes.itemTitle} title-xs`}>
                17 Sep
                <div className="text-muted small">12:00 AM, Hotel time 22 Sep</div>
              </div>
              <div className={classes.text}>
                <b>Full refund</b>
                <p className="text-muted mb-0">You will not be charged</p>
              </div>
            </div>
            <div className={`${classes.item}  ${classes.green}`}>
              <span className={`${classes.status} ${classes.active} ${classes.green}`} />
              <div className={`${classes.itemTitle} title-xs`}>
                17 Sep
                <div className="text-muted small">12:00 AM, Hotel time 22 Sep</div>
              </div>
              <div className={classes.text}>
                <b>Full refund</b>
                <p className="text-muted mb-0">You will not be charged</p>
              </div>
            </div>

            <div className={`${classes.item}  ${classes.red}`}>
              <span className={`${classes.status} ${classes.active} ${classes.red}`} />
              <div className={`${classes.itemTitle} title-xs`}>
                17 Sep
                <div className="text-muted small">12:00 AM, Hotel time 22 Sep</div>
              </div>
              <div className={classes.text}>
                <b>No refund</b>
                <p className="text-muted mb-0">You will be charged the full amount:</p>
                <div className="text-danger">- AED 1,715</div>
              </div>
            </div>

            <div className={`${classes.item}  ${classes.red} ${classes.last}`}>
              <span className={`${classes.status} ${classes.red}`} />
              <div className={`${classes.itemTitle} title-xs`}>
                30 Sep
                <div className="text-muted small">2 PM, Check-in time</div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Modal
            title={t(Translations.Booking.CancellationPolicy)}
            closable
            centered
            visible={modalVisibility}
            onCancel={() => setModalVisibility(false)}
            width={800}
            footer={
              <Button type="primary" onClick={() => setModalVisibility(false)}>
                {t(Translations.Common.Ok)}
              </Button>
            }>
            <HotelCancellationPolicy cancellationPolicy={props.cancellationPolicy} showSteps={false} />
          </Modal>
          {/*<div className="mt-2">*/}
          {/*  <Button type="primary" htmlType="button" icon={<DashOutlined />} onClick={() => setModalVisibility(true)}>*/}
          {/*    {t(Translations.Common.ShowSteps)}*/}
          {/*  </Button>*/}
          {/*</div>*/}
        </>
      )}
    </>
  );
});

export default HotelCancellationPolicy;
