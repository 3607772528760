import { Input, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../features/hooks';
import { Translations } from '../../../../../../features/localization';
import { useBookingTempFilters } from '../../../../../booking';

type Props = {};
const HotelsFilterName: React.VFC<Props> = React.memo(() => {
  const { t } = useTranslation();

  const { filter } = useBookingTempFilters({ moduleType: 'Package' });

  const filteredHotelName = useAppSelector((state) => state.package.temp.packages.processing.filters.hotels.name);
  const [text, setText] = useState<string>();

  const handlePressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    filter({ type: 'HotelName', value: e.currentTarget.value });
  };

  useEffect(() => {
    if (filteredHotelName !== text) {
      setText(filteredHotelName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredHotelName]);

  return (
    <Tooltip title={t(Translations.Tooltips.PressEnterToFilter)} trigger={['focus']}>
      <Input
        className="mb-2"
        placeholder={t(Translations.Hotel.InputHotelName)}
        size="large"
        value={text}
        onChange={(e) => setText(e.currentTarget.value)}
        onPressEnter={handlePressEnter}
        allowClear
      />
    </Tooltip>
  );
});

export default HotelsFilterName;
