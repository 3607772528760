import { RootState } from '../../../features/redux';

export const tenantsSelector = (state: RootState) => {
  return state.shared.tenants;
};

export const ipLocationSelector = (state: RootState) => {
  return state.shared.ipLocation;
};

export const preferencesSelector = (state: RootState) => {
  return state.shared.preferences;
};
