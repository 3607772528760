import { Card, Pagination, Skeleton } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Translations } from '../../../../features/localization';
import { scrollTop } from '../../../../utils/helpers';
import { flightSlice } from '../../../flight';
import { hotelSlice } from '../../../hotel';
import { packageSlice } from '../../../package';
import { ShowTotalPagination } from '../../../shared';
import { BookingModuleType } from '../../types';

type Props = {
  page: number;
  pageSize: number;
  total?: number;
  skeleton: boolean;
  type: BookingModuleType;
  changePage?: (page: number, pageSize?: number) => void;
};
const ReservesPagination: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChange = (page: number, pageSize?: number) => {
    switch (props.type) {
      case 'Hotel':
        dispatch(hotelSlice.actions.tempReservesPagination({ page, pageSize: pageSize ?? 20 }));
        break;
      case 'Flight':
        dispatch(flightSlice.actions.tempReservesPagination({ page, pageSize: pageSize ?? 20 }));
        break;
      case 'Package':
        dispatch(packageSlice.actions.tempReservesPagination({ page, pageSize: pageSize ?? 20 }));
        break;
      case 'DomesticFlight':
      case 'DomesticHotel':
        if (props.changePage) props.changePage(page);
        break;
    }
    scrollTop();
  };

  return props.skeleton ? (
    <Card className="mb-4 box-shadow">
      <Skeleton.Input style={{ width: 400 }} active />
    </Card>
  ) : props.total && props.total > 0 ? (
    <Card className="mb-4 box-shadow">
      <Pagination
        showSizeChanger={false}
        pageSize={props.pageSize}
        current={props.page}
        onChange={handleChange}
        total={props.total}
        showTotal={(total, range) => <ShowTotalPagination from={range[0]} to={range[1]} total={total} recordName={t(Translations.Booking.Reserves)} />}
      />
    </Card>
  ) : null;
});

export default ReservesPagination;
