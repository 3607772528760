import { createAction } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { LocalStorageExpiration, LocalStorageKey } from '../../../features/constants';
import { AbpResponse, PayloadWithHistory, Tenant } from '../../../types';
import { abpApi, initializeFromLocalStorageAndApi, setJsonStorage } from '../../../utils/helpers';
import { accountSlice } from '../../account';
import { getCurrentProfileHttp } from '../../account/http/account-http';
import { GetCurrentProfileResponse } from '../../account/types';
import { flightSlice } from '../../flight';
import { GetFlightSuppliersResponse } from '../../flight/apiTypes';
import { flightGetSuppliersHttp } from '../../flight/http/flight-http';
import { sharedSlice, tenantsSelector } from '../../shared';
import { getAffiliateHttp } from '../../shared/http/coordinator-http';
import { getPermissionsHttp, identityTenantGetByKeywordHttp, loginHttp } from '../http/authentication-http';
import { GetAffiliateResponse, TenantGetByKeywordResponse, TokenAuthLoginResponse } from '../types';
import authenticationSlice from './authentication-slice';

//#region actions
const prefix = 'authentication_saga';

const VerifyTenant = `${prefix}/verifyTenant`;
type VerityTenantSagaPayload = {
  tenant: string;
};
export const verifyTenantSaga = createAction<PayloadWithHistory<VerityTenantSagaPayload>>(VerifyTenant);

const Login = `${prefix}/login`;
type LoginSagaPayload = {
  tenant: string;
  username: string;
  password: string;
};
export const loginSaga = createAction<PayloadWithHistory<LoginSagaPayload>>(Login);

//#endregion

function* login(action: ReturnType<typeof loginSaga>) {
  //#region Verify Tenant
  const verifyTenantResponse: AbpResponse<TenantGetByKeywordResponse> = yield call(
    abpApi,
    identityTenantGetByKeywordHttp,
    action.payload.tenant,
    authenticationSlice.actions.verifyTenantPending,
    authenticationSlice.actions.verifyTenantError
  );

  if (verifyTenantResponse && verifyTenantResponse.result) {
    const tenant = { id: verifyTenantResponse.result.id, name: verifyTenantResponse.result.name };
    yield put(authenticationSlice.actions.verifyTenantSuccess(tenant));
    setJsonStorage(LocalStorageKey.CurrentTenant, tenant);
    let tenants: Tenant[] = yield select(tenantsSelector);
    if (!tenants.find((f) => f.id === tenant.id)) {
      tenants = [...tenants, tenant];
    }
    yield put(sharedSlice.actions.setTenants(tenants));
    setJsonStorage(LocalStorageKey.Tenants, tenants);
  } else {
    return;
  }
  //#endregion

  //#region Login
  const loginResponse: AbpResponse<TokenAuthLoginResponse> = yield call(
    abpApi,
    loginHttp,
    action.payload,
    authenticationSlice.actions.loginPending,
    authenticationSlice.actions.loginError
  );
  if (loginResponse) {
    if (!loginResponse.result!.user.isActive) {
      yield put(authenticationSlice.actions.loginError({ code: -1, message: 'User is disabled.' }));
      return;
    }

    if (loginResponse.result!.user.roleNames && !loginResponse.result!.user.roleNames.includes('Affiliate')) {
      yield put(authenticationSlice.actions.loginError({ code: -1, message: 'Affiliate access denied.' }));
      return;
    }
    yield put(authenticationSlice.actions.setAuthToken(loginResponse.result!));
    setJsonStorage(LocalStorageKey.AuthToken, loginResponse.result, loginResponse.result!.expireInSeconds);
    const { result: profile }: AbpResponse<GetCurrentProfileResponse> = yield call(
      abpApi,
      getCurrentProfileHttp,
      undefined,
      undefined,
      authenticationSlice.actions.loginError
    );
    yield put(accountSlice.actions.updateProfileSuccess(profile!));
    yield put(accountSlice.actions.updateProfileReset());

    const permissionsResponse: AbpResponse<string[]> = yield call(abpApi, getPermissionsHttp, undefined, undefined, authenticationSlice.actions.loginError);
    if (!permissionsResponse || !permissionsResponse.result) {
      return;
    }
    yield put(authenticationSlice.actions.setPermissions(permissionsResponse.result));

    const affiliateResponse: AbpResponse<GetAffiliateResponse> = yield call(
      abpApi,
      getAffiliateHttp,
      undefined,
      undefined,
      authenticationSlice.actions.loginError
    );
    if (!affiliateResponse || !affiliateResponse.result) {
      return;
    }
    yield put(authenticationSlice.actions.setAffiliate(affiliateResponse.result));
    setJsonStorage(LocalStorageKey.Affiliate, affiliateResponse.result, loginResponse.result!.expireInSeconds);

    yield put(authenticationSlice.actions.loginSuccess({ time: new Date(), fromLoginPage: true }));

    //#region Get Flight Suppliers
    yield initializeFromLocalStorageAndApi<GetFlightSuppliersResponse>(
      LocalStorageKey.FlightSuppliers,
      'Getting Flight Suppliers',
      (obj) => obj.supplierList !== undefined && obj.supplierList.length > 0,
      flightGetSuppliersHttp,
      undefined,
      function* (obj) {
        yield put(flightSlice.actions.setSuppliers(obj.supplierList));
      },
      LocalStorageExpiration.Minute * 5,
      false,
      true
    );
    //#endregion

    Modal.info({
      title: <span className="bold">Fareview Program</span>,
      content: (
        <div>
          <p>Dear Colleague,</p>
          <p>
            We have launched a new program called <strong>Fareview</strong>. This program gives you access to all prices, including Publish, Ethnic and Package
            prices, as well as Ruls for all prices.
          </p>
          <p>
            <strong>Fareview</strong> is automatic and dynamic. This means that all changes in prices from airlines will be updated automatically and
            immediately in the program.
          </p>
          <p>
            We hope you will benefit from <strong>Fareview</strong>!
          </p>
        </div>
      ),
    });
  }
  //#endregion
}

export function* watchAuthentication() {
  yield all([takeLatest(Login, login)]);
}
