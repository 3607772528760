import { Card, Collapse } from 'antd';
import moment from 'moment-jalaali';
import React, { useCallback, useEffect, useState } from 'react';

import { PDatePicker2 } from '../../../shared';

// type MapMarker = {
//   lat: number;
//   lng: number;
//   zoom: number;
// };

const TestC: React.VFC = () => {
  const [value, setValue] = useState<string | string[]>();
  const [values, setValues] = useState<string | string[]>();

  console.log(value, values);

  // const [mapMarker, setMapMarker] = useState<MapMarker>({ lat: 51.505, lng: -0.09, zoom: 13 });
  //
  // const position = [mapMarker.lat, mapMarker.lng] as [number, number];

  // const [status, setStatus] = useState<boolean>(false);
  //
  // let array = [
  //   {
  //     name: 'A',
  //     age: 10,
  //   },
  //   {
  //     name: 'B',
  //     age: 9,
  //   },
  // ];
  //
  // array = orderBy(array, 'name', 'desc');
  //
  // console.log(array);
  //
  // const [selectedDay, setSelectedDay] = useState<DayRange>({
  //   from: null,
  //   to: null,
  // });
  //
  // useEffect(() => {
  //   console.log(status);
  // }, [status]);

  // useMount(() => {
  //   notification.error({
  //     message: 'AAAAAAAAAAAAA',
  //     description:
  //       'BBBBBBBBBBBBBBBBBBB BBBBBBBBBBBBBBBBBBBB BBBBBBBBBBBBBBBBBBBB BBBBBBBBBBBBBBBBBBBB BBBBBBBBBBBBBBBBBBBB BBBBBBBBBBBBBBBBBBBB BBBBBBBBBBBBBBBBBBBB BBBBBBBBBBBBBBBBBBBB',
  //     duration: 0,
  //   });
  //   notification.error({ message: 'AAAAAAAAAAAAA', duration: 0 });
  // });

  useEffect(() => {
    // document.addEventListener('visibilitychange', (ev) => {
    //   console.log(document.visibilityState);
    // });
    window.addEventListener('focus', () => {
      console.log('focused');
    });

    window.addEventListener('blur', () => {
      console.log('blurred');
    });
  }, []);

  const [arr, setArr] = useState<number[]>([]);

  const handleClicked = useCallback(() => {
    setArr([...arr, Date.now()]);
  }, [arr]);

  return (
    <>
      <button onClick={handleClicked}>Add</button>
      <Collapse>
        <Collapse.Panel header="This is panel header 1" key="1">
          {arr.map((item) => (
            <p key={item}>{item}</p>
          ))}
        </Collapse.Panel>
      </Collapse>
      <Card title="Date Pickers">
        <div>
          <PDatePicker2 value={value} onChange={setValue} color="purple" minDate={moment().add(3, 'days').format('jYYYY-jMM-jDD')} />
          <br />
          <br />
          <br />
          <PDatePicker2 value={values} onChange={setValues} color="red" mode="range" />
        </div>

        {/*<div>*/}
        {/*  /!*<Map center={[45.4, -75.7]} zoom={12} style={{ width: '100%', height: '900px' }}>*!/*/}
        {/*  /!*  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />*!/*/}
        {/*  /!*</Map>*!/*/}
        {/*</div>*/}
        {/*<h1>{status.toString()}</h1>*/}
        {/*<CheckableButton text="Hello" onChange={setStatus} />*/}
        {/*<Dropdown*/}
        {/*  overlay={*/}
        {/*    <div>*/}
        {/*      <div>1</div>*/}
        {/*      <div>2</div>*/}
        {/*    </div>*/}
        {/*  }>*/}
        {/*  <div>A</div>*/}
        {/*</Dropdown>*/}

        {/*<div>*/}
        {/*  <DatePicker locale="fa" value={selectedDay} onChange={setSelectedDay} inputPlaceholder="Select a day" shouldHighlightWeekends />*/}
        {/*</div>*/}
        {/*<hr />*/}
        {/*<Calendar locale="fa" value={selectedDay} onChange={setSelectedDay} shouldHighlightWeekends />*/}
      </Card>
      <br />
      <table className={'price-calendar'}>
        <thead>
          <tr>
            <th className="price-calendar-th">دوشنبه 17 آبان</th>
            <th className="price-calendar-th">سه شنبه 18 آبان</th>
            <th className="price-calendar-th">چهارشنبه 19 آبان</th>
            <th className="price-calendar-th">پنجشنبه 20 آبان</th>
            <th className="price-calendar-th">جمعه 21 آبان</th>
            <th className="price-calendar-th">یکشنبه 22 آبان</th>
            <th className="price-calendar-th">دوشنبه 23 آبان</th>
            <th className="price-calendar-th">سه شنبه 24 آبان</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">
              <img src="https://cdn3.itours.no/Content/images/Domestic/w5.png" alt={'airlineName'} />
              17,754,000
            </td>
            <td className="price-calendar-td price-calendar-selected">
              <img src="https://cdn3.itours.no/Content/images/Domestic/w5.png" alt={'airlineName'} />
              18,012,000
            </td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">22,100,000</td>
          </tr>
        </tbody>
      </table>
      <br />
      <table className={'price-calendar'}>
        <thead>
          <tr>
            <th className="price-calendar-th">
              <span className={'diagonal-divider'} />
              <div className="h-label">رفت</div>
              <div className="v-label">برگشت</div>
            </th>
            <th className="price-calendar-th">دوشنبه 17 آبان</th>
            <th className="price-calendar-th">سه شنبه 18 آبان</th>
            <th className="price-calendar-th">چهارشنبه 19 آبان</th>
            <th className="price-calendar-th">پنجشنبه 20 آبان</th>
            <th className="price-calendar-th">جمعه 21 آبان</th>
            <th className="price-calendar-th">یکشنبه 22 آبان</th>
            <th className="price-calendar-th">دوشنبه 23 آبان</th>
            <th className="price-calendar-th">سه شنبه 24 آبان</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="price-calendar-th"> جمعه 21 آبان</th>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">
              <img src="https://cdn3.itours.no/Content/images/Domestic/w5.png" alt={'airlineName'} />
              17,754,000
            </td>
            <td className="price-calendar-td">
              <img src="https://cdn3.itours.no/Content/images/Domestic/w5.png" alt={'airlineName'} />
              18,012,000
            </td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">22,100,000</td>
          </tr>
          <tr>
            <th className="price-calendar-th"> شنبه 22 آبان</th>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">
              <img src="https://cdn3.itours.no/Content/images/Domestic/w5.png" alt={'airlineName'} />
              17,754,000
            </td>
            <td className="price-calendar-td">
              <img src="https://cdn3.itours.no/Content/images/Domestic/w5.png" alt={'airlineName'} />
              18,012,000
            </td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td price-calendar-cheapest">
              <img src="https://cdn3.itours.no/Content/images/Domestic/w5.png" alt={'airlineName'} />
              22,100,000
            </td>
            <td className="price-calendar-td">--</td>
          </tr>
          <tr>
            <th className="price-calendar-th"> یکشنبه 23 آبان</th>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td price-calendar-hover">
              <img src="https://cdn3.itours.no/Content/images/Domestic/w5.png" alt={'airlineName'} />
              17,754,000
            </td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td price-calendar-selected">
              <img src="https://cdn3.itours.no/Content/images/Domestic/w5.png" alt={'airlineName'} />
              18,012,000
            </td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">
              <img src="https://cdn3.itours.no/Content/images/Domestic/w5.png" alt={'airlineName'} />
              22,100,000
            </td>
          </tr>
          <tr>
            <th className="price-calendar-th"> دوشنبه 24 آبان</th>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">
              <img src="https://cdn3.itours.no/Content/images/Domestic/w5.png" alt={'airlineName'} />
              17,754,000
            </td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">
              <img src="https://cdn3.itours.no/Content/images/Domestic/w5.png" alt={'airlineName'} />
              18,012,000
            </td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">--</td>
            <td className="price-calendar-td">
              <img src="https://cdn3.itours.no/Content/images/Domestic/w5.png" alt={'airlineName'} />
              22,100,000
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default TestC;
