import Axios from 'axios';

import { ApiDataItem, AxiosInstanceData2, GetAllGenericResponse, GetAllRequestBase } from '../../types';
import { urlWithQuery } from '../../utils/helpers';

const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_TravellerApiBaseUrl;

export const TravellerHttp = {
  GetAll: (data: TravellerPassengersListRq) => axiosInstance.get(urlWithQuery(TravellerApiUrls.Passengers.GetAll, data)),
  Delete: (data: { Id: number }) => axiosInstance.delete(urlWithQuery(TravellerApiUrls.Passengers.Delete, data)),
};

export const TravellerApiUrls = {
  Passengers: {
    GetAll: '/api/services/app/Passenger/GetAll',
    Delete: '/api/services/app/Passenger/Delete',
  },
};

export const TravellerApiDataMap: Map<string, ApiDataItem> = new Map();

export const TravellerAxiosData: AxiosInstanceData2 = {
  instance: axiosInstance,
  DataMap: {
    MapObject: TravellerApiDataMap,
    Urls: TravellerApiUrls,
  },
};

export type TravellerPassenger = {
  id: number;
  firstname: string;
  firstnamePersian?: string;
  lastname: string;
  lastnamePersian?: string;
  gender: boolean;
  nationalId?: string;
  birthDate: string;
  nationality: string;
  email: string;
  passportCountry: string;
  passportExpirationDate: string;
  passportNumber: string;
  phoneNumber: string;
};

export type TravellerPassengersListRq = GetAllRequestBase;
export type TravellerPassengersListRs = GetAllGenericResponse<TravellerPassenger>;
