import { Button, Col, Form, Input, Row, notification } from 'antd';
import React from 'react';

import Dibs from '../../../../assets/partners/white/Dibs.png';
import Giata from '../../../../assets/partners/white/Giata.png';
import IATA from '../../../../assets/partners/white/Iata.png';
import Mastercard from '../../../../assets/partners/white/Mastercard.png';
import Visa from '../../../../assets/partners/white/Visa.png';
import { useAntdValidation, useHttpCall } from '../../../../features/hooks';
import { Env } from '../../../../utils/env';
import { AbpErrorAlert } from '../../../shared';
import { identityNewsLetterCreateHttp } from '../../http/authentication-http';
import classes from './AuthPageFooter.module.scss';

type FormValues = {
  email: string;
};
const AuthPageFooter: React.FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { labelWithRules } = useAntdValidation(form);

  const newsLetterCreateApi = useHttpCall(identityNewsLetterCreateHttp);

  const handleSubmit = (values: FormValues) => {
    newsLetterCreateApi.call({ email: values.email }).then((response) => {
      if (response && response.success) {
        notification.success({
          message: 'Submitted successfully',
        });
        form.setFieldsValue({
          email: '',
        });
      }
    });
  };

  return (
    <div
      className={`${classes.loginFooter} ${Env.TenantHost === 'AyanAir' || Env.TenantHost === 'BarreTravel' ? classes.ayanairFooter : ''} ${
        Env.TenantHost === 'ProTraveling' ? classes.proTravelingFooter : ''
      }`}>
      <div className="page-container">
        <div className={Env.TenantHost === 'AyanAir' || Env.TenantHost === 'BarreTravel' || Env.TenantHost === 'ProTraveling' ? 'py-20 px-1' : ''}>
          <Row gutter={[30, 30]}>
            <Col xs={24} lg={Env.TenantHost === 'AyanAir' || Env.TenantHost === 'BarreTravel' || Env.TenantHost === 'ProTraveling' ? 11 : 24}>
              <div>
                <div className={'page-container text-center'}>
                  <div
                    className={
                      Env.TenantHost === 'AyanAir' || Env.TenantHost === 'BarreTravel' || Env.TenantHost === 'ProTraveling'
                        ? ''
                        : `${classes.topFooterInner} mt-10`
                    }>
                    <Row gutter={[20, 10]} justify={'center'} align={'middle'}>
                      <Col>
                        <img src={Mastercard} alt="Logo" className={classes.partnerLogo} />
                      </Col>
                      <Col>
                        <img src={Visa} alt="Logo" className={classes.partnerLogo} />
                      </Col>
                      <Col>
                        <img src={Dibs} alt="Logo" className={classes.partnerLogo} />
                      </Col>
                      <Col>
                        <img src={Giata} alt="Logo" className={classes.partnerLogo} />
                      </Col>
                      <Col>
                        <img src={IATA} alt="Logo" className={classes.partnerLogo} />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div className={'page-container'}>
                <div className={'text-center mb-8'}>
                  <h4 className={'title-md mt-10 mb-2 color-light text-light'}>Newsletter subscription</h4>
                  <p className={'text-light'}>Sign up for our newsletter to get special offers</p>
                </div>
                <Form form={form} className={classes.newsletterForm} name="newsletter" onFinish={handleSubmit}>
                  <AbpErrorAlert error={newsLetterCreateApi.error} />
                  <Row justify={'center'} wrap={false}>
                    <Col flex={'auto'}>
                      <Form.Item name="email" {...labelWithRules({ fieldName: 'Email', rules: [{ type: 'Required' }, { type: 'Email' }] })}>
                        <Input type="email" dir="ltr" className={Env.TenantHost === 'ProTraveling' ? classes.proTravelingOrangeInput : ''} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={newsLetterCreateApi.pending}
                          className={
                            Env.TenantHost === 'AyanAir' || Env.TenantHost === 'BarreTravel' || Env.TenantHost === 'ProTraveling'
                              ? `text-uppercase ${classes.footerNewsletterSubmit}`
                              : ''
                          }>
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
            {Env.TenantHost === 'ProTraveling' && (
              <Col xs={24} lg={13}>
                <div className="text-white proTraveling-footer-contact">
                  <h3 className="title-xl text-white mb-2">Contact Us</h3>
                  <p className="mb-8">For more information or any question contact us.</p>
                  <p className="mb-2">Address: Iraq, Baghdad, Almansoor 14 Ramdan str</p>
                  <p className="mb-2">Telephone no: 00964-7838330004</p>
                  <p className="mb-2">Whatsapp: 00964-7828000450</p>
                  <p className="mb-2">Email: Support@pro-traveling.com</p>
                </div>
              </Col>
            )}

            {Env.TenantHost === 'AyanAir' && (
              <Col xs={24} lg={13}>
                <div className="text-white ayanair-footer-contact">
                  <h3 className="title-lg text-white text-uppercase">Contact Us</h3>
                  <p className="mb-10">For more information or any question contact us.</p>
                  <p>
                    <span className="text-uppercase">Address: </span> Iraq - Kurdistan - Erbil - 40m - English Village Road.
                    <br />
                    <span className="text-uppercase">Telephone no: </span> 00964 750 4018333
                    <br />
                    <span className="text-uppercase">Whatsapp: </span> 00964 750 4018333
                    <br />
                    <span className="text-uppercase">Email: </span> support@ayanair.net
                  </p>
                </div>
              </Col>
            )}

            {Env.TenantHost === 'BarreTravel' && (
              <Col xs={24} lg={13}>
                <div className="text-white ayanair-footer-contact">
                  <h3 className="title-lg text-white text-uppercase">Contact Us</h3>
                  <p className="mb-10">For more information or any question contact us.</p>
                  <p>
                    <span className="text-uppercase">Address: </span> Olafiagangen 7, 0188 Oslo.
                    <br />
                    {/* <span className="text-uppercase">Telephone no: </span> 00964 750 4018333
                    <br />
                    <span className="text-uppercase">Whatsapp: </span> 00964 750 4018333
                    <br /> */}
                    <span className="text-uppercase">Email: </span> Info@barretravel.com
                  </p>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>

      <div className={Env.TenantHost === 'AyanAir' || Env.TenantHost === 'BarreTravel' ? 'px-10 pb-10 text-white text-uppercase' : classes.bottomFooter}>
        <div className={'page-container'}>
          <div className="order-2 order-sm-1 text-center">
            &copy; {new Date(Date.now()).getFullYear()}{' '}
            {Env.TenantHost === 'AyanAir'
              ? 'Ayan Air'
              : Env.TenantHost === 'BarreTravel'
              ? 'Barre Travel'
              : Env.TenantHost === 'ProTraveling'
              ? 'PRO traveling'
              : 'iTours'}
            , all rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPageFooter;
