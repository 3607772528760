import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Translations } from '../../../../features/localization';

type Props = {} & WithTranslation;
class SectionErrorBoundary extends React.Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return (
        <>
          <span className="text-danger">{this.props.t(Translations.Common.WeAreWorkingToSolveTheProblemSorryForTheInconvenience)}</span>
        </>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(SectionErrorBoundary);
