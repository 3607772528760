import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { useAntdValidation, useHttpCall, useMount } from '../../../features/hooks';
import { Translations } from '../../../features/localization';
import { AbpErrorAlert } from '../../shared';
import { forgotPasswordHttp } from '../http/authentication-http';
import authenticationSlice from '../redux/authentication-slice';

type FormValues = Readonly<{
  username: string;
}>;

const ForgotPassword: React.VFC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const forgotPasswordApi = useHttpCall(forgotPasswordHttp);

  const [formInstance] = Form.useForm();
  const { labelWithRules } = useAntdValidation(formInstance);

  useMount(() => {
    dispatch(authenticationSlice.actions.forgotPasswordSentSwitch(false));
  });

  const initialValues: FormValues = {
    username: '',
  };

  const handleSubmit = async (v: any) => {
    const values = v as FormValues;
    const response = await forgotPasswordApi.call(values.username);
    if (response && response.success) {
      dispatch(authenticationSlice.actions.forgotPasswordSentSwitch(true));
      history.push('/auth/login');
    }
  };
  return (
    <>
      <div className="w-100 max-w-400">
        <div className="mb-10 mb-lg-10">
          <h3 className="text-white font-size-h1">{t(Translations.Auth.ForgottenPassword)}</h3>
          <div className="text-white font-weight-bold">{t(Translations.Auth.EnterYourUsernameToResetYourPassword)}</div>
        </div>
        <AbpErrorAlert error={forgotPasswordApi.error} />
        <Form form={formInstance} initialValues={initialValues} onFinish={handleSubmit} layout="vertical">
          <Form.Item className="white-label" name="username" {...labelWithRules({ label: t(Translations.Auth.Username), rules: [{ type: 'Required' }] })}>
            <Input prefix={<UserOutlined />} type={'email'} placeholder={t(Translations.Auth.EnterYourUsername)} size="large" />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button className="antd-gold6-btn" type="primary" size="large" htmlType="submit" loading={forgotPasswordApi.pending}>
                {t(Translations.Common.Submit)}
              </Button>
              <Link to="/auth/login">
                <Button type="default" size="large" htmlType="button">
                  {t(Translations.Common.Cancel)}
                </Button>
              </Link>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ForgotPassword;
