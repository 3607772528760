import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import minBy from 'lodash/minBy';

import { GetAllRequestBase } from '../../../types';
import {
  buildAirportsFilterArray,
  buildCabinClassesFilterArray,
  buildFlightsDurationFilterArray,
  buildFromToHourRangeFilterArray,
  buildMarketingAirlinesFilterArray,
  convertApiItineraryToItinerarySummary,
} from '../../flight';
import { DepartureAirport, FlightCabinClass, FlightSupplier, OperatingAirline } from '../../flight/apiTypes';
import { buildFlightsStopTimeFilterArray } from '../../flight/helpers/FlightHelpers';
import { FlightItinerarySummary } from '../../flight/types';
import { buildHotelBoardsFilterArray } from '../../hotel';
import { HotelBoard, HotelItem, HotelRegion } from '../../hotel/apiTypes';
import { buildHotelDistrictsFilterArray } from '../../hotel/helpers/HotelHelpers';
import { HotelMapData } from '../../hotel/types';
import { PackageAvailabilityRequest, PackageAvailabilityResponse, PackageGetAllTenantReservesRequest, PackageGetAllTenantReservesResponse } from '../apiTypes';
import { PackageFilterItem, PackageFilterValueTypes } from '../types';

type PackageState = {
  temp: {
    packages: {
      request: {
        search?: PackageAvailabilityRequest;
      };
      response?: {
        flights: FlightItinerarySummary[];
        hotels: HotelItem[];
        isCompleted: boolean;
        isHotelCompleted: boolean;
        isFlightCompleted: boolean;
      };
      constants: {
        flights: {
          airlines: {
            airline: OperatingAirline;
            minPrice: number;
          }[];
          fromToHourRange?: {
            departure: [number, number];
            arrival: [number, number];
          }[];
          airports: DepartureAirport[];
          cabinClasses: FlightCabinClass[];
          durationMinMax: [number, number];
          stopTimeMinMax: [number, number];
        };
        hotels: {
          boards?: HotelBoard[];
          districts?: HotelRegion[];
        };
      };
      processing: {
        pagination: {
          flight: {
            page: number;
            pageSize: number;
          };
          hotel: {
            page: number;
            pageSize: number;
          };
        };
        filters: {
          flights: {
            stop: number;
            airlines?: string[];
            fromToHourRanges: {
              optionIndex: number;
              departureTimes?: [number, number];
              arrivalTimes?: [number, number];
            }[];
            fromToHourRangesCheckbox: {
              departureTimes?: [number, number][];
              arrivalTimes?: [number, number][];
            };
            airports?: string[];
            cabinClasses?: string[];
            duration?: [number, number];
            airlineWithStop?: {
              airline: string;
              direct: boolean;
            }[];
            flightNumber?: string;
          };
          hotels: {
            name?: string;
            ratings?: number[];
            boards?: string[];
            districts?: number[];
          };
        };
      };

      selectedHotel?: HotelItem;
      selectedHotelPage: number;

      selectedFlight?: FlightItinerarySummary;
      selectedFlightPage: number;

      modalFlightDetails?: FlightItinerarySummary;
      hotelMapData?: HotelMapData;
    };
    reserves: {
      request: {
        filters?: Omit<PackageGetAllTenantReservesRequest, keyof GetAllRequestBase>;
        pagination: { page: number; pageSize: number };
        type: 'Initialization' | 'Pagination' | 'Filter';
      };
      response?: PackageGetAllTenantReservesResponse;
    };
  };
};

const initialPagination = {
  flight: {
    page: 1,
    pageSize: 15,
  },
  hotel: {
    page: 1,
    pageSize: 15,
  },
} as PackageState['temp']['packages']['processing']['pagination'];

const initialFilters = {
  flights: {
    fromToHourRanges: [],
    fromToHourRangesCheckbox: {},
    stop: 100,
  },
  hotels: {},
} as PackageState['temp']['packages']['processing']['filters'];

const tempReservesInitialState: PackageState['temp']['reserves'] = {
  request: {
    pagination: { pageSize: 20, page: 1 },
    type: 'Initialization',
  },
};

const initialState: PackageState = {
  temp: {
    packages: {
      request: {},
      constants: {
        flights: {
          airlines: [],
          airports: [],
          cabinClasses: [],
          durationMinMax: [0, 0],
          stopTimeMinMax: [0, 0],
        },
        hotels: {},
      },
      processing: {
        pagination: initialPagination,
        filters: initialFilters,
      },
      selectedFlightPage: 1,
      selectedHotelPage: 1,
    },
    reserves: tempReservesInitialState,
  },
};

const flightFiltersType = ['Stop', 'Airline', 'DepartureTime', 'ArrivalTime', 'Airport', 'CabinClass', 'Duration', 'AirlineWithStop'];
const hotelFiltersType = ['Rating'];

const packageSlice = createSlice({
  name: 'package',
  initialState: initialState,
  reducers: {
    tempClean: (state) => {
      state.temp.packages.request = {};
      state.temp.packages.response = undefined;
      state.temp.reserves = tempReservesInitialState;
    },
    tempSetSearch: (state, action: PayloadAction<PackageAvailabilityRequest>) => {
      state.temp.packages.request.search = action.payload;
      state.temp.packages.response = undefined;
    },
    tempSetPackages: (state, action: PayloadAction<{ data: PackageAvailabilityResponse; flightSuppliers: FlightSupplier[] }>) => {
      state.temp.packages.processing.filters = initialFilters;
      state.temp.packages.processing.pagination = initialPagination;
      state.temp.packages.selectedHotelPage = 1;
      state.temp.packages.selectedFlightPage = 1;

      //#region Flights
      const itinerariesSummary = action.payload.data.flight.map((item) => convertApiItineraryToItinerarySummary(item, action.payload.flightSuppliers));

      state.temp.packages.response = {
        flights: itinerariesSummary,
        hotels: action.payload.data.hotel,
        isCompleted: action.payload.data.isCompleted,
        isFlightCompleted: action.payload.data.isFlightCompleted,
        isHotelCompleted: action.payload.data.isHotelCompleted,
      };

      if (itinerariesSummary.length > 0) {
        state.temp.packages.constants.flights.fromToHourRange = buildFromToHourRangeFilterArray(itinerariesSummary);
        state.temp.packages.constants.flights.airlines = buildMarketingAirlinesFilterArray(itinerariesSummary);
        state.temp.packages.constants.flights.airports = buildAirportsFilterArray(itinerariesSummary);
        state.temp.packages.constants.flights.cabinClasses = buildCabinClassesFilterArray(itinerariesSummary);
        state.temp.packages.constants.flights.durationMinMax = buildFlightsDurationFilterArray(itinerariesSummary);
        state.temp.packages.constants.flights.stopTimeMinMax = buildFlightsStopTimeFilterArray(itinerariesSummary);

        //Set cheapest flight
        state.temp.packages.selectedFlight = convertApiItineraryToItinerarySummary(
          minBy(action.payload.data.flight, 'airItineraryPricingInfo.itinTotalFare.totalFare')!,
          action.payload.flightSuppliers
        );
      }
      //#endregion

      //#region Hotels
      if (action.payload.data.hotel.length > 0) {
        state.temp.packages.constants.hotels.boards = buildHotelBoardsFilterArray(action.payload.data.hotel);
        state.temp.packages.constants.hotels.districts = buildHotelDistrictsFilterArray(action.payload.data.hotel);

        //Set cheapest hotel
        state.temp.packages.selectedHotel = minBy(action.payload.data.hotel, 'salePrice');
      }
      //#endregion
    },
    tempSetModalFlightDetails: (state, action: PayloadAction<FlightItinerarySummary | undefined>) => {
      state.temp.packages.modalFlightDetails = action.payload;
    },
    tempSetHotelMapData: (state, action: PayloadAction<HotelMapData | undefined>) => {
      state.temp.packages.hotelMapData = action.payload;
    },
    tempPagination: (state, action: PayloadAction<{ type: 'Hotel' | 'Flight'; page: number; pageSize: number }>) => {
      state.temp.packages.processing.pagination[action.payload.type === 'Hotel' ? 'hotel' : 'flight'] = {
        page: action.payload.page,
        pageSize: action.payload.pageSize,
      };
    },
    tempFilter: (
      state,
      action: PayloadAction<{
        type: PackageFilterItem;
        value: PackageFilterValueTypes;
      }>
    ) => {
      switch (action.payload.type) {
        case 'Stop':
          state.temp.packages.processing.filters.flights.stop = action.payload.value as number;
          break;
        case 'Airline':
          state.temp.packages.processing.filters.flights.airlines = action.payload.value as string[] | undefined;
          break;
        case 'Airport':
          state.temp.packages.processing.filters.flights.airports = action.payload.value as string[] | undefined;
          break;
        case 'CabinClass':
          state.temp.packages.processing.filters.flights.cabinClasses = action.payload.value as string[];
          break;
        case 'Duration':
          state.temp.packages.processing.filters.flights.duration = action.payload.value as [number, number];
          break;
        case 'DepartureTime':
          const departureTimeFilterValue = action.payload.value as { optionIndex: number; times: [number, number] };
          const obj1 = state.temp.packages.processing.filters.flights.fromToHourRanges.find((f) => f.optionIndex === departureTimeFilterValue.optionIndex);
          if (obj1) {
            obj1.departureTimes = departureTimeFilterValue.times;
          } else {
            state.temp.packages.processing.filters.flights.fromToHourRanges.push({
              optionIndex: departureTimeFilterValue.optionIndex,
              departureTimes: departureTimeFilterValue.times,
            });
          }
          break;
        case 'ArrivalTime':
          const arrivalTimeFilterValue = action.payload.value as { optionIndex: number; times: [number, number] };
          const obj2 = state.temp.packages.processing.filters.flights.fromToHourRanges.find((f) => f.optionIndex === arrivalTimeFilterValue.optionIndex);
          if (obj2) {
            obj2.arrivalTimes = arrivalTimeFilterValue.times;
          } else {
            state.temp.packages.processing.filters.flights.fromToHourRanges.push({
              optionIndex: arrivalTimeFilterValue.optionIndex,
              arrivalTimes: arrivalTimeFilterValue.times,
            });
          }
          break;
        case 'AirlineWithStop':
          state.temp.packages.processing.filters.flights.airlineWithStop = action.payload.value as { airline: string; direct: boolean }[];
          break;
        case 'DepartureTimeCheckbox':
          state.temp.packages.processing.filters.flights.fromToHourRangesCheckbox.departureTimes = action.payload.value as [number, number][];
          break;
        case 'ArrivalTimeCheckbox':
          state.temp.packages.processing.filters.flights.fromToHourRangesCheckbox.arrivalTimes = action.payload.value as [number, number][];
          break;
        case 'HotelName':
          state.temp.packages.processing.filters.hotels.name = action.payload.value as string;
          break;
        case 'HotelRating':
          state.temp.packages.processing.filters.hotels.ratings = action.payload.value as number[];
          break;
        case 'HotelBoards':
          state.temp.packages.processing.filters.hotels.boards = action.payload.value as string[];
          break;
        case 'HotelDistricts':
          state.temp.packages.processing.filters.hotels.districts = action.payload.value as number[];
          break;
        case 'FlightNumber':
          state.temp.packages.processing.filters.flights.flightNumber = action.payload.value as string;
      }

      if (flightFiltersType.includes(action.payload.type)) {
        state.temp.packages.processing.pagination.flight.page = 1;
      }
      if (hotelFiltersType.includes(action.payload.type)) {
        state.temp.packages.processing.pagination.hotel.page = 1;
      }
    },
    tempSetSelectedHotel: (state, action: PayloadAction<HotelItem>) => {
      state.temp.packages.selectedHotel = action.payload;
      state.temp.packages.selectedHotelPage = state.temp.packages.processing.pagination.hotel.page;
    },
    tempSetSelectedFlight: (state, action: PayloadAction<FlightItinerarySummary>) => {
      state.temp.packages.selectedFlight = action.payload;
      state.temp.packages.selectedFlightPage = state.temp.packages.processing.pagination.flight.page;
    },
    tempReservesFilter: (state, action: PayloadAction<Omit<PackageGetAllTenantReservesRequest, keyof GetAllRequestBase>>) => {
      state.temp.reserves.request.filters = action.payload;
      state.temp.reserves.request.type = 'Filter';
    },
    tempReservesPagination: (state, action: PayloadAction<{ page: number; pageSize: number }>) => {
      state.temp.reserves.request.pagination = action.payload;
      state.temp.reserves.request.type = 'Pagination';
    },
    tempReservesSetResponse: (state, action: PayloadAction<PackageGetAllTenantReservesResponse>) => {
      state.temp.reserves.response = action.payload;
    },
  },
});

export default packageSlice;
