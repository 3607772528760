import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { GetAllRequestBase } from '../../../types';
import {
  HotelAvailability,
  HotelGetAllTenantReservesRequest,
  HotelGetAllTenantReservesResponse,
  HotelImage,
  HotelReferenceFacility,
  HotelReferenceSurroundings,
} from '../apiTypes';

type HotelState = {
  temp: {
    hotelDetails: Partial<{
      id: number;
      name: string;
      date: {
        checkIn: string;
        checkOut: string;
        duration: number;
      };
      rating: number;
      country: string;
      city: string;
      address: string;
      //images: HotelReferenceImage[];
      images: HotelImage[];
      descriptions: {
        title: string;
        para: string;
      }[];
      contacts: Partial<{
        phone: string;
        fax: string;
      }>;
      coordinates: {
        latitude: number;
        longitude: number;
      };
      facilities: HotelReferenceFacility[];
      popularFacilities: {
        id?: number;
        name: string;
      }[];
      surroundings: HotelReferenceSurroundings;
      paragraphs: { title: string; para: string }[];

      multipleRooms: boolean;
      availabilities: HotelAvailability[];

      commonRoomFacilities: string[];

      guests: {
        totalAdults: number;
        totalChildren: number;
      };

      startingPrice: number;
      mapUrl: string;
    }>;
    reserves: {
      request: {
        filters?: Omit<HotelGetAllTenantReservesRequest, keyof GetAllRequestBase>;
        pagination: { page: number; pageSize: number };
        type: 'Initialization' | 'Pagination' | 'Filter';
      };
      response?: HotelGetAllTenantReservesResponse;
    };
  };
};

const tempReservesInitialState: HotelState['temp']['reserves'] = {
  request: {
    pagination: {
      page: 1,
      pageSize: 20,
    },
    type: 'Initialization',
  },
};
const initialState: HotelState = {
  temp: {
    hotelDetails: {},
    reserves: tempReservesInitialState,
  },
};
const hotelSlice = createSlice({
  name: 'hotel',
  initialState: initialState,
  reducers: {
    tempUpdateHotelDetails: (state, action: PayloadAction<Partial<HotelState['temp']['hotelDetails']>>) => {
      state.temp.hotelDetails = { ...state.temp.hotelDetails, ...action.payload };
    },
    tempCleanHotelDetails: (state) => {
      state.temp.hotelDetails = {};
    },
    tempCleanHotelReserves: (state) => {
      state.temp.reserves = tempReservesInitialState;
    },
    tempReservesFilter: (state, action: PayloadAction<Omit<HotelGetAllTenantReservesRequest, keyof GetAllRequestBase>>) => {
      state.temp.reserves.request.filters = action.payload;
      state.temp.reserves.request.type = 'Filter';
    },
    tempReservesPagination: (state, action: PayloadAction<{ page: number; pageSize: number }>) => {
      state.temp.reserves.request.pagination = action.payload;
      state.temp.reserves.request.type = 'Pagination';
    },
    tempReservesSetResponse: (state, action: PayloadAction<HotelGetAllTenantReservesResponse>) => {
      state.temp.reserves.response = action.payload;
    },
  },
});

export default hotelSlice;
