import { Checkbox, Col, Row } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../features/hooks';
import { Translations } from '../../../../../../features/localization';
import { ShowAllOrLessFilters, useBookingTempFilters } from '../../../../../booking';
import { FilterItemTitle } from '../../../../../shared';
import { getHotelRegionCount } from '../../../../helpers/HotelHelpers';

type Props = {
  isPackage: boolean;
};
const defaultFiltersShowNumber = 10;
const HotelsFilterDistricts: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const { filter } = useBookingTempFilters({ moduleType: props.isPackage ? 'Package' : 'Hotel' });

  const showAllState = useState<boolean>(false);
  const hotels = useAppSelector((state) => state.package.temp.packages.response?.hotels);
  const districts = useAppSelector((state) => (props.isPackage ? state.package.temp.packages.constants.hotels.districts : undefined));
  const filteredDistricts = useAppSelector((state) => (props.isPackage ? state.package.temp.packages.processing.filters.hotels.districts : undefined));

  const handleResetClicked = () => {
    filter({ type: 'HotelDistricts', value: undefined });
  };

  const handleChanged = (checkedValue: CheckboxValueType[]) => {
    filter({ type: 'HotelDistricts', value: checkedValue as number[] });
  };

  return (
    <>
      <FilterItemTitle
        title={t(Translations.Common.Districts)}
        resetButtonVisibility={filteredDistricts && filteredDistricts.length > 0}
        onResetClick={handleResetClicked}
      />
      <Checkbox.Group className="w-100" value={filteredDistricts} onChange={handleChanged}>
        {districts &&
          districts.map((item, index) =>
            index < defaultFiltersShowNumber || showAllState[0] ? (
              <Row key={item.id} justify="space-between" className="mb-2">
                <Col>
                  <Checkbox value={item.id}>{item.name}</Checkbox>
                </Col>
                <Col>
                  <span className="font-weight-bold">({getHotelRegionCount(hotels, item.id)})</span>
                </Col>
              </Row>
            ) : undefined
          )}
      </Checkbox.Group>
      <ShowAllOrLessFilters showAllState={showAllState} array={districts} defaultFiltersShowNumber={defaultFiltersShowNumber} />
    </>
  );
});

export default HotelsFilterDistricts;
