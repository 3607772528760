import { createSlice } from '@reduxjs/toolkit';

type DomesticHotelsSlice = {};

const initialState: DomesticHotelsSlice = {};

const domesticHotelSlice = createSlice({
  name: 'domesticHotel',
  initialState: initialState,
  reducers: {},
});

export default domesticHotelSlice;
