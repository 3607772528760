import { DownOutlined, InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Divider, InputNumber, Row, Skeleton, Space, Tag, Tooltip } from 'antd';
import { sumBy } from 'lodash';
import last from 'lodash/last';
import sum from 'lodash/sum';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGlobalization } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { getDurationAsHoursAndMinutes, isZeroDuration } from '../../../../../utils/helpers';
import { FormattedCurrency } from '../../../../shared';
import { DepartureAirport, FlightReserveResponsePassenger } from '../../../apiTypes';
import { getFlightTotalFare, getTranslateKeyFlightPassengerType } from '../../../helpers/FlightHelpers';
import { FlightItinerarySummary } from '../../../types';
import classes from '../../Flights/FlightListItem/FlightListItem.module.scss';

type Props = {
  itinerary: FlightItinerarySummary | undefined;
  showButton: boolean;
  manualBook?: boolean;
  buttonLoading?: boolean;
  passengers?: FlightReserveResponsePassenger[];
  extraBaggageSelected?: boolean;
  onCommissionUpdate?: (value: number) => void;
};

const FlightBookingSummary: React.VFC<Props> = React.memo((props) => {
  const { itinerary } = props;
  const { t } = useTranslation();

  const { formatHourMinute, formatShortMonthWeekDay, isValidLocaleDate } = useGlobalization();

  const [commission, setCommission] = useState<number>(0);
  const [commissionError, setCommissionError] = useState<string>();

  const commissionRef = useRef<HTMLInputElement>(null);

  const prices = useMemo(() => {
    if (itinerary) {
      const commissionDiff = commission - (itinerary.priceInfo?.itinTotalFare.commission ?? 0);

      return {
        totalFare: getFlightTotalFare(itinerary, props.extraBaggageSelected)! + commissionDiff,
        totalTax: sumBy(itinerary.priceInfo?.itinTotalFare.taxes, 'amount'),
        baseFare: (itinerary.priceInfo?.itinTotalFare.baseFare ?? 0) + commissionDiff,
      };
    }
  }, [commission, itinerary, props.extraBaggageSelected]);

  const airportTimeComponent = (airport: DepartureAirport, dateTime: string) => (
    <div>
      {isValidLocaleDate(dateTime, true) && (
        <>
          <div className="title-xs mb-0 no-wrap">{formatHourMinute(dateTime)}</div>
          <div className="title-xs mb-0 no-wrap monthWeekDay-small-in-rtl">{formatShortMonthWeekDay(dateTime)}</div>
        </>
      )}
      <div>
        <Tooltip
          title={
            <div>
              <div>{airport.countryName}</div>
              <div>{airport.cityName}</div>
              <div>{airport.locationName}</div>
            </div>
          }>
          <Tag color="default" className={'mx-0'}>
            {airport.locationCode} <InfoCircleOutlined />
          </Tag>
        </Tooltip>
      </div>
    </div>
  );

  useEffect(() => {
    if (props.itinerary?.priceInfo) {
      setCommission(props.itinerary.priceInfo.itinTotalFare.commission);
    }
  }, [props.itinerary?.priceInfo]);

  function handleUpdateCommissionClick() {
    const value = +commissionRef.current!.value ?? 0;
    if (value < 0) {
      setCommissionError('Must be greater than zero.');
    } else {
      setCommissionError(undefined);
      setCommission(value);
      props.onCommissionUpdate!(value);
    }
  }

  return (
    <>
      {itinerary ? (
        <>
          {itinerary.originDestinationOptions.map((option, index) => {
            const firstSegment = option.flightSegments[0];
            const lastSegment = last(option.flightSegments)!;

            return (
              <React.Fragment key={index}>
                <div className={classes.segmentItem}>
                  <Row gutter={[10, 10]} align={'middle'} className="mb-0 no-wrap-flex-sm">
                    <Col flex="40px">
                      <img src={firstSegment.marketingAirline.photoUrl} alt={firstSegment.marketingAirline.name} className={classes.airlineLogo} />
                    </Col>
                    <Col flex="auto">
                      <div className={classes.originDurationDestination}>
                        {airportTimeComponent(firstSegment.departureAirport, firstSegment.departureDateTime)}
                        {isZeroDuration(option.journeyDuration) ? (
                          <small className={classes.duration}>-----</small>
                        ) : (
                          <Tooltip
                            title={
                              <div>
                                <span className="hidden-xs hidden-sm hidden-md">{t(Translations.Common.Duration)}: </span>{' '}
                                {getDurationAsHoursAndMinutes(option.journeyDuration)} ({moment.duration(option.journeyDuration).toISOString()})
                              </div>
                            }>
                            <small className={classes.duration}>{getDurationAsHoursAndMinutes(option.journeyDuration)}</small>
                          </Tooltip>
                        )}

                        <div className="text-right">{airportTimeComponent(lastSegment.arrivalAirport, lastSegment.arrivalDateTime)}</div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </React.Fragment>
            );
          })}
          {itinerary.flightType && (
            <>
              <Divider className="my-4" />
              <Row align={'middle'} justify={'space-between'}>
                <Col>{t(Translations.Flight.FlightType)}</Col>
                <Col>{t(Translations.Flight[itinerary.flightType])}</Col>
              </Row>
            </>
          )}
          <Divider className="my-4">{t(Translations.Common.PriceDetails)}</Divider>
          {itinerary.priceInfo && (
            <>
              <Collapse ghost className="styled-ghost-collapse">
                {itinerary.priceInfo.ptC_FareBreakdown.map((item, index) => (
                  <Collapse.Panel
                    key={index}
                    header={
                      <Row align={'middle'} justify={'space-between'} className="w-100">
                        <Col className="font-weight-bold">
                          {item.passengerTypeQuantity.quantity} x {t(getTranslateKeyFlightPassengerType(item.passengerTypeQuantity.code))} <DownOutlined />
                        </Col>
                        <Col className="font-weight-bold">
                          <FormattedCurrency
                            number={
                              props.extraBaggageSelected && itinerary.hasExtraBaggage
                                ? item.passengerTypeQuantity.quantity * item.passengerFare.totalFareWithExtraBaggage!
                                : item.passengerTypeQuantity.quantity * item.passengerFare.totalFare
                            }
                            currencyDisplay="code"
                          />
                        </Col>
                      </Row>
                    }>
                    <Row align={'middle'} justify={'space-between'} className="text-muted">
                      <Col>{t(Translations.Common.BaseFare)}</Col>
                      <Col>
                        <FormattedCurrency number={item.passengerFare.baseFare} currencyDisplay="code" />
                      </Col>
                    </Row>
                    {props.extraBaggageSelected && itinerary.hasExtraBaggage && (
                      <Row align={'middle'} justify={'space-between'} className="text-muted">
                        <Col>{t(Translations.Flight.ExtraBaggage)}</Col>
                        <Col>
                          <FormattedCurrency number={item.passengerFare.extraBaggage!} currencyDisplay="code" />
                        </Col>
                      </Row>
                    )}

                    <Row align={'middle'} justify={'space-between'} className="text-muted">
                      <Col>{t(Translations.Common.Taxes)}</Col>
                      <Col>
                        <FormattedCurrency number={sum(item.passengerFare.taxes.map((tax) => tax.amount))} currencyDisplay="code" />
                      </Col>
                    </Row>
                  </Collapse.Panel>
                ))}
              </Collapse>
              <Divider className="my-4" />
            </>
          )}

          {prices && (
            <>
              {props.onCommissionUpdate && (
                <>
                  <h5 className={'title-sm'}>
                    <Row align={'middle'} justify={'space-between'}>
                      <Col className="font-weight-bold">{t(Translations.Common.BaseFare)}</Col>
                      <Col className="font-weight-bold">
                        <FormattedCurrency number={prices.baseFare!} currencyDisplay="code" />
                      </Col>
                    </Row>
                  </h5>

                  <h5 className={'title-sm'}>
                    <Row align={'middle'} justify={'space-between'}>
                      <Col className="font-weight-bold">{t(Translations.Common.TotalTax)}</Col>
                      <Col className="font-weight-bold">
                        <FormattedCurrency number={prices!.totalTax} currencyDisplay="code" />
                      </Col>
                    </Row>
                  </h5>

                  <h5 className={'title-sm'}>
                    <div className="mb-1">{t(Translations.Payment.Commission)}</div>
                    <Space>
                      <InputNumber
                        type="number"
                        defaultValue={itinerary.priceInfo!.itinTotalFare.commission}
                        ref={commissionRef}
                        disabled={!props.showButton}
                      />
                      {props.showButton && (
                        <Button type="primary" className="antd-gold6-btn" onClick={handleUpdateCommissionClick}>
                          Update
                        </Button>
                      )}
                    </Space>
                    <div className="text-danger font-size-sm mt-1">{commissionError}</div>
                  </h5>
                </>
              )}

              <h5 className={'title-sm'}>
                <Row align={'middle'} justify={'space-between'}>
                  <Col className="font-weight-bold">{t(Translations.Common.TotalPrice)}</Col>
                  <Col className="font-weight-bold">
                    <FormattedCurrency number={prices.totalFare!} currencyDisplay="code" />
                  </Col>
                </Row>
              </h5>
            </>
          )}

          {props.showButton && (
            <Button type="primary" htmlType="submit" danger block loading={props.buttonLoading}>
              {props.manualBook || itinerary.paymentBeforePNR ? t(Translations.Common.ContinueToPayment) : t(Translations.Flight.RequestPNR)}{' '}
              <RightOutlined className="mirror-in-rtl" />
            </Button>
          )}
        </>
      ) : (
        <div>
          <Skeleton className={'no-title mb-6 mt-4'} loading active avatar paragraph={{ width: '100%' }} />
          <Skeleton className={'no-title my-6'} loading active avatar paragraph={{ width: '100%' }} />
          <Divider className="my-0" />
          <Skeleton loading active paragraph={{ rows: 1, width: '100%' }} />
          <Divider className="my-0" />
          <Skeleton className="one-line-skeleton mb-3" loading active title={{ width: '100%' }} paragraph={{ rows: 0 }} />
        </div>
      )}
    </>
  );
});

export default FlightBookingSummary;
