import { Slider } from 'antd';
import { SliderMarks } from 'antd/es/slider';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../features/hooks';
import { Translations } from '../../../../../../features/localization';
import { useBookingTempFilters } from '../../../../../booking';
import { FilterItemTitle } from '../../../../../shared';

type Props = {
  isPackage?: boolean;
  type: 'Duration' | 'StopTime';
};
const FlightsFilterDuration: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const { filter } = useBookingTempFilters({ moduleType: props.isPackage ? 'Package' : 'Flight' });

  const [minHours, maxHours] = useAppSelector((state) =>
    props.isPackage
      ? state.package.temp.packages.constants.flights[props.type === 'Duration' ? 'durationMinMax' : 'stopTimeMinMax']
      : state.flight.temp.flights.constants[props.type === 'Duration' ? 'durationMinMax' : 'stopTimeMinMax']
  );

  const sliderMarks = useMemo<SliderMarks>(() => {
    const marks: SliderMarks = {};
    marks[maxHours] = `${maxHours}'`;
    marks[minHours] = `${minHours}'`;

    return marks;
  }, [maxHours, minHours]);

  const handleResetClicked = useCallback(() => {
    filter({ type: props.type, value: undefined });
  }, [filter, props.type]);

  const handleFilterChanged = (value: [number, number]) => {
    filter({ type: props.type, value: value });
  };

  return (
    <>
      <FilterItemTitle
        title={`${t(props.type === 'Duration' ? Translations.Common.Duration : Translations.Common.StopTime)} (${t(Translations.Common.Hour)})`}
        resetButtonVisibility={false}
        onResetClick={handleResetClicked}
      />
      <Slider
        className="pb-9 mt-0 mb-2"
        min={minHours}
        max={maxHours}
        step={1}
        marks={sliderMarks}
        range
        defaultValue={[minHours, maxHours]}
        onAfterChange={handleFilterChanged}
      />
    </>
  );
});

export default FlightsFilterDuration;
