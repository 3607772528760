import React from 'react';

import { DefinedRoute } from '../../types';

const prefix = '/admin';

const AdminRoutes = (): readonly DefinedRoute[] => {
  const creaDitUser = React.lazy(() => import('./pages/CreaDitUser/CreaDitUser'));
  return [
    {
      type: 'Route',
      exact: true,
      path: `${prefix}/users`,
      children: React.lazy(() => import('./pages/UserManagement/UserManagement')),
    },
    {
      type: 'Route',
      exact: true,
      path: `${prefix}/users/create`,
      children: creaDitUser,
      childrenProps: { creaDitType: 'Create' },
    },
    {
      type: 'Route',
      exact: true,
      path: `${prefix}/users/edit/:id`,
      children: creaDitUser,
      childrenProps: { creaDitType: 'Edit' },
    },
  ];
};

export default AdminRoutes;
