import { InfoCircleOutlined } from '@ant-design/icons';
import { Divider, Tooltip } from 'antd';
import React from 'react';

import { FlightItinerarySummary } from '../../../types';
import classes from '../FlightDetailsModal/FlightDetailsModal.module.scss';
import FlightSegmentBaggageDetails from '../FlightSegmentBaggageDetails/FlightSegmentBaggageDetails';

type Props = {
  itinerary: FlightItinerarySummary;
  showExtraBaggageInformation?: boolean;
};
const FlightDetailsBaggage: React.VFC<Props> = React.memo((props) => {
  return (
    <table className={classes.baggageDetailTable}>
      <tbody>
        {props.itinerary.originDestinationOptions.map((option, index) => (
          <React.Fragment key={index}>
            {option.flightSegments.map((segment, segmentIndex) => (
              <tr key={segmentIndex}>
                <td>
                  <Tooltip
                    title={
                      <>
                        {segment.arrivalAirport.locationCode}
                        <br />
                        {segment.arrivalAirport.locationName}
                      </>
                    }>
                    <b>{segment.departureAirport.cityName}</b> - {segment.departureAirport.countryName} <InfoCircleOutlined />
                  </Tooltip>
                </td>
                <td>
                  <span className="fas fa-plane  text-muted mirror-in-rtl" />
                </td>
                <td>
                  <Tooltip
                    title={
                      <>
                        {segment.arrivalAirport.locationCode}
                        <br />
                        {segment.arrivalAirport.locationName}
                      </>
                    }>
                    <b>{segment.arrivalAirport.cityName}</b> - {segment.arrivalAirport.countryName} <InfoCircleOutlined />
                  </Tooltip>
                </td>
                <td>
                  <FlightSegmentBaggageDetails segment={segment} hasExtraBaggage={props.itinerary.hasExtraBaggage && props.showExtraBaggageInformation} />
                </td>
              </tr>
            ))}
            {props.itinerary.originDestinationOptions.length - 1 !== index && (
              <tr>
                <td colSpan={4}>
                  <Divider className="my-3" />
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
});

export default FlightDetailsBaggage;
