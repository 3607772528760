import Axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

import { AxiosInstanceData } from '../../../types';
import { isDevelopment, urlWithQuery } from '../../../utils/helpers';
import { IdentityAgentSignCreateRq, IdentityContactUsCreateRq, IdentityNewsLetterCreateRq } from '../types';
import { AuthenticationApiDataMap, AuthenticationApiUrls } from './authentication-apiData';
import authenticationMock from './authentication-mock';

const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_AuthenticationApiBaseUrl;

if (isDevelopment()) {
  const mock = new MockAdapter(axiosInstance, { delayResponse: 2000, onNoMatch: 'passthrough' });
  authenticationMock(mock);
}

export const identityTenantGetByKeywordHttp = (tenantName: string) => {
  const url = urlWithQuery(AuthenticationApiUrls.TenantGetByKeyword, { name: tenantName });
  return axiosInstance.get(url);
};

export const forgotPasswordHttp = (username: string) => {
  return axiosInstance.post(AuthenticationApiUrls.ForgotPassword, { emailOrPhoneNumber: username });
};

export const loginHttp = (data: { username: string; password: string }) => {
  return axiosInstance.post(AuthenticationApiUrls.Login, { emailOrPhoneNumber: data.username, password: data.password });
};

export const getPermissionsHttp = () => {
  return axiosInstance.get(AuthenticationApiUrls.GetPermissions);
};

export const identityAgentSignCreateHttp = (data: IdentityAgentSignCreateRq) => {
  return axiosInstance.post(AuthenticationApiUrls.AgentSignCreate, data);
};

export const identityContactUsCreateHttp = (data: IdentityContactUsCreateRq) => {
  return axiosInstance.post(AuthenticationApiUrls.ContactUsCreate, data);
};

export const identityNewsLetterCreateHttp = (data: IdentityNewsLetterCreateRq) => {
  return axiosInstance.post(AuthenticationApiUrls.NewsLetterCreate, data);
};

export const AuthenticationAxiosData: AxiosInstanceData = {
  instance: axiosInstance,
  DataMap: {
    MapObject: AuthenticationApiDataMap,
    Urls: AuthenticationApiUrls,
  },
};
