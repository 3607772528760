import { ApiDataItem } from '../../../types';

export enum AuthenticationApiUrls {
  TenantGetByKeyword = '/api/services/app/Tenant/GetByKeyword',
  ForgotPassword = '/api/services/app/Account/ForgotPassword',
  Login = '/api/TokenAuth/Login',
  GetPermissions = '/api/services/app/Profile/GetPermissions',

  //AgentSign
  AgentSignCreate = '/api/services/app/AgentSign/Create',

  //ContactUs
  ContactUsCreate = '/api/services/app/ContactUs/Create',

  //NewsLetter
  NewsLetterCreate = '/api/services/app/NewsLetter/Create',
}

export const AuthenticationApiDataMap: Map<string, ApiDataItem> = new Map();
