export const LocalStorageVersion = 1;

export enum LocalStorageKey {
  LocalStorageVersionKey = 'lv',
  Culture = 'culture',
  LastLocation = 'app-lastLocation',
  Tenants = 'tenants',
  CurrentTenant = 'current-tenant',
  AuthToken = 'auth-token',
  Currency = 'currency',
  RecentSearches = 'recent-searches',
  ApiErrors = 'api-errors',
  Affiliate = 'affiliate',
  IpLocation = 'ip-location',
  FlightSuppliers = 'flight-suppliers',
  Rare = 'rare',
  Preferences = 'preferences',
}

export enum LocalStorageExpiration {
  Minute = 60,
  Hour = 60 * 60,
  Day = 60 * 60 * 24,
  Week = 60 * 60 * 24 * 7,
  Infinite = 60 * 60 * 24 * 365 * 50,
}
