import ReactDOM from 'react-dom';

import AyanLogo from '../../../../assets/ayanAir-logo.svg';
import BarreLogo from '../../../../assets/barre-travel/barretravel.png';
import Logo from '../../../../assets/itours-logo.svg';
import ProTravelingLogo from '../../../../assets/proTraveling-logo.svg';
import { Env } from '../../../../utils/env';

const LoadingPortal: React.FC = () => {
  const logo =
    Env.TenantHost === 'AyanAir' ? AyanLogo : Env.TenantHost === 'BarreTravel' ? BarreLogo : Env.TenantHost === 'ProTraveling' ? ProTravelingLogo : Logo;

  const loadingElement = (
    <div className="loading2Holder">
      <img src={logo} title={Env.TenantHost || 'itours'} alt={Env.TenantHost || 'itours'} width="100" style={{ marginLeft: 0, marginBottom: 0 }} />
      <div className={`loading2 ${Env.TenantHost === 'ProTraveling' ? 'ProTravelingLoading' : ''}`}></div>
      <br />
      <br />
      <br />
    </div>
  );
  return ReactDOM.createPortal(loadingElement, document.getElementById('loading_portal')! as HTMLDivElement);
};

export default LoadingPortal;
