import Axios from 'axios';

import { AxiosInstanceData } from '../../../types';
import { urlWithQuery } from '../../../utils/helpers';
import {
  DomesticFlightGerReserveAllForm,
  DomesticFlightGetReserveQueryString,
  DomesticFlightParamsAvailability,
  DomesticFlightPreReserve,
  DomesticFlightValidate,
} from '../apiTypes';
import { DomesticFlightApiDataMap, DomesticFlightApiUrls } from './domesticFlight-apiData';

const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_DomesticFlightApiBaseUrl;

export const DomesticFlightGetAirportHttp = (data: { query: string }) => {
  return axiosInstance.post(DomesticFlightApiUrls.GetAirport, data);
};

export const DomesticFlightGetKeyAvailabilityHttp = (data: DomesticFlightParamsAvailability) => {
  return axiosInstance.post(DomesticFlightApiUrls.GetKeyAvailability, data);
};

export const DomesticFlightGetBookingFlightValidate = (data: DomesticFlightValidate) => {
  return axiosInstance.post(DomesticFlightApiUrls.BookingFlightValidate, data);
};

export const DomesticFlightGetFlightsHttp = (data: { key: string }) => {
  const url = urlWithQuery(DomesticFlightApiUrls.GetFlights, data);
  return axiosInstance.get(url);
};

export const DomesticFlightGetValidateHttp = (data: { preReserveKey: string }) => {
  const url = urlWithQuery(DomesticFlightApiUrls.GetValidate, data);
  return axiosInstance.get(url);
};

export const DomesticFlightPreReserveHttp = (data: DomesticFlightPreReserve) => {
  return axiosInstance.post(DomesticFlightApiUrls.PreReserve, data);
};

export const DomesticFlightGetConfirmReserveHttp = (data: DomesticFlightGetReserveQueryString) => {
  return axiosInstance.post(DomesticFlightApiUrls.ConfirmReserve, data);
};

export const DomesticFlightGetReserveHttp = (data: DomesticFlightGetReserveQueryString) => {
  const url = urlWithQuery(DomesticFlightApiUrls.GetReserve, data);
  return axiosInstance.get(url);
};

export const DomesticFlightGetGetReserveAllHttp = (data: DomesticFlightGerReserveAllForm) => {
  const url = urlWithQuery(DomesticFlightApiUrls.GetReserveAll, data);
  return axiosInstance.get(url);
};

export const DomesticFlightAxiosData: AxiosInstanceData = {
  instance: axiosInstance,
  DataMap: {
    MapObject: DomesticFlightApiDataMap,
    Urls: DomesticFlightApiUrls,
  },
};
