import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Steps } from 'antd';
import { StepProps } from 'antd/es/steps';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Translations } from '../../../../features/localization';
import { BookingModuleType } from '../../types';

type Props = {
  type: BookingModuleType;
  current: number;
  bookStatus?: 'Pending' | 'Success' | 'Failed';
};
const BookingSteps: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  let step4Title = t(Translations.Booking.StepCompleteThePurchase);
  let step4Icon = <span className="fas fa-suitcase-rolling" />;
  let status: StepProps['status'];

  if (props.current === 3) {
    switch (props.bookStatus) {
      case 'Pending':
        step4Title = t(props.type === 'Hotel' ? Translations.Hotel.StepPendingBook : Translations.Flight.StepPendingBook);
        step4Icon = <LoadingOutlined />;
        break;
      case 'Success':
        break;
      case 'Failed':
        step4Title = t(Translations.Common.Unsuccessful);
        step4Icon = <CloseCircleOutlined />;
        status = 'error';
        break;
      default:
        break;
    }
  }

  const stepOneTranslated = () => {
    switch (props.type) {
      case 'Flight':
        return t(Translations.Flight.StepChooseFlight);
      case 'Hotel':
      case 'DomesticHotel':
        return t(Translations.Hotel.StepChooseRoom);
      case 'Package':
        return t(Translations.Package.StepChoosePackage);
      case 'DomesticFlight':
        return t(Translations.Flight.StepChooseFlight);
      default:
        break;
    }
  };

  return (
    <Steps className="mb-6" current={props.current} labelPlacement="horizontal" status={status}>
      <Steps.Step
        title={stepOneTranslated()}
        icon={
          <span
            className={clsx({
              fa: true,
              'fa-plane mirror-in-rtl': props.type === 'Flight' || props.type === 'DomesticFlight',
              'fa-bed': props.type === 'Hotel' || props.type === 'DomesticHotel',
              'fa-box': props.type === 'Package',
            })}
          />
        }
      />
      <Steps.Step
        title={
          props.type === 'Hotel' || props.type === 'DomesticHotel'
            ? t(Translations.Hotel.StepEnterGuestsDetails)
            : t(Translations.Flight.StepEnterPassengersDetails)
        }
        icon={<span className="fas fa-address-card" />}
      />
      {props.type === 'DomesticHotel' && <Steps.Step title={t(Translations.Hotel.CheckCapacity)} icon={<span className="fas fa-search" />} />}

      <Steps.Step title={t(Translations.Booking.StepConfirmAndPayment)} icon={<span className="fas fa-credit-card" />} />
      <Steps.Step title={step4Title} icon={step4Icon} />
    </Steps>
  );
});

export default BookingSteps;
