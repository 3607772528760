import { Form, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAntdValidation } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { Countries } from '../../../../shared';

type Props = {
  form: FormInstance;
};
const PackageSearchFormOptions: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const { labelWithRules } = useAntdValidation(props.form);

  return (
    <>
      <Form.Item
        className={'mb-4'}
        name="nationalityCode"
        {...labelWithRules({
          label: t(Translations.Common.Nationality),
          rules: [{ type: 'Min', arguments: [2] }, { type: 'EnglishLetterAndWhitespaceOnly' }],
        })}>
        <Select showSearch optionFilterProp="children" allowClear>
          {Countries.map((country) => (
            <Select.Option key={country.code} value={country.code}>
              {country.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
});

export default PackageSearchFormOptions;
