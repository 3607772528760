import { Checkbox, Col, Rate, Row } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import range from 'lodash/range';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../features/hooks';
import { Translations } from '../../../../../../features/localization';
import { useBookingTempFilters } from '../../../../../booking';
import { FilterItemTitle } from '../../../../../shared';
import { getHotelRatingCount } from '../../../../helpers/HotelHelpers';

type Props = {
  isPackage: boolean;
};
const HotelsFilterRating: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const { filter } = useBookingTempFilters({ moduleType: props.isPackage ? 'Package' : 'Hotel' });

  const hotels = useAppSelector((state) => state.package.temp.packages.response?.hotels);
  const filteredRatings = useAppSelector((state) => (props.isPackage ? state.package.temp.packages.processing.filters.hotels.ratings : undefined));

  const handleResetClicked = () => {
    filter({ type: 'HotelRating', value: undefined });
  };

  const handleChanged = (checkedValue: CheckboxValueType[]) => {
    filter({ type: 'HotelRating', value: checkedValue as number[] });
  };

  return (
    <>
      <FilterItemTitle
        title={t(Translations.Hotel.Rating)}
        resetButtonVisibility={filteredRatings && filteredRatings.length > 0}
        onResetClick={handleResetClicked}
      />
      <Checkbox.Group className="w-100" value={filteredRatings} onChange={handleChanged}>
        {range(0, 6).map((item) => {
          const hotelsRatingCount = getHotelRatingCount(hotels, item);

          return (
            <Row key={item} justify="space-between" className="mb-2">
              <Col>
                <Checkbox value={item} disabled={hotelsRatingCount === 0}>
                  {item === 0 ? t(Translations.Hotel.Unrated) : <Rate disabled defaultValue={item} />}
                </Checkbox>
              </Col>
              <Col>
                <span className="font-weight-bold">({hotelsRatingCount})</span>
              </Col>
            </Row>
          );
        })}
      </Checkbox.Group>
    </>
  );
});

export default HotelsFilterRating;
