import get from 'lodash/get';
/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { profileSelector } from '../../../../modules/account';
import { DepositBalances } from '../../../../modules/shared';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { UserProfileDropdown } from './dropdowns/UserProfileDropdown';

export function QuickUserToggler() {
  const { t } = useTranslation();

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: get(uiService.config, 'extras.user.layout') === 'offcanvas',
    };
  }, [uiService]);

  const profile = useAppSelector(profileSelector);

  return (
    <>
      {layoutProps.offcanvas && (
        <div id="kt_quick_user_toggle">
          <div className="topbar-item">
            <div className={'text-right px-3'}>
              <OverlayTrigger placement="bottom" overlay={<Tooltip id="quick-user-tooltip">{t(Translations.Tooltips.ViewUser)}</Tooltip>}>
                <div className={'header-user-name'}>
                  <span className="text-muted font-weight-bold font-size-base  mr-1">
                    {t(Translations.Common.Hi)}
                    {t(Translations.Common.Comma)}
                  </span>
                  <span className="text-dark-50 font-weight-bolder font-size-base">{profile.firstName}</span>
                </div>
              </OverlayTrigger>
              <DepositBalances />
            </div>
          </div>
          <OverlayTrigger placement="bottom" overlay={<Tooltip id="quick-user-tooltip">{t(Translations.Tooltips.ViewUser)}</Tooltip>}>
            <div className="topbar-item">
              <span className="symbol symbol-35 symbol-light-success">
                <span className="symbol-label font-size-h5 font-weight-bold">{profile.firstName.toUpperCase().charAt(0)}</span>
              </span>
            </div>
          </OverlayTrigger>
        </div>
      )}

      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  );
}
