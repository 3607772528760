import { AxiosInstanceData, AxiosInstanceData2 } from '../../types';
import { LocalizationHelpers, getApiUrlPart, getStorage } from '../../utils/helpers';
import { LocalStorageKey } from '../constants';
import {
  requestSetAuthTokenInterceptors,
  requestSetCancelTokenInterceptors,
  responseErrorNullifyCancelTokenInterceptors,
  responseSuccessNullifyCancelTokenInterceptors,
} from './configAxios';

export const setupAxiosInstances = (axiosInstancesDataList: AxiosInstanceData[], axiosInstanceData2List: AxiosInstanceData2[]) => {
  const language = getStorage(LocalStorageKey.Culture);

  const axiosInstanceDataCommonSetup = (item: AxiosInstanceData | AxiosInstanceData2) => {
    item.instance.interceptors.request.use(requestSetAuthTokenInterceptors);
    item.instance.interceptors.request.use(requestSetCancelTokenInterceptors);
    item.instance.interceptors.request.use((config) => {
      if (config.url) {
        const urlKey = getApiUrlPart(config.url);
        //@ts-ignore
        config.apiDataItem = item.DataMap.MapObject.get(urlKey);
      }
      return config;
    });
    item.instance.interceptors.response.use(responseSuccessNullifyCancelTokenInterceptors, responseErrorNullifyCancelTokenInterceptors);
    item.instance.defaults.headers.common['Accept-Language'] = language ?? LocalizationHelpers.defaultCulture.Name;
  };

  //AxiosInstanceData type
  for (const item of axiosInstancesDataList) {
    axiosInstanceDataCommonSetup(item);

    //fill api data map
    for (const key of Object.values(item.DataMap.Urls)) {
      item.DataMap.MapObject.set(key, {});
    }
  }

  //AxiosInstanceData2 type
  for (const item of axiosInstanceData2List) {
    axiosInstanceDataCommonSetup(item);

    //fill api data map
    for (const controller of Object.values(item.DataMap.Urls)) {
      for (const apiUrl of Object.values(controller)) {
        item.DataMap.MapObject.set(apiUrl, {});
      }
    }
  }
};
