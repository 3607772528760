import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGlobalization } from '../../../../../../features/hooks';
import { Translations } from '../../../../../../features/localization';
import { useBookingTempFilters } from '../../../../../booking';
import { FilterItemTitle } from '../../../../../shared';
import classes from '../FlightFilter.module.scss';

type Props = {
  isPackage?: boolean;
  type: 'DepartureTime' | 'ArrivalTime';
  origin?: string;
  destination?: string;
};
const FlightFilterDepartureArrivalCheckbox: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const { reversePositionOnRtl } = useGlobalization();

  const { filter } = useBookingTempFilters({ moduleType: props.isPackage ? 'Package' : 'Flight' });

  const handleChanged = (checkedValue: CheckboxValueType[]) => {
    const values = checkedValue as unknown as [number, number][];
    filter({ type: props.type === 'DepartureTime' ? 'DepartureTimeCheckbox' : 'ArrivalTimeCheckbox', value: values });
  };

  return (
    <>
      <FilterItemTitle
        title={
          props.type === 'DepartureTime'
            ? `${t(Translations.Flight.DepartureTime)} ${t(Translations.Common.From)} ${props.origin}`
            : `${t(Translations.Flight.ArrivalTime)} ${t(Translations.Common.To2)} ${props.destination}`
        }
        resetButtonVisibility={false}
      />
      <Checkbox.Group className="w-100" onChange={handleChanged}>
        <Checkbox value={[0, 6]} className={classes.airportFilterItem + ' ' + reversePositionOnRtl('left') + '-aligned'}>
          <div className="pt-1">00:00 {t(Translations.Common.To).toLowerCase()} 05:59</div>
        </Checkbox>
        <Checkbox value={[6, 12]} className={classes.airportFilterItem + ' ' + reversePositionOnRtl('left') + '-aligned'}>
          <div className="pt-1">06:00 {t(Translations.Common.To).toLowerCase()} 11:59</div>
        </Checkbox>
        <Checkbox value={[12, 18]} className={classes.airportFilterItem + ' ' + reversePositionOnRtl('left') + '-aligned'}>
          <div className="pt-1">12:00 {t(Translations.Common.To).toLowerCase()} 17:59</div>
        </Checkbox>
        <Checkbox value={[18, 24]} className={classes.airportFilterItem + ' ' + reversePositionOnRtl('left') + '-aligned'}>
          <div className="pt-1">18:00 {t(Translations.Common.To).toLowerCase()} 23:59</div>
        </Checkbox>
      </Checkbox.Group>
    </>
  );
});

export default FlightFilterDepartureArrivalCheckbox;
