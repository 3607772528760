import { PresetStatusColorType } from 'antd/es/_util/colors';
import { PresetColorType } from 'antd/lib/_util/colors';

import { LocalStorageKey } from '../../../features/constants';
import { addOrSetArrayStorage, getNowISO } from '../../../utils/helpers';
import { DomesticHotelReserveStatus } from '../../domesticHotel/apiTypes';
import { FlightsQueryString } from '../../flight/types';
import { HotelsQueryString } from '../../hotel/types';
import { PackagesQueryString } from '../../package/types';
import { ReserveStatus } from '../apiTypes';
import { BookingModuleType, RecentSearch } from '../types';

export const reserveStatuses = [
  'Undefined',
  'Registered',
  'Pending',
  'Issued',
  'Canceled',
  'WebServiceCancel',
  'PaymentSuccessful',
  'WebServiceUnsuccessful',
  'PriceChange',
  'Unavailable',
  'Refunded',
  'Voided',
  'PaidBack',
  'RefundInProgress',
  'Changed',
  'OnCredit',
  'ContactProvider',
  'UnConfirmed',
];

export const filterableReserveStatuses = [
  'Pending',
  'Issued',
  'Canceled',
  'WebServiceCancel',
  'PaymentSuccessful',
  'WebServiceUnsuccessful',
  'PriceChange',
  'Refunded',
  'Voided',
  'PaidBack',
  'RefundInProgress',
  'Changed',
  'OnCredit',
  'ContactProvider',
  'UnConfirmed',
];

export const addToRecentSearches = (obj: HotelsQueryString | FlightsQueryString | PackagesQueryString, type: BookingModuleType) => {
  addOrSetArrayStorage(LocalStorageKey.RecentSearches, { search: obj, date: getNowISO(), type: type } as RecentSearch, 30);
};

export const reserveStatusTagColor = (status: ReserveStatus | DomesticHotelReserveStatus): PresetColorType | undefined => {
  switch (status) {
    case 'Pending':
      return 'blue';
    case 'Issued':
      return 'green';
    case 'PaymentSuccessful':
      return 'gold';
    case 'PriceChange':
    case 'Undefined':
    case 'Unavailable':
    case 'WebServiceUnsuccessful':
      return 'red';
    case 'UnConfirmed':
    case 'InProgress':
      return 'yellow';
    case 'Registered':
    case 'Refunded':
      return 'purple';
  }
  return undefined;
};

export const reserveStatusBadgeType = (status: ReserveStatus | DomesticHotelReserveStatus): PresetStatusColorType | undefined => {
  switch (status) {
    case 'PaymentSuccessful':
    case 'Pending':
    case 'InProgress':
      return 'processing';
    case 'Refunded':
    case 'Registered':
      return 'warning';
    case 'Canceled':
      return 'default';
    case 'Issued':
      return 'success';
    case 'Undefined':
    case 'WebServiceCancel':
    case 'WebServiceUnsuccessful':
    case 'PriceChange':
    case 'Unavailable':
    case 'Voided':
      return 'error';
  }
  return undefined;
};

export const showSuppliersEnabled = () => {
  return process.env.REACT_APP_ShowSuppliers === 'true';
};
