import { SearchOutlined } from '@ant-design/icons';
import { Col, Form, Row, Select } from 'antd';
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { useHttpCall } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { fromJson, fromQueryString, momentToISOUtcDate, toJson, toQueryString } from '../../../../../utils/helpers';
import { FormItemActions } from '../../../../shared';
import { FlightFareType, GetAirlineResponse } from '../../../apiTypes';
import { getAirlineHttp } from '../../../http/flight-http';
import { FareQuoteFormValues, FlightFareQuoteQueryString } from '../../../types';
import FareQuoteOriginDestination from '../FareQuoteOriginDestination/FareQuoteOriginDestination';

type Props = {
  inline?: boolean;
  fillFromQueryString?: boolean;
};
const FareQuoteSearchForm: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryStringObj = useMemo(() => {
    return fromQueryString<FlightFareQuoteQueryString>(location.search, 'bracket');
  }, [location.search]);
  const defaultAirline =
    queryStringObj.airlineText && queryStringObj.airlineCode ? { Code: queryStringObj.airlineCode, Name: queryStringObj.airlineText } : undefined;

  const [form] = Form.useForm<FareQuoteFormValues>();

  const [airlines, setAirlines] = useState<GetAirlineResponse>();
  const [hasReturn, setHasReturn] = useState<boolean>(false);

  const getAirlineApi = useHttpCall(getAirlineHttp);

  useEffect(() => {
    if (props.fillFromQueryString && queryStringObj) {
      const departureDate = moment(queryStringObj.departureDate).utc(true);
      const returnDate = queryStringObj.returnDate ? moment(queryStringObj.returnDate).utc(true) : undefined;
      if (returnDate?.isValid()) {
        setHasReturn(true);
      }

      form.setFieldsValue({
        departureDate,
        returnDate,
        originCode: { Code: queryStringObj.originCode, Name: queryStringObj.originText },
        destinationCode: { Code: queryStringObj.destinationCode, Name: queryStringObj.destinationText },
        airline: queryStringObj.airlineCode ? toJson({ Code: queryStringObj.airlineCode, Name: queryStringObj.airlineText }, true)! : undefined,
        fareType: queryStringObj.fareType as FlightFareType,
        cabinClass: queryStringObj.cabin,
      });
    }
  }, [form, props.fillFromQueryString, queryStringObj]);

  const debouncedGetAirline = debounce(async (e) => {
    const response = await getAirlineApi.call<GetAirlineResponse>({ value: e });
    if (response && response.success) {
      setAirlines(response.result!.length > 0 ? response.result : []);
    }
  });

  const handleAirlineInputChanged = async (value: string) => {
    if (value.length > 1) {
      await debouncedGetAirline(value);
    }
  };

  const handleChangeHasReturn = useCallback(
    (hasReturn: boolean) => {
      if (!hasReturn) {
        form.setFieldsValue({
          returnDate: undefined,
        });
      }
      setHasReturn(hasReturn);
    },
    [form]
  );

  const fareTypes = ['Publish', 'Ethnic', 'Package'];

  const handleFormSubmit = (data: FareQuoteFormValues) => {
    const airline = fromJson(data.airline) as GetAirlineResponse[0];
    setAirlines(undefined);

    const queryStringObj = {
      originCode: data.originCode.Code,
      originText: data.originCode.Name,

      destinationCode: data.destinationCode.Code,
      destinationText: data.destinationCode.Name,

      departureDate: momentToISOUtcDate(data.departureDate),
      returnDate: data.returnDate ? momentToISOUtcDate(data.returnDate) : undefined,

      fareType: data.fareType,

      airlineCode: airline?.Code,
      airlineText: airline?.Name,
      cabin: data.cabinClass,
    } as FlightFareQuoteQueryString;
    const queryString = toQueryString(queryStringObj);

    history.push(`/flights/fare/quotes?${queryString}`);
  };

  return (
    <Form labelCol={{ span: 24 }} form={form} onFinish={handleFormSubmit} className={'flight-search-form'}>
      <Row gutter={[10, 0]}>
        <Col xs={24} xl={props.inline ? (hasReturn ? 24 : 15) : 24}>
          <FareQuoteOriginDestination inline={props.inline} formInstance={form} onChangeReturn={handleChangeHasReturn} hasReturn={hasReturn} />
        </Col>
        <Col xs={24} md={props.inline ? 8 : 24} xl={props.inline ? (hasReturn ? 6 : 2) : 24}>
          <Form.Item className="mb-4" name="fareType" label={t(Translations.Flight.FareType)} initialValue="Publish">
            <Select>
              {fareTypes.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={props.inline ? 8 : 24} xl={props.inline ? (hasReturn ? 6 : 3) : 24}>
          <Form.Item className="mb-4" name="airline" label={t(Translations.Flight.Airline)}>
            <Select onSearch={handleAirlineInputChanged} showSearch filterOption={false} loading={getAirlineApi.pending} allowClear>
              {airlines
                ? airlines.map((item) => (
                    <Select.Option key={item.Id} value={toJson(item)!}>
                      {item.Name}
                    </Select.Option>
                  ))
                : defaultAirline && <Select.Option value={toJson(defaultAirline, true)!}>{defaultAirline.Name}</Select.Option>}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={props.inline ? 6 : 12}>
          <Form.Item className="mb-4" name="cabinClass" label={t(Translations.Flight.CabinClass)} initialValue="All">
            <Select>
              <Select.Option value="All">{t(Translations.Common.All)}</Select.Option>
              <Select.Option value="Economy">{t(Translations.Flight.Economy)}</Select.Option>
              <Select.Option value="Premium">{t(Translations.Flight.Premium)}</Select.Option>
              <Select.Option value="Business">{t(Translations.Flight.Business)}</Select.Option>
              <Select.Option value="Firstclass">{t(Translations.Flight.FirstClass)}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={props.inline ? 8 : 24} xl={props.inline ? 4 : 24}>
          <div className={props.inline ? 'align-with-formItems-md align-with-formItems-lg align-with-formItems-xl text-md-right' : ''}>
            <FormItemActions
              formInstance={form}
              submitIcon={<SearchOutlined />}
              submitText={t(Translations.Common.Search)}
              submitPending={false}
              submitPendingIconOnly
            />
          </div>
        </Col>
      </Row>
    </Form>
  );
});

export default FareQuoteSearchForm;
