import Axios from 'axios';

import { AxiosInstanceData } from '../../../types';
import { CoordinatorApiDataMap, CoordinatorApiUrls } from './coordinator-apiData';

const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_CoordinatorApiBaseUrl;

export const getAffiliateHttp = () => {
  return axiosInstance.get(CoordinatorApiUrls.GetAffiliate);
};

// export const getAllTenantOrdersHttp = (data: GetAllTenantOrdersRequest) => {
//   const url = urlWithQuery(CoordinatorApiUrls.GetAllTenantOrders, data);
//   return axiosInstance.get(url);
// };

export const CoordinatorAxiosData: AxiosInstanceData = {
  instance: axiosInstance,
  DataMap: {
    MapObject: CoordinatorApiDataMap,
    Urls: CoordinatorApiUrls,
  },
};
