import React from 'react';

import { DefinedRoute } from '../../types';

const prefix = '/ticketing';

export const TicketingPaths = {
  Tickets: prefix,
  TicketCreate: `${prefix}/create`,
  TicketDetails: `${prefix}/details`,
};

const TicketingRoutes = (): readonly DefinedRoute[] => [
  {
    type: 'Route',
    path: TicketingPaths.Tickets,
    exact: true,
    children: React.lazy(() => import('./pages/Tickets/Tickets')),
  },
  {
    type: 'Route',
    path: TicketingPaths.TicketCreate,
    exact: true,
    children: React.lazy(() => import('./pages/TicketCreate/TicketCreate')),
  },
  {
    type: 'Route',
    path: TicketingPaths.TicketDetails,
    exact: true,
    children: React.lazy(() => import('./pages/TicketDetails/TicketDetails')),
  },
];

export default TicketingRoutes;
