import { Checkbox, Col, Radio, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../features/hooks';
import { Translations } from '../../../../../../features/localization';
import { useBookingTempFilters } from '../../../../../booking';
import { tempPackageFlightsSelector } from '../../../../../package/redux/package-selectors';
import { FilterItemTitle } from '../../../../../shared';
import { tempItinerariesSummarySelector } from '../../../../redux/flight-selectors';
import classes from '../FlightFilter.module.scss';

type Props = {
  isPackage?: boolean;
};
const FlightsFilterStop: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const { filter } = useBookingTempFilters({ moduleType: props.isPackage ? 'Package' : 'Flight' });

  const stop = useAppSelector((state) =>
    props.isPackage ? state.package.temp.packages.processing.filters.flights.stop : state.flight.temp.flights.filters.stop
  );
  const itineraries = useAppSelector(props.isPackage ? tempPackageFlightsSelector : tempItinerariesSummarySelector);

  const counts = useMemo(() => {
    if (itineraries) {
      return [itineraries.filter((f) => f.stop === 0).length, itineraries.filter((f) => f.stop === 1).length, itineraries.length];
    }
  }, [itineraries]);

  const filterItemResetClicked = useCallback(() => {
    filter({ type: 'Stop', value: 100 });
  }, [filter]);

  const handleFilterChanged = (e: RadioChangeEvent | CheckboxChangeEvent) => {
    if (props.isPackage) {
      filter({ type: 'Stop', value: e.target.checked ? 0 : 100 });
    } else {
      filter({ type: 'Stop', value: +e.target.value });
    }
  };

  return (
    <>
      <FilterItemTitle title={t(Translations.Flight.Stop)} resetButtonVisibility={stop !== undefined && stop !== 100} onResetClick={filterItemResetClicked} />
      {props.isPackage ? (
        <Checkbox onChange={handleFilterChanged}>{t(Translations.Flight.DirectOnly)}</Checkbox>
      ) : (
        <Radio.Group onChange={handleFilterChanged} value={stop ?? 100} className="w-100">
          <Radio value={0} className={`${classes.spacedBetween} mb-2`} disabled={counts?.[0] === 0}>
            <Row justify="space-between" align="middle">
              <Col>{t(Translations.Flight.DirectOnly)}</Col>
              <Col>({counts?.[0]})</Col>
            </Row>
          </Radio>
          <Radio value={1} className={`${classes.spacedBetween} mb-2`} disabled={counts?.[1] === 0}>
            <Row justify="space-between" align="middle">
              <Col>{t(Translations.Flight.OneStopAndLess)}</Col>
              <Col>({counts?.[1]})</Col>
            </Row>
          </Radio>
          <Radio value={100} className={`${classes.spacedBetween}`} disabled={counts?.[2] === 0}>
            <Row justify="space-between" align="middle">
              <Col>{t(Translations.Flight.MultiStopAndLess)} </Col>
              <Col>({[counts?.[2]]})</Col>
            </Row>
          </Radio>
        </Radio.Group>
      )}
    </>
  );
});

export default FlightsFilterStop;
