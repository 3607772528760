import { Col, Row } from 'antd';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import B2BIcon from '../../../../assets/B2B.svg';
import B2CIcon from '../../../../assets/B2C.svg';
import FlightIcon from '../../../../assets/flight.svg';
import HotelIcon from '../../../../assets/hotel.svg';
import PackageIcon from '../../../../assets/package.svg';
import FlightIconBlack from '../../../../assets/pro-traveling/flight.svg';
import HotelIconBlack from '../../../../assets/pro-traveling/hotel.svg';
import PackageIconBlack from '../../../../assets/pro-traveling/package.svg';
import WebApiIcon from '../../../../assets/web-api.svg';
import WhiteLabelIcon from '../../../../assets/white-label.svg';
import { useAppSelector } from '../../../../features/hooks';
import AuthPageFooter from '../../components/AuthPageFooter/AuthPageFooter';
import AuthPageHeader from '../../components/AuthPageHeader/AuthPageHeader';
import ForgotPassword from '../../components/ForgotPassword';
import Login from '../../components/Login';
import classes from './AuthPageProTraveling.module.scss';

const AuthPageProTraveling: React.VFC = () => {
  const isAuthenticated = useAppSelector((state) => state.authentication.isAuthenticated);
  const { path } = useRouteMatch();

  return (
    <>
      {isAuthenticated ? (
        <Redirect to="/" />
      ) : (
        <>
          <AuthPageHeader />
          <div className="pro-traveling-auth">
            <div className={classes.topBanner}>
              <div className="page-container pro-traveling-auth">
                <div className={classes.sidesWrapper}>
                  <div className={classes.formHolder}>
                    <div className={'d-none'}>
                      <Switch>
                        <Route path={`${path}/login`}>
                          <Login />
                        </Route>
                        <Route path={`${path}/forgot-password`}>
                          <ForgotPassword />
                        </Route>
                        <Redirect exact from={`${path}`} to={`${path}/login`} />
                        <Redirect to={`${path}/login`} />
                      </Switch>
                    </div>
                    <Login />
                  </div>
                  <div className="d-flex align-items-center flex-auto">
                    <div className={classes.bannerText}>
                      <h2 className={classes.welcomeTitle}>
                        Welcome to <span className={classes.orange}> PRO Traveling </span>
                      </h2>
                      <p className={classes.slogan}>THE BEST AND FASTEST WAY TO BOOK YOUR NEXT TRIP</p>
                    </div>
                    <div className={classes.hiddenMobile}>
                      <div className={classes.bannerIconItem}>
                        <img src={FlightIconBlack} alt={'flight'} />
                        <div>Flight</div>
                      </div>
                      <div className={classes.bannerIconItem}>
                        <img src={HotelIconBlack} alt={'hotel'} />
                        <div>Hotel</div>
                      </div>
                      <div className={classes.bannerIconItem}>
                        <img src={PackageIconBlack} alt={'package'} />
                        <div>Dynamic Package</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.servicesSection}>
              <div className="page-container">
                <h2 className={classes.servicesTitle}>WE COVER ALL TRAVEL SERVICES</h2>
                <p className={classes.slogan}>Our services are available for both leisure and corporate travel.</p>
                <br />
                <br />
                <Row align={'middle'} justify={'center'} gutter={[20, 20]} className={'mt-10'}>
                  <Col>
                    <div className={classes.serviceItemHolder}>
                      <img src={FlightIcon} className={classes.icon} alt="Flight" />
                      <h3 className={'title-xs mb-0 mt-2 px-2'}>Flight</h3>
                    </div>
                  </Col>
                  <Col>
                    <div className={classes.serviceItemHolder}>
                      <img src={HotelIcon} className={classes.icon} alt="Hotel" />
                      <h3 className={'title-xs mb-0 mt-2 px-2'}>Hotel</h3>
                    </div>
                  </Col>
                  <Col>
                    <div className={classes.serviceItemHolder}>
                      <img src={PackageIcon} className={classes.icon} alt="Package" />
                      <h3 className={'title-xs mb-0 mt-2 px-2'}>Package</h3>
                    </div>
                  </Col>
                  <Col>
                    <div className={classes.serviceItemHolder}>
                      <img src={B2BIcon} className={classes.icon} alt="B2B Selling Platform" />
                      <h3 className={'title-xs mb-0 mt-2 px-2'}>B2B Selling Platform</h3>
                    </div>
                  </Col>
                  <Col>
                    <div className={classes.serviceItemHolder}>
                      <img src={B2CIcon} className={classes.icon} alt="B2C Booking System" />
                      <h3 className={'title-xs mb-0 mt-2 px-2'}>B2C Booking System</h3>
                    </div>
                  </Col>
                  <Col>
                    <div className={classes.serviceItemHolder}>
                      <img src={WhiteLabelIcon} className={classes.icon} alt="White Label" />
                      <h3 className={'title-xs mb-0 mt-2 px-2'}>White Label</h3>
                    </div>
                  </Col>
                  <Col>
                    <div className={classes.serviceItemHolder}>
                      <img src={WebApiIcon} className={classes.icon} alt="API" />
                      <h3 className={'title-xs mb-0 mt-2 px-2'}>API</h3>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <AuthPageFooter />
          </div>
        </>
      )}
    </>
  );
};

export default AuthPageProTraveling;
