import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Button, Col, Modal, ModalProps, Row, Skeleton } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider, { Settings } from 'react-slick';

import { useHttpCall } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { FormattedCurrency } from '../../../shared';
import { FlightGetAlternativesRs } from '../../apiTypes';
import { flightGetAlternativesHttp } from '../../http/flight-http';
import { FlightItinerarySummary } from '../../types';
import classes from './FlightBookAlternativeModal.module.scss';

type FlightBookAlternativeModalProps = {
  itinerary: FlightItinerarySummary;
  modalProps: ModalProps;
  onCancel: () => void;
  onContinue: (flightKey: string) => void;
};
const FlightBookAlternativeModal: React.FC<FlightBookAlternativeModalProps> = (props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string>(props.itinerary.key!);

  const { call, pending, response } = useHttpCall(flightGetAlternativesHttp);

  useEffect(() => {
    if (props.modalProps.visible) {
      void call<FlightGetAlternativesRs>({ key: props.itinerary.key! });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.itinerary.key, props.modalProps.visible]);

  const alternatives = useMemo(() => {
    const result = response?.result as FlightGetAlternativesRs;
    if (result) {
      return [result.find((f) => f.key === props.itinerary.key)!, ...(result as FlightGetAlternativesRs).filter((f) => f.key !== props.itinerary.key)];
    }
  }, [props.itinerary.key, response?.result]);

  function handleSelectItem(flightKey: string) {
    setSelected(flightKey);
  }

  const settings: Settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Modal title="Flight Alternatives" {...props.modalProps} onCancel={props.onCancel} centered footer={null} closable width={1000}>
      {pending ? (
        <Row justify="space-between">
          <Col md={6}>
            <Skeleton active />
          </Col>
          <Col md={6}>
            <Skeleton active />
          </Col>
          <Col md={6}>
            <Skeleton active />
          </Col>
        </Row>
      ) : (
        <>
          <div className="flight-alternative-carousel">
            <Slider {...settings}>
              {alternatives?.map((item) => (
                <div className="px-3 py-4" key={item.key}>
                  <div className={`box-shadow ${classes.card} ${selected === item.key ? classes.active : ''}`}>
                    <h5 className={classes.title}>{item.brandName}</h5>
                    <ul className="list-style-check">
                      {item.information.map((infoItem) => (
                        <li key={infoItem} className={classes.listItem}>
                          {infoItem}
                        </li>
                      ))}
                    </ul>
                    <footer className="pt-4">
                      <FormattedCurrency className="boldFormattedCurrency" number={item.totalFare} currencyDisplay="code" />
                      <Button block type={selected === item.key ? 'primary' : 'default'} onClick={() => handleSelectItem(item.key)}>
                        {selected === item.key ? t(Translations.Common.Selected) : t(Translations.Common.Select)}
                      </Button>
                    </footer>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="text-center pt-2">
            <Button type="primary" danger className="mt-2 min-width-md" onClick={() => props.onContinue(selected!)} disabled={!selected}>
              Continue
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default React.memo(FlightBookAlternativeModal);
