import Axios from 'axios';

import { AxiosInstanceData } from '../../../types';
import { isDevelopment, urlWithQuery } from '../../../utils/helpers';
import {
  FlightCancelPNRRequest,
  FlightFareQuoteRq,
  FlightFlexCalendarRq,
  FlightGetAllTenantReservesRequest,
  FlightGetCalendarRouteRq,
  FlightPricePNRRequest,
  FlightReservePNRRequest,
  LowFareSearchRequest,
} from '../apiTypes';
import { FlightApiDataMap, FlightApiUrls } from './flight-apiData';

const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_FlightApiBaseUrl;

if (isDevelopment()) {
  // const mock = new MockAdapter(axiosInstance, { onNoMatch: 'passthrough', delayResponse: 0 });
  // flightMock(mock);
}

//BookingFlight

export const getAirportHttp = (data: { value: string }) => {
  const url = urlWithQuery(FlightApiUrls.GetAirport, data);
  return axiosInstance.get(url);
};

export const getAirlineHttp = (data: { value: string }) => {
  const url = urlWithQuery(FlightApiUrls.GetAirline, data);
  return axiosInstance.get(url);
};

export const lowFareSearchHttp = (data: LowFareSearchRequest) => {
  data.airlineCode = data.airlineCode ?? 'all';
  return axiosInstance.post(FlightApiUrls.LowFareSearch, data);
};

export const getFlightRulesHttp = (data: { key: string }) => {
  const url = urlWithQuery(FlightApiUrls.GetFlightRules, data);
  return axiosInstance.get(url);
};

export const flightGetSuppliersHttp = (data: { hasTicketing: boolean }) => {
  const url = urlWithQuery(FlightApiUrls.GetFlightSuppliers, data);
  return axiosInstance.get(url);
};

export const flightValidateHttp = (data: { key: string }) => {
  const url = urlWithQuery(FlightApiUrls.Validate, data);
  return axiosInstance.post(url);
};

export const reservePNRHttp = (data: FlightReservePNRRequest) => {
  return axiosInstance.post(FlightApiUrls.ReservePNR, data);
};

export const getFlightReserveByIdHttp = (data: { reserveId: string }) => {
  const url = urlWithQuery(FlightApiUrls.GetFlightReserveById, data);
  return axiosInstance.get(url);
};

export const getDirectTicketByIdHttp = (data: { reserveId: number }) => {
  const url = urlWithQuery(FlightApiUrls.GetDirectTicketById, data);
  return axiosInstance.get(url);
};

export const cancelPNRHttp = (data: FlightCancelPNRRequest) => {
  return axiosInstance.post(FlightApiUrls.CancelPNR, data);
};

export const getPNRDetailsHttp = (data: { PNRCode: string; SupplierId: string }) => {
  const url = urlWithQuery(FlightApiUrls.GetPNRDetails, data);
  return axiosInstance.get(url);
};

export const pricePNRHttp = (data: FlightPricePNRRequest) => {
  return axiosInstance.post(FlightApiUrls.PricePNR, data);
};

export const issuePNRHttp = (data: { reserveId: number }) => {
  const url = urlWithQuery(FlightApiUrls.IssuePNR, data);
  return axiosInstance.post(url);
};

export const getFlightCancellationPoliciesHttp = (data: { ReserveId: number; Username: string }) => {
  const url = urlWithQuery(FlightApiUrls.GetCancellationPolicies, data);
  return axiosInstance.get(url);
};

export const flightGetCalendarRouteHttp = (data: FlightGetCalendarRouteRq) => {
  const url = urlWithQuery(FlightApiUrls.GetCalendarRoute, data);
  return axiosInstance.get(url);
};

export const flightSabreCalendarRouteHttp = (data: FlightFlexCalendarRq) => {
  return axiosInstance.post(FlightApiUrls.SabreCalendarRoute, data);
};

//TenantReserve

export const getAllTenantReservesFlightHttp = (data: FlightGetAllTenantReservesRequest) => {
  const url = urlWithQuery(FlightApiUrls.GetAllTenantReserves, data);
  return axiosInstance.get(url);
};

export const flightGetServerTime = () => {
  return axiosInstance.get(FlightApiUrls.GetTime);
};

// Fare

export const flightFareQuote = (data: FlightFareQuoteRq) => {
  return axiosInstance.post(FlightApiUrls.FareQuote, data);
};

export const flightGetFarePenalties = (data: { key: string }) => {
  const url = urlWithQuery(FlightApiUrls.FarePenalties, data);
  return axiosInstance.get(url);
};

export const flightGetFareRules = (data: { key: string }) => {
  const url = urlWithQuery(FlightApiUrls.FareRules, data);
  return axiosInstance.get(url);
};

export const flightGetFareRoutes = (data: { key: string }) => {
  const url = urlWithQuery(FlightApiUrls.FareRoutes, data);
  return axiosInstance.get(url);
};

export const flightDownloadFareQuoteExcel = (data: { key: string }) => {
  const url = urlWithQuery(FlightApiUrls.DownloadFareQuoteExcel, data);
  return axiosInstance.get(url, { responseType: 'blob' });
};

export function flightGetAlternativesHttp(data: { key: string }) {
  const url = urlWithQuery(FlightApiUrls.GetAlternatives, data);
  return axiosInstance.get(url);
}

export const FlightAxiosData: AxiosInstanceData = {
  instance: axiosInstance,
  DataMap: {
    MapObject: FlightApiDataMap,
    Urls: FlightApiUrls,
  },
};
