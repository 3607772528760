import { MinusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Select } from 'antd';
import range from 'lodash/range';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Translations } from '../../../../../features/localization';

type Props = {
  showRemoveButton: boolean;
  onRemoveRoom: () => void;
  allRoomQty: number;
  roomNumber: number;
  inline?: boolean;
  allChildrenQty: number;
  onChildQtyChanged: (qty: number) => void;
  defaultChildrenQty: number;
  resetTime: number | undefined;
};

const HotelSearchFormRoom: React.VFC<Props> = (props) => {
  const { t } = useTranslation();

  const [childQty, setChildQty] = useState(props.defaultChildrenQty);

  const handleChildrenChanged = (value: number) => {
    props.onChildQtyChanged(value - childQty);
    setChildQty(value);
  };

  const showAgeOfChildrenLabel = !(props.allRoomQty === 1 && childQty === 0);

  useEffect(() => {
    if (props.resetTime) {
      setChildQty(0);
    }
  }, [props.resetTime]);

  return (
    <Row gutter={[10, 0]} className="nowrap-flex" align={'bottom'}>
      <Col className="small-width-col">
        <Form.Item className="mb-4" name={['adults', props.roomNumber]} label={props.roomNumber === 0 && t(Translations.Common.Adult)} initialValue={2}>
          <Select>
            {range(1, 9).map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col className="small-width-col">
        <Form.Item className="mb-4" name={['children', props.roomNumber]} label={props.roomNumber === 0 && t(Translations.Common.Child)} initialValue={0}>
          <Select onChange={handleChildrenChanged}>
            {range(0, 7).map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      {props.allChildrenQty > 0 && (
        <Col>
          {props.roomNumber === 0 && showAgeOfChildrenLabel && (
            <div className="ant-col ant-col-24 ant-form-item-label">
              <label htmlFor="childAge-0-0">{t(Translations.Common.AgeOfChildren)}</label>
            </div>
          )}
          {childQty > 0 ? (
            <Row gutter={[0, 0]} align={'bottom'} className="no-wrap-flex-sm">
              {range(childQty).map((item) => (
                <Col key={item}>
                  <Form.Item className="mb-4" name={['childAge', props.roomNumber, item]} initialValue={0}>
                    <Select dropdownMatchSelectWidth={false}>
                      {range(0, 18).map((item) => (
                        <Select.Option key={item} value={item}>
                          {/*{item === 0 ? t(Translations.Booking.Under1) : item}*/}
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ))}
            </Row>
          ) : null}
        </Col>
      )}

      {props.showRemoveButton && (
        <Col>
          <Button danger onClick={props.onRemoveRoom} className={'mb-4'}>
            <MinusOutlined />
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default HotelSearchFormRoom;
