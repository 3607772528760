import { notification } from 'antd';
import * as yup from 'yup';

export const stringIsNullOrEmpty = (value: string | undefined | null) => {
  return value === undefined || value === null || value.length === 0;
};

export const validateYupObject = (yupObject: yup.ObjectSchema<any>, obj: any) => {
  try {
    return yupObject.validateSync(obj);
  } catch (reason) {
    notification.error({
      message: reason.errors,
    });
    return undefined;
  }
};

export function validateIranianNationalCode(code: string): boolean {
  //"code" should be 10 digits
  if (stringIsNullOrEmpty(code) || !/^\d{10}$/.test(code)) {
    return false;
  }

  //Last digit as control
  const control = +code[9];

  let sum = 0;
  for (let i = 0; i < 9; i++) {
    //Multiply every digit by its value
    sum += +code[i] * (10 - i);
  }

  const remainder = sum % 11;
  if (remainder < 2) {
    return control === remainder;
  } else {
    return control === 11 - remainder;
  }
}
