import { Alert as AntdAlert } from 'antd';
import { AlertProps as AntdAlertProps } from 'antd/lib/alert';
import React from 'react';
import { Alert as BootstrapAlert, AlertProps as BootstrapAlertProps } from 'react-bootstrap';

import { AbpError } from '../../../../types';

type BootstrapProps = {
  framework?: 'Bootstrap';
  alertType?: BootstrapAlertProps['variant'];
  error?: AbpError;
  showCode?: true;
};

type AntdProps = {
  framework?: 'Antd';
  alertType?: AntdAlertProps['type'];
  error?: AbpError;
  showCode?: true;
};
type Props = BootstrapProps | AntdProps;

const AbpErrorAlert: React.VFC<Props> = (props) => {
  if (!props.error) {
    return null;
  }

  let alertType = props.alertType;
  if (!alertType) {
    switch (props.framework) {
      case 'Bootstrap':
        alertType = 'danger';
        break;
      case 'Antd':
        alertType = 'error';
        break;
    }
  }
  let errorMessage: string | React.ReactNode = props.error?.message;
  if (errorMessage) {
    let array = undefined;
    if (props.error.message.includes('\r\n')) {
      array = props.error.message.split('\r\n');
    } else if (props.error.message.includes('\n')) {
      array = props.error.message.split('\n');
    }
    if (array) {
      errorMessage = array.map((item, index) => <p key={index}>{item}</p>);
    }
  }
  const message = (
    <>
      {props.showCode && (
        <>
          Code : {props.error.code}
          <br />
        </>
      )}
      {errorMessage}
      {!props.error.validationErrors && props.error.details && (
        <>
          <br />
          {props.error.details}
        </>
      )}
      {props.error.validationErrors && (
        <>
          <ul>
            {props.error.validationErrors.map((item) => (
              <li key={item.message}>{item.message}</li>
            ))}
          </ul>
        </>
      )}
    </>
  );

  let alert;
  switch (props.framework) {
    case 'Bootstrap':
      alert = (
        <BootstrapAlert className="my-2" variant={alertType as BootstrapAlertProps['variant']}>
          {message}
        </BootstrapAlert>
      );
      break;
    case 'Antd':
      alert = <AntdAlert className="my-2" type={alertType as AntdAlertProps['type']} message={message} />;
      break;
  }
  return <>{alert}</>;
};

AbpErrorAlert.defaultProps = {
  framework: 'Bootstrap',
};

export default AbpErrorAlert;
