import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { flightSlice } from '../../flight';
import { FlightFilterItem, FlightFilterValueTypes } from '../../flight/types';
import { packageSlice } from '../../package';
import { PackageFilterItem, PackageFilterValueTypes } from '../../package/types';
import { BookingModuleType } from '../types';

export const useBookingTempFilters = (options: { moduleType: BookingModuleType }) => {
  const dispatch = useDispatch();

  const filter = useCallback(
    (filter: { type: FlightFilterItem | PackageFilterItem; value: PackageFilterValueTypes }) => {
      switch (options.moduleType) {
        case 'Hotel':
          break;
        case 'Flight':
          dispatch(flightSlice.actions.tempFilter({ type: filter.type as FlightFilterItem, value: filter.value as FlightFilterValueTypes }));
          break;
        case 'Package':
          dispatch(packageSlice.actions.tempFilter({ type: filter.type as PackageFilterItem, value: filter.value as PackageFilterValueTypes }));
          break;
        case 'DomesticHotel':
          break;
        case 'DomesticFlight':
          break;
      }
    },
    [dispatch, options.moduleType]
  );

  return { filter };
};
