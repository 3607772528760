import { CreditCardOutlined, EnvironmentOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Popover, Rate, Row, Skeleton, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import roomSvg from '../../../../../assets/room-default-image.svg';
import useGlobalization from '../../../../../features/hooks/useGlobalization';
import { Translations } from '../../../../../features/localization';
import { FormattedCurrency } from '../../../../shared';
import { HotelBoard, HotelGetCancellationPolicyResponse, HotelsDate } from '../../../apiTypes';
import HotelAgentProfit from '../HotelAgentProfit/HotelAgentProfit';
import HotelCancellationPolicy from '../HotelCancellationPolicy/HotelCancellationPolicy';
import classes from './HotelBookingSummary.module.scss';

type Props = {
  bookingSummary?: {
    currency?: {
      code: string;
    };
    cancellationPolicy: HotelGetCancellationPolicyResponse;
    salePrice: number;
    introPrice: number;
    accommodation: {
      name: string;
      rating: number;
      address: string;
      mainPhoto: string;
      rooms: {
        name: string;
        board: HotelBoard;
        adults: number;
        children: number;
        ages: number[];
      }[];
    };
    date: HotelsDate;
  };
  onContinue?: () => void;
  continueButtonLoading?: boolean;
  mode: 'PreReserve' | 'Payment';
};
const HotelBookingSummary: React.VFC<Props> = React.memo((props) => {
  const { bookingSummary } = props;

  const { t } = useTranslation();
  const { reversePositionOnRtl, formatShortDate } = useGlobalization();

  return !bookingSummary ? (
    <Skeleton active paragraph={{ rows: 15 }} />
  ) : (
    <>
      <div className={classes.hotelSummeryHeader}>
        <div className={classes.hotelImageBlock} style={{ backgroundImage: 'url(' + bookingSummary.accommodation.mainPhoto + ')' }}>
          {/*<img src={bookingSummary.accommodation.mainPhoto} className={classes.hotelImage} alt={t(Translations.Common.Hotel)} />*/}
        </div>
        <div className={`padding-${reversePositionOnRtl('left')}-1 ${classes.hotelNameBlock}`}>
          <h3 className="title-sm mb-2">{bookingSummary.accommodation.name}</h3>
          <Rate className="mb-2" disabled value={bookingSummary.accommodation.rating} />
          <div className="text-muted">
            <EnvironmentOutlined className="align-middle" />
            <span className="align-middle mx-2">{bookingSummary.accommodation.address}</span>
          </div>
        </div>
      </div>
      <Divider />

      <Row justify="space-between" align={'middle'} gutter={[16, 0]} className="mb-1">
        <Col>{t(Translations.Hotel.CheckIn)}: </Col>
        <Col>
          <Tooltip title={t(Translations.Hotel.PropertyLocalTime)}>
            {formatShortDate(bookingSummary.date.checkIn)} <InfoCircleOutlined />
          </Tooltip>
        </Col>
      </Row>
      <Row justify="space-between" align={'middle'} gutter={[16, 0]} className="mb-1">
        <Col>{t(Translations.Hotel.CheckOut)}:</Col>
        <Col>
          <Tooltip title={t(Translations.Hotel.PropertyLocalTime)}>
            {formatShortDate(bookingSummary.date.checkOut)} <InfoCircleOutlined />
          </Tooltip>
        </Col>
      </Row>
      <Row justify="space-between" align={'middle'} gutter={[16, 0]} className="mb-1">
        <Col>{t(Translations.Common.Duration)}:</Col>
        <Col>{t(Translations.Hotel.NightCount, { count: bookingSummary.date.duration })}</Col>
      </Row>
      <Divider />
      <div>
        {bookingSummary.accommodation.rooms.map((room, index) => (
          <div key={index} className={`${classes.roomSummeryHeader}  ${index > 0 ? 'mt-6' : ''}`}>
            <div className={classes.hotelImageBlock} style={{ backgroundImage: 'url(' + roomSvg + ')' }} />
            <div className={`padding-${reversePositionOnRtl('left')}-1 ${classes.hotelNameBlock}`}>
              <h4 className="title-xs mb-2">{room.name}</h4>
              <ul className="bullet-list mb-2">
                <li>{room.board.name}</li>
              </ul>
              <div>
                <span className="align-middle">({t(Translations.Common.AdultCount, { count: room.adults })}, </span>
                <Tooltip title={room.children > 0 ? `${t(Translations.Common.AgeOfChildren)} - ${room.ages.join(',')}` : undefined}>
                  {t(Translations.Common.ChildCount, { count: room.children })}
                  {room.children > 0 && (
                    <span>
                      {' '}
                      <InfoCircleOutlined className="align-middle" />
                    </span>
                  )}
                  )
                </Tooltip>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Divider />
      <Row>
        <Col>
          <HotelCancellationPolicy tagStatus cancellationPolicy={bookingSummary.cancellationPolicy} />
        </Col>
      </Row>
      <Divider />

      <Row gutter={[16, 10]} align="middle" justify="space-between">
        <Col>
          <h5 className="title-sm mb-0 text-danger">{t(Translations.Common.Price)}</h5>
        </Col>
        <Col>
          <h5 className="title-md mb-0 text-danger">
            <span className="align-middle">
              <FormattedCurrency number={bookingSummary.salePrice} currency={bookingSummary.currency?.code} />
            </span>
            <Popover content={<HotelAgentProfit salePrice={bookingSummary.salePrice} introPrice={bookingSummary.introPrice} />}>
              <InfoCircleOutlined className="align-middle mx-1" />
            </Popover>
          </h5>
        </Col>
      </Row>
      {props.onContinue && (
        <div className="mt-2 text-center">
          <Button
            type="primary"
            danger
            icon={<CreditCardOutlined />}
            size="large"
            className="w-100"
            onClick={props.onContinue}
            loading={props.continueButtonLoading}>
            {t(Translations.Common.ContinueToPayment)}
          </Button>
        </div>
      )}
    </>
  );
});

export default HotelBookingSummary;
