import { PresetStatusColorType } from 'antd/es/_util/colors';
import ceil from 'lodash/ceil';
import flatten from 'lodash/flatten';
import flattenDeep from 'lodash/flattenDeep';
import floor from 'lodash/floor';
import last from 'lodash/last';
import max from 'lodash/max';
import min from 'lodash/min';
import minBy from 'lodash/minBy';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import moment, { Moment } from 'moment';

import { disableYesterdayAndBeforeAntdDatePicker, stringIsNullOrEmpty, toBoolean } from '../../../utils/helpers';
import { PackageFlightItem, PackageGetReserveResponse } from '../../package/apiTypes';
import {
  FlightFlexCalendarRq,
  FlightGetReserveByIdResponse,
  FlightOriginDestinationOption,
  FlightPassengerType,
  FlightPricedItinerary,
  FlightSegmentModel,
  FlightSupplier,
  LowFareSearchRequest,
  OperatingAirline,
  PNRStatus,
} from '../apiTypes';
import { FlightItinerarySummary, FlightTripType, FlightsQueryString } from '../types';

export const getAvailableDepartureDateAntdDatePicker = (current: any, returnDate: Moment | undefined) => {
  if (!returnDate) {
    return disableYesterdayAndBeforeAntdDatePicker(current);
  }
  return moment(current).utc(true).endOf('day') > moment(returnDate).utc(true).endOf('day') || disableYesterdayAndBeforeAntdDatePicker(current);
};

export const getAvailableReturnDateAntdDatePicker = (current: any, departureDate: Moment | undefined) => {
  if (!departureDate) {
    return disableYesterdayAndBeforeAntdDatePicker(current);
  }
  return moment(current).utc(true).endOf('day') <= departureDate;
};

export const getAirTripTypeTranslationKey = (airTripType: FlightTripType) => {
  return airTripType === 'OpenJaw' ? 'MultipleDestination' : airTripType;
};

export const getTranslateKeyFlightPassengerType = (passengerType: FlightPassengerType) => {
  switch (passengerType) {
    case 'ADT':
      return 'Common.Adult';
    case 'CHD':
      return 'Common.Child';
    case 'INF':
      return 'Common.Infant';
  }
};

export const getFlightSegmentStopType = (
  originDestinationOption: FlightOriginDestinationOption,
  currentSegmentIndex: number
): 'AirportChange' | 'TerminalChange' | 'Waiting' | undefined => {
  const currentSegment = originDestinationOption.flightSegments[currentSegmentIndex];

  if (currentSegment.stopTime && currentSegment.stopTime !== '00:00:00') {
    const nextSegment = originDestinationOption.flightSegments[currentSegmentIndex + 1];

    if (nextSegment) {
      const airportChange = currentSegment.arrivalAirport.locationCode !== nextSegment.departureAirport.locationCode;
      if (airportChange) {
        return 'AirportChange';
      }

      const terminalChange = currentSegment.arrivalAirport.terminalID !== nextSegment.departureAirport.terminalID;
      if (terminalChange) {
        return 'TerminalChange';
      }

      return 'Waiting';
    }
  }

  return undefined;
};

export const convertApiItineraryToItinerarySummary = (
  itinerary: FlightPricedItinerary | FlightGetReserveByIdResponse | PackageFlightItem | PackageGetReserveResponse['reserveFlightInfo'],
  suppliers: FlightSupplier[],
  providerName?: string
): FlightItinerarySummary => {
  let supplierId;
  if (providerName) {
    supplierId = suppliers.filter((f) => f.supplierName === providerName)[0]?.supplierId;
  }
  if (!supplierId) {
    supplierId = +(itinerary as FlightPricedItinerary).key.slice(0, 3);
  }
  return {
    key: (itinerary as FlightPricedItinerary).key,
    availableSeats: (itinerary as FlightPricedItinerary).availableSeats,
    flightType: (itinerary as FlightPricedItinerary).flightType,
    supplierId: supplierId,
    stop: max(itinerary.airItinerary.originDestinationOptions.map((option) => option.flightSegments.length))! - 1,
    originDestinationOptions: itinerary.airItinerary.originDestinationOptions,
    totalDuration: sum(itinerary.airItinerary.originDestinationOptions.map((option) => moment.duration(option.journeyDuration).asMinutes())),
    paymentBeforePNR: (itinerary as FlightPricedItinerary | FlightGetReserveByIdResponse).paymentBeforePNR,
    priceInfo: (itinerary as FlightPricedItinerary).airItineraryPricingInfo,
    priceInfoSummary: {
      flightFare: (itinerary as FlightGetReserveByIdResponse).flightFare,
      passengersFare: (itinerary as FlightGetReserveByIdResponse).passengersFare,
    },
    cabinClass: itinerary.airItinerary.originDestinationOptions[0].flightSegments[0].cabinClass,
    airline: itinerary.airItinerary.originDestinationOptions[0].flightSegments[0].marketingAirline,
    operatingAirline: itinerary.airItinerary.originDestinationOptions[0].flightSegments[0].operatingAirline,
    departureHours: itinerary.airItinerary.originDestinationOptions.map((option) => moment(option.flightSegments[0].departureDateTime).utc(true).hour()),
    arrivalHours: itinerary.airItinerary.originDestinationOptions.map((option) => moment(last(option.flightSegments)!.arrivalDateTime).utc(true).hour()),
    totalStopTime: sum(
      itinerary.airItinerary.originDestinationOptions.map((option) => sum(option.flightSegments.map((segment) => moment.duration(segment.stopTime).asHours())))
    ),
    hasExtraBaggage: (itinerary as FlightPricedItinerary | FlightGetReserveByIdResponse).hasExtraBaggage,
    isDomestic: (itinerary as FlightPricedItinerary | FlightGetReserveByIdResponse).isDomestic,
    flightKey: (itinerary as PackageFlightItem).flightKey,
    hasAlternative: (itinerary as FlightPricedItinerary).hasAlternative,
  };
};

export const getFlightTotalFare = (itinerary: FlightItinerarySummary, useExtraBaggage: boolean = false) => {
  if (itinerary) {
    if (useExtraBaggage) {
      return itinerary.priceInfo?.itinTotalFare.totalFareWithExtraBaggage ?? itinerary.priceInfoSummary!.flightFare.totalFareWithExtraBaggage;
    } else {
      return itinerary.priceInfo?.itinTotalFare.totalFare ?? itinerary.priceInfoSummary!.flightFare.totalFare;
    }
  }
};

export const pnrStatusBadgeType = (pnrStatus: PNRStatus): PresetStatusColorType => {
  switch (pnrStatus) {
    case 'Pending':
    case 'Priced':
    case 'Made':
      return 'processing';
    case 'Issued':
      return 'success';
    case 'Voided':
    case 'Canceled':
      return 'default';
  }
};

export const convertFlightsQueryStringObjectToSearchData = (queryStringObj: FlightsQueryString): LowFareSearchRequest => {
  return omit(
    {
      ...queryStringObj,
      adult: +queryStringObj.adult,
      child: +queryStringObj.child,
      infant: +queryStringObj.infant,
      isEthnic: toBoolean(queryStringObj.isEthnic),
      nonStop: toBoolean(queryStringObj.nonStop),
      airlineCode: queryStringObj.airlineCode,
      supplierId: queryStringObj.supplierId,
      flexDate: queryStringObj.flexDate,
    },
    ['originsText', 'destinationsText', 'airlineText', 'ct']
  );
};

export const convertFlightsQueryStringObjectToFlexSearchData = (queryStringObj: FlightsQueryString): FlightFlexCalendarRq => {
  return {
    airline: queryStringObj.airlineCode,
    flexDate: +queryStringObj.flexDate!,
    adult: queryStringObj.adult,
    child: queryStringObj.child,
    infant: queryStringObj.infant,
    cabinType: queryStringObj.cabinClassCode,
    departureDate: queryStringObj.departureDateTimes[0],
    returnDate: queryStringObj.departureDateTimes[1],
    destination: queryStringObj.destinationCodes[0],
    origin: queryStringObj.originCodes[0],
    directOnly: toBoolean(queryStringObj.nonStop),
    isEthnic: toBoolean(queryStringObj.isEthnic),
  };
};

export const buildMarketingAirlinesFilterArray = (itineraries: FlightItinerarySummary[]) => {
  const uniqueAirlines = uniqBy(itineraries, 'airline.code').map((item) => item.airline);

  const marketingAirlinesArray: {
    airline: OperatingAirline;
    minPrice: number;
  }[] = [];

  for (const airline of uniqueAirlines) {
    marketingAirlinesArray.push({
      airline: airline,
      minPrice: minBy(
        itineraries.filter((f) => f.airline.code === airline.code),
        'priceInfo.itinTotalFare.totalFare'
      )!.priceInfo!.itinTotalFare.totalFare,
    });
  }

  return marketingAirlinesArray;
};

export const buildFromToHourRangeFilterArray = (itineraries: FlightItinerarySummary[]) => {
  const array: {
    departure: [number, number];
    arrival: [number, number];
  }[] = [];

  for (let i = 0; i < itineraries[0].departureHours.length; i++) {
    const originDestinationDepartureHours = itineraries.map((item) => item.departureHours[i]);
    const originDestinationArrivalHours = itineraries.map((item) => item.arrivalHours[i]);

    array.push({
      departure: [min(originDestinationDepartureHours), max(originDestinationDepartureHours)! + 1] as [number, number],
      arrival: [min(originDestinationArrivalHours), max(originDestinationArrivalHours)! + 1] as [number, number],
    });
  }
  return array;
};

export const buildAirportsFilterArray = (itineraries: FlightItinerarySummary[]) => {
  const flightSegments: FlightSegmentModel[] = flattenDeep(itineraries.map((item) => item.originDestinationOptions.map((option) => option.flightSegments)));

  const departureAirports = flattenDeep(flightSegments.map((segment) => segment.departureAirport));
  const arrivalAirports = flattenDeep(flightSegments.map((segment) => segment.arrivalAirport));
  return uniqBy(departureAirports.concat(arrivalAirports), 'locationCode');
};

export const buildCabinClassesFilterArray = (itineraries: FlightItinerarySummary[]) => {
  return uniqBy(flatten(itineraries.map((item) => item.cabinClass)), 'name');
};

export const buildFlightsDurationFilterArray = (itineraries: FlightItinerarySummary[]) => {
  const totalDurations = itineraries.map((item) => item.totalDuration);
  return [floor(min(totalDurations)! / 60), ceil(max(totalDurations)! / 60)] as [number, number];
};

export const buildFlightsStopTimeFilterArray = (itineraries: FlightItinerarySummary[]) => {
  const totalStopTime = itineraries.map((item) => item.totalStopTime);
  return [floor(min(totalStopTime)!), ceil(max(totalStopTime)!)] as [number, number];
};

export const buildFlightsBaggageNumbersFilterArray = (itineraries: FlightItinerarySummary[]) => {
  const flightSegments: FlightSegmentModel[] = flattenDeep(itineraries.map((item) => item.originDestinationOptions.map((option) => option.flightSegments)));

  let withoutBaggage = false;
  const array = orderBy(
    uniq(
      flattenDeep(
        flightSegments.map((segment) =>
          segment.baggageInformation
            .filter((f) => f.passengerType === 'ADT')
            .map((item) => {
              if (item.baggageAllowance === 0) {
                withoutBaggage = true;
                return undefined;
              }
              return `${item.baggageAllowance} ${item.unitType}`;
            })
        )
      )
    )
  ).filter((f) => !stringIsNullOrEmpty(f)) as string[];

  if (withoutBaggage) {
    return ['Without Baggage'].concat(array);
  }
  return array;
};

export const isHourInArrayRange = (hour: number, array: [number, number][]): boolean => {
  for (const range of array) {
    if (hour >= range[0] && hour < range[1]) {
      return true;
    }
  }
  return false;
};
