import { BankOutlined, EnvironmentOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row, Select, notification } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import sum from 'lodash/sum';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { useAntdValidation, useAppSelector, useGlobalization, useHttpCall } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { CultureName } from '../../../../../features/localization/cultures';
import {
  convertToNumberArray,
  disableYesterdayAndBeforeAntdDatePicker,
  fromQueryString,
  getNowISO,
  momentToISOUtcDate,
  toQueryString,
} from '../../../../../utils/helpers';
import { addToRecentSearches } from '../../../../booking';
import { ipLocationSelector } from '../../../../shared';
import { Countries, FormItemActions, ImprovedAutoComplete, PDatePicker2 } from '../../../../shared';
import { GetHotelLocationResponse } from '../../../apiTypes';
import { hotelGetLocationHttp } from '../../../http/hotel-http';
import { HotelDetailsQueryString, HotelsQueryString } from '../../../types';
import HotelSearchFormRooms from '../HotelSearchFormRooms/HotelSearchFormRooms';
import classes from './HotelSearchForm.module.scss';

type FormValues = {
  destination: GetHotelLocationResponse[0];
  adults: number[];
  children: number[];
  childAge: number[][];
  checkInCheckOut: [Moment, Moment];
  nationality?: string;
  [key: string]: any;
};
type Props = {
  inline?: boolean;
  fillFromQueryString?: boolean;
  forceDestination?: number;
} & { hide: boolean };
const HotelSearchForm: React.VFC<Props> = React.memo((props) => {
  const maxRoomQty = 4;

  const { t } = useTranslation();
  const ipLocation = useAppSelector(ipLocationSelector);
  const history = useHistory();
  const location = useLocation();
  const queryStringObj = fromQueryString<HotelsQueryString>(location.search, 'bracket');

  const { culture } = useGlobalization();

  const [roomQty, setRoomQty] = useState(1);
  const [hotelLocations, setHotelLocations] = useState<GetHotelLocationResponse>([]);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState<any>();
  const [resetTime, setResetTime] = useState<number>();
  const [decreaseChildren, setDecreaseChildren] = useState<{ date: number; number: number }>({ date: Date.now(), number: 0 });

  const filledFromQueryString = useRef<boolean>(false);

  const getLocationApi = useHttpCall(hotelGetLocationHttp);

  const [form] = Form.useForm<FormValues>();
  const { labelWithRules } = useAntdValidation(form);

  useEffect(() => {
    if (props.fillFromQueryString && !filledFromQueryString.current && queryStringObj.adults && queryStringObj.children) {
      setRoomQty(queryStringObj.adults.length);

      const checkinDate = moment(queryStringObj.checkIn).utc(true);
      const checkoutDate = moment(queryStringObj.checkOut).utc(true);

      form.setFieldsValue({
        destination: { id: queryStringObj.destination, name: queryStringObj.destinationText },
        adults: queryStringObj.adults,
        children: queryStringObj.children,
        checkInCheckOut: culture.Name === CultureName.FaIr ? [checkinDate.toISOString(), checkoutDate.toISOString()] : [checkinDate, checkoutDate],
        nationality: queryStringObj.nationality,
      });

      const obj: number[][] = [];

      let childrenAgesStartPosition = 0;
      for (const item in queryStringObj.children) {
        const childrenQty = +queryStringObj.children[item];
        if (childrenQty > 0) {
          const currentRoomChildrenAges = queryStringObj.childrenAges!.slice(childrenAgesStartPosition, childrenAgesStartPosition + childrenQty);
          obj[item] = [];
          for (const childAge in currentRoomChildrenAges) {
            obj[item].push(+currentRoomChildrenAges[childAge]);
          }
          childrenAgesStartPosition += childrenQty;
        } else {
          //@ts-ignore
          obj[item] = undefined;
        }
      }

      form.setFieldsValue({
        childAge: obj,
      });
    }

    filledFromQueryString.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, roomQty]);

  const debouncedGetLocationApi = debounce(async (e) => {
    const response = await getLocationApi.call<GetHotelLocationResponse>({ value: e });
    if (response && response.success) {
      setHotelLocations(response.result!);
    }
  }, 300);

  const handleAddRoom = () => {
    setRoomQty((prevState) => prevState + 1);
  };

  const handleRemoveRoom = useCallback(() => {
    const totalChildrenArray = [...form.getFieldValue('children')];

    setDecreaseChildren({ date: Date.now(), number: totalChildrenArray[roomQty - 1] });

    totalChildrenArray[roomQty - 1] = 0;

    form.setFieldsValue({
      children: totalChildrenArray,
    });

    setRoomQty((prevState) => prevState - 1);
  }, [form, roomQty]);

  const handleDestinationSearched = async (value: string) => {
    if (value.length > 2) {
      await debouncedGetLocationApi(value);
    }
  };

  const handleDestinationSelected = () => {
    if (!datePickerValue) {
      setDatePickerOpen(true);
    }
  };

  const handleReset = useCallback(() => {
    form.resetFields();
    setRoomQty(1);
    setResetTime(Date.now());
  }, [form]);

  const handleSearch = (formValues: FormValues) => {
    const values = cloneDeep(formValues);
    values.adults = convertToNumberArray(values.adults)!;
    values.children = convertToNumberArray(values.children)!;

    if (!values.checkInCheckOut[0] || !values.checkInCheckOut[1]) {
      notification.error({
        message: t(Translations.Booking.PleaseEnterCheckInCheckOutDate),
      });
      form.scrollToField('checkInCheckOut');
      return;
    }
    if (!values.destination && !props.forceDestination) {
      notification.error({
        message: t(Translations.Booking.PleaseEnterDestination),
      });
      form.scrollToField('destination');
      return;
    }

    const childrenAges: number[] = [];
    if (values.childAge) {
      values.childAge.forEach((roomChildAges) => {
        if (roomChildAges !== undefined && roomChildAges !== null) {
          roomChildAges.forEach((childAge) => {
            childrenAges.push(+childAge);
          });
        }
      });
    }

    const hotelsQueryString: HotelsQueryString | HotelDetailsQueryString = {
      destination: values.destination?.id,
      destinationText: values.destination?.name,
      checkIn: momentToISOUtcDate(values.checkInCheckOut[0])!,
      checkOut: momentToISOUtcDate(values.checkInCheckOut[1])!,
      adults: values.adults,
      children: values.children,
      childrenAges: childrenAges,
      nationality: values.nationality,
    };

    if (props.forceDestination) {
      const omittedQueryStringObj = omit(hotelsQueryString, 'destination');
      const queryString = toQueryString(omittedQueryStringObj, 'bracket');
      history.push(`/hotel/${props.forceDestination}?${queryString}`);
    } else if (values.destination.typeId === 6) {
      const queryString = toQueryString(hotelsQueryString, 'bracket');
      history.push(`hotel/${values.destination.id}?${queryString}`);
    } else {
      const queryString = toQueryString(hotelsQueryString, 'bracket');
      history.push(`/hotels?${queryString}`);

      addToRecentSearches(hotelsQueryString, 'Hotel');
    }
  };

  return (
    <>
      <Form<FormValues> form={form} onFinish={handleSearch} labelCol={{ span: 24 }} className={`hotel-search-form ${props.inline ? 'inline-search-form' : ''}`}>
        <Row
          gutter={[10, 0]}
          align={'top'}
          // className={props.inline ? 'no-wrap-flex-xl' : ''}
          // justify={props.inline ? returnOnBreakpoint('center', 'md') : undefined}
        >
          {!props.forceDestination && (
            <Col xs={24} sm={props.inline ? 12 : 24} xl={props.inline ? 3 : 24}>
              <Form.Item name="destination" className="mb-4" {...labelWithRules({ label: t(Translations.Common.Destination), rules: [{ type: 'Required' }] })}>
                <ImprovedAutoComplete
                  defaultItem={queryStringObj.destination ? { id: queryStringObj.destination, name: queryStringObj.destinationText } : undefined}
                  textPropertyName="name"
                  onChange={handleDestinationSelected}
                  onInputChanged={handleDestinationSearched}
                  options={hotelLocations}
                  asyncPending={getLocationApi.pending}
                  noPaddingRenderItem
                  renderOption={(option) => (
                    <div className={`py-1 px-2 ${option.typeId === 6 ? classes.blue : ''}`}>
                      <Row gutter={[10, 10]} align={'top'} className="nowrap-flex">
                        <Col>{option.typeId === 6 ? <BankOutlined /> : <EnvironmentOutlined />}</Col>
                        <Col>
                          <div className="py-1">
                            <b>{option.name}</b>
                          </div>
                          <div> {option.parentName}</div>
                        </Col>
                      </Row>
                    </div>
                  )}
                  renderTooltip={(option) =>
                    option.parentName ? (
                      <>
                        {option.parentName}
                        <br />
                        {option.name}
                      </>
                    ) : undefined
                  }
                />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={props.inline ? 12 : 24} xl={props.inline ? (props.forceDestination ? 6 : 5) : 24}>
            <Form.Item
              className="mb-4"
              name="checkInCheckOut"
              {...labelWithRules({ label: t(Translations.Hotel.CheckInAndCheckOut), rules: [{ type: 'Required' }] })}>
              {culture.Name === CultureName.FaIr ? (
                <PDatePicker2 initialCalendar="persian" mode="range" minDate={getNowISO()} />
              ) : (
                <DatePicker.RangePicker
                  inputReadOnly
                  className="w-100"
                  open={datePickerOpen}
                  disabledDate={disableYesterdayAndBeforeAntdDatePicker}
                  onOpenChange={(value) => setDatePickerOpen(value)}
                  dropdownClassName="disabled-year-arrow"
                  value={datePickerValue}
                  onChange={(value) => {
                    setDatePickerValue(value);
                    setDatePickerOpen(false);
                  }}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={props.inline ? 20 : 24} xl={props.inline ? (props.forceDestination ? 15 : 13) : 24}>
            <Row gutter={[10, 0]} className={'align-flex-end'}>
              <Col xs={props.inline ? undefined : 24}>
                <Form.Item noStyle className="mb-4">
                  <HotelSearchFormRooms
                    defaultAllChildrenQty={props.fillFromQueryString ? sum(convertToNumberArray(queryStringObj.children)) : 0}
                    decreaseChildren={decreaseChildren}
                    roomQty={roomQty}
                    onRemoveRoom={handleRemoveRoom}
                    inline={props.inline}
                    fillFromQueryString={props.fillFromQueryString}
                    resetTime={resetTime}
                  />
                </Form.Item>
              </Col>
              {roomQty < maxRoomQty && (
                <Col xs={props.inline ? undefined : 24}>
                  <Form.Item className={`mb-4  ${props.inline ? 'align-with-formItems' : 'mt-3'}`}>
                    <Button className={classes.addRoomBtn} icon={<PlusOutlined />} type={'dashed'} onClick={handleAddRoom}>
                      {t(Translations.Hotel.AddAnotherRoom)}
                    </Button>
                  </Form.Item>
                </Col>
              )}
              <Col xs={props.inline ? undefined : 24} xl={props.inline ? (props.forceDestination ? 3 : 3) : 24} className="min-width-100">
                <Form.Item className="mb-4" name="nationality" label={t(Translations.Common.Nationality)} initialValue={ipLocation?.alpha2 || ''}>
                  <Select showSearch optionFilterProp="children" dropdownMatchSelectWidth={180} allowClear>
                    {Countries.map((country) => (
                      <Select.Option key={country.code} value={country.code}>
                        {country.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col
            xs={props.inline ? undefined : 24}
            md={props.inline ? 4 : 24}
            xl={props.inline ? 3 : 24}
            className={`min-width-100  ${props.inline ? 'text-right' : ''}`}>
            <div className={props.inline ? 'align-with-formItems-md align-with-formItems-lg align-with-formItems-xl' : 'mt-2'}>
              <FormItemActions
                formInstance={form}
                onReset={handleReset}
                submitText={props.forceDestination ? t(Translations.Common.ModifySearch) : t(Translations.Common.Search)}
                submitIcon={<SearchOutlined />}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
});

export default HotelSearchForm;
