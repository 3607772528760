import { RootState } from '../../../features/redux';

export const userIdSelector = (state: RootState) => {
  return state.authentication.userId;
};

export const currentTenantSelector = (state: RootState) => {
  return state.authentication.tenant;
};

export const permissionsSelector = (state: RootState) => {
  return state.authentication.permissions;
};
