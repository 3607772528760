import { LoadingOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Modal, Row, Skeleton, Table, Tag, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGlobalization } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { getTranslateKeyFlightPassengerType } from '../../../flight/helpers/FlightHelpers';
import { FormattedCurrency } from '../../../shared';
import { DomesticFlightGetValidateResponse, DomesticFlightPassengerTypes } from '../../apiTypes';
import classes from '../DomesticFlights/DomesticFlightListItem/DomesticFlightListItem.module.scss';

type Props = {
  detailsVisibility?: boolean;
  closeModal?: any;
  showButton?: boolean;
  flights?: DomesticFlightGetValidateResponse;
  loading?: boolean;
  loadingSubmit?: boolean;
};

const DomesticFlightBookingSummary: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const { Column } = Table;
  const { flights } = props;

  const getTableData = () => {
    const detailsTableData = [];
    if (flights?.departureFlight) detailsTableData.push({ ...flights.departureFlight, key: 1 });
    if (flights?.returnFlight) detailsTableData.push({ ...flights.returnFlight, key: 2 });
    return detailsTableData;
  };

  const total = (): number => {
    if (flights) {
      let sum: number = flights.adultTotalPrice + flights?.childTotalPrice + flights?.infantTotalPrice;
      return sum ? sum : 0;
    }
    return 0;
  };

  const { culture, formatShortDate, formatHourMinute, formatShortMonthWeekDay } = useGlobalization();
  const passengerTypes: DomesticFlightPassengerTypes[] = ['ADT', 'CHD', 'INF'];

  return (
    <>
      {props.loading ? (
        <div>
          <Skeleton className={'no-title mb-6 mt-4'} loading active avatar paragraph={{ width: '100%' }} />
          <Skeleton className={'no-title my-6'} loading active avatar paragraph={{ width: '100%' }} />
          <Divider className="my-0" />
          <Skeleton loading active paragraph={{ rows: 1, width: '100%' }} />
          <Divider className="my-0" />
          <Skeleton className="one-line-skeleton mb-3" loading active title={{ width: '100%' }} paragraph={{ rows: 0 }} />
        </div>
      ) : (
        <>
          {[flights?.departureFlight, flights?.returnFlight].map((flight, index: number) => (
            <React.Fragment key={index}>
              {flight ? (
                <>
                  <Modal
                    closable
                    visible={props.detailsVisibility}
                    onCancel={props.closeModal}
                    width={1100}
                    footer={
                      <Button type="primary" onClick={props.closeModal}>
                        {t(Translations.Common.Ok)}
                      </Button>
                    }>
                    <h3 className="title-sm">
                      <Row align={'middle'} gutter={[10, 0]}>
                        <Col>{flight?.departureAirport.city.name}</Col>
                        <Col>
                          <i className={`${classes.icon} fas fa-plane mirror-in-rtl mx-1`} />
                        </Col>
                        <Col>{flight?.arrivalAirport.city.name}</Col>
                      </Row>
                    </h3>
                    <Table dataSource={getTableData()} bordered pagination={false} className={`center-aligned-antd-table ${classes.grayTheadTable}`}>
                      <Column
                        title={t(Translations.Flight.Airline)}
                        dataIndex="airline"
                        key="airline"
                        render={(text, record: any) => (
                          <div className={'font-fa'}>
                            <img
                              src={record?.airline?.picture?.path}
                              alt={record?.airline?.picture?.altAttribute}
                              title={record?.airline?.picture?.titleAttribute}
                              width={35}
                            />
                            <div className="mt-2">{record?.airline?.name}</div>
                          </div>
                        )}
                      />
                      <Column title={t(Translations.Flight.FlightNumber)} dataIndex="flightNumber" key="flightNumber" />
                      <Column
                        title={t(Translations.Flight.Aircraft)}
                        dataIndex="airCraft"
                        key="airCraft"
                        render={(text, record: any) => record?.airCraft?.manufacturer}
                      />
                      <Column
                        title={`${t(Translations.Flight.CabinClass)} (${t(Translations.Common.Code)})`}
                        dataIndex="cabinClass"
                        key="cabinClass"
                        render={(text, record: any) => (
                          <>
                            {record?.cabinClass?.name} ({record?.cabinClass?.code})
                          </>
                        )}
                      />
                      <Column
                        title={t(Translations.Flight.Baggage)}
                        dataIndex="maxAllowedBaggage"
                        key="maxAllowedBaggage"
                        render={(text, record: any) => <>{record?.maxAllowedBaggage && `${record.maxAllowedBaggage} Kg`}</>}
                      />
                      <Column
                        title={t(Translations.Common.Origin)}
                        dataIndex="departure"
                        key="departure"
                        render={(text, record: any) => (
                          <>
                            {record?.departureAirport?.name}
                            {record?.departureAirport?.terminalId && (
                              <>
                                <br />
                                <Tag>Terminal {record?.departureAirport?.terminalId}</Tag>
                              </>
                            )}
                            <div className={'mt-3'}>
                              {record?.departureAirport?.country?.name} , {record?.departureAirport?.city?.name}
                            </div>
                          </>
                        )}
                      />
                      <Column
                        title={t(Translations.Flight.DepartureTime)}
                        dataIndex="departureTime"
                        key="departureTime"
                        render={(text, record: any) =>
                          record?.departureTime ? (
                            <>
                              {formatHourMinute(record.departureTime, culture.Name)}
                              <div className={'mt-3'}>{formatShortDate(record.departureTime, culture.Name)}</div>
                            </>
                          ) : (
                            '--'
                          )
                        }
                      />
                      <Column
                        title={t(Translations.Common.Destination)}
                        dataIndex="arrival"
                        key="arrival"
                        render={(text, record: any) => (
                          <>
                            {record?.arrivalAirport?.name}
                            {record?.arrivalAirport?.terminalId && (
                              <>
                                <br />
                                <Tag>Terminal {record?.arrivalAirport?.terminalId}</Tag>
                              </>
                            )}
                            <div className={'mt-3'}>
                              {record?.arrivalAirport?.country?.name} , {record?.arrivalAirport?.city?.name}
                            </div>
                          </>
                        )}
                      />
                      <Column
                        title={t(Translations.Flight.ArrivalTime)}
                        dataIndex="arrivalTime"
                        key="arrivalTime"
                        render={(text, record: any) =>
                          record?.arrivalTime ? (
                            <>
                              {formatHourMinute(record.arrivalTime, culture.Name)}
                              <div className={'mt-3'}>{formatShortDate(record.arrivalTime, culture.Name)}</div>
                            </>
                          ) : (
                            '--'
                          )
                        }
                      />
                    </Table>
                    <table className="mt-6">
                      <tbody>
                        {passengerTypes.map((item, index) =>
                          flights?.passengersPrice?.[item].count ? (
                            <tr key={index}>
                              <td>{t(getTranslateKeyFlightPassengerType(item))}: </td>
                              <td className={'text-right px-3'}>
                                <FormattedCurrency number={flights?.passengersPrice[item].totalPrice} currencyDisplay="code" />
                              </td>
                            </tr>
                          ) : (
                            <tr key={index}></tr>
                          )
                        )}
                        <tr>
                          <td className="title-sm">{t(Translations.Common.TotalPrice)}:</td>
                          <td className={'text-right px-3 title-sm'}>
                            <FormattedCurrency number={total()} currencyDisplay="code" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Modal>
                  {index ? <Divider className="my-2" /> : null}

                  <div className={classes.segmentItem}>
                    <Row justify={'space-between'} align={'middle'}>
                      <Col flex={'0 0 50px'}>
                        <img src={flight.airline.picture?.path} alt={flight.airline.picture?.altAttribute} width={40} height={40} />
                      </Col>
                      <Col flex={'auto'}>
                        <Row justify={'space-between'} align={'middle'}>
                          <Col>
                            <div className="title-sm font-weight-bolder mb-0">
                              {flight.departureTime ? formatHourMinute(flight.departureTime, culture.Name) : '--:--'}
                            </div>
                            <Tooltip title={formatShortDate(flight.departureTime, culture.Name)}>
                              {flight.departureTime ? formatShortMonthWeekDay(flight.departureTime, culture.Name) : '-'}
                            </Tooltip>
                            <div className="title-xs font-weight-bolder mb-0">
                              <Tooltip
                                title={
                                  <div>
                                    {flight.departureAirport.city.name}
                                    <br />
                                    {flight.departureAirport.name}
                                  </div>
                                }>
                                {flight.departureAirport.code}
                              </Tooltip>
                            </div>
                          </Col>
                          <Col>
                            <i className={`${classes.icon} fas fa-plane mirror-in-rtl mx-1`} />
                          </Col>
                          <Col className="text-right">
                            <div className="title-sm font-weight-bolder mb-0">
                              {flight.arrivalTime ? formatHourMinute(flight.arrivalTime, culture.Name) : '--:--'}
                            </div>
                            <Tooltip title={formatShortDate(flight.arrivalTime, culture.Name)}>
                              {flight.arrivalTime ? formatShortMonthWeekDay(flight.arrivalTime, culture.Name) : '-'}
                            </Tooltip>
                            <div className="title-xs font-weight-bolder mb-0">
                              <Tooltip
                                title={
                                  <div>
                                    {flight.arrivalAirport.city.name}
                                    <br />
                                    {flight.arrivalAirport.name}
                                  </div>
                                }>
                                {flight.arrivalAirport.code}
                              </Tooltip>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                ''
              )}
            </React.Fragment>
          ))}

          <Divider className="my-4">{t(Translations.Common.PriceDetails)}</Divider>
          {flights?.passengersPrice ? (
            <>
              {passengerTypes.map((item, index) =>
                flights?.passengersPrice?.[item].count ? (
                  <Row align={'middle'} justify={'space-between'} key={index} gutter={[10, 10]}>
                    <Col className="font-weight-bold">
                      {flights.passengersPrice[item].count} x {t(getTranslateKeyFlightPassengerType(item))}
                    </Col>
                    <Col className="font-weight-bold">
                      <FormattedCurrency number={flights.passengersPrice[item].totalPrice} currencyDisplay="code" />
                    </Col>
                  </Row>
                ) : (
                  ''
                )
              )}
            </>
          ) : (
            ''
          )}

          <Divider className="my-4" />
          <h5 className={'title-sm'}>
            <Row align={'middle'} justify={'space-between'}>
              <Col className="font-weight-bold">{t(Translations.Common.TotalPrice)}</Col>
              <Col className="font-weight-bold">
                <FormattedCurrency number={total()} currencyDisplay="code" />
              </Col>
            </Row>
          </h5>
          {props.showButton && (
            <Button type="primary" htmlType="submit" danger block>
              {t(Translations.Common.ContinueToPayment)}
              {props.loadingSubmit ? <LoadingOutlined /> : <RightOutlined className="mirror-in-rtl" />}
            </Button>
          )}
        </>
      )}
    </>
  );
});

export default DomesticFlightBookingSummary;
