import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row, Select, Tooltip } from 'antd';
import { FormInstance } from 'antd/es/form';
import range from 'lodash/range';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Translations } from '../../../../../features/localization';

type Props = {
  formInstance: FormInstance;
};
const FlightSearchPassengers: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const [adultsQty, setAdultsQty] = useState<number>(1);

  const excess9Validator = () => ({
    validator(rule: RuleObject, value: StoreValue) {
      if (!value) {
        return Promise.resolve();
      }
      const obj = props.formInstance.getFieldsValue();
      if (obj.adults + obj.children > 9) {
        return Promise.reject(t(Translations.Flight.TotalPassengersValidationText));
      }
      return Promise.resolve();
    },
  });

  return (
    <Row gutter={[10, 0]}>
      <Col xs={8}>
        <Form.Item
          className="mb-4"
          name="adults"
          label={`${t(Translations.Common.Adult)} (+12)`}
          initialValue={adultsQty}
          rules={[excess9Validator]}
          dependencies={['children']}>
          <Select dropdownMatchSelectWidth={false} onChange={(value) => setAdultsQty(value ? +value : 1)}>
            {range(1, 10).map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={8}>
        <Form.Item
          className="mb-4"
          name="children"
          label={`${t(Translations.Common.Child)} (2-12)`}
          initialValue={0}
          rules={[excess9Validator]}
          dependencies={['adults']}>
          <Select dropdownMatchSelectWidth={false}>
            {range(0, 10).map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={8}>
        <Form.Item
          className="mb-4 no-wrap-label"
          name="infants"
          label={
            <>
              {`${t(Translations.Common.Infant)} (0-2)`}
              <Tooltip title={t(Translations.Flight.InfantsNumberCanNotBiggerThanAdultsNumber)}>
                <InfoCircleOutlined className={'mx-2'} />
              </Tooltip>
            </>
          }
          initialValue={0}>
          <Select dropdownMatchSelectWidth={false}>
            {range(0, 10).map((item) => (
              <Select.Option key={item} value={item} disabled={item > adultsQty}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
});

export default FlightSearchPassengers;
