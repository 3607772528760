import { EnvironmentOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAntdValidation, useGlobalization, useHttpCall } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { ImprovedAutoComplete } from '../../../../shared';
import { DomesticFlightGetAirportResponse } from '../../../apiTypes';
import { DomesticFlightGetAirportHttp } from '../../../http/domesticFlight-http';
import { DomesticFlightFormValues, DomesticFlightTripType } from '../../../types';

const options: DomesticFlightGetAirportResponse = [
  {
    name: 'تهران',
    city: {
      name: '',
      code: '',
    },
    country: {
      name: 'ایران',
      code: 'IR',
    },
    code: 'THR',
    airportType: 'City',
    latitude: '',
    longitude: '',
  },
  {
    name: 'فرودگاه اهواز',
    city: {
      name: 'اهواز',
      code: 'AWZ',
    },
    country: {
      name: 'ایران',
      code: 'IR',
    },
    code: 'AWZ',
    airportType: 'Main',
    latitude: '',
    longitude: '',
  },
  {
    name: 'فرودگاه شهید دستغیب شیراز',
    city: {
      name: 'شيراز',
      code: 'SYZ',
    },
    country: {
      name: 'ایران',
      code: 'IR',
    },
    code: 'SYZ',
    airportType: 'Main',
    latitude: '',
    longitude: '',
  },
  {
    name: 'فرودگاه شهید هاشمی نژاد مشهد',
    city: {
      name: 'مشهد',
      code: 'MHD',
    },
    country: {
      name: 'ایران',
      code: 'IR',
    },
    code: 'MHD',
    airportType: 'Main',
    latitude: '',
    longitude: '',
  },
  {
    name: 'فرودگاه بندرعباس',
    city: {
      name: 'بندرعباس',
      code: 'BND',
    },
    country: {
      name: 'ایران',
      code: 'IR',
    },
    code: 'BND',
    airportType: 'Main',
    latitude: '',
    longitude: '',
  },
  {
    name: 'فرودگاه شهید بهشتی اصفهان',
    city: {
      name: 'اصفهان',
      code: 'IFN',
    },
    country: {
      name: 'ایران',
      code: 'IR',
    },
    code: 'IFN',
    airportType: 'Main',
    latitude: '',
    longitude: '',
  },
  {
    name: 'فرودگاه شهید مدنی تبریز',
    city: {
      name: 'تبريز',
      code: 'TBZ',
    },
    country: {
      name: 'ایران',
      code: 'IR',
    },
    code: 'TBZ',
    latitude: '38.133899688720703',
    longitude: '46.235000610351499',
    airportType: 'Main',
  },
  {
    name: 'فرودگاه خلیج فارس کیش',
    city: {
      name: 'کيش',
      code: 'KIH',
    },
    country: {
      name: 'ایران',
      code: 'IR',
    },
    code: 'KIH',
    airportType: 'Main',
    latitude: '',
    longitude: '',
  },
];

type Props = {
  inline?: boolean;
  formInstance: FormInstance<DomesticFlightFormValues>;
  flightTripType: DomesticFlightTripType;
};

const FlightSearchAirport: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const { labelWithRules } = useAntdValidation(props.formInstance);

  const { culture } = useGlobalization();

  const [fromAirports, setFromAirports] = useState<DomesticFlightGetAirportResponse>(culture.LanguageCode === 'fa' ? options : []);
  const [toAirports, setToAirports] = useState<DomesticFlightGetAirportResponse>(culture.LanguageCode === 'fa' ? options : []);

  const airportSearchSegment = useRef<'from' | 'to'>('from');

  const destinationInputRef = useRef<Input>(null);

  const getAirportApi = useHttpCall(DomesticFlightGetAirportHttp);

  const debouncedGetAirports = debounce(async (value: string, to: boolean) => {
    airportSearchSegment.current = to ? 'to' : 'from';
    const response = await getAirportApi.call<DomesticFlightGetAirportResponse>({ query: value });
    if (response && response.success) {
      const data = sortBy(response.result, (item) => item.airportType);
      if (to) {
        setToAirports(data!);
      } else {
        setFromAirports(data!);
      }
    } else {
      if (to) {
        setToAirports([]);
      } else {
        setFromAirports([]);
      }
    }
  }, 300);

  const handleAirportSearched = async (value: string, to: boolean) => {
    if (value && value.length > 2) {
      await debouncedGetAirports(value, to);
    } else {
      if (to) {
        setToAirports(culture.LanguageCode === 'fa' ? options : []);
      } else {
        setFromAirports(culture.LanguageCode === 'fa' ? options : []);
      }
    }
  };

  return (
    <>
      <Col xs={12} md={props.flightTripType === 'RoundTrip' ? 6 : 8} xl={props.inline ? (props.flightTripType === 'RoundTrip' ? 6 : 8) : 12}>
        <Form.Item className="mb-4" name="departureCode" {...labelWithRules({ label: t(Translations.Common.From), rules: [{ type: 'Required' }] })}>
          <ImprovedAutoComplete
            overlayClassName="font-fa"
            asyncPending={airportSearchSegment.current === 'from' && getAirportApi.pending}
            textPropertyName="name"
            // renderTooltip={(option) => (
            //   <>
            //     {option.ParentName}
            //     <br />
            //     {option.Name}
            //   </>
            // )}
            options={fromAirports}
            onInputChanged={(value) => handleAirportSearched(value, false)}
            onChange={(item) => {
              if (item) {
                destinationInputRef.current?.focus();
              }
            }}
            renderOption={(option) => (
              <Row className="no-wrap-flex font-fa" gutter={[10, 0]}>
                <Col flex="20px">
                  {option.airportType === 'City' ? (
                    <EnvironmentOutlined className="align-middle" />
                  ) : (
                    <span className={`fas fa-plane autocomplete-airport-icon`} />
                  )}
                </Col>
                <Col flex={'auto'}>
                  {option.airportType === 'City' ? option.name : option.city?.name} ({option.code})
                  <br />
                  <small>{option.airportType === 'City' ? t(Translations.Flight.AllAirports) : option.name}</small>
                </Col>
              </Row>
            )}
          />
        </Form.Item>
      </Col>
      <Col xs={12} md={props.flightTripType === 'RoundTrip' ? 6 : 8} xl={props.inline ? (props.flightTripType === 'RoundTrip' ? 6 : 8) : 12}>
        <Form.Item className="mb-4" name="returnCode" {...labelWithRules({ label: t(Translations.Common.To2), rules: [{ type: 'Required' }] })}>
          <ImprovedAutoComplete
            overlayClassName="font-fa"
            inputRef={destinationInputRef}
            asyncPending={airportSearchSegment.current === 'to' && getAirportApi.pending}
            textPropertyName="name"
            options={toAirports}
            onInputChanged={(value) => handleAirportSearched(value, true)}
            renderOption={(option) => (
              <Row className="no-wrap-flex p-2 font-fa" gutter={[10, 0]}>
                <Col flex="20px">
                  {option.airportType === 'City' ? (
                    <EnvironmentOutlined className="align-middle" />
                  ) : (
                    <span className={`fas fa-plane autocomplete-airport-icon`} />
                  )}
                </Col>
                <Col flex="auto">
                  {option.airportType === 'City' ? option.name : option.city?.name} ({option.code})
                  <br />
                  <small> {option.airportType === 'City' ? t(Translations.Flight.AllAirports) : option.name} </small>
                </Col>
              </Row>
            )}
          />
        </Form.Item>
      </Col>
    </>
  );
});

export default FlightSearchAirport;
