import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { GetDepositBalancesResponse } from '../apiTypes';

type PaymentState = {
  canChangeCurrency: boolean;
  depositBalances?: GetDepositBalancesResponse;
  depositBalancesLastModified?: {
    manual: boolean;
    date: number;
  };
  temp: {
    manualReceiptClientLastModified: number;
    chargeDeposit: {
      makeToken?: { gatewayId: number; pending: boolean };
      amount: number;
    };
  };
};

const initialState: PaymentState = {
  canChangeCurrency: true,
  temp: {
    manualReceiptClientLastModified: Date.now(),
    chargeDeposit: {
      amount: 0,
    },
  },
};
const paymentSlice = createSlice({
  name: 'payment',
  initialState: initialState,
  reducers: {
    setDepositBalances: (state, action: PayloadAction<GetDepositBalancesResponse>) => {
      state.depositBalances = action.payload;
      state.depositBalancesLastModified = { manual: false, date: Date.now() };
    },
    changingCurrencyAvailability: (state, action: PayloadAction<boolean>) => {
      state.canChangeCurrency = action.payload;
    },
    tempUpdateManualReceiptClientsLastModified: (state) => {
      state.temp.manualReceiptClientLastModified = Date.now();
    },
    tempTriggerUpdateDepositBalances: (state) => {
      state.depositBalancesLastModified = { manual: true, date: Date.now() };
    },
    tempChargeDepositSetAmount: (state, action: PayloadAction<number>) => {
      state.temp.chargeDeposit.amount = action.payload;
    },
    tempChargeDepositMakeTokenPending: (state, action: PayloadAction<{ gatewayId: number; pending: boolean } | undefined>) => {
      state.temp.chargeDeposit.makeToken = action.payload;
    },
  },
});

export default paymentSlice;
