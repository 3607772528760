import { useEffect } from 'react';

import { isDevelopment } from '../../utils/helpers';

const useWindow = (options?: { onFocus?: (this: Window, ev: WindowEventMap[keyof WindowEventMap]) => void }) => {
  // const focusHandler = useCallback(
  //   function (this: Window, ev: WindowEventMap[keyof WindowEventMap]) {
  //     options?.onFocus?.call(this, ev);
  //   },
  //   [options?.onFocus]
  // );

  useEffect(() => {
    function focusHandler(this: Window, ev: WindowEventMap[keyof WindowEventMap]) {
      if (isDevelopment()) {
        console.log('Window Focused');
      }
      options?.onFocus?.call(this, ev);
    }

    window.addEventListener('focus', focusHandler);

    return () => {
      window.removeEventListener('focus', focusHandler);
    };
  }, [options?.onFocus]);

  return;
};

export default useWindow;
