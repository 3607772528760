import { combineReducers } from '@reduxjs/toolkit';

import { accountSlice } from '../../modules/account';
import { authenticationSlice } from '../../modules/authentication';
import { domesticFlightSlice } from '../../modules/domesticFlight';
import { flightSlice } from '../../modules/flight';
import { hotelSlice } from '../../modules/hotel';
import { packageSlice } from '../../modules/package';
import { paymentSlice } from '../../modules/payment';
import { sharedSlice } from '../../modules/shared';
import { testSlice } from '../../modules/testModule';

const rootReducer = () => {
  return combineReducers({
    shared: sharedSlice.reducer,
    test: testSlice.reducer,
    authentication: authenticationSlice.reducer,
    account: accountSlice.reducer,
    payment: paymentSlice.reducer,
    flight: flightSlice.reducer,
    hotel: hotelSlice.reducer,
    package: packageSlice.reducer,
    domesticFlight: domesticFlightSlice.reducer,
  });
};

export default rootReducer;
