import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { AbpError, Tenant } from '../../../types';
import { GetIpLocationResponse } from '../apiTypes';
import { DisplayPath, NotificationPayload } from '../types';

type SharedState = {
  httpError?: AxiosError;
  tenants?: Tenant[];
  initialized: boolean;
  initializationError?: {
    initializationState: string;
    abpError: AbpError;
  };
  displayPath: DisplayPath | null | 'Skeleton';
  notification?: NotificationPayload;
  ipLocation?: GetIpLocationResponse;
  preferences: {
    pageSize: number;
    homeSearchFormTabKey: 'FlightDomestic' | 'Flight' | 'Hotel' | 'HotelDomestic' | 'Ethnic' | 'Package' | 'Ticketing';
  };
};

const initialState: SharedState = {
  initialized: false,
  preferences: {
    pageSize: 20,
    homeSearchFormTabKey: 'Flight',
  },
  displayPath: null,
};

const sharedSlice = createSlice({
  name: 'shared',
  initialState: initialState,
  reducers: {
    handleHttpErrorDefault: (state, action: PayloadAction<AxiosError>) => {
      state.httpError = action.payload;
    },
    setTenants: (state, action: PayloadAction<Tenant[]>) => {
      state.tenants = action.payload;
    },
    setInitializationError: (state, action: PayloadAction<SharedState['initializationError']>) => {
      state.initializationError = action.payload;
    },
    initialized: (state) => {
      state.initialized = true;
    },
    showNotification: (state, action: PayloadAction<NotificationPayload>) => {
      state.notification = { ...action.payload, framework: action.payload.framework ?? 'Antd' };
    },
    hideNotification: (state) => {
      state.notification = undefined;
    },
    setIpLocation: (state, action: PayloadAction<GetIpLocationResponse>) => {
      state.ipLocation = action.payload;
    },
    setDisplayPath: (state, action: PayloadAction<DisplayPath | null | 'Skeleton'>) => {
      if (action.payload && action.payload !== 'Skeleton') {
        action.payload.breadcrumbs = action.payload.breadcrumbs ?? [];
        if (!action.payload.disableAutoLastBreadcrumb) {
          action.payload.breadcrumbs.push({
            title: action.payload.title,
            path: !action.payload.disableAutoLastBreadcrumbLink ? action.payload.path : undefined,
          });
        }
      }

      state.displayPath = action.payload;
    },
  },
});

export default sharedSlice;
