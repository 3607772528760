import Axios from 'axios';

import { AxiosInstanceData } from '../../../types';
import { isDevelopment, urlWithQuery } from '../../../utils/helpers';
import {
  GetHotelByIdRequest,
  GetHotelSearchRequest,
  HotelAvailabilityRq,
  HotelCancelRequest,
  HotelGetAllTenantReservesRequest,
  HotelGetCancellationPolicyRequest,
  HotelGetPreCancelRequest,
  HotelGetReserveByIdRequest,
  HotelPreReserveRequest,
  HotelReserveRequest,
} from '../apiTypes';
import { HotelApiDataMap, HotelApiUrls } from './hotel-apiData';

//import MockAdapter from 'axios-mock-adapter';

//import hotelMock from './hotel-mock';

//import hotelMock2 from './hotel-mock2';

const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_HotelV2ApiBaseUrl;

if (isDevelopment()) {
  //const mock = new MockAdapter(axiosInstance, { onNoMatch: 'passthrough', delayResponse: 500 });
  //hotelMock(mock);
  //hotelMock2(mock);
}

export const hotelGetLocationHttp = (data: { value: string }) => {
  const url = urlWithQuery(HotelApiUrls.GetLocation, data);
  return axiosInstance.get(url);
};

export const hotelGetSearchHttp = (data: GetHotelSearchRequest) => {
  const url = urlWithQuery(HotelApiUrls.GetSearch, data);
  return axiosInstance.get(url);
};

export const hotelAvailabilityHttp = (data: HotelAvailabilityRq) => {
  return axiosInstance.post(HotelApiUrls.Availability, data);
};

export const hotelGetAvailabilityHttp = (data: { key: string }) => {
  const url = urlWithQuery(HotelApiUrls.GetAvailability, data);
  return axiosInstance.get(url);
};

export const hotelGetByIdHttp = (data: GetHotelByIdRequest) => {
  const url = urlWithQuery(HotelApiUrls.GetHotelById, data);
  return axiosInstance.get(url);
};

export const hotelGetCancellationPolicyHttp = (data: HotelGetCancellationPolicyRequest) => {
  const url = urlWithQuery(HotelApiUrls.GetCancellationPolicy, data);
  return axiosInstance.get(url);
};

export const hotelPreReserveHttp = (data: HotelPreReserveRequest) => {
  return axiosInstance.post(HotelApiUrls.PreReserve, data);
};

export const hotelGetPreReserveByKeyHttp = (data: { preReserveKey: string }) => {
  const url = urlWithQuery(HotelApiUrls.GetPreReserveByKey, data);
  return axiosInstance.get(url);
};

export const hotelReserveHttp = (data: HotelReserveRequest) => {
  return axiosInstance.post(HotelApiUrls.Reserve, data);
};

export const hotelGetReserveByIdHttp = (data: HotelGetReserveByIdRequest) => {
  const url = urlWithQuery(HotelApiUrls.GetReserveById, data);
  return axiosInstance.get(url);
};

//14188 = Issue
//14189 = webservicecancel
//username= ramin.derakhshan@gmail.com
export const hotelBookHttp = (data: { reserveId: number; username: string }) => {
  return axiosInstance.post(HotelApiUrls.Book, data);
};

export const hotelGetPreCancelHttp = (data: HotelGetPreCancelRequest) => {
  const url = urlWithQuery(HotelApiUrls.GetPreCancel, data);
  return axiosInstance.get(url);
};

export const hotelCancelHttp = (data: HotelCancelRequest) => {
  return axiosInstance.post(HotelApiUrls.Cancel, data);
};

export const hotelGetAllTenantReservesHttp = (data: HotelGetAllTenantReservesRequest) => {
  const url = urlWithQuery(HotelApiUrls.GetAllTenantReserves, data);
  return axiosInstance.get(url);
};

export const hotelGetTenantReserveHttp = (data: { ReserveId: string; Username: string }) => {
  const url = urlWithQuery(HotelApiUrls.GetTenantReserve, data);
  return axiosInstance.get(url);
};

export const HotelAxiosData: AxiosInstanceData = {
  instance: axiosInstance,
  DataMap: {
    MapObject: HotelApiDataMap,
    Urls: HotelApiUrls,
  },
};
