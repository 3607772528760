import { Button, Col, Form, Modal, Radio, Row, Skeleton, Tag } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useAppSelector, useGlobalization, useHttpCall } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { profileSelector } from '../../../account';
import { FlightGetCancellationPoliciesResponse, FlightReserveItem, FlightSegmentModel } from '../../../flight/apiTypes';
import { getFlightCancellationPoliciesHttp } from '../../../flight/http/flight-http';
import { HotelGetPreCancelResponse, HotelReserveItem } from '../../../hotel/apiTypes';
import { hotelGetPreCancelHttp } from '../../../hotel/http/hotel-http';
import { AbpErrorAlert, FormattedCurrency } from '../../../shared';
import { RefundResponse } from '../../apiTypes';
import { paymentRefundHttp } from '../../http/payment-http';
import { paymentSlice } from '../../index';

type FormValues = {
  refundMethod: 'DepositToWallet' | 'DepositToAccount';
};

type Props = {
  modalVisibilityState: [boolean, Dispatch<SetStateAction<boolean>>];
  reserveData?: FlightReserveItem | HotelReserveItem;
  reserveId: number;
  username?: string;
  type: 'Hotel' | 'Flight';
  onSuccess?: (reserveId: number) => void;
  flightSegments?: FlightSegmentModel[];
};
const RefundModal: React.VFC<Props> = React.memo((props) => {
  const [modalVisibility, setModalVisibility] = props.modalVisibilityState;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { reversePositionOnRtl } = useGlobalization();

  // const pnrDetails = useAppSelector((state) => state.flight.temp.ticketing.pnrDetails);
  const profile = useAppSelector(profileSelector)!;
  const [cancellationPoliciesResponse, setCancellationPoliciesResponse] = useState<FlightGetCancellationPoliciesResponse | HotelGetPreCancelResponse>();
  const [refundResponse, setRefundResponse] = useState<RefundResponse>();
  const [step, setStep] = useState(0);

  const refundApi = useHttpCall(paymentRefundHttp);
  // const getFlightReserveByIdApi = useHttpCall(getFlightReserveByIdHttp);
  // const getHotelReserveByIdApi = useHttpCall(getHotelReserveByIdHttp);
  const getFlightCancellationPoliciesApi = useHttpCall(getFlightCancellationPoliciesHttp);
  const getHotelPreCancelApi = useHttpCall(hotelGetPreCancelHttp);

  const [form] = Form.useForm<FormValues>();

  const closeModal = () => {
    if (refundResponse) {
      props.onSuccess?.(props.reserveId);
    }
    setStep(0);
    setModalVisibility(false);
    setRefundResponse(undefined);
  };

  useEffect(() => {
    if (modalVisibility && step === 0) {
      if (props.type === 'Flight') {
        getFlightCancellationPoliciesApi
          .call<FlightGetCancellationPoliciesResponse>({ ReserveId: props.reserveId, Username: profile.userName })
          .then((response) => {
            if (response && response.success) {
              setCancellationPoliciesResponse(response.result);
              setStep(1);
            }
          });
      } else if (props.type === 'Hotel') {
        getHotelPreCancelApi.call<HotelGetPreCancelResponse>({ reserveId: props.reserveId.toString(), userName: props.username! }).then((response) => {
          if (response && response.success) {
            setCancellationPoliciesResponse(response.result);
            setStep(1);
          }
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisibility, step]);

  // const flights = useMemo(()=>{
  //   if (props.flightSegments){
  //     return {
  //       marketingAirline : props.flightSegments[0].marketingAirline
  //     }
  //   }
  // },[props.flightSegments]);

  const handleSubmit = (values: FormValues) => {
    refundApi.call<RefundResponse>({ method: values.refundMethod, reserveId: props.reserveId.toString(), username: profile.userName }).then((response) => {
      if (response && response.success) {
        setRefundResponse(response.result);
        dispatch(paymentSlice.actions.tempTriggerUpdateDepositBalances());
      }
    });
  };

  const CurrentStepComponent = () => {
    switch (step) {
      case 0:
        return Step0Component();
      case 1:
        return Step1Component();
      case 2:
        return Step2Component();
    }
  };

  const Step0Component = () => <Skeleton active />;

  const Step1Component = () => (
    <>
      {/*{props.flightSegments &&*/}
      {/*<Row align="middle" gutter={[20, 20]} className={'text-center'}>*/}
      {/*  {props.flightSegments[0].marketingAirline.photoUrl}*/}
      {/*  <Col>*/}
      {/*    <img src={props.flightSegments[0].marketingAirline.photoUrl} alt={props.flightSegments[0].marketingAirline.photoUrl} width="40" height="40" />*/}
      {/*  </Col>*/}
      {/*  <Col>*/}
      {/*    <b>London</b>*/}
      {/*    <div>2021-03-24 21:35</div>*/}
      {/*  </Col>*/}
      {/*  <Col>*/}
      {/*    <ArrowRightOutlined className={'mirror-in-rtl'} />*/}
      {/*  </Col>*/}
      {/*  <Col>*/}
      {/*    <b>Paris</b>*/}
      {/*    <div>2021-03-24 23:45</div>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      {/*}*/}
      <Tag color={cancellationPoliciesResponse!.isRefundable ? 'green' : 'red'}>
        {t(
          cancellationPoliciesResponse!.isRefundable ? Translations.Booking.CancellationPolicyRefundable : Translations.Booking.CancellationPolicyNonRefundable
        )}
      </Tag>
      {cancellationPoliciesResponse!.isRefundable ? (
        <Row className={'text-center my-6'}>
          <Col xs={12}>
            <div className={'text-muted'}>{t(Translations.Payment.RefundAmountName)}</div>
            <b>
              <FormattedCurrency
                number={cancellationPoliciesResponse!.refundAmount}
                currency={cancellationPoliciesResponse!.currencyType}
                currencyDisplay="code"
              />
            </b>
          </Col>
          <Col xs={12}>
            <div className={'text-muted'}>{t(Translations.Payment.DamageAmount)}</div>
            <b>
              <FormattedCurrency
                number={cancellationPoliciesResponse!.damageAmount}
                currency={cancellationPoliciesResponse!.currencyType}
                currencyDisplay="code"
              />
            </b>
          </Col>
        </Row>
      ) : (
        <div className="mt-2">{t(Translations.Payment.ReserveIsNotRefundable)}</div>
      )}

      {props.type === 'Flight' && (
        <div className="mt-2">
          <BootstrapAlert variant="warning">
            <span className="fas fa-exclamation-triangle" /> {t(Translations.Flight.RefundFlightWarningNote)}
          </BootstrapAlert>
        </div>
      )}
      <Row justify="end" gutter={[10, 0]} className={'mt-4 mb-0'}>
        <Col>
          <Button htmlType="button" onClick={() => closeModal()}>
            {t(Translations.Common.Close)}
          </Button>
        </Col>
        <Col>
          {cancellationPoliciesResponse!.isRefundable && (
            <Button type="primary" htmlType="button" onClick={() => setStep(2)}>
              {t(Translations.Common.Continue)}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );

  const Step2Component = () => (
    <>
      <h5 className="title-sm">{t(Translations.Payment.ChooseARefundMethod)}</h5>

      <Form.Item name="refundMethod">
        <Radio.Group className={`radio-with-description ${reversePositionOnRtl('left')}-aligned`}>
          <Radio value="DepositToWallet" className={'mb-4'}>
            {t(Translations.Payment.ChargeDeposit)}
            {/*<div className="text-muted radio-item-description mt-2">*/}
            {/*  <Trans i18nKey={Translations.Payment.ChargeDepositRefundOptionDescription} values={{ hours: 36 }} components={{ b: <b /> }} />*/}
            {/*</div>*/}
          </Radio>

          <Radio value="DepositToAccount" disabled>
            {t(Translations.Payment.DepositToBankAccount)} <Tag color="orange">{t(Translations.Common.NotAvailable)}</Tag>
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Row justify="end" gutter={[10, 0]} className="mt-4 mb-0">
        <Col>
          <Button htmlType="button" onClick={() => closeModal()}>
            {t(Translations.Common.Close)}
          </Button>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit" loading={refundApi.pending}>
            {t(Translations.Common.Submit)}
          </Button>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <Modal
        title={t(Translations.Payment.Refund)}
        visible={modalVisibility}
        closable
        footer={null}
        onCancel={() => {
          closeModal();
        }}>
        {getFlightCancellationPoliciesApi.error || getHotelPreCancelApi.error ? (
          <AbpErrorAlert error={getFlightCancellationPoliciesApi.error || getHotelPreCancelApi.error} />
        ) : (
          <>
            <AbpErrorAlert error={refundApi.error} />
            {!refundResponse ? (
              <Form<FormValues> form={form} onFinish={handleSubmit} initialValues={{ refundMethod: 'DepositToWallet' } as FormValues}>
                {CurrentStepComponent()}
              </Form>
            ) : (
              <div className="text-center text-success">{t(Translations.Common.YourRequestSubmittedSuccessfully)}</div>
            )}
          </>
        )}
      </Modal>

      {/*<Form>*/}
      {/*  <Form.Item*/}
      {/*    label="Card Number"*/}
      {/*    name="cardNumber"*/}
      {/*    rules={[*/}
      {/*      {*/}
      {/*        required: true,*/}
      {/*        message: 'Please input card number',*/}
      {/*      },*/}
      {/*      {*/}
      {/*        pattern: new RegExp(/^[0-9a-zA-Z ]*$/),*/}
      {/*        message: `card number is invalid`,*/}
      {/*      },*/}
      {/*    ]}>*/}
      {/*    <Input />*/}
      {/*  </Form.Item>*/}
      {/*  <Form.Item*/}
      {/*    label="Account Name"*/}
      {/*    name={'accountName'}*/}
      {/*    rules={[*/}
      {/*      {*/}
      {/*        required: true,*/}
      {/*        message: 'Please input your account name',*/}
      {/*      },*/}
      {/*      {*/}
      {/*        pattern: new RegExp(/^[a-zA-Zآ-ی ]*$/),*/}
      {/*        message: `Please enter account name using letters only`,*/}
      {/*      },*/}
      {/*    ]}>*/}
      {/*    <Input />*/}
      {/*  </Form.Item>*/}
      {/*</Form>*/}
      {/*<Row justify="end" gutter={[10, 0]} className={'mt-4 mb-0'}>*/}
      {/*  <Col>*/}
      {/*    <Button htmlType={'button'}>Close</Button>*/}
      {/*  </Col>*/}
      {/*  <Col>*/}
      {/*    <Button type={'primary'} htmlType={'button'}>*/}
      {/*      Submit*/}
      {/*    </Button>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </>
  );
});

export default RefundModal;
